import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import { keyOrder, keyLabels, disabledOptions } from "./Constants";
import Cookies from "js-cookie";
import {
  FETCH_MERCHANT_PROFILE,
  UPDATE_PROFILE,
  VERIFY_CALLBACK_URLS,
} from "../../Utils/Constants";
import useApiServices from "../../Utils/useApiService";

import { useSelector } from "react-redux";
import swal from "sweetalert";
import CallbackUrl from "../../Common/CallBackUrl/CallbackUrl";

/**
 * This component returns merchant Profile information.
 * @returns {ReactNode} this component returns information of merchant details.
 */

export default function Profile() {
  const { apiCallFnforPut, apiCallFnforPost, apiCallFnforGet } =
    useApiServices();
  const [merchantData, setMerchantData] = useState(null);
  const [callbacksVerifyOrNot, setCallbacksVerifyOrNot] = useState({
    callBackUrl: false,
    payoutCallbackUrl: false,
    chargebackCallbackUrl: false,
  });
  const [editmode, setEditMode] = useState(false);
  const { mid } = useSelector((state) => state.userData);

  const getProfileDetails = async () => {
    let res = await apiCallFnforGet(FETCH_MERCHANT_PROFILE + mid);
    if (res?.statusCode === 200) {
      setMerchantData(res?.data);
      setCallbacksVerifyOrNot({
        callBackUrl: res?.data?.callBackUrlStatus,
        payoutCallbackUrl: res?.data?.payoutCallbackUrlStatus,
        chargebackCallbackUrl: res?.data?.chargebackCallbackUrlStatus,
      });
      // DefaultFunForVerify(res?.data);
    } else {
      setMerchantData(null);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const EditProfile = () => {
    setEditMode(true);

    if (editmode) {
      handleEditProfile();
    }
  };

  // const DefaultFunForVerify = async (merchantData) => {
  //   for (const [key, value] of Object.entries(merchantData)) {
  //     if (key === "callBackUrl" || key === "payoutCallbackUrl" || key === "chargebackCallbackUrl") {
  //       console.log("called for" + key);

  //       try {
  //         const res = await apiCallFnforPost(VERIFY_CALLBACK_URLS, {
  //           url: value,
  //         });

  //         if (res) {
  //           setCallbacksVerifyOrNot((prevState) => ({
  //             ...prevState,
  //             [key]: true,
  //           }));
  //         } else {
  //           setCallbacksVerifyOrNot((prevState) => ({
  //             ...prevState,
  //             [key]: false,
  //           }));
  //         }
  //       } catch (error) {
  //         console.error(error.message);
  //       }
  //     }
  //   }
  // };

  const handleInputChange = (e, key) => {
    setMerchantData({
      ...merchantData,
      [key]: e.target.value,
    });

    // if (key === "callBackUrl" || key === "payoutCallbackUrl" || key === "chargebackCallbackUrl") {
    //   handleUrlVerify(e.target.value, key);
    // }
  };

  const handleUrlVerify = async (value, key) => {
    try {
      const res = await apiCallFnforPost(VERIFY_CALLBACK_URLS, { url: value });

      if (res) {
        setCallbacksVerifyOrNot((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      } else {
        setCallbacksVerifyOrNot((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEditProfile = async () => {
    const payload = {
      ...merchantData,
      callBackUrlStatus: callbacksVerifyOrNot?.callBackUrl,
      payoutCallbackUrlStatus: callbacksVerifyOrNot?.payoutCallbackUrl,
      chargebackCallbackUrlStatus: callbacksVerifyOrNot?.chargebackCallbackUrl,
    };
    try {
      const resp = await apiCallFnforPut(UPDATE_PROFILE, payload);

      if (resp?.statusCode !== 200) {
        swal({
          icon: "error",
          text: resp?.status,
        });
        return;
      }
      setEditMode(false);
      getProfileDetails();

      swal({
        icon: "success",
        text: resp?.status,
      });
    } catch (error) {
      console.log("error::", error);
    }
  };

  return (
    <>
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Profile Details</h4>
                </div>
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      <form className="form-section-form-group">
                        <div className="row">
                          {merchantData && typeof merchantData === "object" ? (
                            keyOrder.map((key, index) => (
                              <div className="col-lg-6 mb-2" key={index}>
                                <div className="mb-3">
                                  <label className="mb-1 text-dark">
                                    {keyLabels[key]}
                                  </label>
                                  <div className="d-flex align-items-center gap-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={merchantData[key] || ""}
                                      readOnly={!editmode}
                                      disabled={disabledOptions[key]}
                                      required={true}
                                      // onBlur={
                                      //   key === "callBackUrl" ||
                                      //   key === "payoutCallbackUrl" ||
                                      //   key === "chargebackCallbackUrl"
                                      //     ? () =>
                                      //         handleUrlVerify(
                                      //           merchantData[key],
                                      //           key
                                      //         )
                                      //     : null
                                      // }
                                      onChange={(e) =>
                                        handleInputChange(e, key)
                                      }
                                    />
                                    {(key === "callBackUrl" ||
                                      key === "payoutCallbackUrl" ||
                                      key === "chargebackCallbackUrl") &&
                                    !callbacksVerifyOrNot[key] ? (
                                      <div
                                        className="badge badge-primary "
                                        onClick={() =>
                                          handleUrlVerify(
                                            merchantData[key],
                                            key
                                          )
                                        }
                                      >
                                        Verify
                                      </div>
                                    ) : null}
                                  </div>
                                  {key === "callBackUrl" ||
                                  key === "payoutCallbackUrl" ||
                                  key === "chargebackCallbackUrl" ? (
                                    <p
                                      className={
                                        callbacksVerifyOrNot[key]
                                          ? "text-primary"
                                          : "text-danger"
                                      }
                                    >
                                      {callbacksVerifyOrNot[key]
                                        ? "Verified"
                                        : "Not Verified"}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                          <div className="col-lg-6 mb-2 mt-1">
                            <div className="mb-3">
                              <label className="mb-1 text-dark">
                                Payment Services
                              </label>

                              <div className="mt-3 vertical-table-cell-value text-left d-flex flex-wrap" />
                              {merchantData?.paymentService?.map(
                                (service, i) => {
                                  return (
                                    <Chip
                                      key={i + 1}
                                      label={service.paymentService}
                                      variant="outlined"
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="d-flex m-3">
                  {editmode ? (
                    <button
                      className="btn btn-primary me-2"
                      onClick={handleEditProfile}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2"
                      onClick={EditProfile}
                    >
                      Edit
                    </button>
                  )}
                  {editmode && (
                    <button
                      className="btn btn-danger"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
