import React from "react";
import {
  ShimmerTable,
  ShimmerCircularImage,
  ShimmerTitle,
  ShimmerBadge,
  ShimmerThumbnail,
  ShimmerCategoryList,
} from "react-shimmer-effects";

const Shimmer = ({ size, type, line, gap }) => {
  return (
    <div>
      {type === "circle" ? (
        <div className="d-flex justify-content-evenly gap-5">
          <ShimmerCircularImage size={size} />
          <div className="w-50 row gap-2">
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/> 
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          <ShimmerBadge className="col-4 col-md-4 col-lg-4 col-xl-4" width={120}/>
          
          </div>
        </div>
      ) : type === "table" ? (
        <ShimmerTable row={5} col={5} />
      ) : type === "list" ? (
        <ShimmerTitle line={line} gap={gap} variant="primary" />
      ) : type === "card" ? (
        <ShimmerThumbnail height={200} gap={20}   />
      ) : type === "otherDetails" ? (
        <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />
      ) : null}
    </div>
  );
};

export default Shimmer;
