import React from "react";
import ReactDOM from "react-dom";

const Modal = ({
  title,
  children,
  onCancel,
  Width,
  onSave,
  handleClose,
  id,
  onSubmit,
  footer,
}) => {
  const getWidthClassName = (Width) => {
    switch (Width) {
      case "xs":
        return "modal-xs";
      case "sm":
        return "modal-sm";
      case "lg":
        return "modal-lg";
      case "xl":
        return "modal-xl";
      default:
        return ""; // Default to empty string for no specific width
    }
  };

  return (
    <div
      className="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      id={id}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${getWidthClassName(
          Width
        )}`}
      >
        <div class="modal-content">
          <div className="modal-header bg-primary  rounded-2 ">
            <h3 className="modal-title">{title}</h3>
            <button
              type="button"
              class="btn-close"
              onClick={handleClose}
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">{children}</div>
          {footer && (
            <div class="modal-footer">
              <button
                id="close_Btn"
                type="button"
                class="btn btn-danger light"
                data-bs-dismiss="modal"
                // onClick={handleClose}
              >
                Close
              </button>
              <button
                onClick={() => onSubmit(title)}
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
