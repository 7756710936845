import React, { useEffect, useState } from "react";
import { keywords } from "../../Utils/ContantKeys";
import { useDispatch } from "react-redux";
import {
  setReportType,
  setTransactionStatusObject,
} from "../../store/Slices/reportFilterSlice";


/**
 * This component renders a selection of report type.
 *
 * @param {function} callDispatch - redux action function to save report type in redux store.
 * @returns {ReactNode} - Component returns select option for reports types.
 */

const ReportType = ({ callDispatch }) => {
  const [transectionStatus, setTransectionStatus] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setReportType(transectionStatus));
  }, [callDispatch]);

  return (
    <fieldset className="border-2 rounded-0 mb-2">
      <legend className="text-start text-black" >
        Report Type
      </legend>
      <div className="">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <select
                className="form-control fs-4"
                value={transectionStatus}
                onChange={(e) => setTransectionStatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Payin">Payin</option>
                <option value="Payout">Payout</option>
                <option value="Withdraw">Withdraw</option>
                <option value="Settlement">Settlement</option>
                <option value="Chargeback">Chargeback</option>
                <option value="Reconciliation">Reconciliation</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default ReportType;
