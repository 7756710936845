import React from "react";
import useCheckPrivilages from "./useCheckPrivilages";

const CheckExportConst = () => {
  const hasPrivilagesPayin = useCheckPrivilages(180867);
  const hasPrivilagesSettlement = useCheckPrivilages(180868);
  const hasPrivilagesChargeback = useCheckPrivilages(180869);
  const hasPrivilagesMyVendor = useCheckPrivilages(180870);
  const hasPrivilagesPayout = useCheckPrivilages(180871);
  const hasPrivilagesWithdraw = useCheckPrivilages(180872);
  const hasPrivilagesDeposit = useCheckPrivilages(180866);
  const hasPrivilagesHold = useCheckPrivilages(180865);
  const hasPrivilagesWalletBalance = useCheckPrivilages(180864);
  const hasPrivilagesRelease = useCheckPrivilages(180863);
  const hasPrivilagesRecon = useCheckPrivilages(180803);
  const hasPrivilagesCredit = useCheckPrivilages(180862);
  const hasPrivilagesGuarantee = useCheckPrivilages(180802);
  const hasPrivilagesLadger = useCheckPrivilages(180801);
  const hasPrivilagesEkyc = useCheckPrivilages(181364);
  const hasPrivilagesWAccount = useCheckPrivilages(180738);
  const hasPrivilagesWPayout = useCheckPrivilages(180739);
  const hasPrivilagesPendingWV = useCheckPrivilages(180743);
  const hasPrivilagesPTCustomer = useCheckPrivilages(180745);
  const hasPrivilagesPTVendor = useCheckPrivilages(180746);
  const hasPrivilagesViewPayoutReq = useCheckPrivilages(180747);
  const hasPrivilagesPayoutTxnStatus = useCheckPrivilages(180748);
  const hasPrivilagesRegisterVendor = useCheckPrivilages(180752);
  const hasPrivilagesTxnCallback = useCheckPrivilages(180753);
  const hasPrivilagesTxnStatusCheck = useCheckPrivilages(180754);
  const hasPrivilagesPayoutTxnCallback = useCheckPrivilages(180755);
  const hasPrivilagesSubmittedVendor = useCheckPrivilages(180756);
  const hasPrivilagesIMPS = useCheckPrivilages(180758);
  const hasPrivilagesUPI = useCheckPrivilages(180759);
  const hasPrivilagesWallet = useCheckPrivilages(180760);
  const hasPrivilagesQR = useCheckPrivilages(180762);
  const hasPrivilagesVT = useCheckPrivilages(180763);
  const hasPrivilagesLBP = useCheckPrivilages(180764);
  const hasPrivilagesTandP = useCheckPrivilages(180765);
  const hasPrivilagesRefferelLink = useCheckPrivilages(180767);
  const hasPrivilagesMYRefferels = useCheckPrivilages(180768);
  const hasPrivilagesInterest = useCheckPrivilages(180769);
  const hasPrivilagesFRM = useCheckPrivilages(180844);
  const hasPrivilagesSecurityManage = useCheckPrivilages(180858);
  const hasPrivilagesSSettlement = useCheckPrivilages(180859);
  const hasPrivilagesNotification = useCheckPrivilages(180860);
  const hasPrivilagesSWithdraw = useCheckPrivilages(180861);

  const reportPrivilages =
    hasPrivilagesPayin ||
    hasPrivilagesSettlement ||
    hasPrivilagesGuarantee ||
    hasPrivilagesLadger ||
    hasPrivilagesRecon ||
    hasPrivilagesChargeback ||
    hasPrivilagesMyVendor ||
    hasPrivilagesPayout ||
    hasPrivilagesWithdraw ||
    hasPrivilagesDeposit ||
    hasPrivilagesHold ||
    hasPrivilagesCredit ||
    hasPrivilagesWalletBalance ||
    hasPrivilagesEkyc ||
    hasPrivilagesRelease;

  const WithdrawPrivilages =
    hasPrivilagesPendingWV || hasPrivilagesWPayout || hasPrivilagesWAccount;
  const SettingPrivilages =
    hasPrivilagesSecurityManage ||
    hasPrivilagesSSettlement ||
    hasPrivilagesNotification ||
    hasPrivilagesSWithdraw ||
    hasPrivilagesFRM;
  const PayoutReqPrivilages =
    hasPrivilagesPTVendor ||
    hasPrivilagesPTCustomer ||
    hasPrivilagesViewPayoutReq ||
    hasPrivilagesPayoutTxnStatus;
  const AddWalletBalancePrivilages =
    hasPrivilagesIMPS || hasPrivilagesUPI || hasPrivilagesWallet;
  const refferelPrivilages =
    hasPrivilagesMYRefferels ||
    hasPrivilagesRefferelLink ||
    hasPrivilagesInterest;
  const PaymentsPrivilages =
    hasPrivilagesTandP ||
    hasPrivilagesLBP ||
    hasPrivilagesQR ||
    hasPrivilagesVT;
  const ToolsPrivilages =
    WithdrawPrivilages ||
    PayoutReqPrivilages ||
    AddWalletBalancePrivilages ||
    hasPrivilagesTxnCallback ||
    hasPrivilagesTxnStatusCheck ||
    hasPrivilagesRegisterVendor ||
    hasPrivilagesPayoutTxnCallback ||
    hasPrivilagesSubmittedVendor;

  return {
    hasPrivilagesPayin,
    hasPrivilagesWAccount,
    hasPrivilagesSettlement,
    hasPrivilagesGuarantee,
    hasPrivilagesFRM,
    hasPrivilagesLadger,
    hasPrivilagesRecon,
    hasPrivilagesChargeback,
    hasPrivilagesMyVendor,
    hasPrivilagesPayout,
    hasPrivilagesWithdraw,
    hasPrivilagesWPayout,
    hasPrivilagesPendingWV,
    hasPrivilagesWAccount,
    hasPrivilagesDeposit,
    hasPrivilagesHold,
    hasPrivilagesCredit,
    hasPrivilagesWalletBalance,
    hasPrivilagesRelease,
    hasPrivilagesEkyc,
    hasPrivilagesPTVendor,
    hasPrivilagesPTCustomer,
    hasPrivilagesViewPayoutReq,
    hasPrivilagesRegisterVendor,
    hasPrivilagesTxnCallback,
    hasPrivilagesTxnStatusCheck,
    hasPrivilagesPayoutTxnCallback,
    hasPrivilagesSubmittedVendor,
    hasPrivilagesWallet,
    hasPrivilagesUPI,
    hasPrivilagesIMPS,
    hasPrivilagesTandP,
    hasPrivilagesLBP,
    hasPrivilagesQR,
    hasPrivilagesVT,
    hasPrivilagesMYRefferels,
    hasPrivilagesRefferelLink,
    hasPrivilagesInterest,
    hasPrivilagesSecurityManage,
    hasPrivilagesSSettlement,
    hasPrivilagesNotification,
    hasPrivilagesSWithdraw,
    SettingPrivilages,
    refferelPrivilages,
    PaymentsPrivilages,
    AddWalletBalancePrivilages,
    reportPrivilages,
    WithdrawPrivilages,
    PayoutReqPrivilages,
    ToolsPrivilages,
  };
};

export default CheckExportConst;
