export const keyOrder = [
    "companyName",
    "mid",
    "registrationType",
    "companyType",
    "otherBusinessCategory",
    "businessType",
    "businessAs",
    "merchantBankName",
    "merchantAccountNumber",
    "merchantIfscCode",
    "companyPanNumber",
    "gstNumber",
    "authorizedPersonFirstName",
    "email",
    "mobile",
    "authorizedPersonPan",
    "aadharNumber",
    "userName",
    "encKey",
    "apiKey",
    "secretKey",
    "callBackUrl",
    "payoutCallbackUrl",
    "chargebackCallbackUrl",
    "companyAddress",
    "companyAddressCity",
    "companyAddressPinCode",
    "companyAddressState",
    "registeredOfficeAddress",
    "registeredOfficeAddressPinCode",
    "registeredOfficeAddressCity",
    "registeredOfficeAddressState",
    "communicationAddress",
    "communicationAddressPinCode",
    "communicationAddressCity",
    "communicationAddressState",
    "websiteUrl",
    "appUrl"
];

export const disabledOptions = {
  "companyName":"companyName",
  "mid":"mid",
  "registrationType":"registrationType",
  "companyType":"companyType",
  "otherBusinessCategory":"otherBusinessCategory",
  "businessType":"businessType",
  "companyPanNumber":"companyPanNumber",
  "gstNumber":"gstNumber",
  "authorizedPersonPan":"authorizedPersonPan",
  "aadharNumber":"aadharNumber",
  "userName":"userName",
  "encKey":"encKey",
  "apiKey":"apiKey",
  "secretKey":"secretKey"
}


 export const keyLabels = {
    companyName: "Company Name",
    mid: "Merchant ID",
    registrationType: "Registration Type",
    companyType: "Business Type ",
    otherBusinessCategory: "Other Business Category",
    businessType: "Business Category",
    businessAs: "Business As",
    merchantBankName: "Bank Name",
    merchantAccountNumber: "Account Number",
    merchantIfscCode: "IFSC Code",
    companyPanNumber: "Company PAN Number",
    gstNumber: "GST Number",
    authorizedPersonFirstName: "Authorized Person Name",
    email: "e-Mail ID",
    mobile: "Mobile Number",
    authorizedPersonPan: "Authorized Person PAN",
    aadharNumber: "Aadhaar Number",
    userName: "Username",
    encKey: "Payin Encryption Key",
    apiKey: "Payout API Key",
    secretKey: "Payout Secret Key",
    // payinCallbackUrl: "Payin   URL",
    payoutCallbackUrl: "Payout CallBack URL",
    chargebackCallbackUrl: "Chargeback CallBack URL",
    websiteUrl: "Website URL",
    appUrl:"App URL",
    registeredOfficeAddress: "Authorized Signatory Address",
    registeredOfficeAddressPinCode: "Pin Code",
    registeredOfficeAddressCity: "City",
    registeredOfficeAddressState: "State",
    communicationAddress: "Company Communication Address",
    communicationAddressPinCode: "Pin Code",
    communicationAddressCity: "City",
    communicationAddressState: "State",
    paymentServices: "Payment Services",
    callBackUrl: "Payin Callback URL",
    payoutCallbackUrl: "Payout Callback URL",
    // ChargebackCallbackUrl: "Chargeback CallbackUrl",
    AuthorizeSignatureAddress: "Authorize Signature Address",
    Pincode:"PIN Code",
    State:"State",
    City:"City",
    companyAddress:"Company Address",
    companyAddressCity:"Company Address City",
    companyAddressPinCode: "Company Address PinCode",
    companyAddressState: "Company Address State"

  };