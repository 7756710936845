import React from 'react'
import { config, getCompleteDomain, getServerEnvironment } from '../Utils/DynamicPortalFunctions';

/**
 * LeftPageLogIn component renders title, image etc in login page.
 * @returns {ReactNode} A React element that renders title, image etc in login page.
 */
const LeftPageLogIn = () => {
    
  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));
  return (
    <div className="col-xl-6 col-lg-6">
            <div className="pages-left h-100" >
              <div className="d-flex flex-column align-items-center">
                <img
                  src={logoPath}
                  className="mt-5"
                  style={{
                    width: "auto",
                    maxHeight: "80px",
                    objectFit: "contain",
                  }}
                  alt="logo"
                />
                <p className="fs-3">Welcome to {Name}</p>
              </div>

              <div className="login-media text-center">
                <img src="images/login.png" alt="png" />
              </div>
            </div>
          </div>
  )
}

export default LeftPageLogIn