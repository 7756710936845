import React, { useEffect, useState } from "react";
import {
  isEmailIdRegexIsValid,
  isVpaUpiRegexIsValid,
} from "../../Utils/validations";
import { jwtDecode } from "jwt-decode";
 
import { FIND_VENDOR_OR_CUSTOMER, SWIPELINC_API } from "../../Utils/Constants";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import useApiServices from "../../Utils/useApiService";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Filter = ({handleclose,setApiResponse}) => {
  const [searchOption, setSearchOption] = useState("email");
  const [searchValue, setSearchValue] = useState("");
  const [active, setActive] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [reportType, setReportType] = useState("");
  const [searchCustomerOrVendor, setSearchCustomerOrVendor] = useState("");

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);
  const { mid } = useSelector((state) => state.userData);
  const [errorText, setErrorText] = useState("");

  const {apiCallFnforPost}=useApiServices()

  const handleToolsButtonClick = (type, buttonNumber) => {
    setActive(type);
    setActiveButton(buttonNumber);
    setReportType(type);
  };
 

  function getSearchFunctionText(active) {
    let searchFunctionText;

    if (active === "Customer") {
      setSearchCustomerOrVendor("findCustomerInPayin");
    } else if (active === "Vendor") {
        setSearchCustomerOrVendor("getAllVender");
      
    }

    return searchFunctionText;
  }
  useEffect(() => {
    getSearchFunctionText(active)
  }, [active])
  

  const payload = {
    mid,
    email: searchOption === "email" ? searchValue.trim() : "",
    mobile: searchOption === "mobile" ? searchValue.trim() : "",
    vpa: searchOption === "payerVPA" ? searchValue.trim() : "",
  };

  const findAllVendors = async () => {
    if(searchCustomerOrVendor===""){
      swal("Alert!", "Please Select Customer Or Vendor.", "warning");
      return;
    }
    try {
      const response = await apiCallFnforPost(
        FIND_VENDOR_OR_CUSTOMER+searchCustomerOrVendor,
        payload
      );
  
      if(response.statusCode===200){
        setApiResponse(response.data.map((item,i)=>{
            return {
                id:i,
                ...item
            }
        }));

      }else{
        document.getElementById("")
        setSearchValueError("Not Found");
      }
    } catch (error) {
      console.log(error)
    }
  };
  
  const handleInputChange = (e) => {
    const newInputValue = e.target.value;
    setIsSearchValueError(false);
    setSearchValueError("");

    // document.getElementById("searchBtn").disabled = false;
    if (newInputValue.includes(" ")) {
      setErrorText("Input cannot contain spaces.");
      return;
    }

    if (searchOption === "mobile") {
      const numericInput = /^\d*$/.test(newInputValue);
      if (numericInput) {
        setSearchValue(newInputValue.substring(0, 10)); // Only allow up to 10 digits
        setErrorText("");
      } else {
        setErrorText("Input must be a number.");
      }
    } else if (searchOption === "email" || searchOption === "payerVPA") {
      setSearchValue(newInputValue);
      setErrorText("");
    }
  };

  const handelValidations = (e) => {
    if (searchOption === "payerVPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid VPA.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }

    if (searchOption === "email") {
      if (!isEmailIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Email Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
  };

  const {
    PayoutReqPrivilages,
    hasPrivilagesPTVendor,
    hasPrivilagesPTCustomer,
    hasPrivilagesViewPayoutReq,
    hasPrivilagesPayoutTxnStatus,
  } = CheckExportConst();

  return (
    <>
      <div className="">
        <ul className="d-flex gap-3 mb-2">
          {hasPrivilagesPTCustomer && (
            <li
              className={activeButton === 1 ? "active" : ""}
              onClick={() => handleToolsButtonClick("Customer", 1)}
            >
              <div
                className={`btn ${
                  activeButton === 1 ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                Customer
              </div>
            </li>
          )}
          {hasPrivilagesPTVendor && (
            <li
              className={activeButton === 2 ? "active" : ""}
              onClick={() => handleToolsButtonClick("Vendor", 2)}
            >
              <div
                className={`btn ${
                  activeButton === 2 ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                Vendor
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="">
        <div className="filter-field-area">
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={searchOption}
              onChange={(e) => {
                setSearchOption(e.target.value);
                setSearchValue("");
                setSearchValueError("");
                setErrorText("");
              }}
            >
              <option className="justify-content-between" value="email">
                e-Mail
              </option>
              <option value="mobile">Mobile</option>
              <option value="payerVPA">VPA / UPI ID </option>
            </select>
          </div>
          <div className="form-group mb-2">
            <input
              type={searchOption === "mobile" ? "tel" : "text"}
              className="advanced-input-box form-control"
              value={searchValue}
              onChange={(e) => handleInputChange(e)}
              onBlur={handelValidations}
              placeholder={
                searchOption === "email"
                  ? "Enter e-Mail"
                  : searchOption === "payerVPA"
                  ? "Enter Payer VPA"
                  : searchOption === "mobile"
                  ? "Enter Mobile"
                  : ""
              }
            />
            {searchValueError !== "" ? (
              <span id="searchValErr" className=" text-danger">
                {`${searchValueError}`}
              </span>
            ) : (
              ""
            )}
            {errorText !== "" ? (
              <p className="text-red" >{errorText}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="mt-2 d-flex justify-content-end gap-1">
        <div
          id="closeBtnPR"
          className="btn btn-sm btn-outline-danger"
          data-bs-dismiss="modal"
           
          // onClick={()=>handleclose("closeBtnPR")}
        >
          Close
        </div>
        <div className="btn btn-sm btn-primary" onClick={findAllVendors}>
          Submit
        </div>
      </div>
    </>
  );
};

export default Filter;
