import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useLoginForm } from "../Services/LogInService";
import { Link } from "react-router-dom";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../Utils/DynamicPortalFunctions";
import Modal from "../Common/Modal/Modal";
import ForgotPassword from "./ForgotPassword";
import LeftPageLogIn from "./LeftPageLogIn";
import Cookies from "js-cookie";
import useApiServices from "../Utils/useApiService";

/**
 * LogIn component renders login page.
 * @returns {ReactNode} A React element that renders login page.
 */
function LogIn() {
  const {
    userName,
    setuserName,
    password,
    setPassword,
    error,
    errorMessages,
    handleSubmit,
    isFormSubmitted,
    showPopUp,
    setShowPopUp,
  } = useLoginForm();

  const { LogOutAPI } = useApiServices();
  const token = Cookies?.get("access_token");

  useEffect(() => {
    if (token) {
      LogOutAPI();
    } else {
      console.log("No token Available");
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  // const {logoPath,Name}=config(getServerEnvironment(getCompleteDomain()))
  // const AppNameCapitalized=Name
  // const AppName = AppNameCapitalized.toUpperCase()
  if (showPopUp) {
    swal({
      title: "ALERT!",
      text: "Your Application Is Under Process!!",
      icon: "warning",
      timer: 2000,
    });
    setShowPopUp(!showPopUp);
  }

  return (
    <div className="authincation h-100">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
            <div className="login-form">
              <div className="text-center">
                <h3 className="title">Log In</h3>
                <p>Welcome back! Please log in to your account.</p>
              </div>
              {isFormSubmitted && (!userName || !password) && (
                <div className="error-message alert alert-danger mb-4">
                  Please enter username and password
                </div>
              )}
              {error && (
                <p className="alert alert-danger mb-4">
                  {error ? "Something Went Wrong" : ""}
                </p>
              )}
              {errorMessages?.map((errorMessage, index) => (
                <p key={index} className="errorMsg alert alert-danger mb-4">
                  {errorMessage}
                </p>
              ))}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="mb-1 text-dark">Username</label>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => setuserName(e.target.value)}
                    className="form-control form-control"
                    placeholder="username or e-Mail"
                  />
                </div>
                <div className="mb-4 position-relative">
                  <label className="mb-1 text-dark">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="dlab-password"
                    className="form-control form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="password"
                  />
                  <span className="eye">
                    <i
                      className={
                        !showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  </span>
                </div>
                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="mb-4">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#fg-password"
                      className="btn-link text-primary cursor-pointer"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <Modal id="fg-password" title={"Forgot Password"}>
                    <ForgotPassword />
                  </Modal>
                </div>
                <div className="text-center mb-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Log In
                  </button>
                </div>
                <h6 className="login-title">
                  <span>Or</span>
                </h6>
                <div className="text-center mb-4">
                  <Link to="/MerchantOnBoard">
                    <button className="btn btn-secondary btn-block">
                      Sign Up
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <LeftPageLogIn />
        </div>
      </div>
    </div>
  );
}

export default LogIn;
