import React, { useState } from "react";
import styles from "./Settings.module.css";
import SlideBox from "../../Common/SlideBox/SlideBox";
// import WithdrawInPayout from "./WithdrawInPayout";
// import RegisterVendor from "./RegisterVendor";
// import TransactionCallback from "./TransactionCallback";
// import TxnStatusCheck from "./TxnStatusCheck";
import FraudManagement from "./Fraud/FraudManagement";
import { settingicons } from "../../Assets/images/image";
import SubmittedUser from "../../UserManagement/SubmittedUser";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import { useSelector } from "react-redux";

/**
 * 
 * @returns {ReactNode} this component renders merchant settings.
 */

const Settings = () => {
  const settingType = {
    fraudManagement: "fraudManagement",
    userManagement: "userManagement",
    securityManagement: "securityManagement",
    withdraw: "withdraw",
  };

  const [flag, setFlag] = useState(false);
  const [setting, setSettting] = useState("");

  const toggleRightBar = (type) => {
    if (type) {
      setSettting(type);
    }
    setFlag(!flag);
  };

  const {
    SettingPrivilages,
    hasPrivilagesSecurityManage,
    hasPrivilagesSSettlement,
    hasPrivilagesNotification,
    hasPrivilagesSWithdraw,
    hasPrivilagesFRM,
  } = CheckExportConst();

  const { userType } = useSelector((state) => state.privilages);

  return (
    <div className={`content-body ${styles["minHeight"]}`}>
      <div className="container-fluid">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
            <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {settingType[setting] === "fraudManagement" && (
                <FraudManagement tool={setting} />
              )}
              {settingType[setting] === "userManagement" && <SubmittedUser />}
              {/* {settingType[tool] === "securityManagement" && ( */}
              {/* <TransactionCallback /> */}
              {/* )} */}
              {/* {settingType[tool] === "withdraw" && <TxnStatusCheck />} */}
            </SlideBox>
            <div class="row text-center text-lg-start">
              {hasPrivilagesSWithdraw ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("withDraw")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={settingicons.withdrawManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Withdraw Amount
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesFRM ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("fraudManagement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={settingicons.fraudManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Fraud Management
                    </h5>
                  </div>
                </div>
              ) : null}

              {userType ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("userManagement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={settingicons.userManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      User Management
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesSecurityManage ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("securityManagement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={settingicons.securityManagement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Security Management
                    </h5>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
