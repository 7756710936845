import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { AVAILABLE_BALANCE_UPI_IMPS_WALLET } from "../../Utils/Constants";
import useApiServices from "../../Utils/useApiService";
import { useSelector } from "react-redux";

function BalanceComponent({ isShowWalletBalance, isShowIMPSOrUPI, getReq }) {
  const [showLoader, setShowLoader] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    upiAmount: "",
    impsAmount: "",
  });
  const { mid } = useSelector((state) => state.userData);

  const { apiCallFnforGet } = useApiServices();

  const getBalance = async () => {
    setShowLoader(true);

    try {
      const response = await apiCallFnforGet(
        AVAILABLE_BALANCE_UPI_IMPS_WALLET + mid
      );
      if (response.statusCode === 200) {
        setApiResponse(response.data);
      } else if (response.statusCode === 304) {
        setApiResponse({ upiAmount: "", impsAmount: "" });
      } else {
        console.error("API Call Failed");
        // Handle failure
      }
    } catch (error) {
      console.log(error.message);
      // Handle error
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, [getReq]); // Make the API call when the component mounts

  return (
    <div className="d-flex align-items-center">
      {showLoader ? (
        <p>Loading...</p>
      ) : (
        <>
          <h4 class="m-2">Payout Balance (UPI): {apiResponse?.upiAmount}</h4>
          <h4 class="m-2">Payout Balance (IMPS): {apiResponse?.impsAmount}</h4>
        </>
      )}
    </div>
  );
}

export default BalanceComponent;
