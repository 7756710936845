import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { keywords } from "../../Utils/ContantKeys";
import { useDispatch } from "react-redux";
import { setDateObject } from "../../store/Slices/reportFilterSlice";
import swal from "sweetalert";


/**
 * This component is resusable Date selector component .
 *
 * @param {string} type- it is type of report.
 * @param {string} callDispatch-  it is redux action dispatch function which save date selected value in redux
 * @returns {ReactNode} A React elemement that renders reusable component for Date.
 */

const DateFilter = ({ type, callDispatch }) => {
  const [searchOption2, setSearchOption2] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchOption2("");
    setSearchValue2("");
    setStartDate("");
    setEndDate("");
  }, []);

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handleFromToDateInputChange = (from, to) => {
    if (from == to) {
      swal("Warning!", "Start Date and End Date must be different.", "warning");
      return;
    }

    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(
      setDateObject({ searchOption2, searchValue2, startDate, endDate })
    );
  }, [callDispatch]);

  return (
    <>
      <fieldset className="border-2 rounded-0">
        <div className=" ">
          <div className="col align-item-start">
            <legend className="text-start text-black" >
              Select Transaction Date
            </legend>
            <div className="row">
              <div className="mb-3 col-md-6">
                <select
                  className="form-control fs-4"
                  value={searchOption2}
                  onChange={(e) => setSearchOption2(e.target.value)}
                  style={{ paddingRight: '2rem' }}
                >
                  <option value="">Select</option>
                  <option value="date">Date</option>
                  <option value="dateInBetween">Date In Between</option>
                   
                
                </select>
              </div>
              {searchOption2 !== "dateInBetween" ? (
                <div className="mb-3 col-md-6">
                  <div className="form-group">
                    <input
                      className="advanced-input-box form-control fs-4"
                      type="date"
                      value={searchValue2}
                      onChange={(e) => {
                        if (handleDateInputChange(e)) {
                          setSearchValue2("");

                          document
                            .getElementById("dateErr")
                            .classList.remove("d-none");
                        } else {
                          document
                            .getElementById("dateErr")
                            .classList.add("d-none");
                          setSearchValue2(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      placeholder={searchOption2 === "date" ? "Enter Date" : ""}
                    />
                    <span id="dateErr" className=" text-danger d-none">
                      *Date must be lesser than current date.
                    </span>
                  </div>
                </div>
              ) : (
                <div className="mb-3 col-md-6 ">
                  <div className="form-group">
                    <input
                      className="advanced-input-box form-control"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        if (handleDateInputChange(e)) {
                          setStartDate("");
                          document
                            .getElementById("startDateErr")
                            .classList.remove("d-none");
                        } else {
                          document
                            .getElementById("startDateErr")
                            .classList.add("d-none");
                          setStartDate(e.target.value);
                          // getFilterValues(e?.target?.value, keywords.startDate , searchOption2)
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter Start Date"
                    />
                    <span id="startDateErr" className=" text-danger d-none">
                      *Please Enter Valid Date!
                    </span>
                  </div>
                  <div className="form-group mt-1">
                    <input
                      className="advanced-input-box form-control"
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        if (handleDateInputChange(e)) {
                          setEndDate("");
                          document
                            .getElementById("endDateErr")
                            .classList.remove("d-none");
                        } else {
                          if (
                            handleFromToDateInputChange(
                              startDate,
                              e.target.value
                            )
                          ) {
                            document
                              .getElementById("endDateErr")
                              .classList.add("d-none");
                            document
                              .getElementById("fromToDateErr")
                              .classList.add("d-none");
                            setEndDate(e.target.value);
                            // getFilterValues(e?.target?.value, keywords.endDate,searchOption2)
                          } else {
                            document
                              .getElementById("fromToDateErr")
                              .classList.remove("d-none");
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      placeholder="End Date"
                    />
                    <span id="endDateErr" className=" text-danger d-none">
                      *Please Enter Valid Date!
                    </span>
                    <span id="fromToDateErr" className=" text-danger d-none">
                      *TO Date can't be less than FROM Date!
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default DateFilter;
