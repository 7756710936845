import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Card, Col, Row } from "react-bootstrap";
import InputBox from "../Common/InputBox/InputBox";
import styles from "./style.module.css"

import { useDispatch, useSelector } from "react-redux";

import useApiServices from "../Utils/useApiService";
import { getKeyPressed, isEmailIdRegexIsValid } from "../Utils/validations";
import { setAllPrivilagesData } from "../store/Slices/privilagesSlice";
import { GET_ALL_PRIVILAGES, SAVE_USER } from "../Utils/Constants";
import { useNavigate } from "react-router-dom";

/**
 * `CreateUser` component for creating a new user with various privileges.
 * @returns {JSX.Element} - The `CreateUser` form component.
 */
const CreateUser = () => {
  const [privilagesData, setPrivilagesData] = useState([]);
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { apiCallFnforPost } = useApiServices();

  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });

  const { allPrivilagesData } = useSelector((state) => state.privilages);

  /**
   * Sets the privileges data from the Redux store to the local state.
   */
  useEffect(() => {
    setPrivilagesData(allPrivilagesData);
  }, [allPrivilagesData]);

  /**
   * Handles form input changes.
   * @param {Object} e - Event object containing target name and value.
   */
  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setFormDataInputs({ ...formDataInputs, [name]: value });
  };

  /**
   * Handles changes to the privilege checkboxes.
   * @param {Object} e - Event object containing target name and checked status.
   */
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };

  /**
   * Handles changes to the head privileges checkboxes.
   * @param {Object} e - Event object containing target name and checked status.
   */
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;

    const subtypeArray = privilagesData?.find((item) => {
      return String(item.privilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.privilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev?.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };

   /**
   * Handles form submission, validates data, and sends a request to save the user.
   * @param {Object} e - Event object.
   */
  const handleSubmit = async (e) => {
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });

    const payload = {
      fullName: formDataInputs.Name.trim(),
      password: formDataInputs.password.trim(),
      mobile: parseInt(formDataInputs.MobileNumber),
     
      email: formDataInputs.emailId.trim(),
      privilges: privilegs,
    };

    if (formDataInputs.Name === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Merchant Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }

    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }

    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      const res = await apiCallFnforPost(SAVE_USER, payload);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "User Saved Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          Name: "",
          password: "",
          MobileNumber: "",
          //   Prifix: "",
          emailId: "",
        });
        navigate("/Settings/UserManagement");
        setPrivilages([]);
        setPrivilagesData([]);
        const modaldismiss = document.getElementById("priviledgemodal");

        modaldismiss.setAttribute("data-bs-dismiss", "modal");
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };

   /**
   * Handles validation for email field.
   * @param {Object} e - Event object containing target name and value.
   */
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="">
              <div className="">
                <div>
                  <Row className="px-4">
                    <Col xs={12} lg={4} md={6} className="mt-5">
                      <InputBox
                        label={"Name"}
                        required={true}
                        placeholder={"Enter name"}
                        value={formDataInputs?.Name}
                        onChange={handleformDataInputs}
                        name={"Name"}
                      />
                    </Col>

                    <Col xs={12} lg={4} md={6} className="mt-5">
                      <InputBox
                        type="email"
                        label={"e-Mail Id"}
                        required={true}
                        placeholder={"Enter email"}
                        value={formDataInputs.emailId}
                        onChange={handleformDataInputs}
                        name={"emailId"}
                        onKeyDown={(e) => setPressedKeyName(getKeyPressed(e))}
                        onBlur={handleValidations}
                      />
                    </Col>

                    <Col xs={12} lg={4} md={6} className="mt-5">
                      <InputBox
                        label={"Mobile Number"}
                        required={true}
                        placeholder={"Enter mobile number"}
                        maxLength={10}
                        type="number"
                        pattern={'pattern="[0-9]{10}"'}
                        minLength={10}
                        name={"MobileNumber"}
                        value={formDataInputs.MobileNumber}
                        onChange={handleformDataInputs}
                      />
                    </Col>

                    <Col xs={12} lg={4} md={6} className="mt-5">
                      <InputBox
                        label={"Password"}
                        required={true}
                        type="password"
                        placeholder={"Enter password"}
                        name={"password"}
                        value={formDataInputs.password}
                        onChange={handleformDataInputs}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                      Privileges
                    </lable>
                    <div className="d-flex flex-column gap-4">
                      {privilagesData &&
                        privilagesData.map((itemMain, i) => (
                          <div key={i} className="d-flex flex-column">
                            <Card.Header className="form-label mb-3 fs-4 mx-3">
                              <div className="s-lg form-check">
                                <label className="mt-1">
                                  {itemMain.privilegeName}
                                </label>
                                <input
                                  id="flexSwitchCheckDefault"
                                  className="form-check-input fs-4"
                                  type="checkbox"
                                  name={itemMain.privilegeId}
                                  value={HeadPrivilages.includes(
                                    itemMain.privilegeId + ""
                                  )}
                                  onChange={handleHeadPrivilages}
                                />
                              </div>
                            </Card.Header>

                            <div className="d-flex flex-wrap">
                              {itemMain.subTypes.map((item, i) => (
                                <div
                                  key={i}
                                  
                                  className={`d-flex align-items-center gap-4 px-3 py-1 rounded rounded-5 mt-2 mx-2 form-group ${styles["SubTypeStyle"]}`}
                                >
                                  <label className="form-label mt-2 fs-4">
                                    {item.privilegeSubTypeName}
                                  </label>
                                  <div className="s-lg form-check form-switch">
                                    <input
                                      id="flexSwitchCheckDefault"
                                      className="form-check-input fs-4"
                                      data-head={itemMain.adminPrivilegeId}
                                      type="checkbox"
                                      name={item.privilegeSubTypeId}
                                      checked={privilages.includes(
                                        item.privilegeSubTypeId + ""
                                      )}
                                      onChange={handlePrivilages}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Row>
                  <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-primary" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
