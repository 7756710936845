import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import useApiServices from "../../Utils/useApiService";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  ACC_NUM_IFSC_VERIFY_API,
  CHECK_THIRD_PARTY_API_STATUS,
  IFSC_VERIFY_API,
  SAVE_VENDER_DETAILS,
  VERIFY_UPI_API,
  VERIFY_VENDER_AADHAAR,
  VERIFY_VENDER_AADHAAR_OTP,
  VERIFY_VENDER_EMAIL,
  VERIFY_VENDER_MOBILE,
  VERIFY_VENDER_PAN,
} from "../../Utils/Constants";
 
import { jwtDecode } from "jwt-decode";
import { Col } from "react-bootstrap";
import InputBox from "../../Common/InputBox/InputBox";
import { useSelector } from "react-redux";

export default function RegisterVendor({ setCallAgain, callAgain }) {
  const [showBankDetails, setShowBanklDetails] = useState(true);
  const [isIfscVerified, setIsIfscVerified] = useState("");
  const [isUpiVerified, setIsUpiVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isBankVerifieed, setIsBankVerifieed] = useState(false);

  const [isUpiError, setIsUpiError] = useState(true);
  const [isIfscError, setIsIfscError] = useState(true);
  const [isPanError, setIsPanError] = useState(true);
  const [isAadharErr, setIsAadharErr] = useState(true);
  const [isVenderNameErr, setIsVenderNameErr] = useState(false);
  const [isVenderNameLengthErr, setIsVenderLengthNameErr] = useState(false);
  const [isCategoryErr, setIsCategoryErr] = useState(false);
  const [thirdPartyServiceStatus, setThirdPartyServiceStatus] = useState([]);
  const [showAadharOtpInput, setShowAadharOtpInput] = useState(false);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [isBankVerificationRequired, setIsBankVerificationRequired] =
    useState(true);

  const {
    apiCallFnforPostForOpenUrl,
    apiCallFnforGetOpenUrl,
    apiCallFnforPost,
  } = useApiServices();
  const [handelAadharOtpInput, setHandelAadharOtpInput] = useState({
    refNumber: "",
    otp: "",
  });
  const [venderDetails, setVenderDetails] = useState({
    companyName: "",
    mobileNumber: "",
    email: "",
    category: "",
    accountNumber: "",
    confirmAccountNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    upiId: "",
    aadharNumber: "",
    panCard: "",
    isAadharVerified: false,
    isPanverified: false,
    isBankVerified: isBankVerifieed,
  });
 
  const { mid } = useSelector((state) => state.userData);

  const getThirdPartyServiceDetails = async () => {
    const res = await apiCallFnforGetOpenUrl(CHECK_THIRD_PARTY_API_STATUS);
    setThirdPartyServiceStatus(res);

    res?.forEach((service) => {
      if (service.serviceName === "IFSC" && service.isActive === false) {
        setIsBankVerificationRequired(false);
      }
    });
  };
  const handelValidations = (e) => {
    if (e.target.name === "mobileNumber") {
      if (venderDetails.mobileNumber.length !== 10) {
        document.getElementById("mobErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      verifymobileNumber();
    }

    if (e.target.name === "email") {
      const emailRegex = new RegExp(/^[A-Za-z0-9._%]+@[A-Za-z0-9.-]+$/);
      const isValidEmail = emailRegex.test(venderDetails.email);
      if (!isValidEmail) {
        document.getElementById("emailErr").textContent =
          "*Please Enter Valid Email!";
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      verifyEmail();
    }

    if (
      e.target.name === "confirmAccountNumber" ||
      e.target.name === "accountNumber"
    ) {
      if (venderDetails.accountNumber !== venderDetails.confirmAccountNumber) {
        document.getElementById("cnfAccNumErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
      }
    }

    if (e.target.name === "ifscCode") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(venderDetails.ifscCode);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
        return;
      }
      setIsIfscError(false);
    }

    if (!showBankDetails) {
      if (e.target.name === "upiId") {
        const upiRegex = new RegExp(
          /^[A-Za-z0-9.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$|^[A-Za-z0-9.%+-]+@[A-Za-z0-9.-]+$/
        );
        const isValidUpi = upiRegex.test(venderDetails.upiId);
        if (!isValidUpi) {
          setIsUpiError(true);
          document.getElementById("upiErr").classList.remove("d-none");
          document.getElementById("registerVender").disabled = true;
        }
      }
    }

    if (e.target.name === "aadharNumber") {
      let aadharRegex = new RegExp(/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/);
      const isValidAadhar = aadharRegex.test(venderDetails.aadharNumber);
      if (!isValidAadhar) {
        // setIsUpiError(true);
        document.getElementById("aadharErr").classList.remove("d-none");
        document.getElementById("registerVender").disabled = true;
      }
      setIsAadharErr(false);
    }

    if (e.target.name === "panCard") {
      let panCardRegex = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
      const isPanCardValid = panCardRegex.test(venderDetails.panCard);
      if (!isPanCardValid) {
        // setIsUpiError(true);
        document.getElementById("panErr").classList.remove("d-none");
        document.getElementById("panErr").disabled = true;
      }
      setIsPanError(false);
    }
  };
  const getKeyPressed = (e) => {
    setPressedKeyName(e.key);
  };
  function handelVenderData(e) {
    if (
      isIfscVerified &&
      (e.target.name === "bankName" || e.target.name === "branchName")
    ) {
      return;
    }

    if (pressedKeyName === "Backspace" && e.target.name === "aadharNumber") {
      setVenderDetails({ ...venderDetails, [e.target.name]: e.target.value });
    }

    if (
      venderDetails.aadharNumber.length >= 12 &&
      e.target.name === "aadharNumber"
    ) {
      return;
    }

    setVenderDetails({ ...venderDetails, [e.target.name]: e.target.value });

    document.getElementById("mobErr").classList.add("d-none");
    document.getElementById("emailErr").classList.add("d-none");
    document.getElementById("aadharErr").classList.add("d-none");
    document.getElementById("panErr").classList.add("d-none");
    document.getElementById("registerVender").disabled = false;
    setIsPanError(false);
    setIsAadharErr(false);
    setIsVenderNameErr(false);
    setIsVenderLengthNameErr(false);
    setIsCategoryErr(false);

    if (!isPanVerified) {
      setIsPanVerified(false);
      document.getElementById("panCustomerName").innerHTML = "";
    }

    if (e.target.name === "panCard") {
      setIsPanVerified(false);
    }
    if (showBankDetails) {
      if (!isIfscVerified) {
        setIsIfscVerified(false);
      }
      setIsIfscError(true);
      setIsIfscVerified(false);
      // setVenderDetails({ ...venderDetails, isBankVerified: false });
      document.getElementById("cnfAccNumErr").classList.add("d-none");
      document.getElementById("ifscErr").classList.add("d-none");
    }
    if (!showBankDetails) {
      if (!isUpiVerified) {
        setIsUpiVerified(false);
        document.getElementById("upiCustomerName").innerHTML = "";
      }
      setIsUpiError(false);
      setIsUpiVerified(false);
      document.getElementById("upiErr").classList.add("d-none");
      document.getElementById("upiCustomerName").innerHTML = "";
    }
  }
  const emptyIfscError = () => {
    if (venderDetails.ifscCode === "") {
      document.getElementById("ifscErr").classList.remove("d-none");
      document.getElementById("ifscErr").innerHTML = "*Please Enter IFSC Code";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyIfsc = async () => {
    emptyIfscError();
    document.getElementById("bankVerifyErr").classList.add("d-none");

    const isAccVerified = await apiCallFnforPostForOpenUrl(
      ACC_NUM_IFSC_VERIFY_API,
      {
        ifsc: venderDetails.ifscCode,
        accountNumber: venderDetails.accountNumber,
      }
    );

    const res = await apiCallFnforPostForOpenUrl(IFSC_VERIFY_API, {
      ifscCode: venderDetails.ifscCode,
    });

    if (res.statusCode === 200 && isAccVerified.statusCode === 200) {
      setIsBankVerifieed(true);
      setVenderDetails({
        ...venderDetails,
        branchName: res.data.branch,
        bankName: res.data.bank,
        isBankVerified: true,
      });
      setIsIfscVerified(true);
    } else {
      setIsIfscVerified("NotVerified");
      swal({
        title: "Alert!",
        text: "Invalid Bank Details !",
        icon: "error",
      });
    }
  };

  const emptyUpiError = () => {
    if (venderDetails.upiId === "") {
      document.getElementById("upiErr").classList.remove("d-none");
      document.getElementById("upiErr").innerHTML = "*Please Enter UPI ID";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };
  const verifyUpi = async () => {
    emptyUpiError();

    const res = await apiCallFnforPostForOpenUrl(VERIFY_UPI_API, {
      upiId: venderDetails.upiId,
    });

    if (res.data.statusCode === 304) setIsUpiVerified("NotVerified");

    if (res.data.statusCode === 200) {
      setIsUpiVerified(true);
      setVenderDetails({ ...venderDetails, isBankVerified: true });
      document.getElementById("upiCustomerName").innerHTML =
        res.data.customerName;
    }else{
      swal({
        title: "ALERT!",
        text:  "Something went wrong.",
        icon: "error"
      })
    }
  };

  const verifyEmail = async () => {
    
    const res = await apiCallFnforPost(VERIFY_VENDER_EMAIL, {
      data: venderDetails.email,
      mid,
    });
    if (res.statusCode !== 200) {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("emailErr").textContent =
        "Email Already Exists !";
      document.getElementById("registerVender").disabled = true;
    }
  };

  const verifymobileNumber = async () => {
    
    const res = await apiCallFnforPost(VERIFY_VENDER_MOBILE, {
      data: venderDetails.mobileNumber,
      mid,
    });
    if (res.statusCode !== 200) {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("mobErr").textContent =
        "Mobile Number Already Exists !";
      document.getElementById("registerVender").disabled = true;
    }
  };
  const emptyAadharNumberError = () => {
    if (venderDetails.aadharNumber === "") {
      document.getElementById("aadharErr").classList.remove("d-none");
      document.getElementById("aadharErr").innerHTML =
        "*Please Enter Aadhar Number";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };

  const verifyAadharCard = async () => {
    
    // calling api to send otp on aadhaar number

    let res = await apiCallFnforPostForOpenUrl(VERIFY_VENDER_AADHAAR, {
      aadhaarNumber: venderDetails.aadharNumber,
    });

    if (res.statusCode === 200) {
      swal({
        title: "OTP Sent",
        text: "An OTP has been sent your phone number.",
        icon: "success",
        timer: 1000, // 2 seconds
        // No confirm button, auto close
      });
      setShowAadharOtpInput(true);
      document.getElementById("verifyAadharBtn").textContent = "Resend OTP";
      // document.getElementById("aadharCustomerName").textContent =
      //   "OTP Send Successfully";
      setHandelAadharOtpInput({ ...handelAadharOtpInput, refNumber: res.data });
    }
  };

  const verifyAadharOtp = async () => {
    let res = await apiCallFnforPostForOpenUrl(
      VERIFY_VENDER_AADHAAR_OTP,
      handelAadharOtpInput
    );

    if (res.statusCode === 200) {
      setShowAadharOtpInput(false);
      setIsAadharVerified(true);
      setVenderDetails({ ...venderDetails, isAadharVerified: true });
      document.getElementById("aadharCustomerName").textContent = res.data.Name;
    }
  };

  const emptyPanCardError = () => {
    if (venderDetails.panCard === "") {
      document.getElementById("panErr").classList.remove("d-none");
      document.getElementById("panErr").innerHTML = "*Please Enter Pan Number";
      document.getElementById("registerVender").disabled = true;
      return;
    }
  };
  const verifyPanCard = async () => {
    document.getElementById("panVerifyErr").classList.add("d-none");

    let res = await apiCallFnforPostForOpenUrl(VERIFY_VENDER_PAN, {
      panNumber: venderDetails.panCard,
    });

    if (res.statusCode === 200) {
      setIsPanVerified(true);
      setVenderDetails({ ...venderDetails, isPanverified: true });
      document.getElementById("panCustomerName").innerHTML = res?.data?.Name;
    }
    if (res.statusCode !== 200) setIsPanVerified("NotVerified");
  };

  const handelVenderRegister = async (e) => {
    e.preventDefault();

    const {
      companyName,
      category,
      isBankVerified,
      email,
      mobileNumber,
      accountNumber,
      confirmAccountNumber,
      ifscCode,
      bankName,
      branchName,
      upiId,
    } = venderDetails;

    if (companyName.trim() === "") {
      setIsVenderNameErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (companyName.trim().length < 5) {
      setIsVenderLengthNameErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (mobileNumber.trim() === "") {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }
    if (email.trim() === "") {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }

    if (category.trim() === "") {
      setIsCategoryErr(true);
      document.getElementById("registerVender").disabled = true;
      return;
    }

    // if(!isPanVerified) {
    //   document.getElementById("panVerifyErr").classList.remove("d-none");
    // }

    // if (isBankVerificationRequired) {
    //   document.getElementById("bankVerifyErr").classList.remove("d-none");
    //   document.getElementById("registerVender").disabled = true;
    //   return;
    // }

    if (venderDetails.mobileNumber.length !== 10) {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("registerVender").disabled = true;
      return;
    }

    // if (companyName === "") {
    //   document.getElementById("nameErr").classList.remove("d-none");
    // }

    // if (category === "") {
    //   document.getElementById("categoryErr").classList.remove("d-none");
    // }

    if (
      showBankDetails &&
      accountNumber === "" &&
      confirmAccountNumber === "" &&
      ifscCode === "" &&
      bankName === "" &&
      branchName === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Bank Details",
        icon: "error",
      });
      return;
    }

    if (!showBankDetails && upiId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Valid UPI ID",
        icon: "error",
      });
      return;
    }

    if (
      companyName !== "" &&
      category !== "" &&
      email !== "" &&
      mobileNumber !== ""
    ) {
      

      let venderDetail = {
        aadhaarNo: venderDetails.aadharNumber,
        aadharVerfied: venderDetails.isAadharVerified,
        accountNumber: venderDetails.accountNumber,
        bankName: venderDetails.bankName,
        bankVerified: venderDetails.isBankVerified,
        branchName: venderDetails.branchName,
        category: venderDetails.category,
        email: venderDetails.email,
        fullName: venderDetails.companyName,
        ifscCode: venderDetails.ifscCode.toUpperCase(),
        mobile: venderDetails.mobileNumber,
        panNumber: venderDetails.panCard,
        panVerfied: venderDetails.isPanverified,
        parentMid: mid,
        upi: venderDetails.upiId,
      };

      let res = await apiCallFnforPost(SAVE_VENDER_DETAILS, venderDetail);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "Vender Registered Successfully!!",
          icon: "success",
        });
        setVenderDetails({
          companyName: "",
          mobileNumber: "",
          email: "",
          category: "",
          accountNumber: "",
          confirmAccountNumber: "",
          ifscCode: "",
          bankName: "",
          branchName: "",
          upiId: "",
          aadharNumber: "",
          panCard: "",
          isAadharVerified: false,
          isPanverified: false,
          isBankVerified: false,
        })
        // showBankDetails(false)
        const closeBtn=document.getElementById("close_Btn")
        if(closeBtn){
          closeBtn.click()
        }
        setCallAgain(!callAgain);
      } else {
        swal({
          title: "Opps!",
          text: res.message + " !",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    getThirdPartyServiceDetails();
  }, []);

  return (
    <>
      {/* <div className="content-body"> */}
      {/* <div className="container-fluid"> */}
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            {/* <div className="card-header">
                  <h4 className="card-title">Vendor Registration</h4>
                </div> */}
            <div className="card-body">
              <div id="" className="form-wizard order-create">
                <div id="" className="tab-pane" role="tabpanel">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Vendor Name<span class="required"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          name="companyName"
                          value={venderDetails.companyName}
                          onChange={handelVenderData}
                          placeholder="Company Name/Full Name"
                        />
                        {isVenderNameErr ? (
                          <span id="nameErr" className=" text-danger">
                            * Vender Name/Company Name Can't be Empty !!
                          </span>
                        ) : (
                          ""
                        )}

                        {isVenderNameLengthErr ? (
                          <span id="nameErr" className=" text-danger">
                            Vender Name/Company Name Should be Greater than 5
                            Character
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Mobile Number<span class="required"> *</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="mobileNumber"
                          value={venderDetails.mobileNumber}
                          onChange={handelVenderData}
                          onBlur={handelValidations}
                          placeholder="Mobile Number"
                        />
                        <span id="mobErr" className=" text-danger d-none">
                          *Mobile Number can't greater than or less than 10
                          digits!
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          e-Mail Id<span class="required"> *</span>
                        </label>

                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={venderDetails.email}
                          onChange={handelVenderData}
                          onBlur={handelValidations}
                          placeholder="e-Mail"
                        />
                        <span id="emailErr" className=" text-danger d-none">
                          *Please Enter Valid Email!
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Category<span class="required"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          name="category"
                          value={venderDetails.category}
                          onChange={handelVenderData}
                          placeholder="Category"
                        />
                        {isCategoryErr ? (
                          <span id="categoryErr" className=" text-danger">
                            * Category Can't be Empty !!
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Aadhaar Card 
                        </label>

                        <input
                          type="number"
                          className="form-control"
                          name="aadharNumber"
                          value={venderDetails.aadharNumber}
                          onChange={handelVenderData}
                          onKeyDown={getKeyPressed}
                          onBlur={handelValidations}
                          placeholder="Aadhar Number"
                          minLength={0}
                          maxLength={12}
                        />
                        <span id="aadharErr" className=" text-danger d-none">
                          *Please Enter Valid Aadhaar Number!
                        </span>

                        <div className="d-flex justify-content-between mt-1">
                          <span
                            id="aadharCustomerName"
                            className="text-primary"
                          ></span>

                          {isAadharVerified ? (
                            ""
                          ) : (
                            <div className="d-flex justify-content-between">
                              {thirdPartyServiceStatus?.map((serviceName) => {
                                if (
                                  serviceName.serviceName === "AADHAAR" &&
                                  serviceName.isActive === true
                                ) {
                                  return (
                                    <span
                                      role="button"
                                      id="verifyAadharBtn"
                                      onClick={
                                        isAadharErr
                                          ? () => {
                                              emptyAadharNumberError();
                                            }
                                          : verifyAadharCard
                                      }
                                      className="text-primary cursor-pointer align-right mt-1"
                                    >
                                      Verify Aadhaar
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          )}
                          {isAadharVerified === "NotVerified" ? (
                            <div className="d-flex justify-content-end">
                              <span
                                role="button"
                                className="text-danger cursor-pointer align-right mt-1"
                              >
                                <b>
                                  <HighlightOffIcon />
                                  Not Verified
                                </b>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {isAadharVerified === true ? (
                            <div className="d-flex justify-content-end">
                              <span
                                role="button"
                                className="text-success cursor-pointer align-right mt-1"
                              >
                                <b>
                                  <DoneAllIcon />
                                  Verified
                                </b>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {showAadharOtpInput ? (
                      <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Aadhaar OTP 
                        </label>
                          <input
                            type="password"
                            className="form-control"
                            name="otp"
                            value={handelAadharOtpInput.otp}
                            onChange={(e) => {
                              if (pressedKeyName === "Backspace") {
                                // alert("Back")
                                setHandelAadharOtpInput({
                                  ...handelAadharOtpInput,
                                  otp: e.target.value,
                                });
                              }

                              if (handelAadharOtpInput.otp.length >= 6) {
                                return;
                              }
                              setHandelAadharOtpInput({
                                ...handelAadharOtpInput,
                                otp: e.target.value,
                              });
                            }}
                            onKeyDown={getKeyPressed}
                            placeholder="Enter aadhaar otp "
                          />
                          <div className="text-primary mt-1 w-75 px-3 d-flex align-items-center ">
                            <span
                              role="button"
                              className="cursor-pointer"
                              onClick={verifyAadharOtp}
                            >
                              Validate OTP
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-6 mb-2">
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          Pan Card 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="panCard"
                          value={venderDetails.panCard}
                          onChange={handelVenderData}
                          onBlur={handelValidations}
                          placeholder="PAN Card Number"
                        />
                        <span id="panErr" className=" text-danger d-none">
                          *Please Enter Valid Pan Number!
                        </span>
                        <span id="panVerifyErr" className=" text-danger d-none">
                          *Please Verify Your Pan Number!
                        </span>

                        <div className="d-flex justify-content-between mt-1">
                          <span
                            id="panCustomerName"
                            className="text-primary"
                          ></span>
                          {isPanVerified ? (
                            ""
                          ) : (
                            <div className="d-flex justify-content-end">
                              {thirdPartyServiceStatus?.map((serviceName) => {
                                if (
                                  serviceName.serviceName === "PAN" &&
                                  serviceName.isActive === true
                                ) {
                                  return (
                                    <span
                                      role="button"
                                      onClick={
                                        isPanError
                                          ? () => {
                                              emptyPanCardError();
                                            }
                                          : verifyPanCard
                                      }
                                      className="text-primary cursor-pointer align-right mt-1"
                                    >
                                      Verify PAN
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          )}
                          {isPanVerified === "NotVerified" ? (
                            <div className="d-flex justify-content-end">
                              <span
                                role="button"
                                className="text-danger cursor-pointer align-right mt-1"
                              >
                                <b>
                                  <HighlightOffIcon />
                                  Not Verified
                                </b>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {isPanVerified === true ? (
                            <div className="d-flex justify-content-end">
                              <span
                                role="button"
                                className="text-success cursor-pointer align-right mt-1"
                              >
                                <b>
                                  <DoneAllIcon />
                                  Verified
                                </b>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-6 mb-2"> */}
                    <div className="form-group mt-4 gap-4 d-flex">
                      <div className="">
                        <input
                          type="radio"
                          className="form-check-input mb-2"
                          name="bankDetails"
                          checked={showBankDetails}
                          onChange={() => setShowBanklDetails(!showBankDetails)}
                        />
                        <label className="mt-1 mx-2 text-dark">
                          <span>&nbsp;Bank Details</span>
                          
                        </label>
                      </div>

                      <div className="">
                        <input
                          type="radio"
                          className="form-check-input mb-2"
                          name="bankDetails"
                          checked={!showBankDetails}
                          onChange={() => setShowBanklDetails(!showBankDetails)}
                        />
                        <label className="mt-1 mx-2 text-dark">
                          <span> &nbsp;UPI ID / VPA</span>
                        </label>
                      </div>
                    </div>
                    {/* </div> */}

                    {showBankDetails ? (
                      <>
                        <div className="col-lg-6 mb-2 my-4">
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <label class="form-label me-1 fs-5 text-black mx-2">
                              Account Number<span class="required"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="accountNumber"
                              value={venderDetails.accountNumber}
                              onChange={handelVenderData}
                              onBlur={handelValidations}
                              placeholder="Account Number"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2 my-4">
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <label class="form-label me-1 fs-5 text-black mx-2">
                              Confirm Account Number
                              <span class="required"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="confirmAccountNumber"
                              value={venderDetails.confirmAccountNumber}
                              onChange={handelVenderData}
                              onBlur={handelValidations}
                              placeholder="Confirm Account Number"
                            />
                            <span
                              id="cnfAccNumErr"
                              className=" text-danger d-none"
                            >
                              *Account Number Mismatch!{" "}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <label class="form-label me-1 fs-5 text-black mx-2">
                              IFSC Code<span class="required"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ifscCode"
                              value={venderDetails.ifscCode}
                              onChange={handelVenderData}
                              onBlur={handelValidations}
                              placeholder="IFSC Code"
                            />
                            <span id="ifscErr" className=" text-danger d-none">
                              *Please Enter Valid IFSC!{" "}
                            </span>
                            <span
                              id="bankVerifyErr"
                              className=" text-danger d-none"
                            >
                              *Please Verify Your Bank !
                            </span>
                            {!isIfscVerified ? (
                              <div className="d-flex justify-content-end">
                                {thirdPartyServiceStatus?.map((serviceName) => {
                                  if (
                                    serviceName.serviceName === "IFSC" &&
                                    serviceName.isActive === true
                                  ) {
                                    return (
                                      <span
                                        onClick={
                                          isIfscError
                                            ? () => {
                                                emptyIfscError();
                                              }
                                            : verifyIfsc
                                        }
                                        role="button"
                                        className="text-primary cursor-pointer align-right mt-1"
                                      >
                                        Verify IFSC
                                      </span>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                            {isIfscVerified === true ? (
                              <div className="d-flex justify-content-end">
                                <span
                                  role="button"
                                  className="text-success cursor-pointer align-right mt-1"
                                >
                                  <b>
                                    <DoneAllIcon />
                                    Verified
                                  </b>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {isIfscVerified === "NotVerified" ? (
                              <div className="d-flex justify-content-end">
                                <span
                                  role="button"
                                  className="text-danger cursor-pointer align-right mt-1"
                                >
                                  <b>
                                    <HighlightOffIcon />
                                    Not Verified
                                  </b>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <label class="form-label me-1 fs-5 text-black mx-2">
                              Bank Name<span class="required"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              value={venderDetails.bankName}
                              onChange={handelVenderData}
                              placeholder="Bank Name"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <label class="form-label me-1 fs-5 text-black mx-2">
                              Branch Name<span class="required"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="branchName"
                              value={venderDetails.branchName}
                              onChange={handelVenderData}
                              placeholder="Branch Name"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {!showBankDetails ? (
                      <div>
                        <div className="col-lg-6 mb-2 my-4">
                        <div className="mb-3 d-flex flex-column align-items-start">
                        <label class="form-label me-1 fs-5 text-black mx-2">
                          UPI Id<span class="required"> *</span>
                        </label>
                            <input
                              type="text"
                              className="form-control"
                              name="upiId"
                              value={venderDetails.upiId}
                              onChange={handelVenderData}
                              onBlur={handelValidations}
                              placeholder="UPI ID"
                            />
                            <span id="upiErr" className=" text-danger d-none">
                              *Please Enter Valid UPI ID !
                            </span>
                            <div className="d-flex justify-content-between mt-1">
                              <span
                                id="upiCustomerName"
                                className="text-primary"
                              ></span>
                              {!isUpiVerified ? (
                                <div className="d-flex justify-content-end">
                                  {thirdPartyServiceStatus?.map(
                                    (serviceName) => {
                                      if (
                                        serviceName.serviceName === "UPI" &&
                                        serviceName.isActive === true
                                      ) {
                                        return (
                                          <span
                                            onClick={
                                              isUpiError
                                                ? () => {
                                                    emptyUpiError();
                                                  }
                                                : verifyUpi
                                            }
                                            role="button"
                                            className="text-primary cursor-pointer align-right mt-1"
                                          >
                                            Verify UPI
                                          </span>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {isUpiVerified === "NotVerified" ? (
                                <div className="d-flex justify-content-end">
                                  <span
                                    role="button"
                                    className="text-danger cursor-pointer align-right mt-1"
                                  >
                                    <b>
                                      <HighlightOffIcon />
                                      Not Verified
                                    </b>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              {isUpiVerified === true && isUpiVerified ? (
                                <div className="d-flex justify-content-end">
                                  <span
                                    role="button"
                                    className="text-success cursor-pointer align-right mt-1"
                                  >
                                    <b>
                                      {" "}
                                      <DoneAllIcon />
                                      Verified
                                    </b>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                </div>
              </div>
            </div>
                    <div className="col">
                      <div className="form-group d-flex justify-content-end gap-3">
                        <div className="login-btn-sec toolbar-bt text-end">
                          <button
                            id="registerVender"
                            className={"advanced-btn btn btn-primary btn-sm"}
                            onClick={handelVenderRegister}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
