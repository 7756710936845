import React, { useEffect, useState } from "react";
import SlideBox from "../Common/SlideBox/SlideBox";
import { reporticons } from "../Assets/images/image";
import styles from "./report.module.css";
import Reports from "./reports";
import CheckExportConst from "../Utils/PrivilagesUtilityFun/CheckExportConst";
import { useParams } from "react-router-dom";
import Release from "./Release";
import {
  setFilterOn,
  setInitialObject,
} from "../store/Slices/reportFilterSlice";
import { useDispatch } from "react-redux";


/**
 * Report component renders a Report selection page.
 * It displays icons and labels for various Report sections.
 * @returns {ReactNode} A React element that renders Report selection page.
 */

const Report = () => {
  const [flag, setFlag] = useState(false);
  const [report, setReport] = useState("");
  const dispatch = useDispatch();

  const { slide } = useParams();

  useEffect(() => {
    if (slide == 11) {
      toggleRightBar("Wallet");
    } else if (slide == 5) {
      toggleRightBar("Withdraw");
    } else if (slide == 12) {
      toggleRightBar("Payout");
    }
  }, []);

  const reportType = {
    Payin: "Payin",
    Payout: "Payout",
    Chargeback: "Chargeback",
    Vendors: "Vendors",
    Withdraw: "Withdraw",
    Ekyc: "Ekyc",
    Deposit: "Deposit",
    Guarantee: "Guarantee",
    Release: "Release",
    Reconciliation: "Reconciliation",
    Hold: "Hold",
    Wallet: "Wallet",
    Credit: "Credit",
    Ledger: "Ledger",
    Settlement: "Settlement",
    SettlementAdjustment: "SettlementAdjustment",
  };

  const toggleRightBar = (type) => {
    if (type) {
      dispatch(setInitialObject());
      dispatch(setFilterOn(false));
      setReport(type);
    }

    setFlag(!flag);
  };

  const {
    hasPrivilagesPayin,
    hasPrivilagesSettlement,
    hasPrivilagesGuarantee,
    hasPrivilagesLadger,
    hasPrivilagesRecon,
    hasPrivilagesChargeback,
    hasPrivilagesMyVendor,
    hasPrivilagesPayout,
    hasPrivilagesWithdraw,
    hasPrivilagesDeposit,
    hasPrivilagesHold,
    hasPrivilagesCredit,
    hasPrivilagesWalletBalance,
    hasPrivilagesRelease,
    hasPrivilagesEkyc,
    reportPrivilages,
  } = CheckExportConst();

  // console.log("report", report, reportType[report]);
  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div class="card card-inverse card-primary text-center">
          <div class="slideParent card-body pb-1">
            <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {reportType[report] === "Payin" && <Reports type={report} />}
              {reportType[report] === "Settlement" && <Reports type={report} />}
              {reportType[report] === "Chargeback" && <Reports type={report} />}
              {reportType[report] === "Vendors" && <Release type={report} />}
              {reportType[report] === "Withdraw" && <Reports type={report} />}
              {reportType[report] === "Ekyc" && <Release type={report} />}
              {reportType[report] === "Deposit" && <Reports type={report} />}
              {reportType[report] === "Guarantee" && <Reports type={report} />}
              {reportType[report] === "Release" && <Reports type={report} />}
              {reportType[report] === "Reconciliation" && (
                <Reports type={report} />
              )}
              {reportType[report] === "Hold" && <Reports type={report} />}
              {reportType[report] === "Wallet" && <Reports type={report} />}
              {reportType[report] === "Credit" && <Release type={report} />}
              {reportType[report] === "Ledger" && <Reports type={report} />}
              {reportType[report] === "SettlementAdjustment" && (
                <Reports type={report} />
              )}
              {reportType[report] === "Payout" && <Reports type={report} />}
            </SlideBox>

            <div class="row text-center text-lg-start">
              {hasPrivilagesPayin ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Payin")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Payin}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">Payin</h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesSettlement ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Settlement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Settlement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Settlement
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesChargeback ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Chargeback")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.ChargeBack}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Chargeback
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesMyVendor ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Vendors")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Myvendors}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Vendors
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesWithdraw ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Withdraw")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Payout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Withdraw
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesEkyc ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5  ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Ekyc")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Ekyc}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">E-kyc</h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesLadger ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5  ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Ledger")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Ledger}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Ledger
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesGuarantee ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Guarantee")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Guarantee}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Guarantee
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesRecon ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Reconciliation")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Reconcilation}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Reconciliation
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesCredit ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Credit")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.CreditBalance}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Credit Balance
                    </h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilagesRelease ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Release")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Release}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Release
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesWalletBalance ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Wallet")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.WalletBalance}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Wallet Balance
                    </h5>
                  </div>
                </div>
              ) : null}

               

              {hasPrivilagesPayout ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Payout")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Payout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout
                    </h5>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
