import React from "react";
import { useNavigate } from "react-router-dom";


/**
 * This is reusable component renders back button for backward navigation.
 * It displays back button.
 * @returns {ReactNode} A React element that renders Backward button for going on previous page.
 */

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <i
      class="fa-solid fa-arrow-left-long me-3 font-black"
      style={{ color: "black", fontSize: "1.4rem" }}
      onClick={handleBack}
    ></i>
  );
};

export default BackButton;