import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { FETCH_ALL_VENDERS_BY_STATUS_AND_MID } from "../../Utils/Constants";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../Utils/useApiService";
import Modal from "../../Common/Modal/Modal";
import RegisterVendor from "./RegisterVendor";
import styles from "./ArchieveReport.module.css";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import ViewVendor from "./ViewVendor";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useSelector } from "react-redux";

/**
 * @returns {ReactNode} - This component renders vendors list and also the button to register vendor.
 */

const SubmittedVendor = () => {
  const [venderData, setVenderData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const { mid } = useSelector((state) => state.userData);

  const { apiCallFnforPost } = useApiServices();
  const VenderByStatusWithMid = async () => {
    // setShowLoader(true);
    const payload = {
      parentMid: mid,
      status: "Submitted",
    };
    let res = await apiCallFnforPost(
      FETCH_ALL_VENDERS_BY_STATUS_AND_MID,
      payload
    );
    // setShowLoader(false);
    if (res.data === null) {
      setVenderData([]);
    } else {
      setVenderData(
        res?.data.map((item) => {
          return {
            ...item,
            id: item.vendorId,
          };
        })
      );
    }
  };

  venderData.map((entry) => {
    entry["viewDetails"] = (
      <div>
        <button
          className="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#ViewVendor"
          onClick={() => handleViewDetails(entry.vendorId)}
        >
          View Details
        </button>
      </div>
    );
    return entry;
  });

  const handleViewDetails = (vendorId) => {
    setVendorId(vendorId);
  };

  const column = [
    {
      headerName: "Vendor Name",
      field: "fullName",
      minWidth: 150,
    },
    {
      headerName: "e-Mail",
      field: "email",
      minWidth: 150,
    },
    {
      headerName: " Mobile Number",
      field: "mobile",
      minWidth: 150,
    },
    {
      headerName: " Category",
      field: "category",
      minWidth: 150,
    },
    {
      headerName: "Status",
      field: "vendorStatus",
      minWidth: 150,
    },
    {
      headerName: "Action",
      field: "viewDetails",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#ViewVendor"
              onClick={() => handleViewDetails(params.row.vendorId)}
            >
              View Details
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    VenderByStatusWithMid();
  }, [callAgain]);

  const { hasPrivilagesSubmittedVendor, hasPrivilagesRegisterVendor } =
    CheckExportConst();

  return (
    // <div className="content-body">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h4 class="card-title">Vendors</h4>

          {hasPrivilagesRegisterVendor ? (
            <button
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#registerVendor"
            >
              Register Vendor
            </button>
          ) : null}
        </div>

        <Modal id={"registerVendor"} title={"Register Vendor"} Width={"xl"}>
          <RegisterVendor setCallAgain={setCallAgain} callAgain={callAgain} />
        </Modal>
        <Modal id={"ViewVendor"} title={"View Vendor Details"} Width={"xl"}>
          <ViewVendor vendorId={vendorId} />
        </Modal>
        <div className="row">
          <div xs={12} lg={12} md={12} className="col">
            <div className="">
              <div className="">
                <div className="col">
                  {hasPrivilagesSubmittedVendor ? (
                    <div className={`mt-3 ${styles["DataGridHeight"]}`}>
                      {venderData?.length > 0 ? (
                        <DataGrid
                          rows={venderData}
                          columns={column}
                          slots={{
                            toolbar: GridToolbar,
                          }}
                          sx={{
                            "& .css-1rtad1": {
                              display: "block",
                            },
                          }}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* ./card */}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SubmittedVendor;
