import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import "../Assets/css/style.css";
// import svg from "../Assets/images/login-1.png";
// import svg2 from "../Assets/images/logo-full.png";

import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD_API,
  OTP_VERIFY_API,
} from "../Utils/Constants";
 
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../Utils/DynamicPortalFunctions";
import LeftPagePassword from "./LeftPagePassword";
import useApiServices from "../Utils/useApiService";

/**
 * ForgotPassword component renders Forgot Password page.
 * @returns {ReactNode} A React element that renders Forgot Password page.
 */
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  // const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [value, setValue] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [generalError, setGeneralError] = useState(null);
  const [formError, setFormError] = useState({
    email: null,
    otp: null,
    password: null,
  });

  const {apiCallFnforPostForOpenUrl}=useApiServices ()
  const { pathname } = useLocation();

  // alert("insideFP")
  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const handleFormSubmit = async (e) => {
    setGeneralError("");
     e.preventDefault()
    setFormError({ ...formError, email: null });
    try {
      const requestPayload = {
        emailId: email,
      };
      setShowLoader(true);

      const apiResponse = await apiCallFnforPostForOpenUrl(
        FORGOT_PASSWORD_API,
        requestPayload
      );

      if (apiResponse.statusCode === 200) {
        setSuccess(true);
      } else if (apiResponse.statusCode === 304) {
        setGeneralError("Please Enter Registered Mail.");
      } else {
        setGeneralError(apiResponse.message);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setGeneralError("Something went wrong. Please try again.");
    }
  };

  const handleOtpSubmit = async (e) => {
    setGeneralError("");
 
    setFormError({ ...formError, otp: null });

    try {
      const otpPayload = {
        email: email,
        otp: otp,
      };

      const otpVerifyResponse = await apiCallFnforPostForOpenUrl(
        OTP_VERIFY_API,
        otpPayload
      );

      if (otpVerifyResponse.statusCode === 200) {
        setOtpVerified(true);
      } else {
        setGeneralError(otpVerifyResponse.message);
      }
    } catch (error) {
      console.error(error);
      setGeneralError("Something went wrong. Please try again.");
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    setGeneralError("");
    e.preventDefault();
    setFormError({ ...formError, password: null });

    try {
      if (newPassword !== confirmPassword) {
        setGeneralError("Passwords do not match");
        return;
      }

      const changePasswordPayload = {
        emailId: email,
        newPassword: newPassword,
      };
      setShowLoader(true);
      const changePasswordResponse = await apiCallFnforPostForOpenUrl(
        CHANGE_PASSWORD,
        changePasswordPayload
      );

      if (changePasswordResponse.statusCode === 200) {
        // console.log(changePasswordResponse.data);
        setSuccess(true);
        setValue(changePasswordResponse.statusCode);
        setEmail("");
        setNewPassword("");
        setConfirmPassword("");
        
      } else {
        setGeneralError(changePasswordResponse.message);
      }
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      setShowLoader(false);
      setGeneralError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="login-form">
      <div className="text-center">
        <h3 className="title">Forgot Password?</h3>
        <p>Please Enter Your Email</p>
      </div>

      {success && otpVerified ? (
        <form  >
          {generalError && (
            <p className="alert alert-danger mb-4">
              {generalError ? generalError : ""}
            </p>
          )}
          <div className="mb-4">
            <label className="mb-1 text-dark">Email</label>
            <input
              type="email"
              className="form-control form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="hello@example.com"
            />
          </div>
          <div className="mb-4">
            <label className="mb-1 text-dark">New Password</label>
            <input
              type="password"
              className="form-control form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="mb-1 text-dark">Confirm Password</label>
            <input
              type="password"
              className="form-control form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
           
          <div className="text-center mb-4">
            <button   className="btn btn-primary btn-block" onClick={handleNewPasswordSubmit}>
              Submit
            </button>
          </div>
        </form>
      ) : success ? (
        <form >
          {generalError && (
            <p className="alert alert-danger mb-4">
              {generalError ? generalError : ""}
            </p>
          )}
          <div className="mb-4">
            <label className="mb-1 text-dark">OTP</label>
            <input
              type="text"
              className="form-control form-control"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              // placeholder=" "
            />
          </div>
          <div className="form-row d-flex justify-content-between mt-4 mb-2">
            <div className="mb-4"></div>
          </div>
          <div className="text-center mb-4">
            <button type="button" className="btn btn-primary btn-block" onClick={handleOtpSubmit}>
              Submit
            </button>
          </div>
        </form>
      ) : (
        <form >
          {generalError && (
            <p className="alert alert-danger mb-4">
              {generalError ? generalError : ""}
            </p>
          )}
          <div className="mb-4">
            <label className="mb-1 text-dark">Email</label>
            <input
              type="email"
              className="form-control form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="hello@example.com"
            />
          </div>
          
          <div className="text-center mb-4">
            <button  className="btn btn-primary btn-block" onClick={handleFormSubmit}>
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
