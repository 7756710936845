import React, { useState, useEffect } from "react";
import useApiServices from "../../Utils/useApiService";
import swal from "sweetalert";
import { GET_STATIC_QR } from "../../Utils/Constants";
import { useSelector } from "react-redux";

/** 
 * This component renders a static QR code and allows downloading or sharing it.
 * @param {function} setCodeImage - useState function to update QR image.
 * @returns {ReactNode} - this component returns an image for the static QR code.
 * */

const QR = ({ setCodeImage }) => {
  const { apiCallFnforPost } = useApiServices();
  const Type = localStorage.getItem("typeOfServices");
  const paymentType = Type ? JSON.parse(Type) : null;

  const [hexCode, setHexCode] = useState(null);
  const [qrCodeImage, setQRCodeImage] = useState(null);

  const { mid } = useSelector((state) => state.userData);

  const generateQRCode = async () => {
    try {
      // Convert base64 string to Blob
      // const hex = " "
      const byteCharacters = atob(hexCode);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const qrCodeBlob = new Blob([byteArray], { type: "image/png" });
      
      const imageUrl = URL.createObjectURL(qrCodeBlob);
      setQRCodeImage(imageUrl);
      setCodeImage(imageUrl);
      console.log("inside1",qrCodeImage)
    } catch (error) {
      console.error("Error:", error.message);
      swal("Error", "An error occurred while generating QR code", "error");
    }
  };

  const getStaticQr = async () => {
    try {
      const response = await apiCallFnforPost(GET_STATIC_QR);
      if (response.statusCode === "OK") {
        setHexCode(response.responseData);
      } else {
        setHexCode([]);
      }
    } catch (error) {}
  };

  const shareQRCode = async () => {
    if (navigator.share && qrCodeImage) {
      try {
        const response = await fetch(qrCodeImage);
        const blob = await response.blob();
        const file = new File([blob], "qr-code.png", { type: "image/png" });

        await navigator.share({
          files: [file],
          title: "QR Code",
          text: "Here is the QR code you requested.",
        });
      } catch (error) {
        console.error("Sharing failed:", error);
        swal("Error", "Failed to share the QR code", "error");
      }
    } else {
      swal("Error", "Web Share API is not supported in this browser", "error");
    }
  };

  useEffect(() => {
    getStaticQr();
  }, []);

  useEffect(() => {
    if (hexCode) {
      generateQRCode();
    }
  }, [hexCode]);

  return (
    <div className="row">
      <div className="col-12">
        {qrCodeImage ? (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img src={qrCodeImage} alt="QR Code" />
            {/* <button
              className="btn btn-primary mt-3"
              onClick={shareQRCode}
            >
              Share QR Code
            </button> */}
          </div>
        ) : (
          <h3>Service not found!</h3>
        )}
      </div>
    </div>
  );
};

export default QR;
