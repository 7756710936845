import { createSlice } from "@reduxjs/toolkit";

/**
 * Slice for managing user state.
 * Contains initial state and reducers to update the state.
 */
const userSlice = createSlice({
  name: "userData",
  initialState: {
    typeOfServices:[],
    type:null,
    isAuthenticated: false,
    token: null,
    mid: null,
    profileData: null,
    
  },
  reducers: {

    /**
     * Update the states.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action containing the payload with the new registered ID.
     */
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload ;
    },
    setMid: (state, action) => {
      state.mid = action.payload;
    },
    setuserType: (state, action) => {
      state.type = action.payload;
    },
    
    setTypeOfServices: (state, action) => {
      state.typeOfServices = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    
  },
});

/**
 * Action creators generated from the slice.
 */
export const {
    setToken,
    setMid,
    setIsAuthenticated,
    setuserType,
    setTypeOfServices,
    setProfileData
} = userSlice.actions;

export default userSlice.reducer;
