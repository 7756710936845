export const javacode = `
public String decryptData(String encryptedString, String encryptKey) {
    try {
        byte[] encryptedData = Base64.getDecoder().decode(encryptedString);

        byte[] ivBytes = new byte[16];
        System.arraycopy(encryptedData, 0, ivBytes, 0, 16);

        byte[] encryptedBytes = new byte[encryptedData.length - 16];
        System.arraycopy(encryptedData, 16, encryptedBytes, 0, encryptedData.length - 16);

        SecretKeySpec secretKeySpec = new SecretKeySpec(encryptKey.getBytes("UTF-8"), "AES");
        IvParameterSpec ivParameterSpec = new IvParameterSpec(ivBytes);

        Cipher cipher = Cipher.getInstance("AES/CBC/PKCS5Padding");
        cipher.init(Cipher.DECRYPT_MODE, secretKeySpec, ivParameterSpec);
        byte[] decryptedData = cipher.doFinal(encryptedBytes);

        return new String(decryptedData, "UTF-8");

    } catch (Exception e) {
        LOGGER.error("Exception in decryptData(): encryptKey-" + encryptKey + ": Message-" + e.getMessage());
    }
    return null;
}
`

export const nodejscode = `
const crypto = require('crypto');

function decryptData(encryptedString, encryptKey) {
    try {
        const encryptedData = Buffer.from(encryptedString, 'base64');

        // Extract IV
        const ivBytes = encryptedData.slice(0, 16);
        const encryptedBytes = encryptedData.slice(16);

        const decipher = crypto.createDecipheriv('aes-128-cbc', encryptKey, ivBytes);

        let decryptedData = decipher.update(encryptedBytes, 'binary', 'utf8');
        decryptedData += decipher.final('utf8');

        return decryptedData;
    } catch (error) {
        console.error(\Exception in decryptData(): encryptKey - \${encryptKey} : Message - \${error.message}\);
        return null;
    }
}

// Example usage:
const encryptedString = "xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsyzoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7wofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=";
const encryptKey = "your_encrypt_key_here";

const decryptedData = decryptData(encryptedString, encryptKey);
if (decryptedData !== null) {
    console.log(\Decrypted data: \${decryptedData}\);
} else {
    console.log("Failed to decrypt data.");
}
`

export const pythoncode = `
import base64
from Crypto.Cipher import AES
from Crypto.Util.Padding import unpad
import logging

def decrypt_data(encrypted_string, encrypt_key):
    try:
        encrypted_data = base64.b64decode(encrypted_string)

        iv_bytes = encrypted_data[:16]
        encrypted_bytes = encrypted_data[16:]

        cipher = AES.new(encrypt_key.encode('utf-8'), AES.MODE_CBC, iv=iv_bytes)
        decrypted_data = unpad(cipher.decrypt(encrypted_bytes), AES.block_size)

        return decrypted_data.decode('utf-8')

    except Exception as e:
        logging.error("Exception in decrypt_data(): encrypt_key - {} : Message - {}".format(encrypt_key, str(e)))
        return None

`
export const phpcode = `
<?php
function decryptData($encryptedString, $encryptKey) {
    try {
        $encryptedData = base64_decode($encryptedString);

        $ivBytes = substr($encryptedData, 0, 16);
        $encryptedBytes = substr($encryptedData, 16);

        $decryptedData = openssl_decrypt($encryptedBytes, 'AES-128-CBC', $encryptKey, OPENSSL_RAW_DATA, $ivBytes);

        return $decryptedData;

    } catch (Exception $e) {
        error_log("Exception in decryptData(): encryptKey - " . $encryptKey . ": Message - " . $e->getMessage());
        return null;
    }
}
?>`

export const rubycode = `
require 'base64'
require 'openssl'
require 'logger'

def decrypt_data(encrypted_string, encrypt_key)
  begin
    encrypted_data = Base64.decode64(encrypted_string)
    
    iv_bytes = encrypted_data[0..15]
    encrypted_bytes = encrypted_data[16..-1]
    
    decipher = OpenSSL::Cipher.new('AES-128-CBC')
    decipher.decrypt
    decipher.key = encrypt_key
    decipher.iv = iv_bytes
    
    decrypted_data = decipher.update(encrypted_bytes) + decipher.final
    
    return decrypted_data
    
  rescue StandardError => e
    logger = Logger.new(STDOUT)
    logger.error("Exception in decrypt_data(): encrypt_key - #{encrypt_key} : Message - #{e.message}")
    return nil
  end
end
`

export const perlcode = `
use strict;
use warnings;
use MIME::Base64;
use Crypt::CBC;

sub decrypt_data {
    my ($encrypted_string, $encrypt_key) = @_;

    eval {
        my $cipher = Crypt::CBC->new(
            -key         => $encrypt_key,
            -literal_key => 1,
            -cipher      => 'Cipher::AES',
            -iv          => substr($encrypted_string, 0, 16),
            -header      => 'none',
            -padding     => 'standard',
            -keysize     => 16,
        );

        my $decrypted_data = $cipher->decrypt(substr($encrypted_string, 16));

        return $decrypted_data;
    };
    if ($@) {
        warn "Exception in decrypt_data(): encrypt_key - $encrypt_key : Message - $@\n";
        return undef;
    }
}

# Example usage:
my $encrypted_string = "xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsyzoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7wofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=";
my $encrypt_key = "your_encrypt_key_here";

my $decrypted_data = decrypt_data($encrypted_string, $encrypt_key);
if (defined $decrypted_data) {
    print "Decrypted data: $decrypted_data\n";
} else {
    print "Failed to decrypt data.\n";
}
`

export const jscode = `
async function decryptData(encryptedString, encryptKey) {
    try {
        const encryptedData = Uint8Array.from(atob(encryptedString), c => c.charCodeAt(0));

        // Extract IV
        const ivBytes = encryptedData.slice(0, 16);
        const encryptedBytes = encryptedData.slice(16);

        // Convert encryption key to ArrayBuffer
        const keyBuffer = await crypto.subtle.importKey(
            "raw",
            new TextEncoder().encode(encryptKey),
            { name: "AES-CBC" },
            false,
            ["decrypt"]
        );

        // Decrypt data
        const decryptedData = await crypto.subtle.decrypt(
            { name: "AES-CBC", iv: ivBytes },
            keyBuffer,
            encryptedBytes
        );

        return new TextDecoder().decode(decryptedData);
    } catch (error) {
        console.error(\Exception in decryptData(): encryptKey - \${encryptKey} : Message - \${error.message}\);
        return null;
    }
}

// Example usage:
const encryptedString = "xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsyzoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7wofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=";
const encryptKey = "your_encrypt_key_here";

decryptData(encryptedString, encryptKey)
    .then(decryptedData => {
        if (decryptedData !== null) {
            console.log(\Decrypted data: \${decryptedData}\);
        } else {
            console.log("Failed to decrypt data.");
        }
    });`


// encryption codes 
export const encnodejs = `
const crypto = require('crypto');

function encryptData(plainText, encryptKey) {
    try {
        const iv = crypto.randomBytes(16); // Generate a random IV
        const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(encryptKey, 'utf-8'), iv);
        
        let encryptedData = cipher.update(plainText, 'utf-8', 'base64');
        encryptedData += cipher.final('base64');

        const combinedBytes = Buffer.concat([iv, Buffer.from(encryptedData, 'base64')]);

        return combinedBytes.toString('base64');
    } catch (error) {
        console.error(\Exception in decryptData(): encryptKey - \${encryptKey} : Message - \${error.message}\);
        return null;
    }
}

// Example usage:
const plainText = 'Hello, world!';
const encryptKey = 'yourEncryptionKey';

const encryptedText = encryptData(plainText, encryptKey);
console.log("Encrypted text:", encryptedText);
`;

export const encpython = `
import base64
from Crypto.Cipher import AES
from Crypto.Random import get_random_bytes

def encrypt_data(plain_text, encrypt_key):
    try:
        iv = get_random_bytes(16)  # Generate a random IV
        cipher = AES.new(encrypt_key.encode('utf-8'), AES.MODE_CBC, iv)
        
        encrypted_data = cipher.encrypt(pad(plain_text.encode('utf-8'), AES.block_size))

        combined_bytes = iv + encrypted_data

        return base64.b64encode(combined_bytes).decode('utf-8')
    except Exception as e:
        print(f"Exception in encrypt(): encryptMData - {encrypt_key}: Message - {str(e)}")
        return None

# Example usage:
plain_text = 'Hello, world!'
encrypt_key = 'yourEncryptionKey'

encrypted_text = encrypt_data(plain_text, encrypt_key)
print("Encrypted text:", encrypted_text)

`;

export const encjava = `public String encrypt Data (String plain text ,String encrypt Key ) {
    try{
        SecretKeySpecsecretKeySpec=newSecretKeySpec(encryptKey.getBytes("UTF-8"),"AES"); Cipher cipher = Cipher.getInstance("AES/CBC/PKCS5Padding"); cipher.init(Cipher.ENCRYPT_MODE, secretKeySpec);
      
        byte[]ivBytes=cipher.getParameters().getParameterSpec(IvParameterSpec.class).getIV();
      
        byte[]encryptedData=cipher.doFinal(plaintext.getBytes("UTF-8"));
      
        byte[]combined Bytes=newbyte[ivBytes.length+encryptedData.length];
      
        System.arraycopy(ivBytes, 0, combinedBytes, 0, ivBytes.length); System.arraycopy(encryptedData,0,combinedBytes,ivBytes.length,encryptedData.length); return Base64.getEncoder().encodeToString(combinedBytes);
      
      } catch(Exceptione){
            LOGGER.error("Exceptioninencrypt():encryptMData-"+encryptKey+":Message-"+
      e.getMessage());
      }
    }
      returnnull;
    `;

export const encjs = `
const crypto = require('crypto');

function encryptData(plainText, encryptKey) {
    try {
        const iv = crypto.randomBytes(16); // Generate a random IV
        const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(encryptKey, 'utf-8'), iv);
        
        let encryptedData = cipher.update(plainText, 'utf-8', 'base64');
        encryptedData += cipher.final('base64');

        const combinedBytes = Buffer.concat([iv, Buffer.from(encryptedData, 'base64')]);

        return combinedBytes.toString('base64');
    } catch (error) {
        console.error(\Exception in decryptData(): encryptKey - \${encryptKey} : Message - \${error.message}\);
        return null;
    }
}

// Example usage:
const plainText = 'Hello, world!';
const encryptKey = 'yourEncryptionKey';

const encryptedText = encryptData(plainText, encryptKey);
console.log("Encrypted text:", encryptedText);

`;

export const encruby = `
require 'openssl'
require 'base64'

def encrypt_data(plain_text, encrypt_key)
  begin
    cipher = OpenSSL::Cipher.new('AES-256-CBC')
    cipher.encrypt
    key = OpenSSL::Digest::SHA256.new(encrypt_key).digest
    iv = cipher.random_iv

    cipher.key = key
    encrypted_data = cipher.update(plain_text) + cipher.final

    combined_bytes = iv + encrypted_data

    return Base64.strict_encode64(combined_bytes)
  rescue StandardError => e
    puts "Exception in encrypt(): encryptMData - #{encrypt_key}: Message - #{e.message}"
    return nil
  end
end

# Example usage:
plain_text = 'Hello, world!'
encrypt_key = 'yourEncryptionKey'

encrypted_text = encrypt_data(plain_text, encrypt_key)
puts "Encrypted text: #{encrypted_text}"

`;

export const encperl = `
use strict;
use warnings;
use MIME::Base64;
use Crypt::CBC;
use Crypt::Random qw( makerandom_octet );

sub encrypt_data {
    my ($plain_text, $encrypt_key) = @_;
    eval {
        my $cipher = Crypt::CBC->new(
            -key    => $encrypt_key,
            -cipher => 'AES',
            -iv     => makerandom_octet(Length => 16),
            -header => 'none',
            -literal_key => 1,
            -padding => 'standard',
            -keysize => 256 / 8
        );

        my $encrypted_data = $cipher->encrypt($plain_text);
        my $iv = $cipher->iv();
        my $combined_bytes = $iv . $encrypted_data;

        return encode_base64($combined_bytes);
    };
    if ($@) {
        my $error_message = $@;
        chomp($error_message);
        warn "Exception in encrypt(): encryptMData - $encrypt_key: Message - $error_message\n";
        return;
    }
}

# Example usage:
my $plain_text = 'Hello, world!';
my $encrypt_key = 'yourEncryptionKey';

my $encrypted_text = encrypt_data($plain_text, $encrypt_key);
print "Encrypted text: $encrypted_text\n" if $encrypted_text;

`;

export const encphp = `

<?php

function encryptData($plainText, $encryptKey) {
    try {
        $iv = openssl_random_pseudo_bytes(16); // Generate a random IV
        $encryptedData = openssl_encrypt($plainText, 'aes-256-cbc', $encryptKey, OPENSSL_RAW_DATA, $iv);

        $combinedBytes = $iv . $encryptedData;

        return base64_encode($combinedBytes);
    } catch (Exception $e) {
        error_log("Exception in encrypt(): encryptMData - $encryptKey: Message - " . $e->getMessage());
        return null;
    }
}

// Example usage:
$plainText = 'Hello, world!';
$encryptKey = 'yourEncryptionKey';

$encryptedText = encryptData($plainText, $encryptKey);
echo "Encrypted text: " . $encryptedText . "\n";
?>

`;