import React, { useState } from "react";
import { FRM_URL } from "../Utils/Constants";
import CopyButton from "../Common/CopyButton/CopyButton";
import useApiServices from "../Utils/useApiService";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import styles from "./frm.module.css";

/**
 * This component renders  Api documentation for Fraud Management.
 * It displays Fraud Management Api Urls , request parameters, responses, etc.
 * @returns {ReactNode} A React element that renders Fraud-Management documentation.
 */

const FRM = () => {
  const { apiCallFnforPostForOpenUrl } = useApiServices();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [payload, setPayload] = useState({
    email: "",
    ifscCode: "",
    imei: "",
    mid: "",
    mobile: "",
    vpa: "",
  });

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleFrm = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(FRM_URL, payload);

      setResponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleClearData = () => {
    setPayload((prev) => ({
      ...prev,
      email: "",
      ifscCode: "",
      imei: "",
      mid: "",
      mobile: "",
      vpa: "",
    }));
  };

  return (
    <div className="content-body">
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-xl-8 col-xxl-8 pr-1px max-h-600px">
            <div className="card rounded-3">
              <div class={`card-body p-3 ${styles.overflow}`}>
                <h4 className="font-weight-bold fw-bold">
                  Fraud management docs
                </h4>
                <div className="mt-4">
                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://api.icepe.in/MerchantPanel/merchant/frm-block/vpa-request`}</div>
                            <div>
                              <CopyButton
                                text={`https://api.icepe.in/MerchantPanel/merchant/frm-block/vpa-request`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-2">
                    <p className={`mb-2 text-black ${styles.font700}`}>
                      Note :- For Uat testing :- enckey:
                      "8b3a40g766d94345b9d82ad02cfb1df1"
                    </p>
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , Use this Dummy payload with same values
                      given below
                    </h4>
                  </div>
                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            email: "example@example.com",
                            ifscCode: "ABCD0123456",
                            imei: "123456789012345",
                            mid: "SWIPEPROD100007",
                            mobile: "1234567890",
                            vpa: "example@upi",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          email: "example@example.com",
                          ifscCode: "ABCD0123456",
                          imei: "123456789012345",
                          mid: "SWIPEPROD100007",
                          mobile: "1234567890",
                          vpa: "example@upi",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="font-weight-bold fw-bold">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              email
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="email"
                                type="email"
                                value={payload.email}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              ifscCode
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="ifscCode"
                                type="text"
                                value={payload.ifscCode}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              imei
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="imei"
                                type="text"
                                value={payload.imei}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              mid
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="mid"
                                type="text"
                                value={payload.mid}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              mobile
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="mobile"
                                type="text"
                                value={payload.mobile}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              vpa
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="vpa"
                                type="text"
                                value={payload.vpa}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="mb-2 text-black">
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>email</td>
                          <td>string</td>
                          <td>email address</td>
                          <td>example@example.com</td>
                        </tr>
                        <tr>
                          <td>ifscCode</td>
                          <td>string</td>
                          <td>ifsc code of account</td>
                          <td>ABCD0123456</td>
                        </tr>
                        <tr>
                          <td>imei</td>
                          <td>string</td>
                          <td>imei number</td>
                          <td>123456789012345</td>
                        </tr>
                        <tr>
                          <td>mid</td>
                          <td>string</td>
                          <td>merchant id</td>
                          <td>SWIPEPROD100007</td>
                        </tr>
                        <tr>
                          <td>mobile</td>
                          <td>string</td>
                          <td>mobile number</td>
                          <td>1234567890</td>
                        </tr>
                        <tr>
                          <td>vpa</td>
                          <td>string</td>
                          <td>vpa</td>
                          <td>example@upi</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "VPA Saved successfully",
                          status: "success",
                          statusCode: 200,
                          data: {
                            mid: "SWIPEPROD100007",
                            vpa: "example@upi",
                            imei: "016752416040671",
                            email: "abc@gmail.com",
                            mobile: "1234567890",
                            ifscCode: "SBIN0011513",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "VPA Saved successfully",
                        status: "success",
                        statusCode: 200,
                        data: {
                          mid: "SWIPEPROD100007",
                          vpa: "example@upi",
                          imei: "016752416040671",
                          email: "abc@gmail.com",
                          mobile: "1234567890",
                          ifscCode: "SBIN0011513",
                        },
                        duplicateDate: null,
                        data1: null,
                        totalAmount: null,
                        count: 0,
                        numberOfTransactions: 0,
                      },
                      null,
                      2
                    )}
                  </pre>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`col-xl-4 col-xxl-4 pl-1px max-h-600px ${styles.cardmargin}`}
          >
            <div className=" card rounded-3">
              <div className="card-body p-3">
                <h4 className="font-weight-bold fw-bold">VPA verification</h4>
                <div className={`${styles.relative}`}>
                  <div
                    className={`d-flex gap-2 align-items-center ${styles.absolute}`}
                  >
                    <button
                      onClick={handleClearData}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handleFrm}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Try it"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        email: payload.email,
                        ifscCode: payload.ifscCode,
                        imei: payload.imei,
                        mid: payload.mid,
                        mobile: payload.mobile,
                        vpa: payload.vpa,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-h-300px">
                  <div
                    class={`d-flex justify-content-between align-item-center pt-2 ${styles.padding}`}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className=" max-width-300px">
                      {response?.status} <span>{response?.statusCode}</span>
                    </p>
                  </div>

                  <div class={`card-body pt-2 ${styles.card}`}>
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(response?.data, null, 2)}
                    </pre>

                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(response?.message, null, 2)}
                    </pre>
                  </div>
                  {/* <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(response?.data, null, 2)}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FRM;
