import { combineReducers, configureStore } from "@reduxjs/toolkit";
 
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userSlice from "./Slices/userSlice";
import privilagesSlice from "./Slices/privilagesSlice";
import onBoardSlice from "./Slices/onBoardSlice";
import utilSlice from "./Slices/utilSlice";
import reportFilterSlice from "./Slices/reportFilterSlice";
// import reportFilterSlice from "./Slices/reportFilterSlice";
 

// Define slices
/**
 * Redux slices for various parts of the state.
 * Importing individual slices for user data, privileges, onboarding process, utilities, and report filters.
 */
const userReducer = userSlice;
const privilagesReducer = privilagesSlice;
const onBoardReducer = onBoardSlice; 
const reportFilterReducer = reportFilterSlice; 
const utilsReducer = utilSlice; 


// Combine reducers
/**
 * Combining individual slice reducers into a single root reducer.
 */
const rootReducer = combineReducers({
    userData: userReducer,
    privilages: privilagesReducer,
    onBoard: onBoardReducer,
    reportFilter: reportFilterReducer,
    utils: utilsReducer
});

/**
 * Configuration object for redux-persist.
 * Specifies the storage engine and the slices of state to be persisted.
 */
const persistConfig = {
  key: "root", 
  storage, 
  whitelist: ["userData", "privilages", "onBoard"]
};

/**
 * Enhancing the root reducer with persistence capabilities using redux-persist.
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Configuring the Redux store with the persisted reducer.
 * The configureStore function from Redux Toolkit sets up the store with good defaults.
 */
const store = configureStore({
  reducer: persistedReducer,
});

/**
 * Creating a persistor object using redux-persist.
 * This object is used to persist the store.
 */
const persistor = persistStore(store);

export { store, persistor };
