import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getKeyPressed, isEmailIdRegexIsValid } from "../Utils/validations";
import swal from "sweetalert";

import { GET_ALL_USER, USER_UPDATE } from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
import styles from "./style.module.css";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

/**
 * EditUser component for displaying and updating user details.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.trigger - A flag to trigger the data fetching when it changes.
 * @param {string} props.id - The ID of the user to be edited.
 *
 * @returns {JSX.Element} - The rendered EditUser component.
 */
const EditUser = ({ trigger, id }) => {
  const [userData, setUserData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
 
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const { state } = useLocation();

  const [formDataInputs, setFormDataInputs] = useState({
    Name: "",

    password: "",
    MobileNumber: "",

    emailId: "",
  });

  useEffect(() => {
    setFormDataInputs({
      Name: userData.fullName,
      
      password: userData.password,
      MobileNumber: userData.mobile,
       
      emailId: userData.email,
    });
  }, [userData]);

  /**
   * Fetches user data from the API and sets it to the component state.
   * Also sets the privileges based on the fetched data.
   *
   * @async
   * @function getUserData
   * @returns {Promise<void>}
   */
  const getUserData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_USER + id);
      if (response.statusCode === 200) {
        setUserData(response?.data);

        if (response?.data?.subpriveledgeprivilege) {
          const data = response?.data?.subpriveledgeprivilege.map(
            (item) => item.privilegeSubTypeId
          );
          setPrivilages(data);
        }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Handles changes in the form input fields.
   * Updates the form data state with the new input values.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   */
  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, "");
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };

  /**
   * Handles changes to privilege checkboxes.
   * Updates the privileges state based on whether the checkbox is checked or unchecked.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   */
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = parseInt(e.target.name);
    const head = e.target.dataset.head;

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };

  /**
   * Handles changes to head privilege checkboxes.
   * Updates both the head privileges and sub-privileges states.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   */
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = parseInt(e.target.name);

    const subtypeArray = privilagesData.find((item) => {
      return item.privilegeId == adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.privilegeSubTypeId;
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };

  useEffect(() => {
    getUserData();
  }, [trigger]);

  const { allPrivilagesData } = useSelector((state) => state.privilages);

  useEffect(() => {
    setPrivilagesData(allPrivilagesData);
  }, [allPrivilagesData]);

  /**
   * Checks and updates the head privileges based on selected sub-privileges.
   *
   * @param {Array} privilagesData - The privileges data from the store.
   */
  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];

    privilagesData?.forEach((item1, index) => {
      const kjhfd = item1.subTypes.map((item) =>
        privilages.includes(item.privilegeSubTypeId)
      );

      if (!kjhfd.includes(false)) {
        setHeadPrivilages((prev) => {
          return [...prev, item1.privilegeId];
        });
      }
    });
  };

  /**
   * Handles form submission.
   * Validates the form data and submits it to the API.
   *
   * @param {React.FormEvent<HTMLButtonElement>} e - The submit event object.
   * @returns {Promise<void>}
   */
  const handleSubmit = async (e) => {
    if (formDataInputs.Name === "") {
      swal({
        title: "Alert!",
        text: "Please Enter User Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }
    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }
    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });
    const payload = {
      fullName: formDataInputs.Name.trim(),
      //   masterMerchantId: formDataInputs.masterMerchantName,
      password: formDataInputs.password.trim(),
      mobile: formDataInputs.MobileNumber,
      //   midprifix: formDataInputs.Prifix.trim(),
      email: formDataInputs.emailId.trim(),
      privileges: privilegs,
      merchantUserId: id,
    };

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (swalConfirm) {
      const res = await apiCallFnforPut(USER_UPDATE, payload);

      if (res.statusCode === 200) {
        getUserData();
        swal({
          title: "Success",
          text: "User Updated Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          Name: "",
          password: "",
          MobileNumber: "",
          Prifix: "",
          emailId: "",
        });
      } else {
        swal({
          title: "Failed",
          text: res.data.message,
          icon: "error",
        });
      }
    }
  };

  /**
   * Validates the email address on blur event.
   *
   * @param {React.FocusEvent<HTMLInputElement>} e - The blur event object.
   */
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };

  useEffect(() => {
    checkHeadPri(privilagesData);
  }, [privilagesData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div>
              <div className="">
                <div className="">
                  <div>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="Name"
                              value={formDataInputs.Name}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="email"
                              className="form-control"
                              name="emailId"
                              value={formDataInputs.emailId}
                              onChange={handleformDataInputs}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onBlur={handleValidations}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label htmlFor="MobileNumber" className="form-label">
                            Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            onChange={handleformDataInputs}
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      {/* <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Prifix
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="Prifix"
                    value={formDataInputs.Prifix}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
            </Col> */}
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row mt-3">
                      <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                        Privileges
                      </lable>
                      <div className="d-flex flex-column gap-4">
                        {/* ... */}
                        {privilagesData &&
                          privilagesData.map((itemMain, i) => (
                            <div key={i} className="d-flex flex-column">
                              <div className="form-label mt-4 fs-4 mx-3">
                                <div className="s-lg form-check">
                                  <label className="mt-1">
                                    {itemMain.privilegeName}
                                  </label>
                                  <input
                                    id="flexSwitchCheckDefault"
                                    className="form-check-input fs-4"
                                    type="checkbox"
                                    name={itemMain.privilegeId}
                                    checked={HeadPrivilages.includes(
                                      itemMain.privilegeId
                                    )}
                                    onChange={handleHeadPrivilages}
                                  />
                                </div>
                                <hr />
                              </div>

                              <div className="d-flex flex-wrap ">
                                {itemMain.subTypes.map((item, i) => (
                                  <div
                                    key={i}
                                    className={`d-flex align-items-center gap-4 px-3 py-1 rounded rounded-5 mt-2 mx-2   form-group ${styles["SubTypeStyle"]}`}
                                  >
                                    <label className="form-label mt-1 fs-4 mx-1">
                                      {item.privilegeSubTypeName}
                                    </label>
                                    <div className="s-lg form-check form-switch">
                                      <input
                                        id="flexSwitchCheckDefault"
                                        className="form-check-input fs-4"
                                        data-head={itemMain.privilegeId}
                                        type="checkbox"
                                        name={item.privilegeSubTypeId}
                                        checked={privilages.includes(
                                          item.privilegeSubTypeId
                                        )}
                                        onChange={handlePrivilages}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        {/* ... */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center p-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
