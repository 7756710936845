import { createSlice } from "@reduxjs/toolkit";

/**
 * Slice for managing utility state.
 * Contains initial state and reducers to update the state.
 */
const utilSlice = createSlice({
  name: "utilsData",
  initialState: {
   loading:false,
  },
  reducers: {
    /**
     * Update the states.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action containing the payload with the new registered ID.
     */
    requestSent: (state) => {
         state.loading = true;
    },
    requestFailed: (state) => {
          state.loading = false;
    },
    responseReceived: (state) => {
          state.loading = false;
    }
  },
});

/**
 * Action creators generated from the slice.
 */
export const {
   requestSent,
   requestFailed,
   responseReceived
} = utilSlice.actions;

export default utilSlice.reducer;