import React from "react";
import Tooltip from "@mui/material/Tooltip";

/**
 * This component renders a IconWrapper component.
 *
 * @param {string} title title shown on icon.
 * @param {string} children actual icon coming as children.
 * @returns {ReactNode} A React element that renders a reusable component for IconWrapper.
 */
const IconWrapper = ({ title, children }) => {
  return (
    <Tooltip title={title}><span className="fs-3 me-3">{children}</span>
    
    </Tooltip>
  );
};

export default IconWrapper;