import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { keywords } from "../../Utils/ContantKeys";
import { useDispatch } from "react-redux";
import { setTransferTypeObject } from "../../store/Slices/reportFilterSlice";


/**
 * This Component renders a Transfer Type
 * 
 * @param {string} type - this is report type.
 * @param {function} callDispatch - this is redux action function to store transfer type value in redux store.
 * @returns {ReactNode} Component returns select option list for transfer Type.
 */

const TransferTP = ({ type, callDispatch }) => {
  const [transferType, setTransferType] = useState();

  const dispatch = useDispatch();

  const selectStyles = {
    border: "2px solid red",
    borderRadius: "0",
    marginTop: "10px", // Adjust as needed
  };

  const handleChange = (e) => {
    setTransferType(e.target.value);
    // getFilterValues(e.target.value, keywords.transferType);
  };

  useEffect(() => {
    dispatch(setTransferTypeObject(transferType));
  }, [callDispatch]);

  return (
    <fieldset className="border-2 rounded-0 mt-2 mb-2">
      <legend className="text-start text-black">Transfer Type</legend>
      <div className="field-set-form">
        <Row className="g-2">
          <Col lg={12} sm={12}>
            <div className="form-group">
              <select
                className="form-control fs-4"
                onChange={handleChange}
                value={transferType}
                //   onChange={(e) => setTransferType(e.target.value)}
              >
                <option value="">Select</option>
                <option value="UPI">UPI</option>
                <option value="IMPS">IMPS</option>
                <option value="RTGS">RTGS</option>
                <option value="NEFT">NEFT</option>
              </select>
            </div>
          </Col>
        </Row>
      </div>
    </fieldset>
  );
};

export default TransferTP;
