import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import SideBar from "./DashBoard/SideBar";
import Header from "./DashBoard/Header";
import FloatingIcon from "./Common/FloatingIcon/Floating";
import Cookies from "js-cookie";
import IdealTimerContainer from "./Common/IdealTimerContainer";

const ProtectedRoute = ({ children }) => {
  const redirect = "/";
  const { isAuthenticated } = useSelector((state) => state.userData);
  const access_token = Cookies.get("access_token");

  const { pathname } = useLocation();
  console.log(pathname);

  if (!access_token) {
    return <Navigate to={redirect} />;
  }

  return children ? (
    <>
      <SideBar />

      <Header />

      {children}
    </>
  ) : (
    <>
      <IdealTimerContainer />
      {pathname === "/superset" ? null : (
        <>
          <SideBar />
          <Header />
        </>
      )}
      <Outlet /> ;
    </>
  );
};

export default ProtectedRoute;
