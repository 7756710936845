import React, { Component } from 'react';

class ErrorComponent extends Component {
  render() {
    // Throw an error inside the render method
    throw new Error('This is a test error!');
    return <div>This will not be displayed.</div>;
  }
}

export default ErrorComponent;
