export const jsonData = [
  {
    data: "Bon6NhrN0qHKCtiBf1G4Vhd3agrDW8VTbXYDoe9mNbiZ+6wTv9B13aiU90N3GWW39A/bJH5VuBsw70HQ0mSkA6Iar88rEqUjrWpXNl0uiKKSlYkpTYpOi08RZ82z847BDIscNFMmULvWyPuiPI+qhk3uifSf59fu1Jr9lWCsT1WtfI3KKDs9R2m/sWvULcv8n9NB1DO94kk5P2ZbIF5ONXE+1Qce7Y+A2LRun9qUNnsrhw8c5kueP3D0a0rk2Lu5JEB2X+y/ARE01xkDhYyxzSPmi9kFgrhdELiQ4xaCqn8OIu0YwfvOV4Q7vLXdPDLV3GSyJ1ts/BRvzP/L/uBjWQ==",
    mid: "SWIPEPROD100007",
    signature: "782923988",
  },
];

export const transactionData = {
  amount: "100",
  orderNo: "ORD23072214392200916",
  txnStatus: "success",
  txnRespCode: "200",
  mid: "SWIPEPROD100007",
  currency: "INR",
  txnDate: "2023-07-22 14:39:20.796",
  customerIfsc: "none",
  userVpa: "XXXXXXXXXX@ybl",
  custRefNo: "XXXXXXXXXX",
  txnId: "47209036589869018322",
  paymentType: "UPI",
  udf3: "",
  udf4: "",
  udf1: "",
  udf2: "",
};

export const warningData = {
  amount: "100",

  orderNo: "ORD23072214392200916",

  txnStatus: "warning",
  txnRespCode: "1003",
  mid: "SWIPEPROD100007",

  currency: "INR",

  txnDate: "2023-07-2214:39:20.796",

  customerIfsc: "none",

  userVpa: "XXXXXXXXXX@ybl",

  custRefNo: "XXXXXXXXXX",

  txnId: "47209036589869018322",

  paymentType: "UPI",
  udf3: "",

  udf4: "",

  udf1: "",

  udf2: "",
};

export const blackList = {
  amount: "100",

  orderNo: "ORD23072214392200916",

  txnStatus: "blacklist",
  txnRespCode: "1002",
  mid: "SWIPEPROD100007",

  currency: "INR",

  txnDate: "2023-07-2214:39:20.796",

  customerIfsc: "none",

  userVpa: "XXXXXXXXXX@ybl",

  custRefNo: "XXXXXXXXXX",

  txnId: "47209036589869018322",

  paymentType: "UPI",
  udf3: "",

  udf4: "",

  udf1: "",

  udf2: "",
};

export const blockVpaPayload = {
  doBlock: true,
  email: "",
  ifscCode: "",
  imei: "",
  letitude: "",
  longitude: "",
  mid: "",
  mobile: "",
  vpa: "",
};

export const payoutSampleRequestIMPS = {
  apikey: "7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q",
  beneficiaryDetails: {
    emailAddress: "abc@gmail.com",
    ifscCode: "BARB0SAILAN",
    mobileNumber: "9752211753",
    payeeName: "tester",
  },
  purposeMessage: "Fortesting",
  referenceId: "fgfghwytr",
  secrete: "1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC",
  toAccount: "44490100006879",
  toUpi: "8827742560@ybl",
  transferAmount: "1",
  transferType: "IMPS",
};

export const payoutSampleRequestNEFT = {
  apikey: "7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q",
  beneficiaryDetails: {
    emailAddress: "abc@gmail.com",
    ifscCode: "BARB0SAILAN",
    mobileNumber: "9752211753",
    payeeName: "tester",
  },
  purposeMessage: "Fortesting",
  referenceId: "fgfghwytr",
  secrete: "1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC",
  toAccount: "44490100006879",
  toUpi: "8827742560@ybl",
  transferAmount: "1",
  transferType: "NEFT",
};

export const payoutSampleRequestRTGS = {
  apikey: "7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q",
  beneficiaryDetails: {
    emailAddress: "abc@gmail.com",
    ifscCode: "BARB0SAILAN",
    mobileNumber: "9752211753",
    payeeName: "tester",
  },
  purposeMessage: "Fortesting",
  referenceId: "fgfghwytr",
  secrete: "1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC",
  toAccount: "44490100006879",
  toUpi: "8827742560@ybl",
  transferAmount: "1",
  transferType: "RTGS",
};

export const payoutSampleRequestUPI = {
  apikey: "7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q",
  beneficiaryDetails: {
    emailAddress: "abc@gmail.com",
    ifscCode: "BARB0SAILAN",
    mobileNumber: "9752211753",
    payeeName: "tester",
  },
  purposeMessage: "Fortesting",
  referenceId: "fgfghwytr",
  secrete: "1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC",
  toAccount: "44490100006879",
  toUpi: "8827742560@ybl",
  transferAmount: "1",
  transferType: "UPI",
};

export const payoutRequestTableJson = {
  fields: [
    {
      Key: "emailAddress",
      "Type / Mandatory": "string",
      Description: "Valid email address or empty field",
      "Possible values/Example": "abcXXX@domain.com",
    },
    {
      Key: "mobileNumber",
      "Type / Mandatory": "string",
      Description: "Valid mobile number",
      "Possible values/Example": "99XXXXXXXXX",
    },
    {
      Key: "ifscCode*",
      "Type / Mandatory": "string",
      Description:
        "Valid IFSC\n\n* Only required for non- UPI transactions (IMPS/NEFT/RTGS)",
      "Possible values/Example": "key-a89e0a1be44d",
    },
    {
      Key: "payeeName*",
      "Type / Mandatory": "string",
      Description: "Payee Name",
      "Possible values/Example": "Sachin Kumar",
    },
    {
      Key: "referenceId",
      "Type / Mandatory": "string(required)",
      Description: "A specific unique ID assigned by you for this transaction.",
      "Possible values/Example": "SWIPEXXXXXXXXXXXX",
    },
    {
      Key: "purpose Message",
      "Type / Mandatory": "string(required)",
      Description:
        "The narration that you want to show as a message for the transfer. (Note: Purpose message must be at least 5 and not more than 50 characters)",
      "Possible values/Example": "Test Message",
    },
    {
      Key: "toAccount",
      "Type / Mandatory": "string(required)",
      Description: `Account number to where transfer needs to be made. Mandatory, if the intended transfer type is IMPS/NEFT/RTGS.
      
      *Either value toUPI or toAccount is required`,
      "Possible values/Example": "123456789011",
    },
    {
      Key: "toUpi",
      "Type / Mandatory": "string(required)",
      Description: `Valid UPI or VPA 

      *Either value toUPI or toAccount is required`,
      "Possible values/Example": "vpaXXX@XXX",
    },
    {
      Key: "transferType",
      "Type / Mandatory": "string(required)",
      Description:
        "Can be NEFT/ IMPS/ RTGS/ UPI. Refer the table below for transaction limits",
      "Possible values/Example": "IMPS or UPI",
    },
    {
      Key: "transfer Amount",
      "Type / Mandatory": "string(required)",
      Description: "The transfer amount",
      "Possible values/Example": "1000",
    },
    {
      Key: "apikey",
      "Type / Mandatory": "string(required)",
      Description: "Public key required to authorize API access",
      "Possible values/Example": "key-a89e0a1be44d",
    },
    {
      Key: "secrete",
      "Type / Mandatory": "string(required)",
      Description: "API Secrete key required to authorize API access",
      "Possible values/Example": "test-5a8b0b1a",
    },
  ],
};

export const payinRequestTableJson = {
  fields: [
    {
      Key: "mid",
      "Type / Mandatory": "string(required)",
      length: 50,
      Description: "Merchant id",
      "Possible values/Example": "1000000000007",
    },
    {
      Key: "enckey",
      "Type / Mandatory": "string(required)",
      length: 50,
      Description: "Encrypted key",
      "Possible values/Example": "8b3a40g766d94345b9d82ad02cfb1df1",
    },
    {
      Key: "orderNo",
      "Type / Mandatory": "string(required)",
      length: 50,
      Description: "Order number",
      "Possible values/Example": "ORD23062614402635162",
    },
    {
      Key: "amount",
      "Type / Mandatory": "string(required)",
      length: 13,
      Description: "Amount",
      "Possible values/Example": "100 Rs.",
    },
    {
      Key: "currency",
      "Type / Mandatory": "string(required)",
      length: 10,
      Description: "currency type",
      "Possible values/Example": "INR",
    },
    {
      Key: "txnReqType",
      "Type / Mandatory": "string(required)",
      length: 10,
      Description: "S for webbased",
      "Possible values/Example": "S",
    },
    {
      Key: "respUrl",
      "Type / Mandatory": "string(required)",
      length: 150,
      Description: `response url`,
      "Possible values/Example": "www.google.com",
    },
    {
      Key: "emailId",
      "Type / Mandatory": "string",
      length: 50,
      Description: `email address`,
      "Possible values/Example": "test@gmail.com",
    },
    {
      Key: "mobileNo",
      "Type / Mandatory": "string",
      length: 13,
      Description: `mobile number`,
      "Possible values/Example": "9968739673",
    },
    {
      Key: "DateOfReg",
      "Type / Mandatory": "string (required)",
      length: 20,
      Description: `Date Of Reg`,
      "Possible values/Example": "2024-08-07",

    },
    // {
    //   Key: "userVpa",
    //   "Type / Mandatory": "string (required)",
    //   length: 13,
    //   Description: `UserVpa`,
    //   "Possible values/Example": "1234567891@ybl",

    // },
    {
      Key: "userId",
      "Type / Mandatory": "string (required)",
      length: 30,
      Description: `USerID`,
      "Possible values/Example": "ABCDEF1010",

    },
    {
      Key: "customerVpa",
      "Type / Mandatory": "string (required)",
      length: 50,
      Description: `customerVpa`,
      "Possible values/Example": "1457893525",
    },
    {
      Key: "name",
      "Type / Mandatory": "string",
      length: 100,
      Description: `name`,
      "Possible values/Example": "naman",
    },
    {
      Key: "udf1",
      "Type / Mandatory": "string (required)",
      length: 50,
      Description: `latitude`,
      "Possible values/Example": "",
    },
    {
      Key: "udf2",
      "Type / Mandatory": "string (required)",
      length: 50,
      Description: `longitude`,
      "Possible values/Example": "",
    },
    {
      Key: "udf3",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf4",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf5",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf6",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf7",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf8",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf9",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf10",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf11",
      "Type / Mandatory": "string ",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf12",
      "Type / Mandatory": "string",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf13",
      "Type / Mandatory": "string",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
    {
      Key: "udf14",
      "Type / Mandatory": "string",
      length: 50,
      Description: ``,
      "Possible values/Example": "",
    },
  ],
};

export const payinActualRequestTableJson = {
  fields: [
    {
      Key: "mid",
      "Type / Mandatory": "string",
      Description: "Merchant id",
      "Possible values/Example": "1000000000007",
    },
    {
      Key: "payload",
      "Type / Mandatory": "string",
      Description: "payload",
      "Possible values/Example":
        "xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuF...",
    },
  ],
};

export const payinResponseDescription = {
  fields: [
    {
      Key: "message",
      "Type / Mandatory": "string",
      Description: "response message from server",
      "Possible values/Example": "SUCCESS, FAILED",
    },
    {
      Key: "statusCode",
      "Type / Mandatory": "string",
      Description:
        "HTTP status codes indicate request success, failure, or redirection.",
      "Possible values/Example": "OK",
    },
    {
      Key: "amount",
      "Type / Mandatory": "number",
      Description: "amount",
      "Possible values/Example": "100Rs., 200Rs.",
    },
    {
      Key: "orderNo",
      "Type / Mandatory": "string",
      Description: "order number",
      "Possible values/Example": "ORD23091114541117906",
    },
    {
      Key: "qrString",
      "Type / Mandatory": "string",
      Description: "qrString",
      "Possible values/Example": "",
    },
    {
      Key: "created_on",
      "Type / Mandatory": "date/time",
      Description:
        "The created_on field represents the date and time when the item was created.",
      "Possible values/Example": "ORD23091114541117906",
    },
    {
      Key: "mid",
      "Type / Mandatory": "string",
      Description: "merchant id",
      "Possible values/Example": "SWIPEPROD100007",
    },
    {
      Key: "txnId",
      "Type / Mandatory": "string",
      Description: "transaction id",
      "Possible values/Example": "52149242658716179885",
    },
  ],
};

export const payoutTransactionLimitsJson = {
  fields: [
    {
      "Transfer Type": "IMPS",
      "Transaction Limit":
        "Amount <=INR 5,00,000 per transaction. This mode of transfer is immediate and is typically processed 24/7 and is settled to the beneficiary within 1 minute.",
    },
    {
      "Transfer Type": "NEFT",
      "Transaction Limit":
        "Amount <=INR 10,00,000 per transaction (Non-Banking Hours), Amount <= INR 1 Cr per transaction (Banking Hours). This mode of transfer is immediate and is typically processed in batches and is settled within 2-3 working hours.",
    },
    {
      "Transfer Type": "RTGS",
      "Transaction Limit":
        "Amount >INR 2L per transaction, Amount <= INR 5 Cr (Banking Hours). This mode of transfer is immediate and is typically processed in batches and is settled within 2-3 working hours.",
    },
    {
      "Transfer Type": "UPI",
      "Transaction Limit":
        "Amount <= 1L per transaction subject to consumer's bank policies and applicable across transaction coming from UPI apps, and through Virtual Account.",
    },
  ],
};

export const payoutResponseSuccessUPI = {

  "message": "Success",
  "status": "Transaction Successfully Processed",
  "statusCode": 200,
  "data": {
    "status": "success",
    "txnId": 0,
    "responseCode": "200",
    "transferType": "UPI",
    "bankReferenceNumber": "fgfghwytr",
    "newBalance": null,
    "beneficiaryName": "tester",
    "providerResponse": ""
  },
  "duplicateDate": null,
  "data1": null,
  "totalAmount": null,
  "count": 0,
  "numberOfTransactions": 0
}

export const payoutResponseSuccessIMPS = {
  "message": "Success",
  "status": "Transaction Successfully Processed",
  "statusCode": 200,
  "data": {
    "status": "success",
    "txnId": 0,
    "responseCode": "200",
    "transferType": "IMPS",
    "bankReferenceNumber": "fgfghwytr",
    "newBalance": null,
    "beneficiaryName": "tester",
    "providerResponse": ""
  },
  "duplicateDate": null,
  "data1": null,
  "totalAmount": null,
  "count": 0,
  "numberOfTransactions": 0
}

export const payoutResponseSuccessRTGS = {
  "message": "Success",
  "status": "Transaction Successfully Processed",
  "statusCode": 200,
  "data": {
    "status": "success",
    "txnId": 0,
    "responseCode": "200",
    "transferType": "RTGS",
    "bankReferenceNumber": "fgfghwytr",
    "newBalance": null,
    "beneficiaryName": "tester",
    "providerResponse": ""
  },
  "duplicateDate": null,
  "data1": null,
  "totalAmount": null,
  "count": 0,
  "numberOfTransactions": 0
};

export const payoutResponseSuccessNEFT = {
  "message": "Success",
  "status": "Transaction Successfully Processed",
  "statusCode": 200,
  "data": {
    "status": "success",
    "txnId": 0,
    "responseCode": "200",
    "transferType": "NEFT",
    "bankReferenceNumber": "fgfghwytr",
    "newBalance": null,
    "beneficiaryName": "tester",
    "providerResponse": ""
  },
  "duplicateDate": null,
  "data1": null,
  "totalAmount": null,
  "count": 0,
  "numberOfTransactions": 0
}

export const payoutResponsePending = {
  message: "pending",
  status:
    "Transaction amount (plus applied taxes and commission) is more than the balance available in the source account.",
  statusCode: 202,
  data: "DCTRTXXXXXXXXXXXXXXX",
  duplicateDate: null,
  data1: null,
  totalAmount: null,
  count: 0,
  numberOfTransactions: 0,
};

export const payoutResponseTransferTypeFailure = {
  error: {
    message:
      "Transaction amount (plus applied taxes and commission) is more than the balance available in the source account.",

    "response Code": "E00033",
    status: "failure",
  },

  txnId: "DCTRTXXXXXXXXXXXXXXX",
};

export const payoutTransactionSampleRequest = {
  referenceId: " Test101",

  txnId: " DCTRTXXXXXXXXXXXXXXXXX",

  apikey: "Test-key",
  secrete: "secrete",
};

export const payoutTransactionRequestParametersJson = {
  fields: [
    {
      Key: "referenceId *",
      "Type / Mandatory": "string",
      Description:
        "The request ID passed by you for this transaction when initiating it earlier (Mandatory if txnId is not present)",
      "Possible values/Example": "abcXXX@domain.com",
    },
    {
      Key: "txnId *",
      "Type / Mandatory": "string",
      Description:
        "(Mandatory if referenceId is not present) transaction id that we responded with while providing the initiate payment response",
      "Possible values/Example": "99XXXXXXXXX",
    },
    {
      Key: "apikey *",
      "Type / Mandatory": "string",
      Description: "Public key required to authorize API access",
      "Possible values/Example": "key-a89e0a1be44d",
    },
    {
      Key: "secrete *",
      "Type / Mandatory": "string",
      Description: " API Secrete key required to authorize API access",
      "Possible values/Example": "Sachin Kumar",
    },
  ],
};

export const payoutTransactionResponseIMPSJson = {
  status: "success",
  referenceId: "1011010",
  txnId: " DCTRTXXXXXXXXXXXXXXXXX",
  payoutStatusCheckTxnId: "A4DE146CBDB14976926D141EA147D531",
  transferType: "IMPS",
  bankReferenceNumber: "325415868977",
  "beneficiary Name": "Mr Amit Jain",
};

export const payoutTransactionResponseUPIJson = {
  status: "success",
  referenceId: "1wipez112e3",
  txnId: "9478D16CB35C4961B3D6ABCCA015097F",
  payoutStatusCheckTxnId: "F65BAC20A53A44D4A8A74026E5CDE41F",
  transferType: "UPI",
  providerResponse: "Transaction Successfully Processed",
  bankReferenceNumber: "325518802382",
  "beneficiary Name": "Amit Jain",
};

export const payoutTransactionCheckBalanceJson = {
  fields: [
    {
      Key: "apikey(required)",
      "Type / Mandatory": "string",
      Description: "Public key required to authorize API access",
      "Possible values/Example": "key-a89e0a1be44d",
    },
    {
      Key: "secrete(required)",
      "Type / Mandatory": "string",
      Description: "API Secrete key required to authorize API access",
      "Possible values/Example": "test-5a8b0b1a",
    },
    {
      Key: "transferType*",
      "Type / Mandatory": "string",
      Description: "Transfer Type is required for amount differentiation",
      "Possible values/Example": "UPI/IMPS",
    },
  ],
};

export const responseCodes = {
  200: "Success",
  417: "Fail/Exception",
  198: "Transaction processing",
  197: "Rejected by acquirer",
  99: "Failed",
  194: "Declined",
  193: "Authorization success but error processing recurring payment",
};

// Onboarding doc json's

export const onboardingJsonParameters = {
  aadhaarNumber: "941XXXX676527",
  aadharVerifyDto: {
    otp: "717440",
    refNumber: "18628452",
  },
  authorizedPersonFirstName: "JHON",
  authorizedPersonLastName: "SINHA",
  authorizedPersonMiddleName: "",
  authorizedPersonPan: "BOIXXX909L",
  bankBranchName: "SBI DRM",
  bankCity: "Bhopal",
  buisnessTypeName: "",
  businessAs: "",
  businessTypeId: 2,
  callBackUrl: "www.xyz.com",
  chargebackCallbackUrl: "chargeback.com",
  communicationAddress: "shahpura bhopal",
  communicationAddressCity: "Bhopal",
  communicationAddressDistrict: "Madhya Pradesh",
  communicationAddressPinCode: "462039",
  communicationAddressState: "Madhya Pradesh",
  companyAddress: "shahpura bhopal",
  companyAddressCity: "Bhopal",
  companyAddressDistrict: "Bhopal",
  companyAddressPinCode: "462039",
  companyAddressState: "Madhya Pradesh",
  companyName: "XYZ PVT.LTD",
  companyPanNumber: "BOXXX3909L",
  companyTypeId: 1,
  email: "xyz@gmail.com",
  gstNumber: "23AXXXXXXXXX5",
  masterMerchnatId: 1038212,
  mccCode: "5262",
  merchantAccountNumber: "302XXXXXX577",
  merchantBankName: "SBI",
  merchantIfscCode: "SBIN0010146",
  mobile: "7748005659",
  mobileVerifyDto: {
    mobileNumber: "7748005659",
    otp: 461097,
  },
  newCompanyAddress: "shahpura bhopal",
  payinCallbackUrl: "payin.com",
  paymentTypeId: [1, 2, 6],
  payoutCallbackUrl: "payout.com",
  registeredOfficeAddress: "shahpura bhopal",
  registeredOfficeAddressCity: "Bhopal",
  registeredOfficeAddressDistrict: "BHOPAL",
  registeredOfficeAddressPinCode: "462039",
  registeredOfficeAddressState: "Trilanga main road shahpura",
  registrationTypeId: 1,
  websiteUrl: "www.google.com",
  authorizedPersonDateOfBirth: "01/05/2024",
  authorizedPersonFullName: "JHON SINHA",
};

export const onboardingTableJson = [
  {
    Key: "aadhaarNumber(required)",
    "Type / Mandatory": "string",
    Description: "aadhaarNumber",
    "Possible values/Example": "941XXXX676527",
  },
  {
    Key: "otp",
    "Type / Mandatory": "number",
    Description: "OTP",
    "Possible values/Example": "717440",
  },
  {
    Key: "refNumber",
    "Type / Mandatory": "string",
    Description: "refNumber",
    "Possible values/Example": "18628452",
  },
  {
    Key: "authorizedPersonFirstName",
    "Type / Mandatory": "string",
    Description: "authorizedPersonFirstName",
    "Possible values/Example": "JHON",
  },
  {
    Key: "authorizedPersonLastName",
    "Type / Mandatory": "string",
    Description: "authorizedPersonLastName",
    "Possible values/Example": "SINHA",
  },
  {
    Key: "authorizedPersonMiddleName",
    "Type / Mandatory": "string",
    Description: "authorizedPersonMiddleName",
    "Possible values/Example": "",
  },
  {
    Key: "authorizedPersonPan",
    "Type / Mandatory": "string",
    Description: "authorizedPersonPan",
    "Possible values/Example": "BOIXXX909L",
  },
  {
    Key: "bankBranchName",
    "Type / Mandatory": "string",
    Description: "bankBranchName",
    "Possible values/Example": "SBI DRM",
  },
  {
    Key: "bankCity",
    "Type / Mandatory": "string",
    Description: "bankCity",
    "Possible values/Example": "Bhopal",
  },
  {
    Key: "buisnessTypeName",
    "Type / Mandatory": "string",
    Description: "buisnessTypeName",
    "Possible values/Example": "",
  },
  {
    Key: "businessAs",
    "Type / Mandatory": "string",
    Description: "businessAs",
    "Possible values/Example": "",
  },
  {
    Key: "businessTypeId",
    "Type / Mandatory": "string",
    Description: "businessTypeId",
    "Possible values/Example": 2,
  },
  {
    Key: "callBackUrl",
    "Type / Mandatory": "string",
    Description: "callBackUrl",
    "Possible values/Example": "www.xyz.com",
  },
  {
    Key: "chargebackCallbackUrl",
    "Type / Mandatory": "string",
    Description: "chargebackCallbackUrl",
    "Possible values/Example": "chargeback.com",
  },
  {
    Key: "communicationAddress",
    "Type / Mandatory": "string",
    Description: "communicationAddress",
    "Possible values/Example": "shahpura bhopal",
  },
  {
    Key: "communicationAddressCity",
    "Type / Mandatory": "string",
    Description: "communicationAddressCity",
    "Possible values/Example": "Bhopal",
  },
  {
    Key: "communicationAddressDistrict",
    "Type / Mandatory": "string",
    Description: "communicationAddressDistrict",
    "Possible values/Example": "Madhya Pradesh",
  },
  {
    Key: "communicationAddressPinCode",
    "Type / Mandatory": "string",
    Description: "communicationAddressPinCode",
    "Possible values/Example": "462039",
  },
  {
    Key: "communicationAddressState",
    "Type / Mandatory": "string",
    Description: "communicationAddressState",
    "Possible values/Example": "Madhya Pradesh",
  },
  {
    Key: "companyAddress",
    "Type / Mandatory": "string",
    Description: "companyAddress",
    "Possible values/Example": "shahpura bhopal",
  },
  {
    Key: "companyAddressCity",
    "Type / Mandatory": "string",
    Description: "companyAddressCity",
    "Possible values/Example": "Bhopal",
  },
  {
    Key: "companyAddressDistrict",
    "Type / Mandatory": "string",
    Description: "companyAddressDistrict",
    "Possible values/Example": "Bhopal",
  },
  {
    Key: "companyAddressPinCode",
    "Type / Mandatory": "string",
    Description: "companyAddressPinCode",
    "Possible values/Example": "462039",
  },
  {
    Key: "companyAddressState",
    "Type / Mandatory": "string",
    Description: "companyAddressState",
    "Possible values/Example": "Madhya Pradesh",
  },
  {
    Key: "companyName",
    "Type / Mandatory": "string",
    Description: "companyName",
    "Possible values/Example": "XYZ PVT.LTD",
  },
  {
    Key: "companyPanNumber",
    "Type / Mandatory": "string",
    Description: "companyPanNumber",
    "Possible values/Example": "BOXXX3909L",
  },
  {
    Key: "companyTypeId",
    "Type / Mandatory": "string",
    Description: "companyTypeId",
    "Possible values/Example": 1,
  },
  {
    Key: "email",
    "Type / Mandatory": "string",
    Description: "email",
    "Possible values/Example": "xyz@gmail.com",
  },
  {
    Key: "gstNumber",
    "Type / Mandatory": "string",
    Description: "gstNumber",
    "Possible values/Example": "23AXXXXXXXXX5",
  },
  {
    Key: "masterMerchnatId",
    "Type / Mandatory": "string",
    Description: "masterMerchnatId",
    "Possible values/Example": "1038212",
  },
  {
    Key: "mccCode",
    "Type / Mandatory": "string",
    Description: "mccCode",
    "Possible values/Example": "5262",
  },
  {
    Key: "merchantAccountNumber",
    "Type / Mandatory": "string",
    Description: "merchantAccountNumber",
    "Possible values/Example": "302XXXXXX577",
  },
  {
    Key: "merchantBankName",
    "Type / Mandatory": "string",
    Description: "merchantBankName",
    "Possible values/Example": "SBI",
  },
  {
    Key: "merchantIfscCode",
    "Type / Mandatory": "string",
    Description: "merchantIfscCode",
    "Possible values/Example": "SBIN0010146",
  },
  {
    Key: "mobile",
    "Type / Mandatory": "string",
    Description: "mobile",
    "Possible values/Example": "7748005659",
  },
  {
    Key: "mobileNumber",
    "Type / Mandatory": "string",
    Description: "mobileNumber",
    "Possible values/Example": "7748005659",
  },
  {
    Key: "otp",
    "Type / Mandatory": "string",
    Description: "otp",
    "Possible values/Example": "461097",
  },
  {
    Key: "newCompanyAddress",
    "Type / Mandatory": "string",
    Description: "newCompanyAddress",
    "Possible values/Example": "shahpura bhopal",
  },
  {
    Key: "payinCallbackUrl",
    "Type / Mandatory": "string",
    Description: "payinCallbackUrl",
    "Possible values/Example": "payin.com",
  },
  {
    Key: "paymentTypeId",
    "Type / Mandatory": "string",
    Description: "paymentTypeId",
    "Possible values/Example": [1, 2, 6],
  },
  {
    Key: "payoutCallbackUrl",
    "Type / Mandatory": "string",
    Description: "payoutCallbackUrl",
    "Possible values/Example": "payout.com",
  },
  {
    Key: "registeredOfficeAddress",
    "Type / Mandatory": "string",
    Description: "registeredOfficeAddress",
    "Possible values/Example": "shahpura bhopal",
  },
  {
    Key: "registeredOfficeAddressCity",
    "Type / Mandatory": "string",
    Description: "registeredOfficeAddressCity",
    "Possible values/Example": "Bhopal",
  },
  {
    Key: "registeredOfficeAddressDistrict",
    "Type / Mandatory": "string",
    Description: "registeredOfficeAddressDistrict",
    "Possible values/Example": "BHOPAL",
  },
  {
    Key: "registeredOfficeAddressPinCode",
    "Type / Mandatory": "string",
    Description: "registeredOfficeAddressPinCode",
    "Possible values/Example": "462039",
  },
  {
    Key: "registeredOfficeAddressState",
    "Type / Mandatory": "string",
    Description: "registeredOfficeAddressState",
    "Possible values/Example": "Trilanga main road shahpura",
  },
  {
    Key: "registrationTypeId",
    "Type / Mandatory": "string",
    Description: "registrationTypeId",
    "Possible values/Example": 1,
  },
  {
    Key: "websiteUrl",
    "Type / Mandatory": "string",
    Description: "websiteUrl",
    "Possible values/Example": "www.google.com",
  },
  {
    Key: "authorizedPersonDateOfBirth",
    "Type / Mandatory": "string",
    Description: "authorizedPersonDateOfBirth",
    "Possible values/Example": "01/05/2024",
  },
  {
    Key: "authorizedPersonFullName",
    "Type / Mandatory": "string",
    Description: "authorizedPersonFullName",
    "Possible values/Example": "JHON SINHA",
  },


]
