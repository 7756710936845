import React, { useState, useEffect } from "react";
import Modal from "../../../Common/Modal/Modal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { jwtDecode } from "jwt-decode";
import useApiServices from "../../../Utils/useApiService";
import { FETCH_FRM_USERS, FRM_BLOCK_USER } from "../../../Utils/Constants";
import {
  isEmailIdRegexIsValid,
  isIfscRegexIsValid,
  isValidIMEINumber,
  isValidMobileNumber,
  isVpaUpiRegexIsValid,
} from "../../../Utils/validations";
import swal from "sweetalert";
import InputBox from "../../../Common/InputBox/InputBox";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import { useSelector } from "react-redux";


/** 
 * This component returns Fraud management related details.
*/
const FraudManagement = () => {
  const [list, setList] = useState([]);
  const columns = [
    {
      headerName: "Id",
      field: "id",
      minWidth: 170,
    },
    {
      headerName: "VPA type",
      field: "vpa",
      minWidth: 170,
    },
    {
      headerName: "IFSC Code",
      field: "ifscCode",
      minWidth: 170,
    },
    {
      headerName: "E-mail",
      field: "email",
      minWidth: 170,
    },
    {
      headerName: "Mobile",
      field: "mobile",
      minWidth: 170,
    },
    {
      headerName: "IMEI /Device ID",
      field: "imei",
      minWidth: 170,
    },
    {
      headerName: "Latitude",
      field: "letitude",
      minWidth: 170,
    },
    {
      headerName: "Longitude",
      field: "longitude",
      minWidth: 170,
    },
  ];
  const { mid } = useSelector((state) => state.userData);

  const parameter = [
    { id: 1, name: "VPA", payloadName: "vpa" },
    { id: 2, name: "IMEI Number / Device Id", payloadName: "imei" },
    { id: 3, name: "Email ID", payloadName: "email" },
    { id: 4, name: "Mobile Number", payloadName: "mobile" },
    { id: 5, name: "IFSC Code", payloadName: "ifscCode" },
    { id: 6, name: "Location", payloadName: "location" },
  ];

  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const getAllFRMUsers = async () => {
    try {
      const response = await apiCallFnforGet(`${FETCH_FRM_USERS}${mid}`);

      setList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFRMUsers();
  }, []);

  const [selectParameterData, setSelectParameterData] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState([
    { id: 1, name: "VPA", payloadName: "vpa", inputVal: "" },
    {
      id: 2,
      name: "IMEI Number / Device Id",
      payloadName: "imei",
      inputVal: "",
    },
    { id: 3, name: "Email ID", payloadName: "email", inputVal: "" },
    { id: 4, name: "Mobile Number", payloadName: "mobile", inputVal: "" },
    { id: 5, name: "IFSC Code", payloadName: "ifscCode", inputVal: "" },
    {
      id: 6,
      name: "Location",
      payloadName: "location",
      Latitude: "",
      Longitude: "",
    },
  ]);

  const handelParameterInput = (e, id) => {
    if (id === 6) {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    } else {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    }
  };

  const removeParameter = (id, elem) => {
    setSelectedParameter(
      selectedParameter?.filter((elem) => {
        return elem.id !== id;
      })
    );
    setSelectParameterData([...selectParameterData, elem]);
  };

  const handelParameterChange = (e) => {
    let val = e.target.value;
    let select = selectParameterData.filter(
      (data) => data.id === parseInt(val)
    );
    const remaining = selectedParameter.filter((elem) => {
      return elem.id !== select[0].id;
    });
    setSelectedParameter([...remaining, ...select]);
    setSelectParameterData(
      selectParameterData.filter((elem) => {
        return elem.id !== parseInt(val);
      })
    );
  };

  const handleSubmit = async (e) => {
    let parameterName = selectedParameter.map((elem) => elem.payloadName);

    if (parameterName.includes("vpa")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "vpa"
      )[0];

      if (!isVpaUpiRegexIsValid(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid VPA",
          icon: "error",
        });
        return;
      }
    }

    if (parameterName.includes("ifscCode")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "ifscCode"
      )[0];

      if (!isIfscRegexIsValid(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid IFSC Code",
          icon: "error",
        });
        return;
      }
    }

    if (parameterName.includes("email")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "email"
      )[0];

      if (!isEmailIdRegexIsValid(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid Email",
          icon: "error",
        });
        return;
      }
    }

    if (parameterName.includes("mobile")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "mobile"
      )[0];

      if (!isValidMobileNumber(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid Mobile Number",
          icon: "error",
        });
        return;
      }
    }

    if (parameterName.includes("imei")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "imei"
      )[0];

      if (!isValidIMEINumber(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid IMEI Number",
          icon: "error",
        });
        return;
      }
    }

    if (parameterName.includes("location")) {
      let elem = selectedParameter.filter(
        (elem) => elem.payloadName === "location"
      )[0];

      if (
        elem?.Latitude === "" ||
        elem?.Latitude === null ||
        elem?.Latitude === undefined
      ) {
        swal({
          title: "Alert",
          text: "Please Enter valid Location Latitude",
          icon: "error",
        });
        return;
      }

      if (
        elem?.longitude === "" ||
        elem?.longitude === null ||
        elem?.longitude === undefined
      ) {
        swal({
          title: "Alert",
          text: "Please Enter valid Location Longitude",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      doBlock: true,
      email: "",
      ifscCode: "",
      imei: "",
      letitude: "",
      longitude: "",
      mid: mid,
      mobile: "",
      vpa: "",
    };

    selectedParameter.forEach((elem) => {
      if (elem.id === 1) payload.vpa = elem.inputVal;
      if (elem.id === 2) payload.imei = elem.inputVal;
      if (elem.id === 3) payload.email = elem.inputVal;
      if (elem.id === 4) payload.mobile = elem.inputVal;
      if (elem.id === 5) payload.ifscCode = elem.inputVal;
      if (elem.id === 6) {
        payload.letitude = elem.Latitude;
        payload.longitude = elem.Longitude;
      }
    });

    let res = await apiCallFnforPost(FRM_BLOCK_USER, payload);
    if (res.statusCode === 200) {
      swal({
        title: "Success!",
        text: "form submitted successfully",
        icon: "success",
      });
      setSelectParameterData(parameter);
      setSelectedParameter([]);
      getAllFRMUsers();
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
  };

  return (
    // <div className="content-body">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h4 class="card-title">Fraud Management</h4>

          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#addUser"
          >
            Add User
          </button>
        </div>

        <Modal
          id={"addUser"}
          title={"Add User"}
          onSubmit={handleSubmit}
          minWidth={"45rem"}
          // className="modal-lg"
          footer={true}
        >
          <div class="basic-form">
            <form>
              <select
                id="companyDropdown"
                className="form-control"
                onChange={handelParameterChange}
                name="merchantSelect"
              >
                <option value="">Select a Parameter</option>
                {selectParameterData?.map((parameter) => (
                  <option key={parameter.id} value={parameter.id}>
                    {parameter.name}
                  </option>
                ))}
              </select>
            </form>
          </div>

          <div className="col-lg-12 col-sm-12 col-md-8 px-3">
            {selectedParameter?.map((elem, index) => {
              if (elem.id === 6) {
                return (
                  <>
                    <div key={elem.sid} className=" mt-2  py-2  ">
                      <div className="form-group  mt-2 ">
                        <div className="d-flex">
                          <div className="col-md-5  mt-2 me-4 d-flex flex-column align-items-start">
                            <label
                              className="me-1 fs-5 text-black mx-2"
                              htmlFor="email"
                            >
                              {elem.name} (Latitude)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="txnAmt"
                              name="Latitude"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.Latitude}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                          </div>
                          <div className="col-md-5  mt-2 me-4 d-flex flex-column align-items-start">
                            <label
                              className="me-1 fs-5 text-black mx-2"
                              htmlFor="email"
                            >
                              {elem.name} (Longitude)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="txnAmt"
                              name="Longitude"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.Longitude}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-end align-items-end p-3">
                            <span
                              onClick={() => {
                                removeParameter(elem.id, elem);
                              }}
                              className="text-danger fs-4 cursor-pointer"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else
                return (
                  <>
                    <div key={elem.sid} className=" mt-2  py-2  ">
                      <div className="form-group  mt-2 ">
                        <div className="d-flex ">
                          <InputBox
                            type={"text"}
                            label={`${elem.name} (Latitude)`}
                            name="txnAmt"
                            placeholder={`Enter ${elem.name}`}
                            value={elem.inputVal}
                            onChange={(e) => handelParameterInput(e, elem.id)}
                          />
                          {/* <div className="col-md-11">
                            <label htmlFor="email">{elem.name}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="txnAmt"
                              name="txnAmt"
                              placeholder={`Enter ${elem.name}`}
                              value={elem.inputVal}
                              onChange={(e) => handelParameterInput(e, elem.id)}
                            />
                          </div> */}
                          <div className="d-flex flex-column justify-content-end align-items-end mx-3 py-3">
                            <span
                              onClick={() => {
                                removeParameter(elem.id, elem);
                              }}
                              className="text-danger fs-4 cursor-pointer"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
            })}
          </div>
        </Modal>
        <div className="row">
          <div xs={12} lg={12} md={12} className="col">
            <div className="">
              <div className="">
                <div className="col">
                  <div className="mt-3">
                    {list?.length !== 0 ? (
                      <DataGrid
                        rows={list}
                        columns={columns}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        sx={{
                          "& .css-1rtad1": {
                            display: "block",
                          },
                        }}
                      />
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default FraudManagement;
