// REGEX CONSTANTS
export const VPA_UPI_REGEX = new RegExp(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+$/
  );
  export const UPI_REGEX = new RegExp(
    /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/
  );
  export const TRANSACTION_ID_REGEX = new RegExp(/^[0-9]*$/);
  
  export const GST_NUMBER_REGEX = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  
  export const WEBSITE_URL_REGEX = new RegExp(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/
  );
  
  export const ORDER_NUMBER_REGEX = new RegExp(/^[0-9]*$/);
  
  // export const PASSWORD_REGEX = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-=/\\d])(?=.*[A-Z]).{8,}$/;
  
  
  export const IFSC_REGEX = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
  
  export const EMAIL_ID_REGEX = new RegExp(
    /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
  );
  export const UTR_NUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/);
  export const PAN_CARD_REGEX = new RegExp(
    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  );
  
  export const MOBILE_NUMBER_REGEX = new RegExp(/^\d{10}$/);
  
  export const IMEI_NUMBER_REGEX = new RegExp(/^[A-Za-z0-9]{1,20}$/);
  
  export const getKeyPressed = (e) => {
    return e.key;
  };
  
  export const isVpaUpiRegexIsValid = (inputVpaValue) => {
    const isValidVpa = VPA_UPI_REGEX.test(inputVpaValue);
    if (isValidVpa) {
      return true;
    }
    return false;
  };
  
  export const isValidMobileNumber = (inputValue) => {
    const isValid = MOBILE_NUMBER_REGEX.test(inputValue);
    if (isValid) {
      return true;
    }
    return false;
  };
  
  export const isValidIMEINumber = (inputValue) => {
    const isValid = IMEI_NUMBER_REGEX.test(inputValue);
    if (isValid) {
      return true;
    }
    return false;
  };
  
  export const isUpiValid = (inputVpaValue) => {
    const isValidVpa = UPI_REGEX.test(inputVpaValue);
    if (isValidVpa) {
      return true;
    }
    return false;
  };
  
  export const isIfscRegexIsValid = (inputVpaValue) => {
    const isValidIfsc = IFSC_REGEX.test(inputVpaValue);
    if (isValidIfsc) {
      return true;
    }
    return false;
  };
  
  export const isTransactionIdRegexIsValid = (inputValue) => {
    const isValidTransaction = TRANSACTION_ID_REGEX.test(inputValue);
    if (isValidTransaction) return true;
    return false;
  };
  
  export const isGSTRegexIsValid = (inputValue) => {
    const isValidGST = GST_NUMBER_REGEX.test(inputValue);
    if (isValidGST) return true;
    return false;
  };
  
  export const isWebsiteURLRegexIsValid = (inputValue) => {
    const isValidWebURL = WEBSITE_URL_REGEX.test(inputValue);
    if (isValidWebURL) return true;
    return false;
  };
  
  export const isOrderNumberRegexIsValid = (inputValue) => {
    const isValidOrderNumber = ORDER_NUMBER_REGEX.test(inputValue);
    if (isValidOrderNumber) return true;
    return false;
  };
  
  export const isEmailIdRegexIsValid = (inputValue) => {
    const isValidEmail = EMAIL_ID_REGEX.test(inputValue);
    if (isValidEmail) return true;
    return false;
  };
  
  // export const isPasswordRegexIsValid = (inputValue) => {
  //   const isValidPassword = PASSWORD_REGEX.test(inputValue);
  //   if (isValidPassword) return true;
  //   return false;
  // };
  
  export const isUtrNumRegexIsValid = (inputValue) => {
    const isValidUTR = UTR_NUM_REGEX.test(inputValue);
    if (isValidUTR) return true;
    return false;
  };
  
  export const isPanNumRegexIsValid = (inputValue) => {
    const isValidPan = PAN_CARD_REGEX.test(inputValue);
    if (isValidPan) return true;
    return false;
  };
  
  export const stopSpecialCharacterInput = (inputValue) => {
    if (
      inputValue === "!" ||
      inputValue === "@" ||
      inputValue === "#" ||
      inputValue === "$" ||
      inputValue === "%" ||
      inputValue === "^" ||
      inputValue === "&" ||
      inputValue === "*" ||
      inputValue === "(" ||
      inputValue === ")" ||
      inputValue === "+" ||
      inputValue === "-" ||
      inputValue === "*" ||
      inputValue === "/" ||
      inputValue === ";" ||
      inputValue === ":" ||
      inputValue === "'" ||
      inputValue === '"' ||
      inputValue === "," ||
      inputValue === "<" ||
      inputValue === "." ||
      inputValue === ">" ||
      inputValue === "?" ||
      inputValue === "{" ||
      inputValue === "}" ||
      inputValue === "[" ||
      inputValue === "]" ||
      inputValue === "|" ||
      inputValue === "=" ||
      inputValue === "_" ||
      inputValue === "-"
    )
      return true;
    return false;
  };
  
  export const stopNumberCharInput = (inputValue) => {
    // alert("called")
    if (
      inputValue === "1" ||
      inputValue === "2" ||
      inputValue === "3" ||
      inputValue === "4" ||
      inputValue === "5" ||
      inputValue === "6" ||
      inputValue === "7" ||
      inputValue === "8" ||
      inputValue === "9" ||
      inputValue === "0"
    )
      return true;
    return false;
  };
  
  export const stopSpaceInput = (inputValue) => {
    // alert("called")
    if (inputValue === " ") return true;
    return false;
  };
  
  export const stopAlphabetInput = (inputValue) => {
    // alert("called")
    if (
      inputValue !== "1" &&
      inputValue !== "2" &&
      inputValue !== "3" &&
      inputValue !== "4" &&
      inputValue !== "5" &&
      inputValue !== "6" &&
      inputValue !== "7" &&
      inputValue !== "8" &&
      inputValue !== "9" &&
      inputValue !== "0" &&
      inputValue !== "!" &&
      inputValue !== "@" &&
      inputValue !== "#" &&
      inputValue !== "$" &&
      inputValue !== "%" &&
      inputValue !== "^" &&
      inputValue !== "&" &&
      inputValue !== "*" &&
      inputValue !== "(" &&
      inputValue !== ")" &&
      inputValue !== "+" &&
      inputValue !== "-" &&
      inputValue !== "*" &&
      inputValue !== "/" &&
      inputValue !== ";" &&
      inputValue !== ":" &&
      inputValue !== "'" &&
      inputValue !== '"' &&
      inputValue !== "," &&
      inputValue !== "<" &&
      inputValue !== "." &&
      inputValue !== ">" &&
      inputValue !== "?" &&
      inputValue !== "{" &&
      inputValue !== "}" &&
      inputValue !== "[" &&
      inputValue !== "]" &&
      inputValue !== "|" &&
      inputValue !== "=" &&
      inputValue !== "_" &&
      inputValue !== "-"
    )
      return true;
    return false;
  };
  