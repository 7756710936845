import React, { useEffect, useState } from "react";
 
import swal from "sweetalert";
 
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GET_ALL_USER, USER_ENABLE_DISABLE } from "../Utils/Constants";
 
import useApiServices from "../Utils/useApiService";
import Modal from "../Common/Modal/Modal";
import styles from "./style.module.css";
import CreateUser from "./CreateUser";
import ViewUser from "./ViewUser";
import EditUser from "./EditUser";
import NoDataFound from "../Common/NoDataFound/NoDataFound";

/**
 * `SubmittedUser` component displays a list of all users with options to view, edit, or enable/disable users.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.setShowLoader - Function to set the loader state.
 *
 * @returns {JSX.Element} - The rendered `SubmittedUser` component.
 */
const SubmittedUser = ({ setShowLoader }) => {
  const [submittedAggregator, setSubmittedAggregator] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [EnableOrDisable, setEnableOrDisable] = useState("");
  const [openPrivilagesView, setOpenPrivilagesView] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState({});

  const [trigger, settrigger] = useState(false);

  

  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  /**
   * Closes the view modal and resets the user ID.
   */
  const handleClose = () => {
    setId("");
    setOpenView(false);
  };

  /**
   * Closes the edit user modal and resets the user ID.
   */
  const handleCloseEdit = () => {
    setId("");
    setOpenPrivilagesView(false);
  };

  /**
   * Opens the EditUser or ViewUser modal based on the provided user ID.
   *
   * @param {string} masterMerchantId - The ID of the user to be edited or viewed.
   */
  const EditView = (masterMerchantId) => {
    settrigger(!trigger);
    setId(masterMerchantId);
  };

  /**
   * Opens the ViewUser modal based on the provided user ID.
   *
   * @param {string} masterMerchantId - The ID of the user to be viewed.
   */
  const View = (masterMerchantId) => {
    settrigger(!trigger);
    setId(masterMerchantId);
  };

  /**
   * Column definitions for the DataGrid component displaying user information.
   *
   * @type {Array<Object>}
   */
  const columns = [
    { headerName: "S no.", field: "id", minWidth: 50 },
    { headerName: "User Name", field: "fullName", minWidth: 150 },
    { headerName: "e-Mail", field: "email", minWidth: 250 },
    { headerName: "Mobile Number", field: "mobile", minWidth: 150 },
    {
      headerName: "Enable / Disable",
      field: "active",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <button
            className={`btn btn-sm ${
              !params.row.active ? "btn-primary" : "btn-danger"
            }`}
            onClick={() =>
              disableAggregator(
                params.row.active ? "disable" : "enable",
                params.row.merchantUserId
              )
            }
          >
            {params.row.active ? "Disable" : "Enable"}
          </button>
        </div>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#priviledgeuserdetail"
            className="btn btn-sm btn-primary mx-2"
            // onClick={() => View(params.row.merchantUserId)}
            onClick={() => View(params.row.merchantUserId)}
          >
            View Details
          </button>
          <button
            className="btn btn-sm btn-secondary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#priviledgeuseredit"
            // onClick={() => EditView(params.row.merchantUserId)}
            onClick={() => EditView(params.row.merchantUserId)}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  /**
   * Maps the user data for display in the DataGrid component.
   *
   * @type {Array<Object>}
   */
  const mappedData = submittedAggregator
    ? submittedAggregator.map((item, index) => {
        return {
          id: index + 1,
          ...item,
          action: (
            <div>
              <button
                className="btn-sm btn-primary mx-2"
                onClick={() => View(item.merchantUserId)}
              >
                View Details
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => EditView(item.merchantUserId)}
              >
                Edit
              </button>
            </div>
          ),
          active: (
            <div>
              <button
                className={`btn-sm ${
                  !item.active ? "btn-primary" : "btn-danger"
                }`}
                onClick={() =>
                  disableAggregator(
                    item.active ? "disable" : "enable",
                    item?.merchantUserId
                  )
                }
              >
                {item.active ? "Disable" : "Enable"}
              </button>
            </div>
          ),
        };
      })
    : [];

  /**
   * Fetches all users from the API and updates the state with the user data.
   *
   * @async
   * @function getAll
   * @param {string} GET_ALL_USER - The API endpoint to fetch user data.
   * @returns {Promise<void>}
   */
  const getAll = async (GET_ALL_USER) => {
    try {
      const response = await apiCallFnforGet(GET_ALL_USER);

      if (response.statusCode === 200) {
        setSubmittedAggregator(
          response?.data?.map((item, i) => {
            return {
              id: i + 1,
              ...item,
            };
          })
        );
      } else {
        setSubmittedAggregator([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAll(GET_ALL_USER);
  }, [callAgain, openPrivilagesView]);

  /**
   * Toggles the enabled/disabled status of a user.
   *
   * @async
   * @function disableAggregator
   * @param {string} EnableOrDisable - The action to be performed ("enable" or "disable").
   * @param {string} id - The ID of the user to be enabled or disabled.
   * @returns {Promise<void>}
   */
  const disableAggregator = async (EnableOrDisable, id) => {
    swal({
      title: "Alert",
      text: "Are you sure, you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(
          `${USER_ENABLE_DISABLE}${EnableOrDisable}`, // Fix the template string
          formData
        )
          .then((res) => {
            if (res?.statusCode === 200) {
              swal({
                title: "Success",
                text: `User ${EnableOrDisable} Successfully.`,
                icon: "success",
              });
              setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} User.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
        <h4 class="card-title">All Users</h4>

        <a
          data-bs-toggle="modal"
          data-bs-target="#priviledgemodal"
          className="rounded-2 btn-primary btn btn-sm mt-1"
        >
          Add User
        </a>

        <Modal title={"Create User"} id={"priviledgemodal"} Width={"xl"}>
          <CreateUser />
        </Modal>

        <Modal title={"User Details"} id={"priviledgeuserdetail"} Width={"xl"}>
          <ViewUser trigger={trigger} id={id} />
        </Modal>

        <Modal title={"Edit User"} id={"priviledgeuseredit"} Width={"xl"}>
          <EditUser trigger={trigger} id={id} />
        </Modal>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row">
              <div className="col">
                <div className="">
                  <div className="">
                    <div className={`mt-3 ${styles["DataGridHeight"]}`}>
                      {submittedAggregator?.length > 0 ? (
                        <DataGrid
                          rows={submittedAggregator}
                          columns={columns}
                          slots={{
                            toolbar: GridToolbar,
                          }}
                          sx={{
                            "& .css-1rtad1": {
                              display: "block",
                            },
                          }}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                    {/* ) : openPrivilagesView ? (
              <EditSubmittedUser
                handleClose={handleCloseEdit}
                data={data}
                userId={id}
                setShowLoader={setShowLoader}
              />
             
            ) : openView ? (
              <ViewSubmittedUser
                handleClose={handleClose}
                data={data}
                userId={id}
              />
               
            ) : null} */}
                  </div>
                </div>
                {/* </Card.Body>
            </Card> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SubmittedUser;
