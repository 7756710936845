import { formatCompactNumber } from "../Utils/helper";
import CustomTip from "../Common/CustomToolTip/CustomTip";
import MiniLoader from "../Common/MiniLoader/MiniLoader";

/**
 * MiddleCard component renders wallet balance card and used services in dashboard.
 * @returns {ReactNode} A React element that renders the renders wallet balance card and used services in dashboard.
 */
export default function MiddleCard({ data, payinServices, condition }) {
  return (
    <>
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-xl-6">
                <div className="card-bx bg-blue">
                  <img
                    className="pattern-img"
                    src="/images/pattern/pattern6.png"
                    alt=""
                  />
                  <div className="card-info text-white">
                    <img
                      src="/images/pattern/circle.png"
                      className="mb-4"
                      alt=""
                    />
                    {!data ? (
                      <div>
                        <MiniLoader size={18} color={"success"} />
                      </div>
                    ) : (
                      <CustomTip size={20} title={data} placement="top">
                        <h2 className="text-white card-balance">
                          ₹ {formatCompactNumber(data)}
                        </h2>
                      </CustomTip>
                    )}

                    <p className="fs-16">Wallet Balance</p>
                    <span> </span>
                  </div>
                  <a className="change-btn">
                    <i className="fa fa-caret-up up-ico"></i>
                    <span className="reload-icon">
                      <i className="fas fa-sync-alt reload active"></i>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row align-items-center  mt-xl-0 mt-4 gap-4">
                  <>
                    {payinServices?.length !== 0 ? (
                      // {payinServices.length ? (
                      <>
                        <h4 className="card-title">
                          Services Overview (
                          {condition === "1 day" ? "Today" : condition})
                        </h4>
                        {/* <div className="col-md-5"> */}
                        <ul className="card-list row">
                          {payinServices?.map((item, i) => {
                            return (
                              <li
                                className="col-md-6 col-lg-6 col-xl-6"
                                key={i}
                              >
                                <span className="bg-blue circle"></span>
                                {item.paymentService}
                                <span>{item.percentage} %</span>
                              </li>
                            );
                          })}
                        </ul>
                        {/* </div> */}
                      </>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <MiniLoader size={18} color={"success"} />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
