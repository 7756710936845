import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getKeyPressed, isEmailIdRegexIsValid } from "../Utils/validations";
import swal from "sweetalert";

import { GET_ALL_USER, USER_UPDATE } from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { useLocation } from "react-router-dom";

/**
 * `ViewUser` component displays the details of a specific user and allows viewing or updating user privileges.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.trigger - Flag to re-fetch user data when changed.
 * @param {string} props.id - The ID of the user to be viewed.
 *
 * @returns {JSX.Element} - The rendered `ViewUser` component.
 */
const ViewUser = ({ trigger, id }) => {
  const [userData, setUserData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");

  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();

  const { state } = useLocation();

  const [formDataInputs, setFormDataInputs] = useState({
    Name: "",

    password: "",
    MobileNumber: "",

    emailId: "",
  });

  useEffect(() => {
    setFormDataInputs({
      Name: userData.fullName,
      password: userData.password,
      MobileNumber: userData.mobile,
      emailId: userData.email,
    });
  }, [userData]);

  /**
   * Fetches the user data from the API and updates the local state.
   *
   * @async
   * @function getUserData
   * @returns {Promise<void>}
   */
  const getUserData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_USER + id);
      if (response.statusCode === 200) {
        setUserData(response?.data);

        if (response?.data?.subpriveledgeprivilege) {
          const data = response?.data?.subpriveledgeprivilege.map(
            (item) => item.privilegeSubTypeId
          );
          setPrivilages(data);
        }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Handles the change of privileges for a user.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the checkbox input.
   */
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = parseInt(e.target.name);
    const head = e.target.dataset.head;

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };

  useEffect(() => {
    getUserData();
  }, [trigger]);

  const { allPrivilagesData } = useSelector((state) => state.privilages);

  useEffect(() => {
    setPrivilagesData(allPrivilagesData);
  }, [allPrivilagesData]);

  /**
   * Checks which head privileges should be set based on the current privileges.
   *
   * @param {Array<Object>} privilagesData - The list of all available privileges.
   */
  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];

    privilagesData?.forEach((item1, index) => {
      const kjhfd = item1.subTypes.map((item) =>
        privilages.includes(item.privilegeSubTypeId)
      );

      if (!kjhfd.includes(false)) {
        setHeadPrivilages((prev) => {
          return [...prev, item1.privilegeId];
        });
      }
    });
  };

  useEffect(() => {
    checkHeadPri(privilagesData);
  }, [privilagesData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div>
              <div className="">
                <div className="">
                  <div>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="Name"
                              value={formDataInputs.Name}
                              //   onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="email"
                              className="form-control"
                              name="emailId"
                              value={formDataInputs.emailId}
                              //   onChange={handleformDataInputs}
                              //   onKeyDown={(e) =>
                              //     setPressedKeyName(getKeyPressed(e))
                              //   }
                              //   onBlur={handleValidations}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label htmlFor="MobileNumber" className="form-label">
                            Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            // onChange={handleformDataInputs}
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      {/* <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Prifix
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="Prifix"
                    value={formDataInputs.Prifix}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
            </Col> */}
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              //   onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row mt-3">
                      <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                        Privileges
                      </lable>
                      <div className="d-flex flex-column gap-4">
                        {/* ... */}
                        {privilagesData &&
                          privilagesData.map((itemMain, i) => (
                            <div key={i} className="d-flex flex-column">
                              <div className="form-label mt-4 fs-4 mx-3">
                                <div className="s-lg form-check">
                                  <label className="mt-1">
                                    {itemMain.privilegeName}
                                  </label>
                                  <input
                                    id="flexSwitchCheckDefault"
                                    className="form-check-input fs-4"
                                    type="checkbox"
                                    name={itemMain.privilegeId}
                                    checked={HeadPrivilages.includes(
                                      itemMain.privilegeId
                                    )}
                                    //   onChange={handleHeadPrivilages}
                                  />
                                </div>
                                <hr />
                              </div>

                              <div className="d-flex flex-wrap ">
                                {itemMain.subTypes.map((item, i) => (
                                  <div
                                    key={i}
                                    className={`d-flex align-items-center gap-4 px-3 py-1 rounded rounded-5 mt-2 mx-2 form-group ${styles["SubTypeStyle"]}`}
                                  >
                                    <label className="form-label mt-1 fs-4 mx-1">
                                      {item.privilegeSubTypeName}
                                    </label>
                                    <div className="s-lg form-check form-switch">
                                      <input
                                        id="flexSwitchCheckDefault"
                                        className="form-check-input fs-4"
                                        data-head={itemMain.privilegeId}
                                        type="checkbox"
                                        name={item.privilegeSubTypeId}
                                        checked={privilages.includes(
                                          item.privilegeSubTypeId
                                        )}
                                        //   onChange={handlePrivilages}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        {/* ... */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
