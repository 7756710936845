import Payin from "../images/reportIcons/payin.png";
import Settlement from "../images/reportIcons/settlement.png";
import ChargeBack from "../images/reportIcons/chargeback.png";
import Myvendors from '../images/reportIcons/vendor.png';
import Payout from '../images/reportIcons/payout.png';
import Withdraw from '../images/reportIcons/withdraw.png';
import Ekyc from '../images/reportIcons/e-kyc.png';
import Ledger from '../images/reportIcons/ledger.png';
import Guarantee from '../images/reportIcons/guarantee.png';
import Reconcilation from '../images/reportIcons/Reconcilation.png';
import CreditBalance from '../images/reportIcons/credit_balance.png';
import Release from '../images/reportIcons/release.png';
import WalletBalance from '../images/reportIcons/wallet_balance.png';
import Hold from '../images/reportIcons/hold.png';
import Deposit from '../images/reportIcons/deposit.png';
import SettlementAdjustment from '../images/reportIcons/settlement_adjustment.png';

import fraudManagement from '../images/settingIcons/fraud_manage.png';
import userManagement from '../images/settingIcons/user_management.png';
import securityManagement from '../images/settingIcons/security_manage.png';
import withdrawManagement from '../images/settingIcons/withdraw_setting.png';

export const reporticons = {
    Payin,
    Settlement,
    ChargeBack,
    Myvendors,
    Payout,
    Withdraw,
    Ekyc,
    Ledger,
    Guarantee,
    Reconcilation,
    CreditBalance,
    Release,
    WalletBalance,
    Hold,
    Deposit,
    SettlementAdjustment
}

export const settingicons = {
    fraudManagement,
    userManagement,
    securityManagement,
    withdrawManagement
}