import React, { useState } from "react";
import InputBox from "../Common/InputBox/InputBox";
 

const ReportIssuePayin = ({ data }) => {
  const [utrNumber, setUtrNumber] = useState();
  const [errorDesc, setErrorsDesc] = useState(
    "Please Enter Valid UTR Number ( 12 Characters )."
  );
 
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Prevent the user from entering more than 12 characters
    if (value.length > 12) {
      return;
    }

    // Update the input value state
    setUtrNumber(value);

    // Validate input length: show error if not exactly 12 characters
    if (value.length === 12) {
      setErrorsDesc(""); // Clear error if length is exactly 12
    } else {
      setErrorsDesc("Please Enter Valid UTR Number ( 12 Characters )."); // Show error if length is not 12
    }
  };

  console.log(data);
  return (
    <div className="d-flex flex-column align-items-start">
      {/* <Lable label={"Amount"} required={true} value={data?.amount} />
      <InputBox label={"Order Number"} required={true} value={data?.orderNo} />
      <InputBox label={"Transaction Id"} required={true} value={data?.txn_id} />
      <InputBox label={"Amount"} required={true} value={data?.amount} /> */}
      <label class="form-label me-1 fs-4 text-black mx-2">
        Amount :
        <span id="reportIssuesInput1"  className="text-semibold text-primary mx-3">{data?.amount}</span>
      </label>
      <label class="form-label me-1 fs-4 text-black mx-2 ">
        Order Number :{" "}
        <span id="reportIssuesInput2"  className="text-semibold text-primary mx-3">{data?.orderNo}</span>
      </label>
      <label class="form-label me-1 fs-4 text-black mx-2 mb-4">
        Transaction Id :{" "}
        <span id="reportIssuesInput3" className="text-semibold text-primary mx-3">{data?.txn_id}</span>
      </label>
      <label class="form-label me-1 fs-4 text-black mx-2 mb-4">
        Transaction Status :{" "}
        <span id="reportIssuesInput5" className="text-semibold text-primary mx-3">{data?.txnStatus}</span>
      </label>
      <label class="form-label me-1 fs-4 text-black mx-2 mb-4">
        Transaction Date :{" "}
        <span id="reportIssuesInput6" className="text-semibold text-primary mx-3">{data?.txnDate}</span>
      </label>
      <label class="form-label me-1 fs-4 text-black mx-2 mb-4">
        Transaction Time :{" "}
        <span id="reportIssuesInput6" className="text-semibold text-primary mx-3">{data?.txnTime}</span>
      </label>
      <div class="mb-2 col-md-12 d-flex flex-column align-items-start">
        <label class="form-label me-1 fs-5 text-black mx-2">
          UTR Number
          <span class="required"> *</span>
        </label>
        <input
          id="reportIssuesInput4" 
          maxLength="12"
          minLength="12"
          type="number"
          class="form-control"
          placeholder={"Enter UTR Number"}
          value={utrNumber}
          onChange={handleInputChange}
          required={true}
        />
        {errorDesc?.length !== 12 && (
          <p style={{ color: "red" }}>{errorDesc}</p>
        )}
      </div>
      <br />
    </div>
  );
};

export default ReportIssuePayin;
