import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Cookies from "js-cookie";

import useApiServices from "../Utils/useApiService";

const IdealTimerContainer = () => {
  const navigate = useNavigate();
  const { LogOutAPI } = useApiServices();

  const handleLogout = async () => {
    LogOutAPI();
  };

  const handleOnIdle = (event) => {
    swal({
      title: "Alert!",
      text: "Session Expired ! Please LogIn Again",
      icon: "warning",
    }).then(() => {
      handleLogout();
    });
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (event) => {};

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes

    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return <>{/* <h1>Hello Idle</h1> */}</>;
};

export default IdealTimerContainer;
