import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Modal from "../../Common/Modal/Modal";
import styles from "./ArchieveReport.module.css"
import ArchieveFilter from "./ArchieveFilter";
import { Link } from "react-router-dom";

/** 
 * @returns {ReactNode} this component renders archived reports data in slidebar
*/

const ArchieveReport = () => {
  const [venderData, setVenderData] = useState([]);

 
  const column = [
    {
      headerName: "Report Type",
      field: "reportType",
      minWidth: 150,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      minWidth: 150,
    },
    {
      headerName: "End Date",
      field: "endDate",
      minWidth: 150,
    },
    {
      headerName: "Transaction Status",
      field: "txnStatus",
      minWidth: 150,
    },
    {
      headerName: "e-Mail Ids",
      field: "emailIds",
      minWidth: 150,
    },
    {
      headerName: "Download Link",
      field: "downloadLink",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Link
            to={`/Vender-Details/${params.row.vendorId}`}
            className="nav-link text-center"
            activeClassName="active-link"
          >
            <button className="btn btn-sm btn-primary">View Details</button>
          </Link>
        );
      },
    },
  ];

  // useEffect(() => {
  //   VenderByStatusWithMid();
  // }, []);

  const handleSubmit = () => {};

  return (
    // <div className="content-body">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h4 class="card-title">Archived Report</h4>

          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#ArchieveReportDownload"
          >
            Download Archived Report
          </button>
        </div>

        <Modal
          id={"ArchieveReportDownload"}
          title={"Archived Report Download"}
          // onSubmit={handleSubmit}
          Width={"lg"}
        >
          <ArchieveFilter />
        </Modal>
        <div className="row">
          <div xs={12} lg={12} md={12} className="col">
            <div className="">
              <div className="">
                <div className="col">
                  <div className={`mt-3 ${styles["DataGridHeight"]}`} 
                  >
                    {/* {venderData?.length > 0 ? ( */}
                    <DataGrid rows={[]} columns={column} />
                  </div>
                </div>
              </div>
            </div>
            {/* ./card */}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ArchieveReport;
