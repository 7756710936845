import Row from "./Row";

/**
 * PreviousTxns component renders list of various amounts.
 * @param {object} data2 - objects of multiple amounts.
 * @param {object} data -  objects of multiple amounts.
 * @param {number} payIn - amount of payin.
 * @param {string} condition - a condition.
 * @returns {ReactNode} A React element that renders the list of various amounts.
 */
export default function PreviousTxns({ data, data2, payIn, condition }) {
  return (
    <div className="col-xl-6 col-md-12 col-lg-6 col-xxl-6 ">
      <div className="card col-md-12">
        <div className="card-header d-block d-sm-flex border-0">
          <div className="me-3">
            <h4 className="card-title mb-2">
              Other Details ({condition === "1 day" ? "Today" : condition})
            </h4>
          </div>
        </div>
        <div className="card-body tab-content p-0">
          <div className="tab-pane active show fade">
            <div className="table-responsive">
              <table className="table card-table transactions-table">
                <tbody>
                  <Row Name={"Payin"} Amount={payIn || "0"} />

                  <Row
                    Name={"Settlement Charges"}
                    Amount={data?.settlementCharge}
                  />
                  <Row Name={"GST On Settlement"} Amount={data?.settlmentGstCharge} />

                  <Row Name={"Hold"} Amount={data?.holdAmount} />
                  <Row Name={"Deposit"} Amount={data?.depositAmount} />
                  <Row Name={"Chargeback"} Amount={data?.chragebackAmount} />
                  <Row
                    Name={"Withdrawn To Account"}
                    Amount={data?.withDrawToAccount}
                  />
                  <Row
                    Name={"Withdrawn To Payout"}
                    Amount={data?.withDrawTopayout}
                  />
                  <Row
                    Name={"Withdrawn To Credit"}
                    Amount={data?.withdrawnTocredit}
                  />
                  <Row Name={"Charges On Payout"} Amount={data?.payoutServiceCharge} />

                  <Row
                    Name={"GST Charges on Payout"}
                    Amount={data?.payoutGstCharge}
                  />
                  <Row
                    Name={"Commission Earned"}
                    Amount={data?.totalCommisionEarnedAmount}
                  />
                  <Row Name={"Direct Deposit To Wallet"} Amount={data?.depositeToWallet} />
                  <Row Name={"Direct Deposit To Payout"} Amount={data?.depositeToPayout} />
                  <Row
                    Name={"Guarantee Amount"}
                    Amount={data?.guranteeAmount}
                  />
                  <Row Name={"Settled To wallet"} Amount={data?.settlmentAmout} />
                  {/* <Row Name={"Total Wallet Balance"} Amount={data?.settelmentAmountNetAMtToWallet} /> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
