import React, { useState } from "react";
import {
  payoutSampleRequestIMPS,
  payoutSampleRequestUPI,
  payoutRequestTableJson,
  payoutResponseSuccessUPI,
  payoutResponseSuccessIMPS,
  payoutResponseTransferTypeFailure,
  payoutTransactionLimitsJson,
  payoutTransactionSampleRequest,
  payoutTransactionRequestParametersJson,
  payoutTransactionResponseIMPSJson,
  payoutTransactionResponseUPIJson,
  payoutTransactionCheckBalanceJson,
  payoutSampleRequestRTGS,
  payoutSampleRequestNEFT,
  payoutResponseSuccessNEFT,
  payoutResponseSuccessRTGS,
  payoutResponsePending,
} from "../Utils/apiDocs";
import swal from "sweetalert";
import BackButton from "../Common/BackButton/BackButton";
import CopyButton from "../Common/CopyButton/CopyButton";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { PAYOUT_URL } from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
import styles from "./payout.module.css";

/**
 * This component renders documentation for payout.
 * It displays payout Api Urls , request parameters, responses, etc.
 * @returns {ReactNode} A React element that renders payout documentation.
 */

const Payout_Docs = () => {
  const { apiCallFnforPostForOpenUrl } = useApiServices();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    code: "",
    value: "",
  });

  const [response, setResponse] = useState({});
  const [payload, setPayload] = useState({
    apikey: "",
    beneficiaryDetails: {
      emailAddress: "",
      ifscCode: "",
      mobileNumber: "",
      payeeName: "",
    },

    purposeMessage: "",
    referenceId: "",
    secrete: "",
    toAccount: "",
    toUpi: "",

    transferAmount: "",
    transferType: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Determine if the name is for a nested field
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setPayload((prevPayload) => ({
        ...prevPayload,
        [parent]: {
          ...prevPayload[parent],
          [child]: value,
        },
      }));
    } else {
      setPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const validateEmail = /\S+@\S+\.\S+/;
      const validateIFSCcode = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
      const validatePhone = /^[0-9]{10}$/;
      const validateAccount = /^[0-9]{9,18}$/;
      const validateUPI = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const err = {};

      if (payload.apikey !== "7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q") {
        err.error = "Please enter proper apikey which is mention above";
      } else if (payload.emailAddress && !validateEmail.test(payload.emailAddress)
      ) {
        err.error = "Please enter valid email";
      // } else if (!validateIFSCcode.test(payload.ifscCode)) {
      } else if (payload.beneficiaryDetails.ifscCode !== "BARB0SAILAN") {
        err.error = "Please enter valid IFSC code";
      } else if (!validatePhone.test(payload.beneficiaryDetails.mobileNumber)) {
        err.error = "Please enter valid phone number";
      } else if (
        payload.secrete !== "1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC"
      ) {
        err.error = "Please enter proper secrete key which is mention above";
      } else if (!validateAccount.test(payload.toAccount)) {
        err.error = "Please enter valid Account number";
      } 
      // else if (!validateUPI.test(payload.toUpi)) {
      //   err.error = "Please enter valid UPI id";
      // }

      if (Object.keys(err).length !== 0) {
        console.log("err object is empty");
        swal({
          title: "Alert!",
          text: err?.error,
          icon: "error",
        });

        return;
      }

      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(PAYOUT_URL, payload);
      setResponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleClearData = () => {
    setPayload((prev) => ({
      ...prev,
      apikey: "",
      beneficiaryDetails: {
        emailAddress: "",
        ifscCode: "",
        mobileNumber: "",
        payeeName: "",
      },
      purposeMessage: "",
      referenceId: "",
      secrete: "",
      toAccount: "",
      toUpi: "",
      transferAmount: "",
      transferType: "",
    }));
  };

  return (
    <>
      <div class="content-body">
        <div className="container-fluid p-3">
          <div className="row">
            <div className={`col-xl-8 col-xxl-8 ${styles.leftWrapper}`}>
              <div className="card rounded-1">
                <div className="card-header">
                  <BackButton />
                  <h4 className="card-title">Payout Docs</h4>
                </div>
                <div
                  className={`card-body p-3 rounded-1 ${styles.leftSubWrapper}`}
                >
                  <div class="post-details">
                    <h3 className={`mb-2 text-black ${styles.font700}`}>
                      Initiate Payout:
                    </h3>
                    <p class="mb-4 post-meta d-flex flex-wrap text-black">
                      This API allows you to facilitate money transfers to/from
                      the linked virtual bank accounts of your customers from
                      within your application.
                    </p>

                    <div class="table-responsive">
                      <table class="table table-bordered table-responsive-sm">
                        <thead>
                          <tr>
                            <th>Method</th>
                            <th>Environment</th>
                            <th>Url</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="badge badge-primary">POST</span>{" "}
                            </td>
                            <td class="text-black">Production</td>
                            <td>
                              {" "}
                              <div className="text-black">{`https://payout.icepe.in/swipelinc-payout/core-banking/initiate-payout`}</div>
                              <div>
                                <CopyButton
                                  text={`https://payout.icepe.in/swipelinc-payout/core-banking/initiate-payout`}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="badge badge-primary">POST</span>{" "}
                            </td>
                            <td class="text-black">uat / staging</td>
                            <td>
                              {" "}
                              <div className="text-black">{PAYOUT_URL}</div>
                              <div>
                                <CopyButton
                                  text={PAYOUT_URL}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col">
                      <h5 className={`mt-3 ${styles.font600}`}>
                        Icepe provides the below type of transfers.
                      </h5>

                      <ul class="list-group">
                        <li class="list-group-item">
                          Virtual account to actual bank account Icepe supports
                          the below modes of transfers.
                        </li>
                        <li class="list-group-item">NEFT</li>
                        <li class="list-group-item">RTGS</li>
                        <li class="list-group-item">IMPS</li>
                        <li class="list-group-item">UPI</li>
                      </ul>
                    </div>

                    <div className="col mt-4">
                      <h5 className={styles.font700}>
                        Note: API Key & Secrete Key will be share by Icepe.
                      </h5>
                      <h4 className={`${styles.font700} mt-3`}>For Uat :</h4>
                      <h5 className={`${styles.font700} mt-3`}>
                        apiKey : 7Yd2IrCddOSAMu5URi9cgRCbQlFjnYmH8Zva6Q
                      </h5>
                      <h5 className={`${styles.font700} mt-3`}>
                        secreteKey : 1XNJEcBRmFFiCdcmpXfQrBuHW5m476AV5hEoMC
                      </h5>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div class="post-details mt-3">
                    <h4 className={`mb-2 text-black ${styles.font600}`}>
                      Sample Request:
                    </h4>

                    <div class="custom-tab-1">
                      <ul class="nav nav-tabs">
                        <li class="nav-item">
                          <a
                            class="nav-link active text-black"
                            data-bs-toggle="tab"
                            href="#imps"
                          >
                            <i class="la la-home me-2"></i> IMPS
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link text-black"
                            data-bs-toggle="tab"
                            href="#upi"
                          >
                            <i class="la la-user me-2"></i> UPI
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link  text-black"
                            data-bs-toggle="tab"
                            href="#neft"
                          >
                            <i class="la la-home me-2"></i> NEFT
                          </a>
                        </li>

                        <li class="nav-item">
                          <a
                            class="nav-link  text-black"
                            data-bs-toggle="tab"
                            href="#rtgs"
                          >
                            <i class="la la-home me-2"></i> RTGS
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active mt-4"
                          id="imps"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(payoutSampleRequestIMPS, null, 2)}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutSampleRequestIMPS,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>
                        <div
                          class="tab-pane fade show  mt-4"
                          id="upi"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(payoutSampleRequestUPI, null, 2)}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutSampleRequestUPI,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show  mt-4"
                          id="neft"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(payoutSampleRequestNEFT, null, 2)}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutSampleRequestNEFT,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>
                        <div class="tab-pane fade" id="rtgs">
                          <div className="d-flex gap-4 mt-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(payoutSampleRequestRTGS, null, 2)}
                            </pre>

                            <CopyButton
                              text={JSON.stringify(
                                payoutSampleRequestRTGS,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-header">
                          <h4 className={`card-title ${styles.font700}`}>
                            Request Parameters:
                          </h4>
                        </div>
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table header-border table-responsive-sm">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Key
                                  </th>
                                  <th
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Type / Mandatory
                                  </th>
                                  <th
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Length
                                  </th>
                                  <th
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Possible values/Example
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {payoutRequestTableJson.fields.map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <th className={styles.colorblack}>
                                          {item?.Key}
                                        </th>
                                        <td className={styles.colorblack}>
                                          {item?.["Type / Mandatory"]}
                                        </td>
                                        <td className={styles.colorblack}>
                                          {item?.length}
                                        </td>
                                        <td className={styles.colorblack}>
                                          {item?.Description}
                                        </td>
                                        <td className={styles.colorblack}>
                                          {item?.["Possible values/Example"]}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <h5 className={`text-black ${styles.font600}`}>
                        BODY PARAMS:
                      </h5>
                      <div class="basic-list-group">
                        <ul className="list-group">
                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                apikey
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="apikey"
                                  type="text"
                                  value={payload.apikey}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="api key"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                emailAddress
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="beneficiaryDetails.emailAddress"
                                  type="email"
                                  value={
                                    payload.beneficiaryDetails.emailAddress
                                  }
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="abc@gmail.com"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                ifscCode
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="beneficiaryDetails.ifscCode"
                                  type="text"
                                  value={payload.beneficiaryDetails.ifscCode}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="SBIN0007131"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                mobileNumber
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="beneficiaryDetails.mobileNumber"
                                  type="number"
                                  value={
                                    payload.beneficiaryDetails.mobileNumber
                                  }
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="9827525252"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                payeeName
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="beneficiaryDetails.payeeName"
                                  type="text"
                                  value={payload.beneficiaryDetails.payeeName}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="payee name"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                purposeMessage
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="purposeMessage"
                                  type="text"
                                  value={payload.purposeMessage}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="message"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                referenceId
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="referenceId"
                                  type="text"
                                  value={payload.referenceId}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="Test101"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                secrete
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="secrete"
                                  type="text"
                                  value={payload.secrete}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="secrete"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                toAccount
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="toAccount"
                                  type="text"
                                  value={payload.toAccount}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="toAccount"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                toUpi
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="toUpi"
                                  type="text"
                                  value={payload.toUpi}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="toUpi"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                transferAmount
                              </label>
                              <div className="col-sm-5">
                                <input
                                  name="transferAmount"
                                  type="number"
                                  value={payload.transferAmount}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="transfer amount"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item p-3">
                            <div className="row d-flex justify-content-between align-items-center">
                              <label className="col-sm-7 col-form-label col-form-label-md text-black">
                                transferType
                              </label>
                              <div className="col-sm-5">
                                <select
                                  name="transferType"
                                  onChange={handleChange}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                >
                                  <option value="">Select</option>
                                  <option value="IMPS">IMPS</option>
                                  <option value="UPI">UPI</option>
                                  <option value="NEFT">NEFT</option>
                                  <option value="RTGS">RTGS</option>
                                </select>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      {"Response (Success) "}
                    </h4>

                    <div class="custom-tab-1 ">
                      <ul class="nav nav-tabs">
                        <li class="nav-item text-black">
                          <a
                            class="nav-link active"
                            data-bs-toggle="tab"
                            href="#payoutsuccessupi"
                          >
                            <i class="la la-home me-2"></i> UPI
                          </a>
                        </li>
                        <li class="nav-item ">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#payoutsuccessimps"
                          >
                            <i class="la la-user me-2"></i> IMPS
                          </a>
                        </li>
                        <li class="nav-item ">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#payoutsuccessneft"
                          >
                            <i class="la la-user me-2"></i> NEFT
                          </a>
                        </li>
                        <li class="nav-item ">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#payoutsuccessrtgs"
                          >
                            <i class="la la-user me-2"></i> RTGS
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="payoutsuccessupi"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(
                                payoutResponseSuccessUPI,
                                null,
                                2
                              )}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutResponseSuccessUPI,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="payoutsuccessimps"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(
                                payoutResponseSuccessIMPS,
                                null,
                                2
                              )}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutResponseSuccessIMPS,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="payoutsuccessneft"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(
                                payoutResponseSuccessNEFT,
                                null,
                                2
                              )}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutResponseSuccessNEFT,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="payoutsuccessrtgs"
                          role="tabpanel"
                        >
                          <div className="d-flex gap-4">
                            <pre className={styles.m1}>
                              {JSON.stringify(
                                payoutResponseSuccessRTGS,
                                null,
                                2
                              )}
                            </pre>
                            <CopyButton
                              text={JSON.stringify(
                                payoutResponseSuccessRTGS,
                                null,
                                2
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* payout response pending start */}
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      {"Response (Pending) "}
                    </h4>

                    <div class="custom-tab-1">
                      <div className="d-flex gap-4">
                        <pre className={styles.m1}>
                          {JSON.stringify(payoutResponsePending, null, 2)}
                        </pre>
                        <CopyButton
                          text={JSON.stringify(payoutResponsePending, null, 2)}
                        />
                      </div>
                    </div>

                    <div className="d-flex gap-4">
                      <h4 className={`mb-2 text-black ${styles.font700}`}>
                        {" "}
                        For any transfer type (failure):
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          payoutResponseTransferTypeFailure,
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre className={styles.m1}>
                      {JSON.stringify(
                        payoutResponseTransferTypeFailure,
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div class="post-details mt-5">
                    <h3 className={`mb-2 text-black ${styles.font700}`}>
                      Transaction Limit
                    </h3>
                    <p class="mb-4 post-meta d-flex flex-wrap">
                      Please note the below transaction limits as per prescribed
                      by RBI.
                    </p>

                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table header-border table-responsive-sm">
                          <thead>
                            <tr>
                              <th style={{ fontWeight: "700" }}>
                                Transfer Type
                              </th>
                              <th style={{ fontWeight: "700" }}>
                                Transaction Limit
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {payoutTransactionLimitsJson.fields.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <th className={styles.colorblack}>
                                      {item?.["Transfer Type"]}
                                    </th>
                                    <td className={styles.colorblack}>
                                      {item?.["Transaction Limit"]}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="post-details">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Get Transaction Status:
                    </h4>
                    <p class="mb-4 post-meta d-flex flex-wrap">
                      This API can be used by you to check the outgoing
                      transactions that were initiated by your customers.
                    </p>

                    <div class="profile-skills mt-5 mb-5">
                      <div class="table-responsive">
                        <table class="table table-bordered table-responsive-sm">
                          <thead>
                            <tr>
                              <th>Method</th>
                              <th>Environment</th>
                              <th>Url</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span class="badge badge-primary">POST</span>{" "}
                              </td>
                              <td class="text-black">Production</td>
                              <td>
                                {" "}
                                <div className="text-black">{`https://payout.icepe.in/swipelinc-payout/core-banking/get-status`}</div>
                                <div>
                                  <CopyButton
                                    text={`https://payout.icepe.in/swipelinc-payout/core-banking/get-status`}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="badge badge-primary">POST</span>{" "}
                              </td>
                              <td class="text-black">uat / staging</td>

                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex gap-4 mt-4">
                        <h4 className={`text-black ${styles.font700}`}>
                          Sample Request:
                        </h4>
                        <CopyButton
                          text={JSON.stringify(
                            payoutTransactionSampleRequest,
                            null,
                            2
                          )}
                        />
                      </div>
                      <pre className={styles.m1}>
                        {JSON.stringify(
                          payoutTransactionSampleRequest,
                          null,
                          2
                        )}
                      </pre>

                      {/* </pre> */}

                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-header">
                            <h5
                              className={`card-title text-black ${styles.font700}`}
                            >
                              Request Parameters:
                            </h5>
                          </div>
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table header-border table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        color: "Black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Key
                                    </th>
                                    <th
                                      style={{
                                        color: "Black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Type / Mandatory
                                    </th>
                                    <th
                                      style={{
                                        color: "Black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Description
                                    </th>
                                    <th
                                      style={{
                                        color: "Black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Possible values/Example
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payoutTransactionRequestParametersJson.fields.map(
                                    (item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th className={styles.colorblack}>
                                            {item?.Key}
                                          </th>
                                          <td className={styles.colorblack}>
                                            {item?.["Type / Mandatory"]}
                                          </td>
                                          <td className={styles.colorblack}>
                                            {item?.Description}
                                          </td>
                                          <td className={styles.colorblack}>
                                            {item?.["Possible values/Example"]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 className={`text-black mb-2 mt-4 ${styles.font700}`}>
                        Response
                      </h4>
                      <div class="custom-tab-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active text-black"
                              data-bs-toggle="tab"
                              href="#txnimps"
                            >
                              <i class="la la-home me-2"></i> IMPS
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link text-black"
                              data-bs-toggle="tab"
                              href="#txnupi"
                            >
                              <i class="la la-user me-2"></i> UPI
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active mt-4"
                            id="txnimps"
                            role="tabpanel"
                          >
                            <div className="d-flex gap-4">
                              <pre className={styles.m1}>
                                {JSON.stringify(
                                  payoutTransactionResponseIMPSJson,
                                  null,
                                  2
                                )}
                              </pre>
                              <CopyButton
                                text={JSON.stringify(
                                  payoutTransactionResponseIMPSJson,
                                  null,
                                  2
                                )}
                              />
                            </div>
                          </div>

                          <div
                            class="tab-pane fade show mt-4"
                            id="txnupi"
                            role="tabpanel"
                          >
                            <div className="d-flex gap-4">
                              <pre className={styles.m1}>
                                {JSON.stringify(
                                  payoutTransactionResponseUPIJson,
                                  null,
                                  2
                                )}
                              </pre>
                              <CopyButton
                                text={JSON.stringify(
                                  payoutTransactionResponseUPIJson,
                                  null,
                                  2
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="post-details">
                    <h3 className={`mb-2 text-black ${styles.font700}`}>
                      Check Balance:
                    </h3>

                    <div class="profile-skills mt-2 mb-5">
                      <div class="table-responsive">
                        <table class="table table-bordered table-responsive-sm">
                          <thead>
                            <tr>
                              <th>Method</th>
                              <th>Environment</th>
                              <th>Url</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span class="badge badge-primary">POST</span>{" "}
                              </td>
                              <td class="text-black">Production</td>
                              <td>
                                {" "}
                                <div className="text-black">{`https://payout.icepe.in/swipelinc-payout/core-banking/check-balance`}</div>
                                <div>
                                  <CopyButton
                                    text={`https://payout.icepe.in/swipelinc-payout/core-banking/check-balance`}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="badge badge-primary">POST</span>{" "}
                              </td>
                              <td class="text-black">uat / staging</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex mt-4 gap-4">
                        <h4 className={`text-black ${styles.font700}`}>
                          Sample Request:
                        </h4>
                        <CopyButton
                          text={JSON.stringify(
                            {
                              apikey: "Test-key",
                              secrete: "secrete",
                              transferType: "UPI",
                            },
                            null,
                            2
                          )}
                        />
                      </div>
                      <pre className={styles.m1}>
                        {JSON.stringify(
                          {
                            apikey: "Test-key",
                            secrete: "secrete",
                            transferType: "UPI",
                          },
                          null,
                          2
                        )}
                      </pre>
                      {/* </pre> */}

                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-header">
                            <h4 className={`card-title ${styles.font700}`}>
                              Request Parameters:
                            </h4>
                          </div>
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table header-border table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        color: "black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Key
                                    </th>
                                    <th
                                      style={{
                                        color: "black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Type / Mandatory
                                    </th>
                                    <th
                                      style={{
                                        color: "black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Description
                                    </th>
                                    <th
                                      style={{
                                        color: "black",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Possible values/Example
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payoutTransactionCheckBalanceJson.fields.map(
                                    (item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th className={styles.colorblack}>
                                            {item?.Key}
                                          </th>
                                          <td className={styles.colorblack}>
                                            {item?.["Type / Mandatory"]}
                                          </td>
                                          <td className={styles.colorblack}>
                                            {item?.Description}
                                          </td>
                                          <td className={styles.colorblack}>
                                            {item?.["Possible values/Example"]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 className={`mb-2 text-black mt-4 ${styles.font700}`}>
                        Response
                      </h4>
                      <div className="custom-tab-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active text-black"
                              data-bs-toggle="tab"
                              href="#successresponse"
                            >
                              <i class="la la-home me-2"></i> Sucess
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link text-black"
                              data-bs-toggle="tab"
                              href="#failedresponse"
                            >
                              <i class="la la-user me-2"></i> Failed
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active mt-4"
                            id="successresponse"
                            role="tabpanel"
                          >
                            <div className="d-flex gap-4">
                              <pre className={styles.m1}>
                                {JSON.stringify(
                                  {
                                    amount: "21892.00",
                                    message: "success",
                                    statusCode: 200,
                                  },
                                  null,
                                  2
                                )}
                              </pre>
                              <CopyButton
                                text={JSON.stringify(
                                  {
                                    amount: "21892.00",
                                    message: "success",
                                    statusCode: 200,
                                  },
                                  null,
                                  2
                                )}
                              />
                            </div>
                          </div>

                          <div
                            className="tab-pane fade show mt-4"
                            id="failedresponse"
                            role="tabpanel"
                          >
                            <div className="d-flex gap-4">
                              <pre className={styles.m1}>
                                {JSON.stringify(
                                  {
                                    amount: "0",
                                    message: "Bad Request",
                                    statusCode: 306,
                                  },
                                  null,
                                  2
                                )}
                              </pre>
                              <CopyButton
                                text={JSON.stringify(
                                  {
                                    amount: "0",
                                    message: "Bad Request",
                                    statusCode: 306,
                                  },
                                  null,
                                  2
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex gap-4">
                        <h5 className={`mb-2 text-black ${styles.font700}`}>
                          IF Apikey and secrete key is mismatch:
                        </h5>
                        <CopyButton
                          text={JSON.stringify(
                            {
                              amount: "0",
                              message: "Please check API key and Secret key",
                              statusCode: 304,
                            },
                            null,
                            2
                          )}
                        />
                      </div>
                      <pre className={styles.m1}>
                        {JSON.stringify(
                          {
                            amount: "0",
                            message: "Please check API key and Secret key",
                            statusCode: 304,
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </div>

                  <div class="post-details">
                    <h3 className={`mb-2 text-black ${styles.font700}`}>
                      For Callback URL
                    </h3>
                    <p
                      className={`mb-4 post-meta d-flex flex-wrap ${styles.colorblack}`}
                    >
                      Merchant needs to share the Callback URL with the Icepe.
                    </p>

                    <div class="table-responsive">
                      <table class="table header-border table-responsive-sm">
                        <thead>
                          <tr>
                            <th style={{ color: "black", fontWeight: "600" }}>
                              Type
                            </th>
                            <th style={{ color: "black", fontWeight: "600" }}>
                              Description
                            </th>
                            <th style={{ color: "black", fontWeight: "600" }}>
                              Possible values/Example
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={styles.colorblack}>string</td>
                            <td className={styles.colorblack}>
                              With the Callback URL, merchant will get the
                              Callback response. <br /> (Mandatory for Callback
                              response)
                            </td>
                            <td className={styles.colorblack}>
                              https://api.xyzpay.in/callback/icepe/payout
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h4 className={`mb-2 text-black mt-4 ${styles.font700}`}>
                      Response JSON :
                    </h4>
                    <div className="d-flex gap-4">
                      <h5
                        className={`text-primary mb-2  text-black ${styles.font600}`}
                      >
                        Success:
                      </h5>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            attempt: 1,

                            timestamp: "2023-12-06T11:27:19.292247",
                            callbackTxnId: "AD65F2FA25CA4A538C7C78F55E70D24F",

                            originalCallbackTxnId:
                              "AD65F2FA25CA4A538C7C78F55E70D24F",
                            transactionStatus: "success",

                            referenceId: "SWIPEXYZXM",

                            decentroTxnId: "591279EC18FA4CA281C2CC87438F7687",

                            transactionMessage: "For testing",
                            transferType: "IMPS",
                            bankReferenceNumber: "334011887395",
                            "beneficiary Name": "Mrs. XYZ CHAVAN",
                            transactionAmount: 8700,
                            providerMessage: null,

                            errorKey: null,
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre className={styles.m1}>
                      {JSON.stringify(
                        {
                          attempt: 1,

                          timestamp: "2023-12-06T11:27:19.292247",
                          callbackTxnId: "AD65F2FA25CA4A538C7C78F55E70D24F",

                          originalCallbackTxnId:
                            "AD65F2FA25CA4A538C7C78F55E70D24F",
                          transactionStatus: "success",

                          referenceId: "SWIPEXYZXM",

                          decentroTxnId: "591279EC18FA4CA281C2CC87438F7687",

                          transactionMessage: "For testing",
                          transferType: "IMPS",
                          bankReferenceNumber: "334011887395",
                          "beneficiary Name": "Mrs. XYZ CHAVAN",
                          transactionAmount: 8700,
                          providerMessage: null,

                          errorKey: null,
                        },
                        null,
                        2
                      )}
                    </pre>

                    <div className="d-flex gap-4">
                      <h5
                        className={`text-primary mb-2 text-black ${styles.colorblack} ${styles.font600}`}
                      >
                        Excepected Response Json from merchant:
                      </h5>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            status: "CB_S00000",

                            statusCode: 200,
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre className={styles.m1}>
                      {JSON.stringify(
                        {
                          status: "CB_S00000",

                          statusCode: 200,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-xl-4 col-xxl-4 ${styles.rightWrapper}`}>
              <div className="card rounded-1">
                <div className="card-body p-2">
                  <div className={styles.position_relative}>
                    <div
                      className="d-flex gap-2 align-items-center"
                      style={{
                        position: "absolute",
                        bottom: "5%",
                        right: "5%",
                      }}
                    >
                      <button
                        onClick={handleClearData}
                        className={styles.buttons}
                      >
                        clear
                      </button>
                      <button onClick={handleSubmit} className={styles.buttons}>
                        {loading ? <MiniLoader /> : "Try it"}
                      </button>
                    </div>

                    <SyntaxHighlighter
                      style={dark}
                      language="javascript"
                      showLineNumbers
                    >
                      {JSON.stringify(
                        {
                          apikey: payload.apikey,
                          beneficiaryDetails: {
                            emailAddress:
                              payload.beneficiaryDetails.emailAddress,
                            ifscCode: payload.beneficiaryDetails.ifscCode,
                            mobileNumber:
                              payload.beneficiaryDetails.mobileNumber,
                            payeeName: payload.beneficiaryDetails.payeeName,
                          },

                          purposeMessage: payload.purposeMessage,
                          referenceId: payload.referenceId,
                          secrete: payload.secrete,
                          toAccount: payload.toAccount,
                          toUpi: payload.toUpi,

                          transferAmount: payload.transferAmount,
                          transferType: payload.transferType,
                        },
                        null,
                        2
                      )}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <div
                  className="m-2 border rounded-2"
                  style={{ maxHeight: "300px" }}
                >
                  <div
                    className={`d-flex justify-content-between align-item-center pt-2 ${styles.m2}`}
                  >
                    <p className="text-black" style={{ fontSize: "0.8rem" }}>
                      Response
                    </p>
                    <p className="text-black" style={{ maxWidth: "300px", fontSize: "0.7rem" }}>
                      {response?.status} <span>{response?.statusCode}</span>
                    </p>
                  </div>

                  <div className={`card-body border pt-1 ${styles.m3}`}>
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(response?.data, null, 2)}
                    </pre>
                  </div>
                  <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(response?.data, null, 2)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payout_Docs;
