import React from "react";
import useApiServices from "../../Utils/useApiService";
import { NOTIFICATION_DELETE_API } from "../../Utils/Constants";

const Notify = ({ notifications }) => {
  const { apiCallFnforPut } = useApiServices();

  const handledisable = async (id) => {
    try {
      const response = await apiCallFnforPut(NOTIFICATION_DELETE_API + id);

      if (response.data.statusCode === 200) {
        // setGetNotify(!getNotify);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  return (
    <div
      class="alert alert-success text-black d-flex align-items-center gap-2"
      // role="alert"
    >
      <div className="d-flex align-items-center gap-2">
        <i class="fa fa-circle-check fs-2 text-success"></i>
        {notifications?.notification}
      </div>
      <div
        className="p-1 px-2  bg-danger rounded-5"
        onClick={() => handledisable(notifications.id)}
      >
        {/* <button
        type="button"
        class="btn-close"
        onClick={DeleteNotification}
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button> */}
        <i class="fa fa-trash text-white mt-1"></i>
      </div>
    </div>
  );
};

export default Notify;
