import React from 'react'
import { config, getCompleteDomain, getServerEnvironment } from '../Utils/DynamicPortalFunctions';
import { Link } from 'react-router-dom';

/**
 * LeftPagePassword component renders title, image etc in password change page.
 * @returns {ReactNode} A React element that renders title, image etc in password change page.
 */
const LeftPagePassword = () => {
    
  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));
  return (
    <div className="col-xl-6 col-lg-6">
    <div className="pages-left h-100">
      <div className="login-content">
        <Link to="/">
        <img
          src={logoPath}
          className="mt-5"
          style={{
            width: "auto",
            maxHeight: "80px",
            objectFit: "contain",
          }}
          alt="logo"
        />
        </Link>

        <p>
          Your true value is determined by how much more you give in
          value than you take in payment. ...
        </p>
      </div>
      <div className="login-media text-center">
        <img src="images/login-1.png" className="w-50" alt="" />
      </div>
    </div>
  </div>
  )
}

export default LeftPagePassword