import React, { useEffect, useState } from "react";
import DateFilter from "../Filter/DateFilter";
import Statusfilter from "../Filter/Statusfilter";
import ReportType from "../Filter/ReportType";

import useApiServices from "../../Utils/useApiService";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GET_DOWNLOAD_CSV_OF_ARCHIEVE_REPORT } from "../../Utils/Constants";
import DownloadCsv from "./DownloadCsv";
import styles from "./ArchieveReport.module.css";
import { setInitialObject } from "../../store/Slices/reportFilterSlice";
import swal from "sweetalert";

/** 
 * This component is used for modal for archieve filter.
 * @param {function} - handleClose - this is useState function to close or toggle modal of archieve filter.
 * @returns {ReactNode} - This component renders elements of archieved filters in modal.
*/

const ArchieveFilter = ({ handleClose }) => {
  const [callDispatch, setCallDispatch] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailIds, setEmailIds] = useState([]);
  const [archieveFilterFlag, setArchieveFilterFlag] = useState(false);
  // const [removeData,setRemoveData] = useState(false)
  const { mid } = useSelector((state) => state.userData);

  const dispatch = useDispatch();

  const {
    dateoption,
    otherDateoption,
    date,
    startDate,
    endDate,
    otherdate,
    otherStartDate,
    otherEndDate,
    parameterType,
    parameterValue,
    transectionStatus,
    transferType,
    startAmount,
    endAmount,
    reportType,
  } = useSelector((state) => state.reportFilter);

  const { apiCallFnforPost } = useApiServices();

  const handleSave = async () => {
    setArchieveFilterFlag(true);

    await setCallDispatch(!callDispatch);
    setFlag(!flag);
  };

  const handleSetEmailIds = (e) => {
    const value = e.target.value;
    setEmailIds(value.split(","));
  };

  const handleRemoveData = (e) => {
    setData([]);
  };

  const keysToRemove = [
    "callbackJson",
    "json",
    "merchantcallbackResponse",
    "callbackAttempt",
  ];

  const removeUnwantedKeys = (data, keysToRemove) => {
    return data.map((item) => {
      const newItem = { ...item };
      keysToRemove?.forEach((key) => delete newItem[key]);
      return newItem;
    });
  };

  const fetchData = async () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const differenceInTime = endDateObj.getTime() - startDateObj.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // Check if the difference is greater than 60 days
    if (differenceInDays > 60) {
      swal("Warning!", "Date range exceeds 60 days.", "warning");
      return;
    }

    const payload = {
      type: reportType,
      date: dateoption?.toUpperCase() === "DATE" ? date : "",
      startDate: dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
      endDate: dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
      status: transectionStatus,
      email: emailIds,
      mid: mid,
    };

    try {
      const response = await apiCallFnforPost(
        GET_DOWNLOAD_CSV_OF_ARCHIEVE_REPORT,
        payload
      );

      if (response.statusCode === 200) {
        setData(
          response?.data === "Done"
            ? []
            : removeUnwantedKeys(response?.data, keysToRemove)
        );
        dispatch(setInitialObject());
        if (response?.data == "Done") {
          swal({
            title: "Success!",
            text: "Csv has been sent to the given e-Mail Ids.",
            icon: "success",
          });
          let clsbtn = document.getElementById("close_btn");
          clsbtn.click();
          return;
        }
        swal({
          title: "Success!",
          text: "Please Download the CSV From Download Button",
          icon: "success",
        });
        // Assuming the API response contains the data for the Excel file
      } else {
        swal({
          title: "Failed!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      swal({
        title: "Failed!",
        text: "Somwthing Went Wrong.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (archieveFilterFlag) {
      fetchData();
    }
  }, [flag]);

  useEffect(() => {
    handleRemoveData();
  }, [downloaded]);

  return (
    <>
      <div>
        <ReportType callDispatch={callDispatch} />
        <DateFilter className="mt-2" callDispatch={callDispatch} />
        <Statusfilter callDispatch={callDispatch} />
      </div>

      <div className="mt-3">
        <label className="d-flex justify-content-center align-items-center fs-4 text-black">
          <input
            className={`mx-3 fs-4 ${styles["CheckBoxScale"]}`}
            type="checkbox"
            checked={sendEmail}
            onChange={(e) => setSendEmail(e.target.checked)}
          />
          Send report via email
        </label>
        {sendEmail && (
          <div className="d-flex flex-column text-black">
            <legend className="text-start text-black">e-Mail IDs</legend>
            <div className={`${styles["email-ids-container"]}`}>
              {emailIds.map((email, index) => (
                <div key={index} className={`${styles["email-id-box"]}`}>
                  {email}
                  <button
                    className={`${styles["remove-email-btn"]}`}
                    onClick={() =>
                      setEmailIds((prevEmailIds) =>
                        prevEmailIds.filter((_, i) => i !== index)
                      )
                    }
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            <input
              className="advanced-input-box form-control fs-4"
              type="text"
              value={emailIds}
              onChange={handleSetEmailIds}
              placeholder="Enter e-Mail Id"
            />
            <span className="mx-4 text-start text-black">
              Please use comma ( , ) as separater
            </span>
          </div>
        )}
      </div>

      <div className="modal-footer">
        {data.length ? (
          <div className="modal-footer">
            <DownloadCsv
              setDownloaded={setDownloaded}
              DownLoadData={data}
              Name={`${reportType}-Report`}
              setData={setData}
            />
          </div>
        ) : (
          <>
            <button
              id="close_btn"
              type="button"
              className="btn btn-danger light"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Submit
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ArchieveFilter;
