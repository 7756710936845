import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuthorizedPersonNameAf,
  updateAadharNumberAf,
  updatePanCardNumberAf,
  updateAadharNumberVerifyAf,
  updatePanCardNumberVerifyAf,
  updateFirstName,
  updateLastName,
  updateBusinessType,
  updateBusinessTypeId,
  updateCompanyName,
  updateCompanyPanNumber,
  updateBusinessAs,
  updateWebsite,
  updateBusinessCategory,
  updateGstNumber,
  updateOtherBusinessCategory,
  updateGstVerified,
  updateCompanyPanNumberVerified,
  updateRegistrationTypeId,
  updateAppurl,
  updatePanCardHolderDOB,
  updatePanCardHolderName,
  updateCompanyPanCardHolderName,
  updateCompanyPanCardHolderDOB,
  updateMerchantType,
  updateIncorporationDate,
} from "../store/Slices/onBoardSlice";
import {
  getKeyPressed,
  isPanNumRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../Utils/validations";
import { useNavigate } from "react-router-dom";

import {
  ADHAR_OTP_VERIFICATION,
  ADHAR_VERIFICATION,
  FETCH_BUSINESS_CATEGORIES,
  FETCH_BUSINESS_TYPE,
  FETCH_REGISTRATION_TYPES,
  GST_VERIFICATION,
  PAN_VERIFICATION,
  SWIPELINC_API,
} from "../Utils/Constants";
import swal from "sweetalert";
import { Col } from "react-bootstrap";
import useApiServices from "../Utils/useApiService";
import axios from "axios";

/**
 * AdharForm component renders Adhar form of the onboarding process.
 * @returns {ReactNode} A React element that renders Adhar form of the onboarding process.
 */
export default function AdharForm() {
  const prevData = useSelector((state) => state.onBoard);

  const { apiCallFnforGetOpenUrl, apiCallFnforPostForOpenUrl } =
    useApiServices();
  const [showComPanInput, setShowComPanInput] = useState(
    prevData.businessType === "6" ? false : true
  );
  const [showIncorrectOTP, setShowIncorrectOTP] = useState(false);
  const [adharVerifyOtp, setAdharVerifyOtp] = useState();
  const [adharVerifyValue, setadharVerifyValue] = useState(false);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [registrationType, setRegistrationTypes] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessType, setBusinessTypes] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [isGstVerify, setisGstVerify] = useState(false);
  const [panVerify, setPanVerify] = useState();
  const [panVerifyValueAf, setPanVerifyValueAf] = useState(false);
  const [isPersonalPanVerified, setIsPersonalPanVerified] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [authorizedPersonName, setAuthorizedPersonName] = useState("");
  const [sendOTPCount, setSendOTPCount] = useState(0);
  const [isOtpTimerStart, setIsOtpTimerStart] = useState(false);
  const [otpTimer, setOtpTimer] = useState(45);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [adharVerify, setAdharVerify] = useState();
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [otpResendAttempts, setOtpResendAttempts] = useState(0);
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [showPopupPan, setShowPopupPan] = useState(false);

  const dispatch = useDispatch(); // Get the dispatch function
  const navigate = useNavigate();
  if (!localStorage.getItem("registrationId")) {
    navigate("/MerchantOnBoard");
  }

  const initialState = {
    registrationTypeId: "",
    merchantType: "",
    businessType: "",
    incorporationDate: "",
     
    businessCategory: "",
    otherBusinessCategory: "",
    companyName: "",
    gstNumber: "",
    companyPanNumber: "",
    businessAs: "",
    website: "",
    appUrl: "",
    firstName: "",
    lastName: "",
    otp: "",
    panCardNumber: "",
    aadharNumber: "",
    // typeOfServices: "",
    isAadharNumberVerified: false,
  };

  useEffect(() => {
    if (isOtpTimerStart) {
      let myInterval = setInterval(() => {
        if (otpTimer > 0) {
          setOtpTimer(otpTimer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [otpTimer, isOtpTimerStart]);

  const getInitialValues = (prevData) => {
    const isReturning = localStorage.getItem("isReturning");
    // console.log(prevData.aadharNumberVerifyAf);
    // console.log(prevData.panCardNumberAf);
    if (isReturning) {
      return {
        ...initialState,
        registrationTypeId: prevData.registrationTypeId,
        merchantType: prevData.merchantType,
        businessType: prevData.businessType,
        incorporationDate: prevData.incorporationDate,
        businessCategory: prevData.businessCategory,
        otherBusinessCategory: prevData.otherBusinessCategory,
        companyName: prevData.companyName,
        gstNumber: prevData.gstNumber,
        companyPanNumber: prevData.companyPanNumber,
        businessAs: prevData.businessAs,
        website: prevData.website,
        appUrl: prevData.appUrl,
        firstName: prevData.firstName,
        lastName: prevData.lastName,
        aadharNumber: prevData.aadharNumberAf,
        isAadharNumberVerified:
          prevData.aadharNumberVerifyAf === "" ? false : true,
        panCardNumber: prevData.panCardNumberAf,
        panCardHolderName: prevData.panCardHolderName,
        panCardHolderDOB: prevData.panCardHolderDOB,
        CompanyPanCardHolderName: prevData.companyPanCardHolderName,
        CompanyPanCardHolderDOB: prevData.companyPanCardHolderDOB,
        otp: "",
      };
    } else {
      return initialState;
    }
  };

  const [formData, setFormData] = useState(getInitialValues(prevData));

  const handleSkipAadharVerification = () => {
    document.getElementById("adharSendOTPBtn").disabled = true;
    setAdharVerify(false);
    setShowOtpInput(false);
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (stopSpecialCharacterInput(pressedKeyName) && name === "businessAs") {
      return;
    }
    if (name == "merchantType") {
      console.log(name, value);
      setFormData({ ...formData, merchantType: value });
    }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "firstName"
    ) {
      return;
    }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "lastName"
    ) {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "aadharNumber") {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    if (formData.aadharNumber.length >= 12 && name === "aadharNumber") {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "otp") {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    if (formData.otp.length >= 6 && name === "otp") {
      return;
    }

    setFormData({ ...formData, [name]: value?.toUpperCase() });
    // let res;
    // if(name === "businessType") {
    //   // alert(value)
    //   res = businessType.filter((elem) => elem.companyTypeId === 5)
    // }
    // setRenderCompanyPan(res)
  };

  const handlePanVerification = async (panBelongsTo) => {
    let payload;
    if (panBelongsTo === "company") {
      payload = {
        panNumber: formData.companyPanNumber,
        name: formData.CompanyPanCardHolderName,
        dob: formData.CompanyPanCardHolderDOB,
      };
    }
    if (panBelongsTo === "personal") {
      payload = {
        panNumber: formData.panCardNumber,
        name: formData.panCardHolderName,
        dob: formData.panCardHolderDOB,
      };
    }
    try {
      
      const response = await apiCallFnforPostForOpenUrl(
        `${SWIPELINC_API}MerchantPanel/merchant/ekyc/${
          panBelongsTo === "company"
            ? "companyPanVerification"
            : "panVerification"
        }`,
        payload
      );
      const data = response;

      if (data.message === "Verified" && data.status === "Success") {
        setPanVerify(data);
      
        if (panBelongsTo === "company") {
          setPanVerifyValueAf(true);
          // document.getElementById("companyPanName").innerText = data.data.Name;
          document.getElementById("companyPanVerifyBtn").innerText = "Verified";
          document.getElementById("companyPanVerifyBtn").disabled = "true";
        }
        setAuthorizedPersonName(formData.firstName + " " + formData.lastName);
        if (panBelongsTo === "personal") {
          setIsPersonalPanVerified(true);
          // document.getElementById("prsnlPanName").innerText = data.data.Name;
          document.getElementById("prsnlPanVerifyBtn").innerText = "Verified";
          document.getElementById("prsnlPanVerifyBtn").disabled = "true";
        }
      }
      if (data.message !== "Verified" || data.status !== "Success") {
        setPanVerify("Invalid Pan Number");
        setShowLoader(false);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handelValidations = (e) => {
    if (e.target.name === "companyPanNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (e.target.name === "panCardNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.panCardNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, panCardNumber: "" });
        });
        return;
      }
    }
  };

  const fetchRegistrationTypes = async () => {
    try {
      const response = await apiCallFnforGetOpenUrl(FETCH_REGISTRATION_TYPES);

      setRegistrationTypes(response);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };

  const fetchBusinessCategories = async () => {
    try {
      const response = await apiCallFnforGetOpenUrl(FETCH_BUSINESS_CATEGORIES);
      setBusinessCategories(response);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };
  const handleBusinessCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === "3") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
    handleChange(e);
  };
  const fetchBusinessTypes = async () => {
    try {
      const response = await apiCallFnforGetOpenUrl(FETCH_BUSINESS_TYPE);

      setBusinessTypes(response);
    } catch (error) {
      console.log("Error fetching business types:", error);
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
    fetchBusinessCategories();
    fetchRegistrationTypes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.registrationTypeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Registration Type*",
        icon: "error",
      });
      return;
    }
    if (formData.incorporationDate === "") {
      swal({
        title: "Alert!",
        text: "Please Enter incorpoartion Date.",
        icon: "error",
      });
      return;
    }
    if (formData.merchantType === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Merchant Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessType === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessCategory === "") {
      swal({
        title: "Alert!",
        text: " Please Select Your Business Category*",
        icon: "error",
      });
      return;
    }

    if (
      formData.businessCategory === "3" &&
      formData.otherBusinessCategory.trim() === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Fill Business Category*",
        icon: "error",
      });
      return;
    }

    if (formData.companyName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Fill Company Name* ",
        icon: "error",
      });
      return;
    }

    if (formData.website.trim() !== "") {
      if (!isWebsiteURLRegexIsValid(formData.website)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL*",
          icon: "error",
        });
        return;
      }
    }

    if (showComPanInput === true) {
      if (formData.companyPanNumber.trim() === "") {
        swal({
          title: "Alert!",
          text: "Please Fill Company PAN*",
          icon: "error",
        });
        return;
      }
      // if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
      //   swal({
      //     title: "Alert!",
      //     text: "Please Enter Valid PAN Number !",
      //     icon: "error",
      //   });
      //   return
      // }
    }

    if (formData.firstName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter First Name*",
        icon: "error",
      });
      return;
    }

    if (formData.lastName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Last Name*",
        icon: "error",
      });
      return;
    }

    if (formData.aadharNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Aadhar Number*",
        icon: "error",
      });
      return;
    }

    if (formData.panCardNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: " Please Enter PAN Number* ",
        icon: "error",
      });
      return;
    }
    if (showComPanInput) {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (!isPanNumRegexIsValid(formData.panCardNumber)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid PAN Number !",
        icon: "error",
      }).then(() => {
        setFormData({ ...formData, panCardNumber: "" });
      });
      return;
    }

    if (!formData.isAadharNumberVerified && sendOTPCount < 3) {
      // document.getElementById("aadharVerifyErr").classList.remove("d-none");
      swal({
        title: "Alert!",
        text: " Please Verify Your Aadhar* ",
        icon: "error",
      });
      // document.getElementById("saveDetailsBtn").disabled = true;
      return;
    }

    dispatch(updateFirstName(formData.firstName));
    dispatch(updateLastName(formData.lastName));
    dispatch(updateBusinessType(formData.businessType));
    dispatch(updateCompanyName(formData.companyName));
    dispatch(updateCompanyPanNumber(formData.companyPanNumber));
    dispatch(updateBusinessAs(formData.businessAs));
    dispatch(updateWebsite(formData.website.trim()));
    dispatch(updateBusinessCategory(formData.businessCategory));
    dispatch(updateGstNumber(formData.gstNumber));
    dispatch(updateBusinessTypeId(formData.companyTypeId));
    dispatch(updateOtherBusinessCategory(formData.otherBusinessCategory));
    dispatch(updateRegistrationTypeId(formData.registrationTypeId));
    dispatch(updateMerchantType(formData.merchantType));
    dispatch(updateAppurl(formData.appUrl));
    dispatch(updateAadharNumberAf(formData.aadharNumber));
    dispatch(updatePanCardNumberAf(formData.panCardNumber));
    dispatch(updateGstVerified(isGstVerify));
    dispatch(updateCompanyPanNumberVerified(panVerifyValueAf));
    dispatch(updatePanCardNumberVerifyAf(isPersonalPanVerified));
    dispatch(updateAadharNumberVerifyAf(formData.isAadharNumberVerified));
    dispatch(updateAuthorizedPersonNameAf(authorizedPersonName));
    dispatch(updatePanCardHolderName(formData.panCardHolderName));
    dispatch(updatePanCardHolderDOB(formData.panCardHolderDOB));
    dispatch(updateCompanyPanCardHolderName(formData.CompanyPanCardHolderName));
    dispatch(updateCompanyPanCardHolderDOB(formData.CompanyPanCardHolderDOB));
    dispatch(updateIncorporationDate(formData.incorporationDate));

    navigate("/CompanyAddForm");
    // localStorage.removeItem("isReturning")
    localStorage.setItem("isReturning", false);
  };

  const handleGstVerification = async () => {
    try {
      // setShowLoader(true);
      const response = await apiCallFnforPostForOpenUrl(GST_VERIFICATION, {
        gstNumber: formData.gstNumber,
      });

      if (response.message === "Verified" && response.statusCode === 200) {
        setisGstVerify(true);
        // setShowLoader(false);
        document.getElementById("gstHolderName").innerHTML =
          response.data.companyName;
        document.getElementById("gstVerifyBtn").disabled = true;
      }
      if (response.message !== "Verified" && response.status !== "Success") {
        // setPanVerify("Invalid Pan Number");
        swal({
          title: "Alert!",
          text: response?.data.message + " !",
          icon: "error",
        });
        // setShowLoader(false);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const sendOTP = async () => {
    if (formData.aadharNumber.length === 12) {
      setSendOTPCount(sendOTPCount + 1);
      setShowLoader(true);

      try {
        const response = await apiCallFnforPostForOpenUrl(ADHAR_VERIFICATION, {
          aadhaarNumber: formData.aadharNumber,
        });
        if (
          response.statusCode === 200 &&
          response.message === "Otp Send successfully"
        ) {
          setOtpTimer(45);
          setIsOtpTimerStart(true);
          setShowLoader(false);
          document.getElementById("aadharCustomerName").innerText =
            "OTP Sent Successfully !!";
          setShowIncorrectOTP(false);
          setAdharVerifyOtp(response.data);

          setadharVerifyValue(true);
          setShowOtpInput(true);
          document.getElementById("adharSendOTPBtn").disabled = true;
          setIsOtpTimerStart(true);

          setTimeout(() => {
            document.getElementById("adharSendOTPBtn").disabled = false;
          }, otpTimer * 1000);
          setOtpResendAttempts((prevAttempts) => prevAttempts + 1);
          if (otpResendAttempts >= 2) {
            setShowSkipButton(true);
          }
          if (otpResendAttempts === 0) {
            setShowResendOTP(true);
          }
        } else {
          setShowLoader(false);
          swal({
            title: "Alert!",
            text: response.message + " !",
            icon: "error",
          });
        }
      } catch (error) {}
    } else {
      setShowIncorrectOTP(true);
    }
  };

  const handleOTPVerification = async () => {
    if (formData.otp.length === 6) {
      try {
        setShowLoader(true);
        const response = await apiCallFnforPostForOpenUrl(
          ADHAR_OTP_VERIFICATION,
          {
            otp: formData.otp,
            refNumber: adharVerifyOtp,
          }
        );
        if (
          response.message === "Aadhaar verfied" &&
          response.status === "Success"
        ) {
          setShowLoader(false);
          setAdharVerify(response.data);
          setIsAadharVerified(true);
          setFormData({ ...formData, isAadharNumberVerified: true });
          document.getElementById("aadharCustomerName").innerHTML =
            response.data.Name;
          setShowOtpInput(false);
          document.getElementById("adharOTPVerifyBtn").disabled = true;
          document.getElementById("adharSendOTPBtn").disabled = true;
        }

        if (response.message === "invalid otp" && response.statusCode === 307) {
          swal({
            title: "Alert!",
            text: "Invalid Aadhar OTP !",
            icon: "error",
          });
          setShowLoader(false);
        }

        if (otpResendAttempts >= 2) {
          setAdharVerify(false);
          return;
        }
        if (
          response.message !== "Aadhaar verfied" ||
          response.status !== "Success"
        ) {
          setAdharVerify("Otp is Invalid");
          setShowLoader(false);
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  };
  return (
    <>
      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">COMPANY DETAILS</h4>
              </div>
              <div className="card-body py-4">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <form
                      onSubmit={handleSubmit}
                      className="form-section-form-group"
                    >
                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Merchant Type
                              <span className="required">*</span>
                            </label>

                            <select
                              id="merchantForm"
                              name="merchantType"
                              value={formData.merchantType}
                              onChange={handleChange}
                              className="form-control"
                            >
                              <option value="">Select a Merchnant type*</option>
                              <option value="ONLINE">Online</option>
                              <option value="OFFLINE">Offline</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Registration Type
                              <span className="required">*</span>
                            </label>

                            <select
                              id="merchantForm"
                              name="registrationTypeId"
                              value={formData.registrationTypeId}
                              onChange={handleChange}
                              className="form-control"
                            >
                              <option value="">
                                Select a Registration type*
         
                              </option>
                              {registrationType?.map((type) => (
                                <option
                                  key={type.registrationTypeId}
                                  value={type.registrationTypeId}
                                >
                                  {type.registrationType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Business Type<span className="required">*</span>
                            </label>
                            <select
                              // id="businessType"
                              id="merchantForm"
                              name="businessType"
                              value={formData.businessType}
                              onChange={(e) => {
                                handleChange(e);
                                setShowComPanInput(
                                  e.target.value === "6" ? false : true
                                );
                              }}
                              className="form-control"
                            >
                              <option value="">
                                Select a business type*
                                
                              </option>
                              {businessType?.map((type) => (
                                <option
                                  key={type.companyTypeId}
                                  value={type.companyTypeId}
                                >
                                  {type.companyType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Business Category
                              <span className="required">*</span>
                            </label>

                            <select
                              // id="businessCategory"
                              id="merchantForm"
                              name="businessCategory"
                              value={formData.businessCategory}
                              onChange={(e) => {
                                handleBusinessCategoryChange(e);
                              }}
                              className="form-control"
                            >
                              <option value="">
                                Select business categories*
                              </option>
                              {businessCategories?.map((category) => (
                                <option
                                  key={category.businessTypeId}
                                  value={category.businessTypeId}
                                >
                                  {category.businessType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Incorporation Date
                              <span className="required">*</span>
                            </label>
                            <input
                                type="text"
                                // id="businessCategory"
                                id="merchantForm"
                                name="incorporationDate"
                                value={formData.incorporationDate}
                                onChange={handleChange}
                                placeholder="DD/MM/YYYY"
                                className="form-control"
                              />
                          </div>
                        </div>
                        {showOtherInput && (
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark">
                                Other Business Category
                                <span className="required">*</span>
                              </label>

                              <input
                                type="text"
                                // id="businessCategory"
                                id="merchantForm"
                                name="otherBusinessCategory"
                                value={formData.otherBusinessCategory}
                                onChange={handleChange}
                                className="form-control"
                              />

                              <span
                                id="otherBusinessErr"
                                className="text-primary "
                              >
                                Please Fill Business Category*
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Company Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="merchantForm"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                            />
                            <span id="" className=" text-primary ">
                              *Note- The Company name must be similar to the
                              Bank Account Name
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              GST Number<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              id="merchantForm2"
                              name="gstNumber"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              value={formData.gstNumber}
                              onChange={handleChange}
                              className="form-control"
                            />

                            <span
                              id="gstHolderName"
                              className="text-primary"
                            ></span>
                            <div className="d-flex justify-content-end py-2">
                              <button
                                id="gstVerifyBtn"
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => handleGstVerification()}
                              >
                                Verify GST
                              </button>
                            </div>
                          </div>
                        </div>

                        {!showComPanInput ? null : (
                          <>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label
                                  htmlFor="CompanyPanCardHolderName"
                                  className="mb-1 text-dark"
                                >
                                  Name on Company Pan
                                  <sup className="text-danger">*</sup>
                                </label>

                                <input
                                  type="text"
                                  id="CompanyPanCardHolderName"
                                  name="CompanyPanCardHolderName"
                                  onChange={handleChange}
                                  className="form-control"
                                  value={formData.CompanyPanCardHolderName}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label
                                  htmlFor="mb-2 text-dark"
                                  className="form-label"
                                >
                                  DOB on Company Pan
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="text"
                                    id="CompanyPanCardHolderDOB"
                                    name="CompanyPanCardHolderDOB"
                                    onChange={handleChange}
                                    className="form-control"
                                    value={formData.CompanyPanCardHolderDOB}
                                    placeholder="DD/MM/YYYY"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="mb-1 text-dark">
                                  Company PAN Number
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="merchantForm2"
                                  name="companyPanNumber"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formData.companyPanNumber}
                                  onChange={handleChange}
                                  onBlur={handelValidations}
                                  className="form-control"
                                />
                                <span
                                  id="companyPanName"
                                  className="text-primary"
                                ></span>
                                <div className="d-flex justify-content-end py-2">
                                  <button
                                    id="companyPanVerifyBtn"
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      handlePanVerification("company")
                                    }
                                  >
                                    Verify PAN
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Business As / Doing Business as :
                            </label>
                            <input
                              type="text"
                              id="merchantForm2"
                              name="businessAs"
                              value={formData.businessAs}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">Website</label>
                            <input
                              type="text"
                              id="merchantForm2"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="website"
                              value={formData.website}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">App URL</label>
                            <input
                              type="text"
                              id="merchantForm2"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="appUrl"
                              value={formData.appUrl}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="card-header">
                          <h4 className="card-title">
                            AUTHORIZED PERSON DETAILS
                          </h4>
                        </div>
                        <div className="col-lg-6 mb-2 my-4">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              First Name (As per Aadhaar)
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              id="merchantForm"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2 my-4">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Last Name (As per Aadhaar)
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              id="merchantForm"
                              name="lastName"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              value={formData.lastName}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Aadhaar Number<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              // id={aadharForm}
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="aadharNumber"
                              value={formData.aadharNumber}
                              onChange={handleChange}
                              className="form-control"
                            />
                            <div className="d-flex justify-content-end py-2">
                              <button
                                id="adharSendOTPBtn"
                                type="button"
                                className="btn btn-primary btn-sm"
                                // onClick={sendOTP}
                                onClick={
                                  sendOTPCount >= 3
                                    ? handleSkipAadharVerification
                                    : sendOTP
                                }
                              >
                                {sendOTPCount >= 3
                                  ? "Skip"
                                  : showResendOTP
                                  ? "Resend OTP"
                                  : "Send OTP"}
                              </button>
                            </div>

                            <div className="d-flex justify-content-between">
                              <span
                                id="aadharCustomerName"
                                className="text-primary"
                              ></span>
                              <span id="countdowntimer" className="text-danger">
                                {isOtpTimerStart && otpTimer != 0
                                  ? `Resend OTP in ${otpTimer} seconds`
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                        {showOtpInput && (
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="mb-1 text-dark">
                                OTP<span className="required">*</span>
                              </label>
                              <input
                                type="number"
                                // id="otp"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                // id={aadharForm3}
                                name="otp"
                                value={formData.otp}
                                onChange={handleChange}
                                className="form-control"
                              />
                              {showOtpInput && (
                                <div className="d-flex justify-content-end py-2">
                                  <button
                                    id="adharOTPVerifyBtn"
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleOTPVerification()}
                                  >
                                    Verify OTP
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label
                              htmlFor="panCardHolderName"
                              className="mb-1 text-dark"
                            >
                              PAN Card Holders Name
                              <sup className="text-danger">*</sup>
                            </label>

                            <input
                              type="text"
                              id="panCardHolderName"
                              name="panCardHolderName"
                              onChange={handleChange}
                              className="form-control"
                              value={formData.panCardHolderName}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label
                              htmlFor="panCardHolderDOB"
                              className="mb-2 text-dark"
                            >
                              PAN Holders DOB
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="text"
                                id="panCardHolderDOB"
                                name="panCardHolderDOB"
                                onChange={handleChange}
                                className="form-control"
                                value={formData.panCardHolderDOB}
                                placeholder="DD/MM/YYYY"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              PAN Card Number (Authorized Person)
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              // id={styles.aadharForm3}
                              name="panCardNumber"
                              onChange={handleChange}
                              onBlur={handelValidations}
                              className="form-control"
                              value={formData.panCardNumber}
                            />
                            <div className="d-flex justify-content-end py-2">
                              <button
                                id="prsnlPanVerifyBtn"
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  handlePanVerification("personal")
                                }
                              >
                                Verify PAN
                              </button>
                            </div>
                            <span
                              id="prsnlPanName"
                              className="text-primary"
                            ></span>
                            {panVerify &&
                              panVerify.message === "Verified" &&
                              panVerify.status === "Success" &&
                              showPopupPan && (
                                <div className="popup_container">
                                  <div className="popup">
                                    <h3>Success</h3>
                                    <p>PAN Number verified successfully.</p>
                                    <p>
                                      PAN number associated with :
                                      <span className="gst_name">
                                        {panVerify.data.Name}
                                      </span>
                                    </p>
                                    <button
                                      className="close_button"
                                      onClick={() => setShowPopupPan(false)}
                                    >
                                      Okay{" "}
                                    </button>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <Col xs={12} lg={12} md={12}>
                        <div className="form-group d-flex justify-content-end gap-3">
                          <div className="login-btn-sec toolbar-bt text-end">
                            <button
                              id="saveDetailsBtn"
                              className="btn btn-primary btn-sm"
                              type="submit"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </Col>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </>
  );
}
