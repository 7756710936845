import React, { useEffect, useState } from "react";
import { keywords } from "../../Utils/ContantKeys";
import { useDispatch } from "react-redux";
import { setTransactionStatusObject } from "../../store/Slices/reportFilterSlice";
import CustomTip from "../../Common/CustomToolTip/CustomTip";
import styles from "./Filter.module.css"



/**
 * This Component renders 
 * @param {*} param0 
 * @returns 
 */
const Statusfilter = ({ type, callDispatch }) => {
  const [transectionStatus, setTransectionStatus] = useState("");

  const dispatch = useDispatch();

  const handleStatusSelect = (value) => {
    
    setTransectionStatus(value);
  };

  // const values = {
  //   Payin: ["success", "pending", "failure", "blacklisted", "warning", ""],
  //   Payout: ["success", "pending", "failure", ""],
  //   Withdraw: ["success", "pending", "failure", ""],
  // };
 

  useEffect(() => {
    dispatch(setTransactionStatusObject(transectionStatus));
  }, [callDispatch]);

  return (
    <fieldset className="border-2 rounded-0 mt-2 mb-2">
      <legend className="text-start text-black">
        Transaction Status
      </legend>
      <div className="">
        <div className="row">
          <div className="col">
            <div className="form-group d-flex gap-1">
              {/* <select
                className="form-control fs-4"
                value={transectionStatus}
                onChange={(e) => setTransectionStatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="pending">Pending</option>
                <option value="success">Success</option>
                <option value="failure">Failure</option>
              </select> */}

              <CustomTip title={"Success"}>
                <div
                   
                  className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-success ${styles["statusCircle"]} ${
                    transectionStatus === "success" ? "border-primary" : ""
                  }`}
                  data-value="success"
                  onClick={(e) =>
                    handleStatusSelect(e.target.getAttribute("data-value"))
                  }
                > 
                  S
                </div>
              </CustomTip>

              <CustomTip title={"Pending"}>
                <div
                 
                  className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-warning ${styles["statusCircle"]}  ${
                    transectionStatus === "pending" ? "border-primary" : ""
                  }`}
                  data-value="pending"
                  onClick={(e) =>
                    handleStatusSelect(e.target.getAttribute("data-value"))
                  }
                >
                  P
                </div>
              </CustomTip>

              <CustomTip title={"Failure"}>
                <div
              
                  className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-danger ${styles["statusCircle"]} ${
                    transectionStatus === "failure" ? "border-primary" : ""
                  }`}
                  data-value="failure"
                  onClick={(e) =>
                    handleStatusSelect(e.target.getAttribute("data-value"))
                  }
                >
                  F
                </div>
              </CustomTip>

              {/* {values[type].includes("blacklisted") ? ( */}
                <CustomTip title={"Blacklisted"}>
                  <div
                    
                    className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-secondary ${styles["statusCircle"]} ${
                      transectionStatus === "blacklisted"
                        ? "border-primary"
                        : ""
                    }`}
                    data-value="blacklisted"
                    onClick={(e) =>
                      handleStatusSelect(e.target.getAttribute("data-value"))
                    }
                  >
                    B
                  </div>
                </CustomTip>
              {/* ) : null} */}

              {/* {values[type].includes("warning") ? ( */}
                <CustomTip title={"Warning"}>
                  <div
                    
                    className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-info ${styles["statusCircle"]} ${
                      transectionStatus === "warning" ? "border-primary" : ""
                    }`}
                    data-value="warning"
                    onClick={(e) =>
                      handleStatusSelect(e.target.getAttribute("data-value"))
                    }
                  >
                    W
                  </div>
                </CustomTip>
              {/* ) : null} */}

              <CustomTip title={"All"}>
                <div
       
                  className={`d-flex justify-content-center align-items-center fs-4 border border-5 bg-light ${styles["statusCircle"]} ${
                    transectionStatus === "" ? "border-primary" : ""
                  }`}
                  data-value=""
                  onClick={(e) =>
                    handleStatusSelect(e.target.getAttribute("data-value"))
                  }
                >
                  All
                </div>
              </CustomTip>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Statusfilter;
