import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { SAVE_PAYOUT_REQUEST } from "../../Utils/Constants";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import swal from "sweetalert";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import useApiServices from "../../Utils/useApiService";
import Filter from "./Filter";
import NumberInWords from "../../Common/NumberInWords/NumberInWords";
import { useSelector } from "react-redux";

const TablePopUp = ({ getAgain, setGetAgain }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [amount, setAmount] = useState(0);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const { apiCallFnforPost } = useApiServices();

  const { mid } = useSelector((state) => state.userData);

  const handleInput = (e) => {
    if (e.target.value > 0) {
      setAmount(parseInt(e.target.value));
    }
  };

  const columnForRequestPayout = [
    {
      headerName: "Vendor Name",
      field: "fullName",
      minWidth: 150,
    },
    {
      headerName: "Type",
      field: "payoutFor",
      minWidth: 150,
    },
    {
      headerName: "VPA / UPI ID",
      field: "userVpa",
      minWidth: 150,
    },
    {
      headerName: "Account number",
      field: "accountNumber",
      minWidth: 150,
    },
    {
      headerName: "IFSC Code",
      field: "ifscCode",
      minWidth: 150,
    },
    {
      headerName: "Amount In Rupees",
      field: "amount",
      minWidth: 150,
      renderCell: () => (
        <div className="px-2 d-flex flex-column align-items-start">
          <input
            className="px-2"
            type="text"
            value={amount}
            onChange={handleInput}
          />
          <span className="mx-2">
            <NumberInWords number={amount} />
          </span>
        </div>
      ),
    },
  ];

  const Payload = apiResponse.map((entry) => {
    return {
      accountNumber: entry.accountNumber || "",
      amount: amount,
      bankName: entry.bankName || "",
      branchName: entry.branchName || "",
      category: entry.category || "",
      email: entry.email || "",
      fullName: entry.fullName || "",
      ifscCode: entry.ifscCode || "",
      mid: mid,
      mobile: entry.mobileNo || "",
      payoutFor: entry.payoutFor || "",
      remark: entry.remark || "",
      upi: entry.userVpa || "",
    };
  });
  const payload = Payload[0];

  const handleclose = (id) => {
    document.getElementById(id).click();
  };

  const handleSubmitEntry = async () => {
    if (isSubmiting) {
      return; // Disable the submit button
    }

    if (!payload.amount) {
      swal({
        title: "Warning",
        text: "Payout amount should be more than zero.",
        icon: "warning",
      })
      return; // Disable the submit button
    }


    setIsSubmitting(true);
    try {
      var button = document.getElementById("closeBtnPR");
      const response = await apiCallFnforPost(SAVE_PAYOUT_REQUEST, payload);
      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Your Payout request has been submitted successfully!!!",
          icon: "success",
        });
        setApiResponse([]);
        setAmount(0);
        setGetAgain(!getAgain);

        let subbtn = document.getElementById("subbtn");
        if (subbtn) {
          subbtn.setAttribute("data-bs-dismiss", "modal");
          subbtn.click();
        } else {
          console.error("Submit button not found");
        }
      } else if (response.statusCode === 103) {
        swal({
          title: "Failed",
          text: "Your maximum payout limit has been exceeded.",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log("error", error);
      swal({
        title: "Error",
        text: "Something went wrong. Please try again later.",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex flex-row">
          <Col xs={12} lg={12} md={12}>
            {!apiResponse?.length ? (
              <Filter
                handleclose={handleclose}
                setApiResponse={setApiResponse}
              />
            ) : (
              <DataGrid
                rows={apiResponse}
                columns={columnForRequestPayout}
                slots={{
                  toolbar: GridToolbar,
                }}
              />
            )}
          </Col>
        </div>
        {apiResponse?.length > 0 && (
          <div className="mt-2 d-flex justify-content-end gap-1">
            <div
              className="btn btn-sm btn-outline-danger"
              onClick={() => setApiResponse([])}
            >
              Close
            </div>
            <div
              id="subbtn"
              className="btn btn-sm btn-primary"
              // data-bs-dismiss="modal"
              onClick={handleSubmitEntry}
            >
              Submit
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TablePopUp;
