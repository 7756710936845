import React from 'react'
import NoDataFoundImg from "../../Assets/images/NoDataFound.png";

const NoDataFound = () => {
  return (
    
    <img src={NoDataFoundImg} height="559"  />
  )
}

export default NoDataFound
