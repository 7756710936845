import React, { useEffect, useState } from "react";
import {
  BALANCE_FETCH,
  PENDING_WITHDRAW_REQUEST_FETCH,
  TRANSFER_AMOUNT,
} from "../../Utils/Constants";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../Utils/useApiService";
import Modal from "../../Common/Modal/Modal";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import NumberInWords from "../../Common/NumberInWords/NumberInWords";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";
import { jwtDecode } from "jwt-decode";
import { convert_number } from "../../Utils/NumberInWords/NumberInWords";
import { useSelector } from "react-redux";

/** 
 * @returns {ReactNode} - This component renders data of withdraws in payout.
*/


const WithdrawInPayout = () => {
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [apiResponse, setApiResponse] = useState([]);
  const [balance, setBalance] = useState();
  const [flag, setFlag] = useState(false);
  const [payload, setPayload] = useState({
    amount: "",
    mid: "",
    transferType: "account",
    virtualAccountType: "",
  });

  const { mid } = useSelector((state) => state.userData);

  const [radioOption, setRadioOption] = useState("");

  const isWithdrawalPayoutActive = localStorage.getItem(
    "isWithdrawalPayoutActive"
  );
  const isWithdrawalAcountActive = localStorage.getItem(
    "isWithdrawalAcountActive"
  );

  const handleChange = (event) => {
    setRadioOption(event);
  };

  const fetchDataForWithdraw = async (mid) => {
    try {
      const response = await apiCallFnforGet(
        `${PENDING_WITHDRAW_REQUEST_FETCH}${mid}`
      );
      const finalres = response.data.map((item, i) => ({
        id: i,
        amount: item.amount || "",
        gstCharge: item.gstCharge,
        serviceCharge: item.serviceCharge ,
        amountInWords: convert_number(parseInt(item.amount)),
        transferType: item.transferType?.toUpperCase() || "",
        virtualAccountType: item.virtualAccountType || "",
        status: item.status?.toUpperCase() || "",
        createTime: item.createTime || "",
        createDate: item.createDate || "",
      }));
      setApiResponse(finalres);
    } catch (error) {
      setApiResponse([]);
    }
  };

  const fetchBalance = async (mid) => {
    try {
      setPayload((prevState) => ({ ...prevState, mid }));
      const response = await apiCallFnforGet(`${BALANCE_FETCH}${mid}`);
      setBalance(response?.data);
    } catch (error) {
      setBalance();
    }
  };

  useEffect(() => {
    
    fetchDataForWithdraw(mid);
    fetchBalance(mid);
  }, [flag]);

  const handleSubmit = async (event) => {
    if (!payload?.amount) {
      return swal("Error!", "Enter amount please", "error");
    }
    
    if (event === "To Payout" && radioOption === "") {
      swal("Alert!", "Please Select Atleast One Payout Option.", "warning");
      return;
    }

    const obj = { ...payload };

    if (event === "To Account") {
      obj.transferType = "account";
      obj.virtualAccountType = "";
    } else {
      obj.transferType = "payout";
      obj.virtualAccountType = radioOption;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to submit this withdrawal request?",
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        await saveChanges(obj);
      } else {
        setPayload((prevState) => ({ ...prevState, amount: "" }));
        swal("Submission canceled!");
      }
    });
  };

  const saveChanges = async (obj) => {
    setPayload((prevState) => ({ ...prevState, amount: "" }));
    try {
      const response = await apiCallFnforPost(TRANSFER_AMOUNT, obj);
      if (response.statusCode === 200) {
        swal(
          "Success!",
          "Your Withdrawal request has been submitted successfully",
          "success"
        );
        setFlag(!flag);
      } else if (response.statusCode === 309) {
        swal("Alert!", response.message, "warning");
      } else {
        swal("Alert!", response.message, "error");
      }
    } catch (error) {
      swal("Error!", "Something went wrong", "error");
    }
  };

  const columns = [
    { headerName: "Amount", field: "amount", minWidth: 170 },
    { headerName: "Amount In Words", field: "amountInWords", minWidth: 170 },
    {
      headerName: "Actual Amount",
      field: "requestedAmount",
      minWidth: 150,
    },
    {
      headerName: "Service Charge",
      field: "serviceCharge",
      minWidth: 150,
    },
    {
      headerName: "GST on Service",
      field: "gstCharge",
      minWidth: 150,
    },
    { headerName: "Transfer Type", field: "transferType", minWidth: 170 },
    { headerName: "Transfer Mode", field: "virtualAccountType", minWidth: 170 },
    { headerName: "Transaction Status", field: "status", minWidth: 170 },
    { headerName: "Created Date", field: "createDate", minWidth: 170 },
    { headerName: "Created Time", field: "createTime", minWidth: 170 },
  ];

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value > 0 ? value : 0,
    }));
  };

  const {
    hasPrivilagesWPayout,
    hasPrivilagesPendingWV,
    hasPrivilagesWAccount,
  } = CheckExportConst();

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h4 className="mb-3 mb-md-0">Pending Withdraws</h4>
          <div className="m-2">
            <h4>Available Wallet Balance: {balance?.wallet}</h4>
            <span className="text-muted">
              <NumberInWords number={balance?.wallet} />
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            {hasPrivilagesWAccount && isWithdrawalAcountActive === "true" && (
              <div data-bs-toggle="modal" data-bs-target="#AccountModal">
                <button className="btn btn-sm btn-primary">
                  <i className="fa fa-plus mx-1"></i> Account
                </button>
              </div>
            )}
            {hasPrivilagesWPayout && isWithdrawalPayoutActive === "true" && (
              <div data-bs-toggle="modal" data-bs-target="#PayoutModal">
                <button className="btn btn-sm btn-primary">
                  <i className="fa fa-plus mx-1"></i> Payout
                </button>
              </div>
            )}
            <Link className="text-black" to="/Reports/5">
              <IconWrapper title="Go to Withdraw Report">
              <i class="fa-solid fa-location-arrow text-dark"></i>
              </IconWrapper>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col card-body">
            {hasPrivilagesPendingWV && (
              <Box sx={{ height: 400, width: 1 }}>
                <DataGrid
                  rows={apiResponse}
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                  sx={{
                    "& .css-1rtad1": {
                      display: "block",
                    },
                  }}
                />
              </Box>
            )}

            <Modal
              id="AccountModal"
              title="To Account"
              onSubmit={handleSubmit}
              footer
            >
              <form>
                <div className="mb-3 d-flex flex-column align-items-start">
                  <input
                    type="number"
                    className="form-control input-default"
                    name="amount"
                    placeholder="Enter amount"
                    value={payload.amount}
                    required
                    onChange={handleInput}
                  />
                  <span className="mx-3">
                    <NumberInWords number={payload.amount} />
                  </span>
                </div>
              </form>
            </Modal>

            <Modal
              id="PayoutModal"
              title="To Payout"
              onSubmit={handleSubmit}
              footer
            >
              <>
                <div className="d-flex gap-5 mx-4">
                  <div className="mb-3 d-flex gap-2">
                    <input
                      type="radio"
                      name="transactionType"
                      value="IMPS"
                      checked={radioOption === "IMPS"}
                      onChange={() => handleChange("IMPS")}
                    />
                    <span
                      className={`${radioOption === "IMPS" ? "fw-bold" : ""}`}
                    >
                      To IMPS
                    </span>
                  </div>
                  <div className="mb-3 d-flex gap-2">
                    <input
                      type="radio"
                      name="transactionType"
                      value="UPI"
                      checked={radioOption === "UPI"}
                      onChange={() => handleChange("UPI")}
                    />
                    <span
                      className={`${radioOption === "UPI" ? "fw-bold" : ""}`}
                    >
                      To UPI
                    </span>
                  </div>
                </div>
                <div className="card-body d-flex flex-column justify-content-start align-items-start">
                  <input
                    type="number"
                    className="form-control input-default"
                    name="amount"
                    placeholder="Enter amount"
                    value={payload.amount}
                    onChange={handleInput}
                  />
                  <span className="mx-3">
                    <NumberInWords number={payload.amount} />
                  </span>
                </div>
              </>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawInPayout;
