import React, { useState } from "react";

/**
 * This component is resuable CopyButton component it copies the given text..
 *
 * @param {string} text .
 * @returns {ReactNode} A React component that renders CopyButton.
 */
const CopyButton = ({ text }) => {
  const [status, setStatus] = useState(false);

  const copyUrlToClipboard = () => {
    var textarea = document.createElement("textarea");

    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setStatus(true);
    setTimeout(() => {
      setStatus(false);
    }, 2000);
  };
  return (
    <div onClick={copyUrlToClipboard}>
      {status ? (
        <i class="fa-solid fa-circle-check text-primary"></i>
      ) : (
        <i class="fa-regular fa-copy"></i>
      )}
    </div>
  );
};

export default CopyButton;
