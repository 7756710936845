import React, { useEffect, useState } from "react";
import { setFilterOn } from "../../store/Slices/reportFilterSlice";
import { useDispatch, useSelector } from "react-redux";


/**
 * This component is resuable button component..
 *
 * @param {string} setCondition.
 * @param {string} type.
 * @param {string} isOpen.
 * @param {string} setPage.
 * @param {string} setSize.
 * @param {string} condition.
 * @returns {ReactNode} A React component that renders a reusable DropDown.
 */

export default function Dropdown({
  setCondition,
  type,
  isOpen,
  setPage,
  setSize,
  condition,
}) {
  const [value, setValue] = useState("this year");
  const dispatch = useDispatch();
  const { filterOn } = useSelector((state) => state.reportFilter);
  const options = [
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "Today",
      value: "1 day",
    },
    {
      label: "Last Week",
      value: "last week",
    },
    {
      label: "This Week",
      value: "this week",
    },
    {
      label: "Last Month",
      value: "last month",
    },
    {
      label: "This Month",
      value: "this month",
    },
    {
      label: "Last Quarter",
      value: "last quarter",
    },
    {
      label: "This Quarter",
      value: "this quarter",
    },
    {
      label: "This Year",
      value: "this year",
    },
  ];

  useEffect(() => {
    setCondition(condition);
    setValue(condition);
  }, [condition, type]);


  function handleSelect(event) {
    
    const selectedValue = event.target.getAttribute("data-value");
    if (type !== "DashBoard") {
      dispatch(setFilterOn(false));
      setPage(1);
      setSize(100);
    }
    setValue(selectedValue);
    setCondition(selectedValue);
  }

  return (
    <div className="d-flex justify-content-evenly gap-2">
      {!isOpen && (
        <div
          className={`badge fs-10 badge-primary cursor-pointer`}
          onClick={handleSelect}
        >
          {filterOn
            ? "Filter On"
            : options.find((option) => option?.value === value)?.label}
        </div>
      )}

      {isOpen &&
        options.map((option, i) => (
          <div
            className={`badge fs-10 cursor-pointer ${
              option.value === value ? "badge-primary" : "badge-secondary"
            }`}
            key={i}
            data-value={option?.value}
            onClick={handleSelect}
          >
            {option?.label}
          </div>
        ))}
    </div>
  );
}
