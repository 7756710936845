import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD_AFTER_LOGIN } from "../Utils/Constants";
import { jwtDecode } from "jwt-decode";
 
import styles from "./FirstStage.module.css";
import swal from "sweetalert";
import LeftPagePassword from "./LeftPagePassword";
import useApiServices from "../Utils/useApiService";
import { useSelector } from "react-redux";

/**
 * PasswordChangeForm component renders  password change form.
 * @description takes privious password and new password then preapare payload and calls the api for changing password.
 * @returns {ReactNode} A React element that renders password change form.
 */
export default function PasswordChangeForm() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const {   apiCallFnforPutForOpenUrl } =
  useApiServices();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") setCurrentPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };
  const togglePasswordVisibility = (inputName) => {
    if (inputName === "currentPassword") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (inputName === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (inputName === "confirmNewPassword") {
      setShowConfirmNewPassword(!showConfirmNewPassword);
    }
  };
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.[A-Z])(?=.[0-9])(?=.[!@#$%^&])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };
  const { mid } = useSelector((state) => state.userData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!validatePassword(newPassword)) {
    //   setError(
    //     "New password must contain at least 8 characters, 1 capital letter, 1 special character, and 1 numeric value."
    //   );
    //   setSuccess("");
    //   return;
    // }

    if (newPassword !== confirmNewPassword) {
      setError("New password and confirm new password do not match.");
      setSuccess("");
      return;
    }
    try {
      const payload = {
        currentPassword: currentPassword,
        mid: mid,
        newPassword: newPassword,
      };

      // Make the PUT request to the API endpoint
      const response = await apiCallFnforPutForOpenUrl(
        CHANGE_PASSWORD_AFTER_LOGIN,
        payload
      );

      if (response.status === "Success") {
        swal({
          title: "Success!",
          text: "Password Changed Successfully",
          icon: "success",
        });
        navigate("/");
      } else {
        setError(response.message);
        setSuccess("");
      }
    } catch (error) {
      setError("An error occurred while changing the password.");
      setSuccess("");
    }
  };

  return (
    <>
      <div className="authincation h-100">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
              <div className="login-form">
                <div className="text-center">
                  <h3 className="title">Change Password</h3>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">Current Password</label>
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      id="currentPassword"
                      className="form-control"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={handleChange}
                      required
                    />
                    <span className="eye">
                      <i
                        id="password_show_icon"
                        className={`${
                          showCurrentPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                        onClick={() =>
                          togglePasswordVisibility("currentPassword")
                        }
                      ></i>
                    </span>
                  </div>

                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">New Password</label>
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      className="form-control"
                      name="newPassword"
                      value={newPassword}
                      onChange={handleChange}
                      required
                    />{" "}
                    <span className="eye">
                      <i
                        className={`${
                          showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                        onClick={() => togglePasswordVisibility("newPassword")}
                      ></i>
                    </span>
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">
                      Confirm New Password
                    </label>

                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="confirmNewPassword"
                      className="form-control"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={handleChange}
                      required
                    />
                    <span className="eye">
                      <i
                        className={`${
                          showConfirmNewPassword
                            ? "fa fa-eye"
                            : "fa fa-eye-slash"
                        }`}
                        onClick={() =>
                          togglePasswordVisibility("confirmNewPassword")
                        }
                      ></i>
                    </span>
                  </div>
                  {error && <div className={styles.error}>{error}</div>}

                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <LeftPagePassword />
          </div>
        </div>
      </div>
    </>
  );
}
