import React, { useEffect, useRef, useState } from "react";
import DateFilter from "../Filter/DateFilter";

import Parameter from "../Filter/Parameter";
import Statusfilter from "../Filter/Statusfilter";
import TransferTP from "../Filter/TransferTP";
import OtherDateFilter from "../Filter/OtherDateFilter";
import { useDispatch, useSelector } from "react-redux";

import {
  CHARGEBACK_MULTIPLE_FILTER_API,
 
  COMMON_ADVANCE_FILTER_API,
  COMMON_MULTIPLE_FILTER_API,
  GET_REPORT_BY_CONDITION_API,
  PAYIN_MULTIPLE_FILTER_API,
  PAYOUT_MULTIPLE_FILTER_API,
  RECONCILIATION_MULTIPLE_FILTER_API,
  SETTLEMENT_MULTIPLE_FILTER_API,
  WITHDRAW_MULTIPLE_FILTER_API,
} from "../../Utils/Constants";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";
import { jwtDecode } from "jwt-decode";
import { setFilterOn } from "../../store/Slices/reportFilterSlice";
import useApiServices from "../../Utils/useApiService";
import { Modal } from "react-bootstrap";
import useFilterServices from "../../Utils/useFilterServices";
import swal from "sweetalert";

/**
 * This component is used to select multiple options on MoreFilters Modal.
 * @param {string} page - page number for pagination on reports.
 * @param {string} size - size to show number of rows in one page.
 * @param {string} type - report type.
 * @param {function} setData - useState function to update Report Data in DataGrid.
 * @param {function} setTransactionDetails - useState function to update transaction details.
 * @param {function} setErrorCodeList - useState function to update error code list in reports section.
 * @param {function} showFilter - useState function to show Filter modal which takes boolean value.
 * @param {function} handleClose - useState function to close Filter modal which takes boolean value.
 */

const Morefilter = ({
  page,
  size,
  type,
  setData,
  setTransactionDetails,
  setErrorCodeList,
  showFilter,
  active,
  handleClose,
}) => {
  const { mid } = useSelector((state) => state.userData);

  const [startAmt, setStartAmt] = useState(0);
  const [endAmt, setEndAmt] = useState(0);

  const {
    USE_CHARGEBACK_MULTIPLE_FILTER_API,
    USE_MULTIPLE_FILTER_BOX_API,
    USE_PAYIN_ADVANCE_FILTER_API,
    USE_PAYIN_MULTIPLE_FILTER_API,
    USE_PAYOUT_MULTIPLE_FILTER_API,
    USE_RECONCILIATION_MULTIPLE_FILTER_API,
    USE_GURANTEE_MULTIPLE_FILTER_API,
    USE_SETTLEMENT_MULTIPLE_FILTER_API,
    USE_WITHDRAW_MULTIPLE_FILTER_API,
    USE_COMMON_MULTIPLE_FILTER_API,
    USE_COMMON_ADVANCE_FILTER_API
  } = useFilterServices();

  const [flag, setFlag] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const isFirstRender = useRef(true);
  const [toggle, setToggle] = useState(true);
  const [optionValue, setOptionValue] = useState();
  const [parameterValuead, setParameterValueAd] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setParameterValueAd("");
    setOptionValue("");
  }, [handleClose]);

  const reduxData = useSelector((state) => state.reportFilter);
  const {
    dateoption,
    otherDateoption,
    date,
    startDate,
    endDate,
    otherdate,
    otherStartDate,
    otherEndDate,
    parameterType,
    parameterValue,
    transectionStatus,
    transferType,
    startAmount,
    endAmount,
    filterOn,
  } = reduxData;

  useEffect(() => {
    if (!filterOn) {
      return;
    }
    if (
      dateoption === "" &&
      otherDateoption === "" &&
      date === "" &&
      startDate === "" &&
      endDate === "" &&
      otherdate === "" &&
      otherStartDate === "" &&
      otherEndDate === "" &&
      parameterType === "" &&
      parameterValue === "" &&
      transectionStatus === "" &&
      transferType === "" &&
      startAmount === "" &&
      endAmount === ""
    ) {
      return;
    }

    if (!reduxData) {
      return;
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }

    if (type === "Settlement") {
      USE_SETTLEMENT_MULTIPLE_FILTER_API(
        SETTLEMENT_MULTIPLE_FILTER_API,
        "",
        "",
        parameterType.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType.toUpperCase() === "AMOUNT RANGE" ? startAmount : "",
        parameterType.toUpperCase() === "AMOUNT RANGE" ? endAmount : "",
        // "",
        dateoption.toUpperCase() === "DATE"
          ? date
          : dateoption.toUpperCase() === "DATEINBETWEEN"
          ? startDate
          : "",
        dateoption.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        "",
        mid,
        "",
        "",
        setData,
        setTransactionDetails,
        page,
        size
      );
    } else if (type === "Payout") {
      USE_PAYOUT_MULTIPLE_FILTER_API(
        PAYOUT_MULTIPLE_FILTER_API,
        parameterType?.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "AMOUNTINBETWEEN"
          ? parameterValue?.split("-")[0]
          : "",
        parameterType?.toUpperCase() === "AMOUNTINBETWEEN"
          ? parameterValue?.split("-")[1]
          : "",
        dateoption?.toUpperCase() === "DATE" ? date : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        "",
        "",
        "",
        "",
        "",
        transectionStatus,
        transferType,
        "",
        mid,
        setData,
        setTransactionDetails,
        page,
        size
      );
    } else if (type === "Withdraw") {
      USE_WITHDRAW_MULTIPLE_FILTER_API(
        WITHDRAW_MULTIPLE_FILTER_API,
        parameterType?.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? startAmount : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? endAmount : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        otherDateoption?.toUpperCase() === "OTHERDATEINBETWEEN"
          ? otherStartDate
          : "",
        otherDateoption?.toUpperCase() === "OTHERDATEINBETWEEN"
          ? otherEndDate
          : "",
        dateoption?.toUpperCase() === "DATE" ? date : "",
        otherDateoption?.toUpperCase() === "OTHERDATE" ? otherdate : "",
        parameterType?.toUpperCase() === "UTR NUMBER" ? parameterValue : "",
        transectionStatus,
        mid,
        setData,
        setTransactionDetails,
        page,
        size
      );
    } else if (type === "Chargeback") {
      USE_CHARGEBACK_MULTIPLE_FILTER_API(
        CHARGEBACK_MULTIPLE_FILTER_API,
        parameterType?.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "AMOUNTINBETWEEN" ? startAmount : "",
        parameterType?.toUpperCase() === "AMOUNTINBETWEEN" ? endAmount : "",
        dateoption?.toUpperCase() === "DATE" ? date : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        otherDateoption?.toUpperCase() === "OTHERDATE" ? otherdate : "",
        otherDateoption?.toUpperCase() === "OTHERDATEINBETWEEN"
          ? otherStartDate
          : "",
        otherDateoption?.toUpperCase() === "OTHERDATEINBETWEEN"
          ? otherEndDate
          : "",
        "",
        "",
        "",
        "",
        "",
        "",
        mid,
        setData,
        setTransactionDetails,
        page,
        size
      );
    } else if (type === "Payin") {
      USE_PAYIN_MULTIPLE_FILTER_API(
        PAYIN_MULTIPLE_FILTER_API,
        parameterType?.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "BANK LOCATION" ? parameterValue : "",
        parameterType?.toUpperCase() === "CUSTOMER LOCATION"
          ? parameterValue
          : "",
        dateoption.toUpperCase() === "DATE" ? date : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? endAmount : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        "",
        "",
        "",
        mid,
        parameterType?.toUpperCase() === "UPI ID / VPA" ? parameterValue : "",
        parameterType?.toUpperCase() === "Type of Payment"
          ? parameterValue
          : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? startAmount : "",
        dateoption.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        transectionStatus,
        parameterType.toUpperCase() === "STATUS CODE"
          ? parseInt(parameterValue)
          : 0,
        setData,
        setTransactionDetails,
        page,
        size
      );

      USE_MULTIPLE_FILTER_BOX_API(
        dateoption.toUpperCase() === "DATE" ? date : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        mid,
        dateoption.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        setErrorCodeList
      );
    } else if (type === "Reconciliation") {
      USE_RECONCILIATION_MULTIPLE_FILTER_API(
        RECONCILIATION_MULTIPLE_FILTER_API,
        parameterType?.toUpperCase() === "AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? endAmount : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        mid,
        parameterType?.toUpperCase() === "UPI ID / VPA" ? parameterValue : "",
        "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? startAmount : "",
        dateoption?.toUpperCase() === "DATE"
          ? date
          : dateoption?.toUpperCase() === "DATEINBETWEEN"
          ? startDate
          : "",
        "",
        setData,
        setTransactionDetails,
        page,
        size
      );
    } else if (type === "Guarantee") {
      USE_GURANTEE_MULTIPLE_FILTER_API(
        parameterType?.toUpperCase() === "GUARANTEE AMOUNT RANGE" ? startAmount : "",
        parameterType?.toUpperCase() === "GUARANTEE AMOUNT RANGE" ? endAmount : "",
        parameterType?.toUpperCase() === "GUARANTEE AMOUNT" ? parameterValue : "",
        parameterType?.toUpperCase() === "NET AMOUNT" ? parameterValue : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        dateoption?.toUpperCase() === "DATE" ? date : "",
        parameterType?.toUpperCase() === "REFERENCE NUMBER" ? parameterValue : "",
        mid,
        setData,
         
      );
    } else if (type === "Wallet" || type === "Vendors" || type === "Credit" || type === "Release" ) {
      USE_COMMON_MULTIPLE_FILTER_API(
        COMMON_MULTIPLE_FILTER_API,
        dateoption.toUpperCase() === "DATE" ? date : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? endDate : "",
        dateoption?.toUpperCase() === "DATEINBETWEEN" ? startDate : "",
        transectionStatus,
        mid,
        type === "Credit" ? active : type,
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? parseInt(startAmount) : "",
        parameterType?.toUpperCase() === "AMOUNT RANGE" ? parseInt(endAmount) : "",
        parameterType?.toUpperCase() === "AMOUNT" ? parseInt(parameterValue) : "",
        setData,
        page,
        size
      );
    }
  }, [reduxData, page, size]);

  useEffect(() => {
    if (filterFlag) {
      if (type === "Settlement") {
        USE_SETTLEMENT_MULTIPLE_FILTER_API(
          SETTLEMENT_MULTIPLE_FILTER_API,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          optionValue === "Transaction ID" ? parameterValuead : "",
          mid,
          optionValue === "Order Number" ? parameterValuead : "",
          "",
          setData,
          setTransactionDetails
        );
      } else if (type === "Payout") {
        USE_PAYOUT_MULTIPLE_FILTER_API(
          PAYOUT_MULTIPLE_FILTER_API,
          "",
          "",
          "",
          "",
          "",
          "",
          optionValue === "e-Mail" ? parameterValuead : "",
          optionValue === "Mobile" ? parameterValuead : "",
          optionValue === "Reference ID" ? parameterValuead : "",
          optionValue === "Transaction ID" ? parameterValuead : "",
          optionValue === "UTR No" ? parameterValuead : "",
          "",
          "",
          optionValue === "Payer VPA" ? parameterValuead : "",
          mid,
          setData,
          setTransactionDetails
        );
      } else if (type === "Chargeback") {
        USE_CHARGEBACK_MULTIPLE_FILTER_API(
          CHARGEBACK_MULTIPLE_FILTER_API,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          optionValue === "e-Mail" ? parameterValuead : "",
          optionValue === "Mobile" ? parameterValuead : "",
          optionValue === "Payer VPA" ? parameterValuead : "",
          optionValue === "RRN No" ? parameterValuead : "",
          "",
          "",
          mid,
          setData,
          setTransactionDetails
        );
      } else if (type === "Reconciliation") {
        USE_RECONCILIATION_MULTIPLE_FILTER_API(
          RECONCILIATION_MULTIPLE_FILTER_API,
          "",
          "",
          "",
          mid,
          "",
          optionValue === "UTR No" ? parameterValuead : "",
          "",
          "",
          optionValue === "Transaction ID" ? parameterValuead : "",
          setData,
          setTransactionDetails
        );
      } else if (type === "Payin") {
        USE_PAYIN_ADVANCE_FILTER_API(
          GET_REPORT_BY_CONDITION_API,
          optionValue === "e-Mail" ? parameterValuead : "",
          optionValue === "Order Number" ? parameterValuead : "",
          optionValue === "Transaction ID" ? parameterValuead : "",
          "",
          "",
          optionValue === "Mobile" ? parameterValuead : "",
          optionValue === "Payer VPA" ? parameterValuead : "",
          optionValue === "UTR No" ? parameterValuead : "",
          mid,
          setData,
          setTransactionDetails
        );
      } else if (type === "Wallet" || type === "Vendors" || type === "Credit" || type === "Release" ) {
        USE_COMMON_ADVANCE_FILTER_API(
          COMMON_ADVANCE_FILTER_API,
          optionValue === "e-Mail" ? parameterValuead : "",
          optionValue === "Mobile" ? parameterValuead : "",
          optionValue === "Order Number" ? parameterValuead : "",
          optionValue === "Transaction ID" ? parameterValuead : "",
          optionValue === "UTR No" ? parameterValuead : "",
          optionValue === "Payer VPA" ? parameterValuead : "",
          type === "Credit" ? active : type,
          setData,
          page,
          size
        );
      }
    }
  }, [flag]);

  const [callDispatch, setCallDispatch] = useState(false);

  const DateType = {
    Payin: true,
    Settlement: true,
    Chargeback: true,
    Payout: true,
    Wallet: true,
    Credit: true,
    Release: true,
    Vendors: true,
    
    Withdraw: true,
    Guarantee: true,
    Reconciliation: true,
  };

  const ParameterType = {
    Payin: true,
    Settlement: true,
    Chargeback: true,
    Payout: true,
    Release: true,
    Withdraw: true,
    Guarantee: true,
    Reconciliation: true,
  };

  const StatusType = {
    Payin: true,
    Payout: true,
    Withdraw: true,
  };

  const TransferType = {
    Payout: true,
    // Withdraw : true
  };

  const OtherDateType = {
    Chargeback: true,
    Withdraw: true,
  };

  const parameterTypeAd = {
    Payin: [
      "Transaction ID",
      "Order Number",
      "e-Mail",
      "Mobile",
      "Payer VPA",
      "UTR No",
    ],
    Settlement: ["Transaction ID", "Order Number"],
    Chargeback: ["e-Mail", "Mobile", "Payer VPA", "RRN No"],
    Payout: [
      "Transaction ID",
      "e-Mail",
      "Mobile",
      "Payer VPA",
      "Reference ID",
      "UTR No",
    ],
    Wallet: [
      "Transaction ID",
      "e-Mail",
      "Mobile",
      "Payer VPA",
      "Order No",
      "UTR No",
    ],
    Vendors: [
      "Transaction ID",
      "e-Mail",
      "Mobile",
      "Payer VPA",
      "Order No",
      "UTR No",
    ],
    Release: [
      // "Transaction ID",
      // "e-Mail",
      // "Mobile",
      // "Payer VPA",
      // "Order No",
      "UTR No",
    ],
    Credit: [
      "Transaction ID",
      "e-Mail",
      "Mobile",
      "Payer VPA",
      "Order No",
      "UTR No",
    ],
    Reconciliation: ["Transaction ID", "UTR No"],
  };

  const handleGetRangeAmount = (start, end) => {
    setStartAmt(start);
    setEndAmt(end);
  };

  const handleSave = async () => {
    // if (endAmt <= startAmt) {
    //   swal("Warning!", "End Amount must be grater than Start Amount", "warning");
    //   return;
    // }

    setFilterFlag(true);
    dispatch(setFilterOn(true));
    if (toggle) {
      await setCallDispatch(!callDispatch);
    } else {
      setFlag(!flag);
    }
    handleClose();
  };

  return (
    <Modal show={showFilter} centered>
      <Modal.Header className="bg-dark text-white">
        <Modal.Title className="text-white">
          {toggle ? "More Filter" : "Advanced Filter"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {toggle ? (
            <div className="">
              {DateType[type] ? (
                <DateFilter type={type} callDispatch={callDispatch} />
              ) : null}
              {OtherDateType[type] ? (
                <OtherDateFilter type={type} callDispatch={callDispatch} />
              ) : null}
              {ParameterType[type] ? (
                <Parameter
                  type={type}
                  callDispatch={callDispatch}
                  // handleGetRangeAmount={handleGetRangeAmount}
                />
              ) : null}
              {TransferType[type] ? (
                <TransferTP type={type} callDispatch={callDispatch} />
              ) : null}
              {StatusType[type] ? (
                <Statusfilter type={type} callDispatch={callDispatch} />
              ) : null}
              {type === "Guarantee" || type === "Withdraw" ? null : (
                <div class="mt-5 d-flex" onClick={() => setToggle(!toggle)}>
                  {
                    <a class="fs-3 text-primary cursor-pointer">
                      <u>{toggle ? "Advanced Filter" : "More Filter"}</u>
                    </a>
                  }
                </div>
              )}
            </div>
          ) : (
            <div className="m-5">
              <div className="form-group ">
                <select
                  id="single-select"
                  className="form-control fs-4"
                  value={optionValue}
                  onChange={(e) => setOptionValue(e.target.value)}
                >
                  <option value="">Select</option>
                  {parameterTypeAd[type]?.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group mt-3">
                <input
                  className="advanced-input-box form-control fs-4"
                  type="text"
                  min={0}
                  value={parameterValuead}
                  placeholder={optionValue}
                  onChange={(e) => setParameterValueAd(e.target.value)}
                />
              </div>

              <div class="mt-5 d-flex" onClick={() => setToggle(!toggle)}>
                {
                  <a className="fs-3 text-primary text-ul cursor-pointer">
                    <u>{toggle ? "Advanced Filter" : "More Filter"}</u>
                  </a>
                }
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              // data-bs-dismiss="modal"
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleSave}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Morefilter;
