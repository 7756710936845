import React from "react";
import "./slideBox.css";

const SlideBox = ({ flag, toggleRightBar, children }) => {
  return (
    <div className={`chatbox chat1 ${flag == true && "active"}`}>
      <div class="chatbox-close chat1close"></div>
      <div class="d-flex">
        <button
          type="button"
          class="btn-close m-3"
          onClick={toggleRightBar}
        ></button>

        <div
          class="card-body contacts_body p-0 dlab-scroll"
          id="dlab_W_Contacts_Body"
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SlideBox;