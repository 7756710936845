import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/**
 * This component renders a MyReferral Link component.
 * @returns {ReactNode} A React element that renders a Myreferral Link.
 */

const MyReferralLink = () => {
  const referralData = JSON.parse(localStorage?.getItem("data"));

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card.Body>
            <div className="filter-filed-area">
              <div className="merchant-from-info">
                <Col xs={12} lg={12} md={12}>
                  <div className="Referral_earn">
                    <h4 className="referral_value mb-3"> Give Referral</h4>
                    <div className="referral_first_i con mb-3 d-flex">
                      <SendIcon
                        size={20}
                        color="black"
                        className="referral_logo"
                      />
                      <p className="referral_invite mb-4 ">
                        <strong> Invite Your Friend to</strong>
                      </p>
                    </div>
                    <div className="referral-cd mb-4">
                      <p className="referral_code">
                        <strong>Referral Code </strong>
                      </p>

                      <button type="button" class="btn btn-primary">
                        A1Z#B8W$12
                      </button>
                    </div>
                    <div className=" referral_link">
                      <p className="referral_link_main">
                        <strong>Referral Link</strong>
                      </p>
                      <div className="share-link-btn d-flex">
                        <button type="button" class="btn btn-primary mr-2">
                          Copy
                        </button>

                        <div className="referral_share_parent d-flex">
                          <button type="button " class="btn btn-sm">
                            <WhatsAppIcon
                              size={25}
                              round
                              style={{
                                fill: "black",
                                backgroundColor: "#25D366",
                                borderRadius: "50%",
                              }}
                            />
                            <span className="ml-2 mx-2">
                              Share via WhatsApp
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Card.Body>
        </Col>
      </Row>
    </>
  );
};

export default MyReferralLink;
