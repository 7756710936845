import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEffect } from "react";
import useApiServices from "../../Utils/useApiService";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { TREND_CHART_API } from "../../Utils/Constants";
import IbtnWrapper from "../../Common/CustomToolTip/IButtonWrraper";
import { axisClasses } from "@mui/x-charts";
import MiniLoader from "../../Common/MiniLoader/MiniLoader";
import { useSelector } from "react-redux";

/**
 * SimpleBarChart component renders a bar chart.
 * prepare payload with given condition, calls the api and prepare the bar chart according to the received data.
 * @param {string} condition -  condition.
 * @returns {ReactNode} A React element that renders the bar chart.
 */
export default function SimpleBarChart({ condition }) {
  const [successSeries, setSuccessSeries] = useState([]);
  const [failedSeries, setFailedSeries] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);

  const { apiCallFnforPost } = useApiServices();

  const { mid } = useSelector((state) => state.userData);
  
  const getDataPayinServices = async () => {
    const payload = {
      mid: mid,
      timeFrame: condition,
    };
    try {
      const response = await apiCallFnforPost(TREND_CHART_API, payload);
      if (response.statusCode === 200) {
        setSuccessSeries(
          response.responseData.map((dataPoint, index) => {
            return parseInt(dataPoint.successCount); // Use successCount as y value
          })
        );
        setFailedSeries(
          response.responseData.map((dataPoint, index) => {
            return parseInt(dataPoint.failedCount); // Use successCount as y value
          })
        );
        setXAxisLabels(
          response.responseData.map(
            (dataPoint, i) =>
              dataPoint.date.split("-")[1] + "-" + dataPoint.date.split("-")[2]
          )
        );
      } else {
        setSuccessSeries([]);
        setFailedSeries([]);
      }
    } catch (error) {
      setSuccessSeries([]);
      setFailedSeries([]);
      // console.error(error);
    }
  };
  useEffect(() => {
    getDataPayinServices();
  }, [condition]);

  const chartSetting = {
    yAxis: [
      {
        label: "Transactions Counts",
        fontSize: "10px",
      },
    ],
    xAxis: [
      { data: xAxisLabels, scaleType: "band", label: `Dates of ${condition === "1 day" ? "Today" : condition === "this year" ?"This Month":condition === "this quarter" ?"This Month":condition === "last quarter" ?"This Month":condition === "last month" ?"This Month":condition}` },
    ],
    width: 900,
    height: 500,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  return (
    <div class="col-xl-12 col-xxl-12">
      <div class="card">
        <div class="card-header d-block border-0">
          <div class="d-flex align-items-center justify-content-between mb-1">
            <h4 class="card-title">Overview Payment ({condition === "1 day" ? "Today" : condition === "this year" ?"This Month":condition === "this quarter" ?"This Month":condition === "last quarter" ?"This Month":condition === "last month" ?"This Month":condition})</h4>
            {/* <h4 class="card-title">Overview Payment ({condition === "1 day" ? "Today" : condition})</h4> */}

            <IbtnWrapper color={"text-black"} desc={"Overview Payment"} />
          </div>
        </div>
        <div class="card-body py-0 px-sm-3 d-flex justify-content-center align-items-center ">
          {/* <div id="marketChart" class="market-line"></div> */}
          {successSeries?.length !== 0 && failedSeries?.length !== 0 ? (
            <BarChart
              series={[
                {
                  data: successSeries,
                  label: "Success",
                  id: "pvId",
                  color: "#5DBFB8",
                  borderRadius: "5px",
                }, // Set color for successSeries
                {
                  data: failedSeries,
                  label: "Failed",
                  id: "uvId",
                  color: "#EE9D2E",
                  borderRadius: "5px",
                }, // Set color for failedSeries
              ]}
              {...chartSetting}
            />
          ) : (
            <div>
              <MiniLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
