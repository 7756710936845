import axios from 'axios';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useEffect } from 'react';

const supersetUrl = 'https://dashboard.lincpay.in';  
// const supersetUrl = 'http://148.113.1.195:8088'; // Assuming Superset is running locally
const supersetApiUrl = `${supersetUrl}/api/v1/security`;
const dashboardId = "f32ef923-5171-4978-9ecc-94b923e8ffc2"; // Your dashboard ID
const token = "5bxHjDj3JB2MK0I2oxaJKvEob1UZvFI7VpNKphjHmp0="; // Your dashboard ID

async function getToken() {
  try {
    // Calling login to get access token
    const loginBody = {
      "password": "admin",
      "provider": "db",
      "refresh": true,
      "username": "admin"
    };

    const loginHeaders = {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`
    };

    // Obtain access token
    console.log( "-----------------------")
    const { data: loginData } = await axios.post(`${supersetApiUrl}/login`, loginBody, { headers: loginHeaders });
    const accessToken = loginData.access_token;
    console.log(loginData,"-----------------------")

    // Create guest token
    const guestTokenBody = JSON.stringify({
      "resources": [
        {
          "type": "dashboard",
          "id": dashboardId,
        }
      ],
      "rls": [],
      "user": {
        "username": "",
        "first_name": "",
        "last_name": "",
      }
    });

    const guestTokenHeaders = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    };

    // Obtain guest token
    const { data: guestData } = await axios.post(`${supersetApiUrl}/guest_token/`, guestTokenBody, { headers: guestTokenHeaders });

    // Embed the dashboard
    embedDashboard({
      id: dashboardId,
      supersetDomain: supersetUrl,
      mountPoint: document.getElementById("superset-container"),
      fetchGuestToken: () => guestData.token,
      dashboardUiConfig: {
        filters: { expanded: true },
        urlParams: { standalone: 3 } // Add any required URL params here
      }
    });

    // Adjust iframe styling if needed
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.style.width = '100%';
      iframe.style.minHeight = '100vh'; // Use vh instead of vw for height
    }

  } catch (error) {
    console.error('Error embedding dashboard:', error.message);
  }
}

export default function Superset() {
  useEffect(() => {
    getToken(); // Fetch token and embed dashboard on component mount
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="App">
      <div id="superset-container" style={{ width: '100%', height: '100vh' }}>// here </div>
    </div>
  );
}
