import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import styles from "./FirstStage.module.css";
import {
  getKeyPressed,
  isIfscRegexIsValid,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../Utils/validations";
import { useNavigate, Link } from "react-router-dom";
import { ACC_NUM_IFSC_VERIFY_API, CHECK_FILE_IS_PDF } from "../Utils/Constants";
import { jwtDecode } from "jwt-decode";
import { Col } from "react-bootstrap";
import useApiServices from "../Utils/useApiService";
import { useSelector } from "react-redux";

/**
 * FirstStage component renders First Stage  form of the onboarding process.
 * @returns {ReactNode} A React element that renders first stage form of the onboarding process.
 */
export default function FirstStage() {
  const initialValues = {
    accountNumber: "",
    ifsc: "",
    bankName: "",
    branchName: "",
    bankCity: "",
    callbackUrl: "",
    payoutCallBackUrl: "",
    websiteUrl: "",
    docs: "",
  };
  const [companyRegFile, setCompanyRegFile] = useState(null);
  const [passwordPopUp, setPasswordPopUp] = useState(true);
  const [accountName, setAccountName] = useState("");

  const { mid } = useSelector((state) => state.userData);

  const [formData, setFormData] = useState(initialValues);

  const [supportingFileList, setSupportingFileList] = useState([]);
  const [docsFile, setDocsFile] = useState([]);
  const [docsName, setDocsName] = useState([]);

  const [pressedKeyName, setPressedKeyName] = useState("");

  const [isIfscVerified, setIsIfscVerified] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [companyType, setCompanyType] = useState("");

   
  const stageData = localStorage.getItem("data");
  const { isPasswordUpdate, CompanyType } = JSON.parse(stageData);

  const {
    apiCallFnforPostFormData,
    ifscCodeVerifier,
    updateMerchantBankDetails,
    apiCallFnforPostForOpenUrl,
    uploadMerchantDocs,
  } = useApiServices();

  const checkIsFileIsPDF = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await apiCallFnforPostFormData(
        `${CHECK_FILE_IS_PDF}`,
        formData
      );
      // console.log(response?.data?.statusCode);
      return response?.data?.statusCode;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handelFormData = (e) => {
    const { name, value } = e.target;

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      (name === "bankCity" || name === "bankName" || name === "branchName")
    ) {
      return;
    }

    if (isIfscVerified && (name === "ifsc" || name === "accountNumber")) {
      setAccountName("");
      setIsIfscVerified(false);
    }

    if (
      isIfscVerified &&
      (name === "bankName" || name === "branchName" || name === "bankCity")
    ) {
      return;
    }

    if (isIfscVerified === "NotVerified") {
      setIsIfscVerified(false);
    }

    if (name === "payoutCallBackUrl" || name === "callbackUrl") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }
  };

  const handelValidations = (e) => {
    if (e.target.name === "ifsc" && e.target.value !== "") {
      if (!isIfscRegexIsValid(formData.ifsc)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, ifsc: "" });
        });
        return;
      }
    }
  };
  const setFilesToState = () => {
    let nameArr = [];
    let docsArr = [];
    // extracting documents data from state
    supportingFileList.forEach((element, indx) => {
      let name = element.name;
      let file = element.file;
      nameArr.push(name);
      docsArr.push(file);
    });

    setDocsName(nameArr);
    setDocsFile(docsArr);
  };

  const saveDataToServer = async (payload) => {
    try {
      setShowLoader(true);
      let response = await updateMerchantBankDetails(payload);
      setShowLoader(false);

      if (response.statusCode === 200) {
        setShowLoader(true);
        let merchantDocsRes = await uploadMerchantDocs(
          mid,
          [companyType],
          [formData.docs]
        );
        setShowLoader(false);
        if (merchantDocsRes === 200) {
          document.getElementById("docs").value = "";
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          });
        }
      }

      if (response.statusCode === 200 && supportingFileList.length > 0) {
        setShowLoader(true);
        let merchantSupportingDocs = await uploadMerchantDocs(
          mid,
          docsName,
          docsFile
        );
        setShowLoader(false);
        if (merchantSupportingDocs === 200) {
          setSupportingFileList([]);
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          });
        }
      }

      if (response.statusCode === 200) {
        setFormData(initialValues);
        swal({
          title: "Success!",
          text: "Data Saved Successfully !",
          icon: "success",
        }).then(function () {
          window.location = "https://swipelinc.com/";
        });
        setCompanyRegFile(null);
        // localStorage.removeItem("token")
      } else if (response.statusCode === 304) {
        swal({
          title: "Alert!",
          text: "Beneficiary Account Name and Company Name Must be Similiar !",
          icon: "error",
        });
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong !",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (companyType !== "") {
      if (document.getElementById("docs").value === "") {
        swal({
          title: "ALERT!",
          text: "Please Select your document*",
          icon: "error",
        });
        return;
      }
    }

    if (formData.accountNumber === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Account number*",
        icon: "error",
      });
      return;
    }

    if (formData.ifsc === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter IFSC Code*",
        icon: "error",
      });
      return;
    }

    if (formData.bankName === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank Name*",
        icon: "error",
      });
      return;
    }

    if (formData.branchName === "") {
      swal({
        title: "ALERT!",
        text: " Please Enter Branch Name*",
        icon: "error",
      });
      return;
    }

    if (formData.bankCity === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank City*",
        icon: "error",
      });
      return;
    }

    if (accountName === "") {
      swal({
        title: "ALERT!",
        text: "Please Verify your Bank*",
        icon: "error",
      });
      return;
    }

    supportingFileList.forEach((docsRow) => {
      if (docsRow.name === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Supporting Documents Labels",
          icon: "error",
        });
        return;
      }
    });

    supportingFileList.forEach((docsRow) => {
      if (docsRow.file === "") {
        swal({
          title: "Alert!",
          text: "Please Select Supporting Documents",
          icon: "error",
        });
        return;
      }
    });

    const payload = {
      accoutBeneficiaryName: accountName,
      bankBranchName: formData.branchName,
      callBackUrl: formData.callbackUrl,
      merchantAccountNumber: formData.accountNumber,
      merchantBankName: formData.bankName,
      merchantIfscCode: formData.ifsc,
      mid: mid,
      websiteUrl: formData.websiteUrl,
      payoutCallBackUrl: formData.payoutCallBackUrl,
    };

    saveDataToServer(payload);
 
  };

  const handleViewFile = (file) => {
    if (!file) {
      return; // If no file is selected, do nothing
    }
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const verifyIfsc = async () => {
    if (formData.accountNumber === "") {
      swal({
        title: "ALERT!",
        text: "*Please Enter Account number",
        icon: "error",
      });
      return;
    }
    if (formData.ifsc === "") {
      swal({
        title: "ALERT!",
        text: "*Please Enter IFSC Code",
        icon: "error",
      });
      return;
    }
    setShowLoader(true);
    const isAccVerified = await apiCallFnforPostForOpenUrl(
      ACC_NUM_IFSC_VERIFY_API,
      { ifsc: formData.ifsc, accountNumber: formData.accountNumber }
    );

    setAccountName(isAccVerified.data.AccoutBeneficiaryName);

    const res = await ifscCodeVerifier(formData.ifsc);
    setShowLoader(false);

    if (res.status === 200 && isAccVerified.statusCode === 200) {
      setFormData({
        ...formData,
        branchName: res.data.branch,
        bankName: res.data.bank,
        bankCity: res.data.city,
      });
      setIsIfscVerified(true);
    } else {
      setIsIfscVerified("NotVerified");
      swal({
        title: "Alert!",
        text: "Invalid Bank Details !",
        icon: "error",
      });
    }
  };

  const addSupportingDocumentField = () => {
    let newEntry = {
      id: new Date().getTime().toLocaleString(),
      name: "",
      file: "",
    };
    setSupportingFileList([...supportingFileList, newEntry]);
  };

  const removeSupportingDocumentField = (id) => {
    let newData = supportingFileList.filter((elem) => {
      return elem.id !== id;
    });

    let nameArray = newData.map((elem) => {
      return elem.name;
    });

    let fileArray = newData.map((elem) => {
      return elem.file;
    });

    setDocsName(nameArray);
    setDocsFile(fileArray);

    setSupportingFileList(newData);
  };

  const handelDocumentsFileName = (event, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.name = event.target.value?.toUpperCase();
        return elem;
      }
      return elem;
    });

    setSupportingFileList(newArr);
    setFilesToState();
  };

  const handelDocumentsFileData = async (file, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = file;
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);
    setFilesToState();
  };
  const isPdfFile = async (event, id) => {
    // clear state of pdf seleted
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = "";
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);

    let file = event.target.files[0];
    setShowLoader(true);
    let res = await checkIsFileIsPDF(file);
    setShowLoader(false);
    if (res === 200) {
      handelDocumentsFileData(file, id);
      return;
    } else {
      swal({
        title: "Alert!",
        text: "Kindly Upload PDF FIles Only",
        icon: "error",
      });
      setCompanyRegFile(null);
      document.getElementById(id).value = "";
      return false;
    }
  };

  const getCompanyType = () => {
    const { companyType } = CompanyType;
    if (
      companyType?.trim() === "PRIVATE LIMITED" ||
      companyType?.trim() === "PUBLIC LIMITED" ||
      companyType?.trim() === "OPC"
    ) {
      setCompanyType("MOA");
    } else if (
      companyType?.trim() === "LLP" ||
      companyType?.trim() === "PARTNERSHIP"
    ) {
      setCompanyType("Partnership Deal");
    } else if (companyType?.trim() === "PROPERITARY") {
      setCompanyType("Gumasta");
    } else if (companyType?.trim() === "NGO/TRUST") {
      setCompanyType("Bye-laws");
    }
  };

  useEffect(() => {
    getCompanyType();
  }, []);

  return (
    <>
      <div className="container-fluid px-5 py-5">
        <div className="row page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Form</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="javascript:void(0)">Wizard</a>
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Account Details</h4>
              </div>
              <div className="card-body py-4">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <form
                      onSubmit={handleSubmit}
                      className="form-section-form-group"
                    >
                      <div className="row">
                        {companyType != "" ? (
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                {companyType}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="file"
                                accept=".pdf"
                                id="docs"
                                name="docs"
                                className="form-control"
                                onChange={(event) => {
                                  // Handle file upload here
                                  setCompanyRegFile(event.target.files[0]);
                                  if (isPdfFile(event, "docs")) {
                                    setFormData({
                                      ...formData,
                                      docs: event.target.files[0],
                                    });
                                  }
                                }}
                              />

                              {companyRegFile && (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={() => handleViewFile(companyRegFile)}
                                >
                                  View
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Account Number
                              <span className="required">*</span>
                            </label>
                            <input
                              type="number"
                              // id="firstName"
                              id="accountNumber"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="accountNumber"
                              value={formData.accountNumber}
                              onChange={handelFormData}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              IFSC Code
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="ifscCode"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onBlur={handelValidations}
                              name="ifsc"
                              value={formData.ifsc}
                              onChange={handelFormData}
                              className="form-control"
                            />

                            <div className="d-flex justify-content-end">
                              {!isIfscVerified ? (
                                <span
                                  onClick={verifyIfsc}
                                  role="button"
                                  className="text-primary cursor-pointer align-right mt-1"
                                >
                                  Verify IFSC
                                </span>
                              ) : (
                                ""
                              )}
                              {isIfscVerified === true ? (
                                <div className="d-flex justify-content-end">
                                  <span
                                    role="button"
                                    className="text-success cursor-pointer align-right mt-1"
                                  >
                                    <b>Verified</b>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              {isIfscVerified === "NotVerified" ? (
                                <div className="d-flex justify-content-end">
                                  <span
                                    role="button"
                                    className="text-danger cursor-pointer align-right mt-1"
                                  >
                                    <b>Not Verified</b>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Bank Name
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="bankName"
                              onKeyDown={(e) => {
                                // if (e.key === " ") {
                                //   e.preventDefault();
                                // }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="bankName"
                              value={formData.bankName}
                              onChange={handelFormData}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Branch Name
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="branchName"
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              name="branchName"
                              value={formData.branchName}
                              onChange={handelFormData}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              City
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              id="city"
                              onKeyDown={(e) => {
                                // if (e.key === " ") {
                                //   e.preventDefault();
                                // }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="bankCity"
                              value={formData.bankCity}
                              onChange={handelFormData}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Payin Callback URL
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="callBackUrl"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                // setPressedKeyName(getKeyPressed(e));
                              }}
                              name="callbackUrl"
                              value={formData.callbackUrl}
                              onChange={handelFormData}
                              className="form-control"
                            />
                            <span
                              id="callBackUrlErr"
                              className=" text-danger d-none"
                            >
                              Please Enter CallBack Url*
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="text-label form-label">
                              Payout Callback URL
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="payoutCallBackUrl"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                // setPressedKeyName(getKeyPressed(e));
                              }}
                              name="payoutCallBackUrl"
                              value={formData.payoutCallBackUrl}
                              onChange={handelFormData}
                              className="form-control"
                            />
                            <span
                              id="payoutCallBackUrlErr"
                              className=" text-danger d-none"
                            >
                              Please Enter Payout CallBack Url*
                            </span>
                          </div>
                        </div>

                        <Col xs={12} lg={12} md={12}>
                          <label
                            className="mt-2 fs-4"
                            htmlFor="registeredAddressToggle"
                          >
                            Other Supporting Documents{" "}
                            <span
                              onClick={addSupportingDocumentField}
                              className="text-primary fs-5 cursor-pointer"
                            >
                              <i class="fa-solid fa-circle-plus"></i>
                            </span>{" "}
                          </label>
                        </Col>
                        {supportingFileList?.map((elem) => {
                          return (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <div className="d-flex gap-3">
                               
                                  <label className="text-label form-label">
                                    Label Name
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    id="callBackUrl"
                                    name="name"
                                    value={elem.name}
                                    required="true"
                                    onChange={(event) =>
                                      handelDocumentsFileName(event, elem.id)
                                    }
                                    className="form-control"
                                  />

                                  <input
                                    type="file"
                                    accept=".pdf"
                                    required="true"
                                    onChange={(event) =>
                                      isPdfFile(event, elem.id)
                                    }
                                    id={elem.id}
                                    className="form-control"
                                  />
                                </div>
                               
                                <div className="d-flex justify-content-end py-3 mx-2">
                                  <span
                                    onClick={() =>
                                      removeSupportingDocumentField(elem.id)
                                    }
                                    className="text-danger fs-10 cursor-pointer"
                                  >
                                    <i class="fa-solid fa-trash-can"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
                <Col xs={12} lg={12} md={12}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleSubmit}
                      id="submitDetailsBtn"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPasswordUpdate !== true && passwordPopUp === true ? (
        <div className={styles.popup_container_frist_stage}>
          <div className={styles.popup_first_stage}>
            <h3>Please change Your Password</h3>
            <p>Click Here to Change Your Password</p>
            <Link to="/PasswordChangeForm" className="btn btn-primary">
              Change Password
            </Link>
            <div className={styles.checkbox_first_stage}>
              <input type="checkbox" onChange={() => setPasswordPopUp(false)} />
              <label className="text-label form-label">
                Do Not show this again
              </label>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
