import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MERCHANT_ONBOARD_OTP_CHANGE_EMAIL, MERCHANT_ONBOARD_OTP_CHANGE_MOBILE, MERCHANT_ONBOARD_SAVE, MERCHANT_ONBOARD_VERIFIED, SEND_OTP_TO_EMAIL, SEND_OTP_TO_MOBILE, SWIPELINC_API, VERIFY_MERCHANT_ONBOARD_EMAIL, VERIFY_MERCHANT_ONBOARD_MOBILE } from "../Utils/Constants";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { updateRegisteredIdEmail } from "../store/Slices/onBoardSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import {config,getCompleteDomain,getServerEnvironment} from "../Utils/DynamicPortalFunctions";
import useApiServices from "../Utils/useApiService";
import LeftPageLogIn from "../Login/LeftPageLogIn";
 
/**
 * MerchantOnBoard component renders Merchant OnBoard form of the onboarding process.
 * @description it takes email and mobile number via inputs and send otp and varify it then redirect to the onboarding process.
 * @returns {ReactNode} A React element that renders Merchant OnBoard form of the onboarding process.
 */
export default function MerchantOnBoard() {
  const [showIncorrectOTP, setShowIncorrectOTP] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiMobileResponse, setApiMobileResponse] = useState(null);
  const [registerId, setRegisterId] = useState();
  const [otpCorrect, setOtpCorrect] = useState(false);
  const [mobileOtpCorrect, setMobileOtpCorrect] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const {apiCallFnforPostForOpenUrl}=useApiServices()

   const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));
  const [showLoader, setShowLoader] = useState(false);
   const AppNameCapitalized=Name
   const AppName = AppNameCapitalized.toUpperCase()

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    emailOTP: "",
    mobileNumber: "",
    mobileOTP: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("E-mail ID is required"),

    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      formik.resetForm();
    },
  });

  const handleSendOTP = async () => {
    
    if (formik.values.email === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email!",
        icon: "error",
      });
      return;
    }

    if (formik.values.mobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number!",
        icon: "error",
      });
      return;
    }

    setShowLoader(true);
    let emailRes = await apiCallFnforPostForOpenUrl(
      VERIFY_MERCHANT_ONBOARD_EMAIL,
     { email:formik.values.email}
    );
    let mobRes = await apiCallFnforPostForOpenUrl(
      VERIFY_MERCHANT_ONBOARD_MOBILE,
    { mobile: formik.values.mobileNumber}
    );
    setShowLoader(false);

    if (emailRes.statusCode === 600 && mobRes.statusCode === 600) {
      swal({
        title: "Opps!",
        text: emailRes.message + " !",
        icon: "error",
      });
      return;
    }

    if (emailRes.statusCode === 200) {
      document.getElementById("email-error-message").classList.remove("d-none");
    }
    if (mobRes.statusCode === 200) {
      document.getElementById("mob-error-message").classList.remove("d-none");
    }

    if (emailRes.statusCode !== 200 && mobRes.statusCode !== 200) {
      try {
        setShowLoader(true);
        const response = await apiCallFnforPostForOpenUrl  (
          SEND_OTP_TO_EMAIL, {
            email: formik.values.email,
          }
        );

        const responseMobOtp = await apiCallFnforPostForOpenUrl (
          SEND_OTP_TO_MOBILE, {
          mobile: formik.values.mobileNumber,
        });
        setShowLoader(false);
        document.getElementById("sendOtpBtn").classList.add("d-none");
        setApiResponse(response);
        setApiMobileResponse(responseMobOtp);

        if (responseMobOtp?.statusCode === 200) {
          setOtpCorrect(false); // Reset OTP correctness status
          setMobileOtpCorrect(false);
        } else {
          swal({
            title: "ALERT!",
            text: `${responseMobOtp.message} !`,
            icon: "error",
          });
          return;
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  const handleOTPChange = async (event) => {
    const otp = event.target.value;
    formik.handleChange(event);
    let length = otp.length;
    if (length === 6) {
      try {
        const response = await apiCallFnforPostForOpenUrl (
          MERCHANT_ONBOARD_OTP_CHANGE_EMAIL,
          {
            otp: otp,
          }
        );

        if (response.statusCode === 200) {
          setOtpCorrect(true);
        } else {
          setOtpCorrect(false);
          swal({
            title: "ALERT!",
            text: `Invalid Email OTP !`,
            icon: "error",
          });
          return;
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  const handleOTPChangeMobile = async (event) => {
    const otp = event.target.value;
    formik.handleChange(event);
    let length = otp.length;
    if (length === 6) {
      try {
        const response = await apiCallFnforPostForOpenUrl(
          MERCHANT_ONBOARD_OTP_CHANGE_MOBILE,
          {
            email: formik.values.mobileNumber,
            otp: otp,
          }
        );

        if (response.statusCode === 200) {
          setMobileOtpCorrect(true);
        } else {
          setMobileOtpCorrect(false);
          swal({
            title: "ALERT!",
            text: `Invalid Mobile OTP !`,
            icon: "error",
          });
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
  };

  const handleFormSubmit = async () => {
    if (formik.errors.emailOTP || formik.errors.mobileOTP) {
      setShowIncorrectOTP(true);
    } else {
      if (apiResponse && apiResponse.statusCode === 200) {
        try {
          const response = await apiCallFnforPostForOpenUrl(
            MERCHANT_ONBOARD_SAVE,
            {
              email: formik.values.email,
              emailVerified: true,
              mobile: formik.values.mobileNumber,
              mobileVerified: true,
            }
          );

          if (response.statusCode === 200) {
            try {
              const mobileResponse = await apiCallFnforPostForOpenUrl(
                MERCHANT_ONBOARD_VERIFIED,
                {
                  mobile: formik.values.mobileNumber,
                }
              );

              setApiMobileResponse(mobileResponse.data);
            } catch (error) {}
          } else {
            setApiMobileResponse([]);
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      } else {
        setShowIncorrectOTP(true);
      }
    }
  };

  useEffect(() => {
    const verifyMobile = async () => {
      if (otpCorrect || mobileOtpCorrect) {
        swal({
          title: "Success!",
          text: "Your OTP's have been verified successfully !",
          icon: "success",
        }).then(function () {
          navigate("/AdharForm");
        });
        try {
          const response = await apiCallFnforPostForOpenUrl(
            MERCHANT_ONBOARD_VERIFIED,
            {
              mobile: formik.values.mobileNumber,
            }
          );
          setApiMobileResponse(response?.data);
        } catch (error) {
          console.error("API call failed:", error);
        }

        const NA = "NA";
        try {
          const response = await apiCallFnforPostForOpenUrl(
            `${SWIPELINC_API}MerchantPanel/merchant/onboard/save?mid=${NA}`,
            {
              email: formik.values.email,
              emailVerified: true,
              mobile: formik.values.mobileNumber,
              mobileVerified: true,
            }
          );
          setRegisterId(response?.data);
          dispatch(updateRegisteredIdEmail(response?.data?.data?.registeredId));

          if (response?.data?.statusCode === 200) {
            localStorage.setItem(
              "registrationId",
              response?.data?.data?.registeredId
            );
          } else if (response?.data?.statusCode === 600) {
            swal({
              title: "Alert!",
              text: "Unable To Generate Registration ID",
              icon: "error",
            }).then(function () {
              navigate("/EmailForm");
            });
            return;
          } else {
            swal({
              title: "Alert!",
              text: response?.data?.message,
              icon: "error",
            }).then(function () {
              navigate("/EmailForm");
            });
            return;
          }
        } catch (error) {
          console.log("API call failed:", error);
        }
      }
    };

    verifyMobile();
  }, [otpCorrect, mobileOtpCorrect]);

  return (
    <>
      <div className="vh-100">
        <div className="authincation h-100">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
                <div className="login-form">
                  <div className="text-center">
                    <h3 className="title">Registration</h3>
                    <p>Welcome! Please Create your account.</p>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-4">
                      <label className="mb-1 text-dark">Email</label>
                      <div>
                        <div>
                          <input
                            type="email"
                            id="email_form_input"
                            name="email"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              document
                                .getElementById("email-error-message")
                                .classList.add("d-none");
                            }}
                            placeholder="E-mail ID"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.email && formik.errors.email
                                ? "error form-control"
                                : "form-control form-control"
                            }
                          />
                          {apiResponse && apiResponse.statusCode === 200 && (
                            <div className="d-flex justify-content-end py-2">
                              <button
                                type="button"
                                onClick={handleSendOTP}
                                className="btn btn-primary btn-sm"
                              >
                                Resend OTP
                              </button>
                            </div>
                          )}
                        </div>
                        <span
                          id="email-error-message"
                          className=" text-danger d-none" >
                        
                          Email Already registered.
                        </span>
                        {formik.touched.email && formik.errors.email && (
                          <span className="error-message d-block">
                            {formik.errors.email}
                          </span>
                        )}
                      </div>
                      {apiResponse &&
                        apiResponse.statusCode === 200 &&
                        formik.values.email && (
                          <>
                          <div>
                              {otpCorrect ? (
                                <p>
                                  Entered OTP is correct
                                </p>
                              ) : (
                                <p>Please Enter OTP</p>
                              )}
                              <input
                                type="text"
                                // id="emailOTP"
                                id="email_form_input"
                                name="emailOTP"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                value={formik.values.emailOTP}
                                onChange={handleOTPChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.emailOTP &&
                                  formik.errors.emailOTP
                                  ? "error form-control"
                                : "form-control form-control"
                                }
                                placeholder="Enter OTP"
                              />
                            </div>
                          </>
                        )}
                      {formik.touched.emailOTP && formik.errors.emailOTP && (
                        <span className="error-message">
                          {formik.errors.emailOTP}
                        </span>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 text-dark">Mobile Number</label>
                      <div>
                        <div>
                          <input
                            type="number"
                            id="email_form_input"
                            name="mobileNumber"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              document
                                .getElementById("mob-error-message")
                                .classList.add("d-none");
                            }}
                            placeholder="Mobile Number"
                            value={formik.values.mobileNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.mobileNumber &&
                              formik.errors.mobileNumber
                                ? "error form-control"
                                : "form-control form-control"
                            }
                          />

                          {apiResponse && apiResponse.statusCode === 200 && (
                            <div className="d-flex justify-content-end py-2">
                              <button
                                type="button"
                                onClick={handleSendOTP}
                                className="btn btn-primary btn-sm"
                              >
                                Resend OTP
                              </button>
                            </div>
                          )}
                        </div>
                        <span
                          id="mob-error-message"
                          className=" text-danger d-none"
                        >
                          *Mobile Number Already Registered !
                        </span>

                        {formik.touched.mobileNumber &&
                          formik.errors.mobileNumber && (
                            <span className="error-message">
                              {formik.errors.mobileNumber}
                            </span>
                          )}
                      </div>
                      {apiMobileResponse &&
                        apiMobileResponse.statusCode === 200 &&
                        formik.values.mobileNumber && (
                          <>
                            <div className="input-group-onbording-otp">
                              {mobileOtpCorrect ? (
                                <p className="otp_message">
                                  Entered OTP is correct
                                </p>
                              ) : (
                                <p className="otp_message">Please Enter OTP</p>
                              )}

                              <input
                                type="text"
                                // id="mobileOTP"
                                id="email_form_input"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                name="mobileOTP"
                                value={formik.values.mobileOTP}
                                onChange={handleOTPChangeMobile}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.mobileOTP &&
                                  formik.errors.mobileOTP
                                    ? "error form-control"
                                    : "form-control"
                                }
                                placeholder="Enter OTP"
                              />

                              {formik.touched.mobileOTP &&
                                formik.errors.mobileOTP && (
                                  <span className="error-message">
                                    {formik.errors.mobileOTP}
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                      {formik.touched.mobileOTP && formik.errors.mobileOTP && (
                        <span className="error-message">
                          {formik.errors.mobileOTP}
                        </span>
                      )}
                    </div>
                    <div class="text-center mb-4">
                      <button
                        type="button"
                        onClick={handleSendOTP}
                        id="sendOtpBtn"
                        className="btn btn-primary btn-block"
                      >
                        Send OTP
                      </button>
                    </div>
                  </form>
                  <p class="text-center">
                    <a className="btn-link text-primary" href="/ResumeOnboarding">
                      Resume Your Journey
                    </a>
                  </p>
                </div>
              </div>
              <LeftPageLogIn/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
