import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-bootstrap";
import { FaqList } from "./FaqList";

/**
 * This component is a FAQ component
 * @returns {ReactNode} A React component that renders FAQ related information about Merchant FAQs.
 */

const FAQ = () => {
 
  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="">
          <div className="">
            <div className="row">
              <div className="col" xs={12} lg={12} md={12}>
                <div className="card">
                  <div className="card-body">
                    <div className="">
                      {FaqList.map((faqList, i) => (
                        <Accordion defaultActiveKey={0} key={i}>
                          <AccordionItem className="">
                            <AccordionHeader>
                              <span
                                className="text-primary"
                                dangerouslySetInnerHTML={{
                                  __html: faqList.question,
                                }}
                              ></span>
                            </AccordionHeader>
                            <AccordionBody>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: faqList.answer,
                                }}
                              ></span>
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                </div>
                {/* ./card */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
