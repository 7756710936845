import CustomTip from "../Common/CustomToolTip/CustomTip";
import IbtnWrapper from "../Common/CustomToolTip/IButtonWrraper";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import { formatCompactNumber } from "../Utils/helper";

/**
 * TotalTransaction component renders a card with title and transaction count.
 * @description calls the various api's  and renders the sidebar with received data.
 * @param {string} title - title of the card.
 * @param {number} data -  transaction count.
 * @returns {ReactNode} A React element that renders card with title and transaction count.
 */
export default function TotalTransaction({ title, data }) {
  return (
    <>
      <div className="col-sm-6">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mt-2">{title}</h4>
            <div className="d-flex align-items-center mt-3 mb-2">
              <CustomTip size={20} title={data ?? 0} placement="top">
                {data === null || data === undefined ? (
                  <MiniLoader size={15} color={"primary"} />
                ) : (
                  <h2 className="fs-38 mb-0 me-3">
                    {formatCompactNumber(data ?? 0)}
                  </h2>
                )}
              </CustomTip>
              {/* <span className="badge badge-danger">-6.4%</span> */}
            </div>
          </div>
          <IbtnWrapper color={"text-black"} desc={title} />
        </div>
      </div>
    </>
  );
}
