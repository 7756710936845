import React, { useEffect, useState } from 'react'
import useApiServices from '../../Utils/useApiService';
import { GET_NOTIFICATION_BY_MID } from '../../Utils/Constants';
import { jwtDecode } from 'jwt-decode';
import Notify from './Notify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


/** 
 * This component notification icon in header.
 * @param {function} - This is useState function to update notification counts. 
*/

const Notification = ({setNotificationCount}) => {

    const [notifications, setNotifications] = useState([])
    // const [notificationCount, setNotificationCount] = useState("")
    const { mid } = useSelector((state) => state.userData);
 

    const { apiCallFnforGet } = useApiServices();

    const getAllNotifications = async () => {
        try {
          const res = await apiCallFnforGet(GET_NOTIFICATION_BY_MID + mid);
        
          if (res.statusCode === 200) {
            setNotifications(res?.data);
            setNotificationCount(res?.data?.length);
          } else {
            setNotifications([]);
          }
        } catch (error) {
          setNotifications([]);
        }
      };
    
      useEffect(() => {
        getAllNotifications();
      }, []);


  return (
    <div className="dropdown-menu dropdown-menu-end">
    <div
      id="dlab_W_Notification1"
      className="widget-media dlab-scroll p-3"
      style={{ height: "380px" }}
    >
      <ul className="timeline">
        {notifications?.map((notifications, i) => (
          <Notify key={i+1} notifications={notifications} />
        ))}
      </ul>
    </div>
    <Link
      className="all-notification text-primary"
      to="#"
    >
      See all notifications <i className="ti-arrow-right"></i>
    </Link>
  </div>
  )
}

export default Notification