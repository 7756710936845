import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
 
// import "../App.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import useApiServices from '../../Utils/useApiService';
import { GET_ALL_NOTICE, IMG_SLIDE_PATH } from '../../Utils/Constants';

const Input = styled('input')({
  display: 'none',
});

export default function NoticeComponent() {
  const [slides, setSlides] = useState(null);
  const [heading, setHeading] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [image, setImage] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [error, setError] = useState('');
  const token = localStorage.getItem("token");

  const {apiCallFnforGet }=useApiServices( )

  const handleHeadingChange = (e) => setHeading(e.target.value);
  const handleParagraphChange = (e) => setParagraph(e.target.value);
  const handleImageChange = (e) => setImage(e.target.files[0]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await apiCallFnforGet(
          GET_ALL_NOTICE,
          {
            headers: {
              Authentication: "Bearer " + token
            },
          }
        );

        if (response?.statusCode === 200) {
          const processedSlides = response.data.map(slide => ({
            ...slide,
            image: IMG_SLIDE_PATH+slide.fileName
          }));
          setSlides(processedSlides);
        } else {
          setError('Error fetching slides');
        }
      } catch (error) {
        setError('Error fetching slides');
      }
    };

    fetchSlides();
  }, [token]);

  return (
    <div className="container" style={{marginTop:"0"}}>
      <div className="card coin-card bg-dark text-white">
        <div className="card-body">
          <div className="fs-4 text-black">Upcoming...</div>
          <hr />
          <Carousel
            interval={null}
            className="mb-4"
            prevIcon={
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  height: '30px',
                  width: '30px',
                  backgroundSize: '100% 100%',
                }}
              />
            }
            nextIcon={
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  height: '30px',
                  width: '30px',
                  backgroundSize: '100% 100%',
                }}
              />
            }
          >
            {slides?.map((slide) => (
              <Carousel.Item key={slide.id}>
                <div className="row align-items-center">
                  <div className="col-md-4 d-flex align-items-center justify-content-center">
                    <img
                      src={slide.image}
                      alt="Slide"
                      className="img-fluid img-thumbnail"
                      style={{ height: '150px', objectFit: 'cover' }}
                    />
                  </div>
                  <div className="col-md-8">
                    <h3>{slide.heading}</h3>
                    <p>{slide.paragraph}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

          {/* {isFormVisible && (
            <form onSubmit={handleSubmit} className="mb-4">
              <div className="form-group mb-3">
                <TextField 
                  label="Heading"
                  variant="outlined"
                  fullWidth
                  value={heading}
                  onChange={handleHeadingChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <TextField
                  label="Paragraph"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  value={paragraph}
                  onChange={handleParagraphChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="image-upload">
                  <Input accept="image/*" id="image-upload" type="file" onChange={handleImageChange} required />
                  <Button variant="contained" component="span">
                    Choose File
                  </Button>
                  <span className="ms-2">
                    {image ? image.name : 'No file chosen'}
                  </span>
                </label>
              </div>
              <div className="d-flex gap-2">
                <button  type="submit" className="me-2 badge badge-rounded badge-warning py-2">
                  Submit
                </button>
                <button className='badge badge-rounded badge-info py-2' type="button" onClick={handleAddSlideClick}>
                  Cancel
                </button>
              </div>
            </form>
          )} */}

          {/* {!isFormVisible && (
            <Button variant="contained" color="primary" onClick={handleAddSlideClick} className="py-2">
              Add Slide
            </Button>
          )} */}

          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
      </div>
    </div>
  );
}