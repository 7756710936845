import React, { useEffect, useState } from "react";
import WithDrawIcon from "../../Assets/images/tools/withdraw.png";
 
import TxnCbIcon from "../../Assets/images/tools/txnCallback.png";
 
import SubmittedVendorIcon from "../../Assets/images/tools/vendor (2).png";
import PayoutTxnCB from "../../Assets/images/tools/payout_txn_cb.png";
import WalletBalanceIcon from "../../Assets/images/tools/wallet.png";
import styles from "./tool.module.css";
// import Modal from "../../Common/Modal/Modal";
import SlideBox from "../../Common/SlideBox/SlideBox";
import WithdrawInPayout from "./WithdrawInPayout";
import RegisterVendor from "./RegisterVendor";
import TransactionCallback from "./TransactionCallback";
 
import SubmittedVendor from "./SubmittedVendor";
 
import AddWalletBalance from "./AddWalletBalance";
import PayoutRequest from "./PayoutRequest";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import ArchieveReport from "./ArchieveReport";
import ArchievedReportsIcon from "../../Assets/images/tools/archieved_report.png";
import { useParams } from "react-router-dom";

const Tools = () => {
  const toolType = {
    withDraw: "withDraw",
    TxnStatus: true,
    rvendor: "rvendor",
    transactionCallback: "transactionCallback",
    transactionStatus: "transactionStatus",
    payoutTransactioncallback: "payoutTransactioncallback",
    addWalletBalance: "addWalletBalance",
    submittedVendor: "submittedVendor",
    PayoutRequest: "PayoutRequest",
    ArchievedReport: "ArchievedReport",
  };

  const { slidebar } = useParams();

  const [flag, setFlag] = useState(false);
  const [tool, setTool] = useState("");

  const toggleRightBar = (type) => {
    if (type) {
      setTool(type);
    }
    setFlag(!flag);
  };

  useEffect(() => {
    if (slidebar == 6) {
      toggleRightBar("ArchievedReport");
      setTimeout(() => {
        window.openArchieveDialough();
      }, 600);
    } else if (slidebar == 5) {
      toggleRightBar("addWalletBalance");
    } else if (slidebar == 1) {
      toggleRightBar("withDraw");
    } else if (slidebar == 2) {
      toggleRightBar("PayoutRequest");
    }
  }, []);

  const {
    AddWalletBalancePrivilages,
    hasPrivilagesRegisterVendor,
    WithdrawPrivilages,
    hasPrivilagesSubmittedVendor,
    hasPrivilagesPayoutTxnCallback,
    hasPrivilagesTxnCallback,

    PayoutReqPrivilages,
  } = CheckExportConst();

  return (
    <div className={`content-body ${styles["minHeight"]}`}  >
      <div className="container-fluid">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
            <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {toolType[tool] === "withDraw" && (
                <WithdrawInPayout tool={tool} />
              )}
              {toolType[tool] === "rvendor" && <RegisterVendor />}
              {toolType[tool] === "transactionCallback" && (
                <TransactionCallback />
              )}
              {/* {toolType[tool] === "transactionStatus" && <TxnStatusCheck />} */}
              {/* {toolType[tool] === "payoutTransactioncallback" && (
                <PayoutTransactioncallback />
              )} */}
              {toolType[tool] === "addWalletBalance" && <AddWalletBalance />}
              {toolType[tool] === "submittedVendor" && <SubmittedVendor />}
              {toolType[tool] === "PayoutRequest" && <PayoutRequest />}
              {toolType[tool] === "ArchievedReport" && <ArchieveReport />}
            </SlideBox>
            <div class="row text-center text-lg-start">
              {WithdrawPrivilages ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("withDraw")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={WithDrawIcon}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Withdraw Amount
                    </h5>
                  </div>
                </div>
              ) : null}

              {/* <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("rvendor")}
                >
                  <img
                    class={`img-fluid img-thumbnail`}
                    src={RegisterVendorIcon}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Register vendor
                  </h5>
                </div>
              </div> */}

              {PayoutReqPrivilages ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("PayoutRequest")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={PayoutTxnCB}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout Request
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilagesPayoutTxnCallback || hasPrivilagesTxnCallback ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("transactionCallback")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={TxnCbIcon}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Callback
                    </h5>
                  </div>
                </div>
              ) : null}

              {/* <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("transactionStatus")}
                >
                  <img class="img-fluid img-thumbnail" src={TxnStatus} alt="" />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Transaction status
                  </h5>
                </div>
              </div> */}
              {hasPrivilagesSubmittedVendor || hasPrivilagesRegisterVendor ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                  onClick={() => toggleRightBar("submittedVendor")}
                >
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <img
                      class="img-fluid img-thumbnail"
                      src={SubmittedVendorIcon}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Vendors
                    </h5>
                  </div>
                </div>
              ) : null}

              {AddWalletBalancePrivilages ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("addWalletBalance")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={WalletBalanceIcon}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Wallet Balance
                    </h5>
                  </div>
                </div>
              ) : null}

              <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("ArchievedReport")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={ArchievedReportsIcon}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Archived Reports
                  </h5>
                </div>
                          
              </div>

              {/* <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["tool-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => toggleRightBar("payoutTransactioncallback")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={PayoutRequestIcon}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Payout txn callback
                  </h5>
                </div>
              </div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Tools;
