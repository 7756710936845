import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../Utils/validations";
import useApiServices from "../../Utils/useApiService";
import { POST_LINK_BASED_PAYMENT } from "../../Utils/Constants";
import InputBox from "../../Common/InputBox/InputBox";
import { useSelector } from "react-redux";

/** 
 * this component renders amount input for link based payments via way of communication such as whatsapp, email and SMS.
 * @returns {ReactNode} The Component returns input type and checkboxes for way of communication.
*/


const LinkBasedPayments = () => {
  const { apiCallFnforPost } = useApiServices();
  // const [activeButton, setactiveButton] = useState(1);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [transferType, setTransferType] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  // const [virtualAccountType, setVirtualAccountType] = useState("");
  // const [mobileNum, setMobileNum] = useState(false);
  // const [email, setEmail] = useState(false);
  const [showInputs, setShowInputs] = useState({ mobile: false, email: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [formDataInputs, setFormDataInputs] = useState({
    mobileNum: "",
    email: "",
  });
  const { mid } = useSelector((state) => state.userData);

  const Type = localStorage.getItem("typeOfServices");
  const paymentType = JSON.parse(Type);

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setFormDataInputs({ ...formDataInputs, [name]: value });
  };

  const Payload = {
    amount: amount,
    email: formDataInputs.email,
    mid: mid,
    mobile: parseInt(formDataInputs.mobileNum),
  };

  const handleAmount = (e) => {
    const value = e.target.value;
    const numberPattern = /^[1-9]\d*$/;

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };

  const handleSubmit = async () => {
    if (transferType.includes("email")) {
      if (!isEmailIdRegexIsValid(formDataInputs.email)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          setFormDataInputs({ ...formDataInputs });
        });
        return;
      }
    }

    if (amount.length === 0) {
      swal({
        title: "Alert",
        text: "Please Enter Amount",
        icon: "warning",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to submit this  request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        try {
          const response = await apiCallFnforPost(
            POST_LINK_BASED_PAYMENT,
            Payload
          );

          if (response.statusCode === 309) {
            swal({
              title: "Alert",
              text: response.message,
              icon: "error",
            });
            setAmount("");
            setFormDataInputs({
              mobileNum: "",
              email: "",
            });
            setTransferType("");
            return;
          }

          if (response.statusCode === 200) {
            swal({
              title: "Success",
              text: "Your  request has been submitted successfully.",
              icon: "success",
            });
            setGeneratedLink(response?.data);
            setAmount("");
            setFormDataInputs({
              mobileNum: "",
              email: "",
            });
            setTransferType("");
          } else {
            swal({
              title: "Failed",
              text: "Your  request has been declined.",
              icon: "warning",
            });
          }
        } catch (error) {
          setError(true);
          swal({
            title: "Failed",
            text: "Something went wrong",
            icon: "error",
          });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        swal("Submission canceled!");
      }
    });
  };

  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.email)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };

  return (
    <>
      {/* <div className="row">
        <div className="col-xs-12"> */}
      {/* <div className="my-3">
            <label htmlFor="companyPanNumber" className="form-label">
              Enter Amount
              <sup className="text-danger">*</sup>
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Amount"
              value={amount}
              onChange={handleAmount}
            />
          </div> */}
      <InputBox
        type={"text"}
        name={"amount"}
        label={"Amount"}
        value={amount}
        onChange={handleAmount}
        placeholder="Enter Amount"
      />
      <div className="d-flex flex-column align-items-start">
        <label
          htmlFor="wayOfCommunication"
          className="form-label mt-2 mx-2 fs-5 text-black"
        >
          Way Of Communication
          <sup className="text-danger">*</sup>
        </label>
        <div className="d-flex gap-4 px-3">
          <div className="form-check custom-checkbox mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="whatsappCheckbox"
              checked={transferType.includes("whatsapp")}
              onChange={() =>
                setTransferType((prevTypes) =>
                  prevTypes.includes("whatsapp")
                    ? prevTypes.filter((type) => type !== "whatsapp")
                    : [...prevTypes, "whatsapp"]
                )
              }
            />
            <label className="form-check-label mt-2" htmlFor="customCheckBox6">
              WhatsApp
            </label>
          </div>

          <div className="form-check custom-checkbox mb-3 ">
            <input
              type="checkbox"
              className="form-check-input"
              id="emailCheckbox"
              checked={transferType.includes("email")}
              onChange={() =>
                setTransferType((prevTypes) =>
                  prevTypes.includes("email")
                    ? prevTypes.filter((type) => type !== "email")
                    : [...prevTypes, "email"]
                )
              }
            />
            <label className="form-check-label mt-2" htmlFor="customCheckBox6">
              Email
            </label>
          </div>

          <div className="form-check custom-checkbox mb-3 ">
            <input
              type="checkbox"
              className="form-check-input"
              id="smsCheckbox"
              checked={transferType.includes("sms")}
              onChange={() =>
                setTransferType((prevTypes) =>
                  prevTypes.includes("sms")
                    ? prevTypes.filter((type) => type !== "sms")
                    : [...prevTypes, "sms"]
                )
              }
            />
            <label className="form-check-label mt-2" htmlFor="customCheckBox8">
              SMS
            </label>
          </div>
        </div>
      </div>
      {transferType.includes("whatsapp") || transferType.includes("sms") ? (
        <InputBox
          label={"Mobile Number"}
          type={"tel"}
          maxLength={10}
          minLength={10}
          value={formDataInputs.mobileNum}
          onChange={handleformDataInputs}
          name={"mobileNum"}
          required={true}
          isMobile={"[0-9]{10}"}
          placeholder={"Enter Mobile Number"}
        />
      ) : null}

      {transferType.includes("email") ? (
        <InputBox
          label={"E-Mail Id"}
          type={"email"}
          value={formDataInputs.email}
          onChange={handleformDataInputs}
          name={"email"}
          required={true}
          isMobile={"[0-9]{10}"}
          placeholder={"Enter E-Mail Id"}
        />
      ) : null}

      <div class="mt-4 d-flex justify-content-end">
        <button
          id="companyPanVerifyBtn"
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          Submit
        </button>
      </div>

      {generatedLink && (
        <div className="form-group mt-3">
          <h5>Generated Link</h5>
          <a href={generatedLink} target="_blank" rel="noopener noreferrer">
            {generatedLink}
          </a>
        </div>
      )}
    </>
  );
};

export default LinkBasedPayments;