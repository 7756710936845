import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { isEmailIdRegexIsValid } from "../Utils/validations";
import {config,getCompleteDomain,getServerEnvironment} from "../Utils/DynamicPortalFunctions";
import { CHECK_EMAIL_IS_VERIFIED_OR_NOT, CHECK_MOBILE_IS_VERIFIED_OR_NOT } from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
import LeftPageLogIn from "../Login/LeftPageLogIn";

/**
 * ResumeOnboarding component renders Resume Onboarding form.
 * @description it takes email and mobile number via inputs and call the api for registration Id then redirect to the onboarding process.
 * @returns {ReactNode} A React element that renders Resume Onboarding form.
 */
export default function ResumeOnboarding() {
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    mobileNumber: "",
  });

  const { apiCallFnforPostForOpenUrl } = useApiServices()

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));
  const AppNameCapitalized = Name;
  const AppName = AppNameCapitalized.toUpperCase();

  const navigate = useNavigate();

  const getKeyPressed = (e) => {
    setPressedKeyName(e.key);
  };

  const handelValidations = (e) => {
    if (e.target.name === "mobileNumber") {
      if (userData.mobileNumber.length !== 10) {
        document.getElementById("mobErr").classList.remove("d-none");
        document.getElementById("verifyBtn").disabled = true;
        return;
      }
    }

    if (e.target.name === "email") {
      if (!isEmailIdRegexIsValid(userData.email)) {
        document.getElementById("emailErr").textContent =
          "*Please Enter Valid Email!";
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("verifyBtn").disabled = true;
        return;
      }
    }
  };
  const CheckEmailMobileIsVerifiedOrNot = async () => {
    const email = userData.email.trim();
    const mobileNumber = userData.mobileNumber.trim();
  
    // Check for empty email or mobile number and show errors accordingly
    if (!email) {
      document.getElementById("emailErr").classList.remove("d-none");
      document.getElementById("verifyBtn").disabled = true;
      return;
    }
  
    if (!mobileNumber) {
      document.getElementById("mobErr").classList.remove("d-none");
      document.getElementById("verifyBtn").disabled = true;
      return;
    }
  
    try {
      // Make API calls to check email and mobile verification status
      const [emailRes, mobRes] = await Promise.all([
        apiCallFnforPostForOpenUrl(CHECK_EMAIL_IS_VERIFIED_OR_NOT, { email }),
        apiCallFnforPostForOpenUrl(CHECK_MOBILE_IS_VERIFIED_OR_NOT, { mobile:mobileNumber }),
      ]);
  
      if (emailRes.statusCode === 200 && mobRes.statusCode === 200) {
        // Both email and mobile are verified
        if (emailRes.data.VarifyEmail === "true" && mobRes.data.VarifyMobile === "true") {
          swal({
            title: "Alert",
            text: "You Have Already OnBoarded. Please Log In.",
            icon: "warning",
          }).then(() => navigate("/"));
        } else if (emailRes.data.registeredId === mobRes.data.registeredId) {
          // Save the registration ID to local storage
          localStorage.setItem("registrationId", mobRes.data.registeredId);
          swal({
            title: "Success!",
            text: "Great! Please Continue Your Onboarding Process!",
            icon: "success",
          }).then(() => navigate("/AdharForm"));
        } else {
          // Email or mobile does not match registered merchant
          swal({
            title: "Alert!",
            text: "Either Email or Mobile Number does not match the registered Merchant",
            icon: "error",
          });
        }
      } else if (emailRes.statusCode === 307 && mobRes.statusCode === 307) {
        // Email and mobile need verification
        swal({
          title: "Alert",
          text: "Please Verify Your Email And Mobile First!",
          icon: "error",
        }).then(() => navigate("/MerchantOnboard"));
      } else {
        // Handle other possible status codes or errors
        console.error("Unexpected status codes:", emailRes.data.statusCode, mobRes.data.statusCode);
      }
    } catch (error) {
      console.error("Error during API calls:", error);
    }
  };
  

  const handleDataChange = (e) => {
    if (pressedKeyName === "Backspace" && e.target.name === "mobileNumber") {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
    if (
      userData.mobileNumber.length >= 10 &&
      e.target.name === "mobileNumber"
    ) {
      return;
    }

    setUserData({ ...userData, [e.target.name]: e.target.value });

    document.getElementById("emailErr").classList.add("d-none");
    document.getElementById("mobErr").classList.add("d-none");
    document.getElementById("verifyBtn").disabled = false;
  };

  return (
    <>
      <div className="vh-100">
        <div className="authincation h-100">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
                <div className="login-form">
                  <div className="text-center">
                    <h2 className="title">Welcome Back</h2>
                    <p>
                      {" "}
                      Welcome! Please Continue Your 
                      Onboarding Journey from
                      Here......
                    </p>
                  </div>
                  <form>
                    <div className="mb-4">
                      <label className="mb-1 text-dark">E-mail ID</label>
                      <div>
                        <div>
                          <input
                            type="email"
                            id="email_form_input"
                            name="email"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            placeholder="E-mail ID"
                            value={userData.email}
                            onChange={handleDataChange}
                            onBlur={handelValidations}
                            className="form-control form-control"
                          />
                        </div>
                        <span id="emailErr" className=" text-danger d-none">
                          *Please Enter Email
                        </span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="mb-1 text-dark">Mobile Number</label>

                      <div>
                        <div>
                          <input
                            type="text"
                            // id="mobileNumber"
                            id="email_form_input"
                            name="mobileNumber"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              getKeyPressed(e);
                            }}
                            placeholder="Mobile Number"
                            value={userData.mobileNumber}
                            onChange={handleDataChange}
                            onBlur={handelValidations}
                            className="form-control form-control"
                          />
                        </div>
                        <span id="mobErr" className=" text-danger d-none">
                          *Please Enter Mobile Number
                        </span>
                      </div>
                    </div>
                    <div className="login-btn-sec">
                      <button
                        type="button"
                        onClick={CheckEmailMobileIsVerifiedOrNot}
                        class="btn btn-primary btn-block"
                        id="verifyBtn"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <LeftPageLogIn/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
