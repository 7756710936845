import React from "react";

const TapNPay = () => {
  return (
    <>
      <h2>Service not Active!</h2>
    </>
  );
};

export default TapNPay;