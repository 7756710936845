import React from "react";
import NumberInWords from "../NumberInWords/NumberInWords";

const InputBox = ({
  label,
  placeholder,
  onChange,
  type,
  value,
  name,
  required,
  isMobile,
  minLength,
  NumberInWord,
  maxLength,
  onBlur,
  onKeyDown,
}) => {
  return (
    <div class="mb-2 col-md-12 d-flex flex-column align-items-start">
      <label class="form-label me-1 fs-5 text-black mx-2">
        {label}
        {required && <span class="required"> *</span>}
      </label>
      <input
        maxLength={maxLength+""}
        minLength={minLength+""}
        pattern={isMobile}
        type={type}
        class="form-control"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        required={required}
      />
      {NumberInWord ? (
        <span className="mx-2">
          <NumberInWords number={value} />
        </span>
      ) : null}
    </div>
  );
};

export default InputBox;
