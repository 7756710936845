import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Styles from "./style.module.css";


/**
 * This is reusable component for Loading.
 * @returns {ReactNode} this is a reusable component for loader renders on component screen.
 */

export default function Loader() {
  return (
   <div className={Styles.overlay}>
     <Box sx={{ display: 'flex', position:"absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)" }}>
      <CircularProgress />
    </Box>
   </div>
  );
}