import { useState } from "react";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyButton from "../Common/CopyButton/CopyButton";
import styles from "./onboard.module.css";

import {
  onboardingJsonParameters,
  onboardingTableJson,
} from "../Utils/apiDocs";
import {
  ADHAR_VERIFICATION,
  ONBOARDING_URL,
  SAVE_IMAGE_URL,
  SEND_OTP_TO_MOBILE,
  UPLOAD_MERCHANT_DOCS,
} from "../Utils/Constants";

/**
 * This component renders documentation for merchant onboarding.
 * It displays Onboarding Api Urls , request parameters, responses, etc.
 * @returns {ReactNode} A React element that renders Merchant Onboarding documentation.
 */

const OnboardingDoc = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    aadhaarNumber: "941XXXX676527",
    aadharVerifyDto: {
      otp: "717440",
      refNumber: "18628452",
    },
    authorizedPersonFirstName: "JHON",
    authorizedPersonLastName: "SINHA",
    authorizedPersonMiddleName: "",
    authorizedPersonPan: "BOIXXX909L",
    bankBranchName: "SBI DRM",
    bankCity: "Bhopal",
    buisnessTypeName: "",
    businessAs: "",
    businessTypeId: 2,
    callBackUrl: "www.xyz.com",
    chargebackCallbackUrl: "chargeback.com",
    communicationAddress: "shahpura bhopal",
    communicationAddressCity: "Bhopal",
    communicationAddressDistrict: "Madhya Pradesh",
    communicationAddressPinCode: "462039",
    communicationAddressState: "Madhya Pradesh",
    companyAddress: "shahpura bhopal",
    companyAddressCity: "Bhopal",
    companyAddressDistrict: "Bhopal",
    companyAddressPinCode: "462039",
    companyAddressState: "Madhya Pradesh",
    companyName: "XYZ PVT.LTD",
    companyPanNumber: "BOXXX3909L",
    companyTypeId: 1,
    email: "xyz@gmail.com",
    gstNumber: "23AXXXXXXXXX5",
    masterMerchnatId: 1038212,
    mccCode: "5262",
    merchantAccountNumber: "302XXXXXX577",
    merchantBankName: "SBI",
    merchantIfscCode: "SBIN0010146",
    mobile: "7748005659",
    mobileVerifyDto: {
      mobileNumber: "7748005659",
      otp: 461097,
    },
    newCompanyAddress: "shahpura bhopal",
    payinCallbackUrl: "payin.com",
    paymentTypeId: [1, 2, 6],
    payoutCallbackUrl: "payout.com",
    registeredOfficeAddress: "shahpura bhopal",
    registeredOfficeAddressCity: "Bhopal",
    registeredOfficeAddressDistrict: "BHOPAL",
    registeredOfficeAddressPinCode: "462039",
    registeredOfficeAddressState: "Trilanga main road shahpura",
    registrationTypeId: 1,
    websiteUrl: "www.google.com",
    authorizedPersonDateOfBirth: "01/05/2024",
    authorizedPersonFullName: "JHON SINHA",
  });

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div class="content-body">
      <div className="container-fluid p-1">
        <div className="row">
          <div className={`col-xl-12 col-xxl-12 ${styles.leftWrapper}`}>
            <div className="card">
              <div  
                className="card"
                style={{ position: "fixed", height: "40px", marginTop: "-10px" }}
              >
                 <div className="card-header" style={{ padding: "0px" }}> 
                  <ul
                    className="navbar-nav"
                    style={{
                      padding: "0px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <li
                      className="nav-item border-none"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div
                        onClick={() => scrollToSection("api-docs")}
                        className="btn btn-link nav-link text-black border-none"
                      >
                        Onboarding Api Docs
                      </div>
                    </li>
                    <li className="nav-item border-none" style={{ margin: "0px" }}>
                      <div
                        onClick={() => scrollToSection("send-otp")}
                        className="btn btn-link nav-link text-black"
                      >
                        Send OTP to Mobile Number
                      </div>
                    </li>
                    <li className="nav-item" style={{ margin: "0px" }}>
                      <div
                        onClick={() => scrollToSection("aadhaar-verification")}
                        className="btn btn-link nav-link text-black"
                      >
                        Aadhaar Verification
                      </div>
                    </li>
                    <li className="nav-item" style={{ margin: "0px" }}>
                      <div
                        onClick={() => scrollToSection("save-docs")}
                        className="btn btn-link nav-link text-black"
                      >
                        Save Merchant Documents
                      </div>
                    </li>
                    <li className="nav-item" style={{ margin: "0px" }}>
                      <div
                        onClick={() => scrollToSection("save-image")}
                        className="btn btn-link nav-link text-black"
                      >
                        Save Image Api
                      </div>
                    </li>
                  </ul>
                 </div>
              </div>

              <div className={`card-body p-3 ${styles.leftSubWrapper}`}>
                {/* <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  onClick={() => scrollToSection("api-docs")}
                  className="btn btn-link nav-link"
                >
                  Onboarding Api Docs
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToSection("send-otp")}
                  className="btn btn-link nav-link"
                >
                  Send OTP to Mobile Number
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToSection("aadhaar-verification")}
                  className="btn btn-link nav-link"
                >
                  Aadhaar Verification
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToSection("save-docs")}
                  className="btn btn-link nav-link"
                >
                  Save Merchant Documents
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => scrollToSection("save-image")}
                  className="btn btn-link nav-link"
                >
                  Save Image Api
                </button>
              </li>
               </ul> */}
                <div id="api-docs pt-5"></div>
                <h3 className={styles.font700}>Onboarding Api Docs</h3>

                <div class="table-responsive mt-4">
                  <table class="table table-bordered table-responsive-sm">
                    <thead>
                      <tr>
                        <th>Method</th>
                        <th>Environment</th>
                        <th>Url</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span class="badge badge-primary">POST</span>{" "}
                        </td>
                        <td class="text-black">Production</td>
                        <td>
                          {" "}
                          <div className="text-black">{`https://api.icepe.in/MerchantPanel/merchant/onboard/saveMerchantDetails`}</div>
                          <div>
                            <CopyButton
                              text={`https://api.icepe.in/MerchantPanel/merchant/onboard/saveMerchantDetails`}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="badge badge-primary">POST</span>{" "}
                        </td>
                        <td class="text-black">uat / staging</td>
                        <td>
                          {" "}
                          <div className="text-black">{`https://api2.lincpay.in/MerchantPanel/merchant/onboard/saveMerchantDetails
`}</div>
                          <div>
                            <CopyButton
                              text={`https://api2.lincpay.in/MerchantPanel/merchant/onboard/saveMerchantDetails
`}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    For Uat testing , you have to use this dummy payload
                  </h4>
                </div>

                <div class="post-details">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Sample Request:
                  </h4>

                  <div className="d-flex gap-4">
                    <pre className={styles.m1}>
                      {JSON.stringify(onboardingJsonParameters, null, 2)}
                    </pre>
                    <CopyButton
                      text={JSON.stringify(onboardingJsonParameters, null, 2)}
                    />
                  </div>
                </div>

                <h4 className={`mb-2 text-black ${styles.font700}`}>
                  Request paramenter description :
                </h4>

                <div class="table-responsive">
                  <table class="table header-border table-responsive-sm border">
                    <thead>
                      <tr>
                        <th style={{ fontWeight: "700" }}>Key</th>
                        <th style={{ fontWeight: "700" }}>Type / Mandatory</th>

                        <th style={{ fontWeight: "700" }}>Description</th>
                        <th style={{ fontWeight: "700" }}>
                          Possible values/Example
                        </th>
                      </tr>
                    </thead>
                    <tbody className={styles.colorblack}>
                      {onboardingTableJson?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.Key}</td>
                            <td>{item?.["Type / Mandatory"]}</td>
                            <td>{item?.Description}</td>
                            <td>{item?.["Possible values/Example"]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    This is Success Response for Uat testing
                  </h4>
                </div>
                <div className="mt-4 d-flex align-items-center gap-2">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Response (success):
                  </h4>
                  <CopyButton
                    text={JSON.stringify(
                      {
                        message: "Merchant Created",
                        status: " New merchant created",
                        statusCode: 200,
                        data: "Mid :- AVDWDJ99889MM",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  />
                </div>

                <pre className="text-black">
                  {JSON.stringify(
                    {
                      message: "Merchant Created",
                      status: " New merchant created",
                      statusCode: 200,
                      data: "Mid :- AVDWDJ99889MM",
                      duplicateDate: null,
                      count: 0,
                    },
                    null,
                    2
                  )}
                </pre>

                <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    This is Failed Response for Uat testing
                  </h4>
                </div>
                <div className="mt-4 d-flex align-items-center gap-2">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Response (failed):
                  </h4>
                  <CopyButton
                    text={JSON.stringify(
                      {
                        message: "Merchant not saved",
                        status: " New merchant created",
                        statusCode: 200,
                        data: "Mid :- AVDWDJ99889MM",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  />
                </div>

                <pre className="text-black">
                  {JSON.stringify(
                    {
                      message: "Merchant not saved",
                      status: " New merchant created",
                      statusCode: 200,
                      data: "Mid :- AVDWDJ99889MM",
                      duplicateDate: null,
                      count: 0,
                    },
                    null,
                    2
                  )}
                </pre>

                <section id="send-otp"   style={{ marginTop: "50px" }}>
                  {/* Send OTP to Mobile Number */}
                  <h3 className={`mb-2 text-black ${styles.font700}`}>
                    Send OTP to Mobile Number
                  </h3>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://api.icepe.in/MerchantPanel/merchant/verify-email/sentotpmobile`}</div>
                            <div>
                              <CopyButton
                                text={`https://api.icepe.in/MerchantPanel/merchant/verify-email/sentotpmobile`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , you have to use this dummy payload
                    </h4>
                  </div>
                  <div className="text-black p2-4">
                    <div className="d-flex gap-4">
                      <h4 className={`font-weight-bold ${styles.font700}`}>
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            mobile: "7748005659",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          mobile: "7748005659",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={styles.colorblack}>
                        <tr>
                          <td>mobile</td>
                          <td>string</td>
                          <td>mobile number</td>
                          <td>7748005659</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Success Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-2 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (success):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "OTP sent successfully",
                          status: "success",
                          statusCode: 200,
                          data: "Done",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "OTP sent successfully",
                        status: "success",
                        statusCode: 200,
                        data: "Done",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Failed Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-2 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (failed):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Failed to send OTP",
                          status: "error",
                          statusCode: 600,
                          data: "null",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "Failed to send OTP",
                        status: "error",
                        statusCode: 600,
                        data: "null",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>
                </section>

                {/* Aadhaar verifcation */}
                <section id="aadhaar-verification">
                  <h3 className={`text-black ${styles.font700}`}>
                    Aadhaar Verification
                  </h3>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://api.icepe.in/MerchantPanel/merchant/ekyc/aadhaarVerification`}</div>
                            <div>
                              <CopyButton
                                text={`https://api.icepe.in/MerchantPanel/merchant/ekyc/aadhaarVerification`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , you have to use this dummy payload
                    </h4>
                  </div>
                  <div className="text-black pt-2">
                    <div className="d-flex gap-4">
                      <h4 className={styles.font700}>Request parameters</h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            aadhaarNumber: "941702676527",
                          },

                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          aadhaarNumber: "941702676527",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={styles.colorblack}>
                        <tr>
                          <td>aadhaarNumber</td>
                          <td>string</td>
                          <td>aadhaarNumber</td>
                          <td>941702676527</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Success Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-2 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (success):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Otp Send successfully",
                          status: "Otp sended",
                          statusCode: 200,
                          data: "string",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "Otp Send successfully",
                        status: "Otp sended",
                        statusCode: 200,
                        data: "string",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Failed Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-2 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (failed):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "invalid aadhar no",
                          status: "please enter correct aadhar no",
                          statusCode: 307,
                          data: "Denied",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "invalid aadhar no",
                        status: "please enter correct aadhar no",
                        statusCode: 307,
                        data: "Denied",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>
                </section>
                {/* /Aadhaar verifcation */}

                {/* save documents*/}
                <section id="save-docs">
                  {/* Save merchant documents */}
                  <h3 className={`text-black mt-5 ${styles.font700}`}>
                    Save Merchant Documents
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">
                              {`https://api.icepe.in/MerchantPanel/merchant/onboard/savemerchantdocuments`}
                            </div>
                            <div>
                              <CopyButton
                                text={`https://api.icepe.in/MerchantPanel/merchant/onboard/savemerchantdocuments`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat Testing , use this dummy payload
                    </h4>
                  </div>
                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className={styles.font700}>Request parameters</h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            fileName: "array[string]",
                            namesmid: "ID of the merchant",
                            pdfFiles: "array[file]",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          fileName: "array[string]",
                          namesmid: "ID of the merchant",
                          pdfFiles: "array[file]",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <h5 className={`mb-2 text-black ${styles.font700}`}>
                    {" "}
                    Request body type :{" "}
                  </h5>
                  <p className="text-black">Content Type: application/json</p>
                  <p className="text-black">
                    Content Type: multipart/form-data
                  </p>

                  <h5 className={`mb-2 text-black ${styles.font700}`}>
                    Example
                  </h5>
                  <p className="text-black">
                    https://api.icepe.in/MerchantPanel/merchant/onboard/savemerchantdocuments?fileName&mid=33
                  </p>

                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={styles.colorblack}>
                        <tr>
                          <td>Array of file </td>
                          <td>array[string]</td>
                          <td>filename</td>
                          <td>Array of file</td>
                        </tr>
                        <tr>
                          <td>namesmid</td>
                          <td>string</td>
                          <td>namesmid</td>
                          <td>ID of the merchant</td>
                        </tr>
                        <tr>
                          <td>pdfFiles</td>
                          <td>string</td>
                          <td>array[file]</td>
                          <td>Array of multipart files containing documents</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Success Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (success):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Document saved",
                          status: "Success",
                          statusCode: 200,
                          data: "Done",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "Document saved",
                        status: "Success",
                        statusCode: 200,
                        data: "Done",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>

                  <div className="mt-5">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      This is Failed Response for Uat testing
                    </h4>
                  </div>
                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Response (failed):
                    </h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Pleasse Upload Pdf file.",
                          status: "BAD_REQUEST",
                          statusCode: 304,
                          data: "null",
                          duplicateDate: null,
                          count: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "Pleasse Upload Pdf file.",
                        status: "BAD_REQUEST",
                        statusCode: 304,
                        data: "null",
                        duplicateDate: null,
                        count: 0,
                      },
                      null,
                      2
                    )}
                  </pre>
                </section>
                {/* /save documents */}

                <section id="save-image">
                  {/* Save image api */}
                  <h3 className={`text-black ${styles.font700}`}>
                    Save Image Api
                  </h3>
                  <p className="text-black">
                    Save merchant Images associated with a specific merchant &
                    their buissness.
                  </p>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">
                              {`https://api.icepe.in/MerchantPanel/merchant/onboard/upload`}
                            </div>
                            <div>
                              <CopyButton
                                text={`https://api.icepe.in/MerchantPanel/merchant/onboard/upload`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className={styles.font700}>Request parameters</h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            mid: "array[string]",
                            latitude: "ID of the merchant",
                            longitude: "array[file]",
                            pdfFiles: "array[file]",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          mid: "array[string]",
                          latitude: "ID of the merchant",
                          longitude: "array[file]",
                          pdfFiles: "array[file]",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <h5 className={`mb-2 text-black ${styles.font700}`}>
                    {" "}
                    Request body type :{" "}
                  </h5>
                  <p className="text-black">Content Type: application/json</p>
                  <p className="text-black">
                    Content Type: multipart/form-data
                  </p>

                  <h5 className={`mb-2 text-black ${styles.font700}`}>
                    Example
                  </h5>
                  <p className="text-black">
                    https://uatmerchant.lincpay.in/MerchantPanel/merchant/onboard/upload?latitude=e&longitude=e&mid=e
                  </p>
                </section>

                {/* /Save image api */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingDoc;
