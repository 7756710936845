import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import swal from "sweetalert";
import { ADD_WALLET_BALANCE_API, MERCHANT_ACCOUNT_DETAIL, SWIPELINC_API } from "../../Utils/Constants";
 
import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../../Utils/DynamicPortalFunctions";
 
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import InputBox from "../../Common/InputBox/InputBox";
import NumberInWords from "../../Common/NumberInWords/NumberInWords";
import { Link } from "react-router-dom";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";
import useApiServices from "../../Utils/useApiService";
import { useSelector } from "react-redux";

// import CheckExportConst from "./CheckexportConst";


/** 
 * this component returns elements to add balance in wallet
 * @returns This component renders the elements such as reusable input boxes for UPI or IMPS
 */

const AddWalletBalance = () => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  const {apiCallFnforPost} =useApiServices()

  // const [virtualAccountType, setVirtualAccountType] = useState("");
  const [transactionType, setTransactionType] = useState("WALLET");
  const [utrNumber, setUtrNumber] = useState("");
  const [date, setDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [hasPayout, setPayout] = useState(false);
  const [payouttype, setPayoutType] = useState();
  const [accountDetails, setAccountDetails] = useState({
    account: "",
    ifsc: "",
  });

  const { mid } = useSelector((state) => state.userData);
  const { bankDeatails } = config(getServerEnvironment(getCompleteDomain()));
  const handleAmount = (e) => {
    const value = e.target.value;
    const numberPattern = /^[1-9]\d*$/; // Updated pattern to disallow 0 and negative numbers

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
      setShowTransactionDetails(value > 100000);
      //   setShowTransactionDetails(value > 100000);
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };
  const handleUtrNumber = (e) => {
    const value = e.target.value;

    setUtrNumber(value);
    //   setShowTransactionDetails(value > 100000);
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    // Create a DateTime object from the input value
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    // Check if the input date is valid (i.e., it was successfully parsed)
    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    // Check if the input date is greater than today's date
    const today = DateTime.now().startOf("day");
   

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setDate(inputDateTime.toISODate()); // Assuming you want to store the date as a string (ISO format)
    }
  };
  const handleTransactionTypeChange = async (type) => {
    setTransactionType(type);

    if (type == "PAYOUT") {
      setPayout(true);
    } else if (type == "WALLET") {
      setPayout(false);
    }

    try {
      setShowLoader(true);
      const response = await apiCallFnforPost(
        MERCHANT_ACCOUNT_DETAIL,
        { accountType: type, mid },
        
      );

      if (response.statusCode === 200) {
        setAccountDetails(response.data);
        setShowLoader(false);
      } else {
        setAccountDetails({
          account: "",
          ifsc: "",
        });
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
      setAccountDetails({
        account: "",
        ifsc: "",
      });
      setShowLoader(false);
    }
  };

  const HandleSubmit = async () => {
    const DataForSubmit = {
      amount: amount,
      utr: utrNumber,
      transactionDate: date,
      mid: mid,
      transferMode: transactionType,
    };
    
    
    // Calculate the grand total of selected rows' amounts
    if (!utrNumber.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (transactionType!=="WALLET" && transactionType!=="IMPS" && transactionType!=="UPI" ) {
      swal({
        title: "Alert",
        text: "Please Select IMPS Or UPI.",
        icon: "error",
      });
      return;
    }
   
    if (!date.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (!transactionType.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (!amount.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }

    if (isSubmitting) {
      // Prevent multiple submissions while one is in progress
      return;
    }

    setIsSubmitting(true);

    try {
      setShowLoader(true);
      const response = await apiCallFnforPost(
        ADD_WALLET_BALANCE_API,
        DataForSubmit
      );

      if (response.statusCode === 309) {
        swal({
          title: "Alert",
          text: response?.message,
          icon: "error",
        });
        setShowLoader(false);

        return;
      }

      if (response?.statusCode === 200) {
        swal({
          title: "Success",
          text: "Your request has been submitted successfully.",
          icon: "success",
        });
        setDate("");
        setUtrNumber("");
        setAmount("");
        setTransactionType("");
        
        setShowLoader(false);
      } else {
        swal({
          title: "Failed",
          text: "Something went Wrong.",
          icon: "error",
        });
        setShowLoader(false);
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: "Something Went Wrong.",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false); // Reset the submission status to false
    }
  };

  const {
    AddWalletBalancePrivilages,
    hasPrivilagesIMPS,
    hasPrivilagesUPI,
    hasPrivilagesWallet,
  } = CheckExportConst();

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3 d-flex flex-md-row justify-content-between align-items-center">
                <h4 class="mb-3 mb-md-0">Add Wallet Balance</h4>
                {/* <h4 class="mb-3 mb-md-0">Add Wallet Balance</h4> */}
                <Link className="text-black" to="/Reports/11">
                  <IconWrapper title={"Go to Wallet Report"}>
                  <i class="fa-solid fa-location-arrow text-dark"></i>
                  </IconWrapper>
                </Link>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="w-50 d-flex flex-column">
                    <div className="w-100 merchant-form-info d-flex justify-content-center mt-5">
                      <Col>
                        <div className="form-group w-75">
                          {/* <div className="input-field-validate"> */}
                          {/* <input
                              type="text"
                              value={amount}
                              onChange={handleAmount}
                              className="form-control w-100"
                            /> */}
                          <InputBox
                            type={"number"}
                            value={amount}
                            onChange={handleAmount}
                            label={"Enter Amount"}
                            required={true}
                            placeholder={"Enter amount"}
                            name={"amount"}
                            NumberInWord={true}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                    </div>

                    <>
                      <Col className="w-75 d-flex justify-content-center">
                        <div className=" mt-3 d-flex flex-column gap-2">
                          <label
                            htmlFor="transactionType"
                            className="form-label mb-1"
                          >
                            Balance Added To Be
                          </label>

                          <div className="w-75 d-flex justify-content-around gap-3">
                            <div className="form-group d-flex gap-3">
                              {hasPrivilagesIMPS || hasPrivilagesUPI ? (
                                <>
                                  <input
                                    type="radio"
                                    name="transactionType"
                                    value="IMPS"
                                    checked={transactionType === "PAYOUT"}
                                    onChange={() =>
                                      handleTransactionTypeChange("PAYOUT")
                                    }
                                  />
                                  <span
                                    className={`${
                                      transactionType === "PAYOUT" ||
                                      "IMPS" ||
                                      "UPI"
                                        ? "fw-bold"
                                        : ""
                                    }`}
                                  >
                                    PAYOUT
                                  </span>
                                </>
                              ) : null}
                            </div>

                            <div className="form-group d-flex gap-3">
                              {hasPrivilagesWallet ? (
                                <>
                                  <input
                                    type="radio"
                                    name="transactionType"
                                    value="IMPS"
                                    checked={transactionType === "WALLET"}
                                    onChange={() =>
                                      handleTransactionTypeChange("WALLET")
                                    }
                                  />{" "}
                                  <span
                                    className={`${
                                      transactionType === "WALLET"
                                        ? "fw-bold"
                                        : ""
                                    }`}
                                  >
                                    WALLET
                                  </span>
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="w-75 d-flex justify-content-around gap-5">
                            <div>
                              {hasPayout ? (
                                <>
                                  <div className="form-group d-flex gap-3">
                                    {hasPrivilagesIMPS ? (
                                      <>
                                        <input
                                          type="radio"
                                          name="transactionType"
                                          value="IMPS"
                                          checked={transactionType === "IMPS"}
                                          onChange={() =>
                                            handleTransactionTypeChange("IMPS")
                                          }
                                        />
                                        <span
                                          className={`${
                                            transactionType === "IMPS"
                                              ? "fw-bold"
                                              : ""
                                          }`}
                                        >
                                          IMPS
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="form-group d-flex gap-3">
                                    {hasPrivilagesUPI ? (
                                      <>
                                        <input
                                          type="radio"
                                          name="transactionType"
                                          value="IMPS"
                                          checked={transactionType === "UPI"}
                                          onChange={() =>
                                            handleTransactionTypeChange("UPI")
                                          }
                                        />
                                        <span
                                          className={`${
                                            transactionType === "UPI"
                                              ? "fw-bold"
                                              : ""
                                          }`}
                                        >
                                          UPI
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div></div>
                          </div>
                        </div>
                      </Col>

                      <div className="py-4">
                        <div className="merchant-form-info d-flex justify-content-center mt-4">
                          <Col>
                            <div className="form-group w-75">
                              <InputBox
                                type={"text"}
                                value={utrNumber}
                                onChange={handleUtrNumber}
                                label={"UTR Number"}
                                required={true}
                                placeholder={"Enter UTR Number"}
                                name={"utrnumber"}
                              />
                              {/* <label htmlFor="amount" className="form-label">
                                UTR Number
                                <sup className="text-danger">*</sup>
                              </label> */}
                              {/* <div className="input-field-validate">
                                <input
                                  type="text"
                                  value={utrNumber}
                                  onChange={handleUtrNumber}
                                  className="form-control"
                                />
                              </div> */}
                            </div>
                          </Col>
                        </div>
                        <div className="merchant-form-info d-flex justify-content-center mt-4">
                          <Col>
                            <div className="form-group w-75">
                              <InputBox
                                type={"date"}
                                value={date}
                                onChange={handleDateInputChange}
                                label={"Transaction Date"}
                                required={true}
                                placeholder={"Enter transaction date"}
                                name={"transactiondate"}
                              />
                              {/* <label htmlFor="amount" className="form-label">
                                Transaction Date
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="date"
                                  value={date}
                                  onChange={handleDateInputChange}
                                  className="form-control"
                                />
                              </div> */}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </>

                    <Button className="w-25 ml-5" onClick={HandleSubmit}>
                      Submit
                    </Button>
                  </div>

                  <div className="w-50">
                    <div className="d-flex justify-content-center mt-4">
                      <div className="w-100  border border-1 py-3 pt-4 rounded-4 shadow-lg">
                        <div className="merchant-form-info d-flex justify-content-center px-5">
                          <Col>
                            {transactionType !== "UPI" &&
                              transactionType !== "IMPS" && (
                                <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                  <label
                                    htmlFor="amount"
                                    className="form-label w-25"
                                  >
                                    Account Beneficiary Name
                                  </label>
                                  <div className="input-field-validate w-75">
                                    <input
                                      type="text"
                                      value={bankDeatails.BName}
                                      readOnly
                                      className="form-control w-100"
                                    />
                                  </div>
                                </div>
                              )}
                          </Col>
                        </div>
                        <div className="merchant-form-info d-flex justify-content-center px-5">
                          <Col>
                            {transactionType !== "UPI" &&
                              transactionType !== "IMPS" && (
                                <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                  <label
                                    htmlFor="amount"
                                    className="form-label w-25"
                                  >
                                    Bank Name
                                    {/* <sup className="text-danger">*</sup> */}
                                  </label>
                                  <div className="input-field-validate w-75">
                                    <input
                                      type="text"
                                      value={bankDeatails.bankName}
                                      // value={amount}
                                      // onChange={handleAmount}
                                      readOnly
                                      className="form-control w-100"
                                    />
                                  </div>
                                </div>
                              )}
                          </Col>
                        </div>
                        <div className="merchant-form-info d-flex justify-content-center px-5">
                          <Col>
                            <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                              <label
                                htmlFor="amount"
                                className="form-label w-25"
                              >
                                Account Number
                                {/* <sup className="text-danger">*</sup> */}
                              </label>
                              <div className="input-field-validate w-75">
                                <input
                                  type="text"
                                  value={
                                    transactionType === "WALLET"
                                      ? bankDeatails.Account
                                      : accountDetails.account
                                  }
                                  // value={amount}
                                  // onChange={handleAmount}
                                  readOnly
                                  className="form-control w-100"
                                />
                              </div>
                            </div>
                          </Col>
                        </div>

                        <div className="merchant-form-info d-flex justify-content-center px-5">
                          <Col>
                            <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                              <label
                                htmlFor="amount"
                                className="form-label w-25"
                              >
                                IFSC Code
                              </label>
                              <div className="input-field-validate w-75">
                                <input
                                  type="text"
                                  value={
                                    transactionType === "WALLET"
                                      ? bankDeatails.ifsc
                                      : accountDetails.ifsc
                                  }
                                  readOnly
                                  className="form-control w-100"
                                />
                              </div>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default AddWalletBalance;
