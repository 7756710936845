import React from "react";
 
import { formatCompactNumber } from "../Utils/helper";
 
import IbtnWrapper from "../Common/CustomToolTip/IButtonWrraper";
import CustomTip from "../Common/CustomToolTip/CustomTip";
import MiniLoader from "../Common/MiniLoader/MiniLoader";

/**
 * Card component renders a card.
 * prepare the card according to the received data.
 * @param {string} name -  title of the card.
 * @param {number} amount - amount which will be shown.
 * @param {string} color -  color of the card.
 * @param {string} image - A image path.
 * @returns {ReactNode} A React element that renders the card.
 */
export default function Card({ name, amount, color, image }) {
  return (
    <>
      <div className="col-xl-3 col-xxl-3 col-sm-6">
        <div className={`card ${color} invoice-card relative`}>
          <div className="card-body d-flex">
            <div className="icon me-3">
              <img
                className="text-white"
                src={image}
                alt=""
                width={27}
                height={30}
              />
 
            </div>
            <div>
              {amount == null || amount == undefined ? (
                <div>
                  <MiniLoader size={15} color={"primary"} />
                </div>
              ) : (
                <CustomTip size={20} title={amount} placement="top">
                  <h2 className="text-white invoice-num ">
                    {formatCompactNumber(amount) ?? 0}
                  </h2>
                </CustomTip>
              )}

              <span className="text-white fs-18">{name}</span>
            </div>
          </div>
          <IbtnWrapper color={"text-white"} desc={name} />
        </div>
      </div>
    </>
  );
}
