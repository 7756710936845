import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
 
import { MY_REFFERELS, SWIPELINC_API } from "../../Utils/Constants";

import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import MyReferralLink from "./MyReferralLink";
import Modal from "../../Common/Modal/Modal";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import { useSelector } from "react-redux";
import useApiServices from "../../Utils/useApiService";


/**
 * This component renders a MyReferral component.
 * @returns {ReactNode} A React element that renders a Myreferral.
 */

const MyReferral = () => {
  const [earnData, setEarnData] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);

  const { mid } = useSelector((state) => state.userData);
  

  const { apiCallFnforPost, apiCallFnforGet } = useApiServices();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCallFnforGet(
          MY_REFFERELS+mid
        );
        setEarnData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [mid]);

  const handlePopUp = () => {
    setOpenPopUp(true);
  };

  const {
    refferelPrivilages,
    hasPrivilagesMYRefferels,
    hasPrivilagesRefferelLink,
    hasPrivilagesInterest,
  } = CheckExportConst();

  return (
    <>
      <div className="content-body" style={{minHeight:"700px"}}>
        <div className="container-fluid">
          {/* <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>My Referral</a>
              </li>
            </ol>
          </div> */}
          <Row  >
            <Col xs={12} lg={12} md={12}>
              <Card className="mb-10 ds-header-card">
                <Card.Header className="d-flex justify-content-between">
                  <Card.Title>My Referrals</Card.Title>
                  <div className="d-flex gap-2">
                    {hasPrivilagesMYRefferels ? (
                      <button
                        className="btn btn-sm btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#myReferralLink"
                      >
                        My Referral Link
                      </button>
                    ) : null}

                    <Modal
                      id={"myReferralLink"}
                      title={"My Referral Link"}
                      onSubmit={";lkjhg"}
                      Width={"md"}
                    >
                      <MyReferralLink />
                    </Modal>
                    {/* <Link to="/Earnings/MyReferralLink">
                      <span className="btn btn-sm  btn-primary">
                        My referral Link
                      </span>
                    </Link> */}
                    {/* <Link to="/Earnings/InterestIncome"> */}

                    {hasPrivilagesInterest ? (
                      <span className="btn btn-sm  btn-primary">
                        Interest Income
                      </span>
                    ) : null}

                    {/* </Link> */}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="filter-filed-area">
                    <div className="merchant-from-info">
                      <Col xs={12} lg={12} md={12}>
                        <div className="Referral_earn">
                          {hasPrivilagesRefferelLink ? (
                            <table className="table custom-table-style">
                              <thead>
                                <tr>
                                  <th>Company name</th>
                                  <th>Email</th>
                                  <th>Commission percentage</th>
                                  <th>Commission Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {earnData?.map((item) => (
                                  <tr key={uuidv4()}>
                                    <td>{item?.companyName}</td>
                                    <td>{item?.email}</td>
                                    <td>
                                      {item?.commissionPercentage
                                        ? `${item.commissionPercentage}%`
                                        : ""}
                                    </td>
                                    <td>{item?.commissionAmt}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : null}
                        </div>
                      </Col>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default MyReferral;
