import React, { useState } from "react";
import styles from "./style.module.css";
import Dropdown from "./DropDown";
import IconWrapper from "../IconWrapper/IconWrapper";


/**
 * This component is flaoting button for Dashboard and reports section..
 *
 * @param {string} setCondition.
 * @param {string} condition.
 * @returns {ReactNode} A React component that renders a floating button.
 */


const FloatingIcon = ({ setCondition, condition }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={styles.flot_cont}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "9999",
        width: isOpen ? "700px" : "100px",
        transition: "width 0.3s ease",
        overflow: "visible",
      }}
    >
      <div
        className="w-auto font-white d-flex justify-content-center align-items-center cursor-pointer bg-secondary rounded rounde-5 py-2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Dropdown
          className="w-100"
          type="DashBoard"
          isOpen={isOpen}
          setCondition={setCondition}
          condition={condition}
        />
      </div>
    </div>
  );
};

export default FloatingIcon;
