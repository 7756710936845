import React, { useState } from "react";
import styles from "./style.module.css";
import Dropdown from "./DropDown";

/**
 * This component renders a floating Reports.
 *
 * @param {string} setCondition.
 * @param {string} setPage.
 * @param {string} setSize.
 * @param {string} condition.
 * @returns {ReactNode} A React element that renders a floating reports.
 */
const FloatingReports = ({ setCondition, setPage, setSize, condition }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={styles.flot_cont}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "9999",
        width: isOpen ? "650px" : "100px",
        transition: "width 0.3s ease",
        overflow: "visible",
      }}
    >
      <div
        className="w-auto font-white d-flex justify-content-center align-items-center cursor-pointer bg-secondary rounded rounde-5 py-2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Dropdown
          className="w-100"
          isOpen={isOpen}
          setPage={setPage}
          setSize={setSize}
          setCondition={setCondition}
          condition={condition}
          type="Report"
        />
      </div>
    </div>
  );
};

export default FloatingReports;
