export const keywords = {
    transferType : "transferType",
    parameter    : "parameter",
    transactionStatus : "transactionStatus",
    chargeBackDate : "chargeBackDate",
    createDate : "createDate",
    searchValue2 : "searchValue2",
    startDate  : "startDate",
    endDate  : "endDate",
    transactionDate : "transactionDate", 
    transactionStartDate : "transactionStartDate",
    transactionEndDate : "transactionEndDate",
    chargeBackDate : "chargeBackDate",
    chargeBackStartDate : "chargeBackStartDate",
    chargeBackEndDate : "chargeBackEndDate",
    createDate : "createDate",
    createStartDate : "createStartDate",
    createEndDate : "createEndDate",
}

export const errorCodes = {
    "U91": "#A3D8FF",
    "U90": "#94FFD8",
    "U89": "#FDFFC2",
    "U86": "#DADD98",
    "U78": "#68D2E8",
    "U68": "#FDDE55",
    "U67": "#D0F0C0",
    "U66": "#F3D0D7",
    "U54": "#F0EBE3",
    "U31": "#CCD3CA",
    "U30": "#B5C0D0",
    "U28": "#FFBE98",
    "U16": "#F7DED0",
    "U09": "#BED1CF",
    "U03": "#7BD3EA",
    "S96": "#AAD7D9",
    "S95": "#F9F7C9",
    "HS3": "#FFBE98",
    "E05": "#E2BFB3",
    "000": "#A3D8FF",
    "U17": "#E6E6FA",
    "U19": "#F8F4E1",
    "U29": "#DADD98",
    "U69": "#ACE1AF",
    "U84": "#FFB6C1",  // Light Pink
    "U80": "#FFA07A",  // Light Salmon
    "U92": "#D3D3D3",  // Light Gray
    "S99": "#AFEEEE",  // Pale Turquoise
    "S97": "#98FB98",  // Pale Green
    "M16": "#FFD700",  // Gold
    "U53": "#FFE4B5",  // Moccasin
    "Success": "#6FCB6F"
}


export const serviceColors = {
    "QR": "#6bbdff",
    "IPG": "#fceab3",
    "AEPS": "#fffa80",
    "DMT": "#ff44a6",
    "PAYOUT": "#3bc7eb",
    "POS": "#ffe637",
    "mATM": "#00a3cc",
    "UPI": "#f68fb0",
    "SOFTPOS": "#e8e1d9",
    "REMITTANCE": "#a5afa5",
    "NET BANKING": "#8e9bb0",
    "e-KYC": "#ff8f4d",
    "VIRTUAL TERMINAL": "#f4c4a8",
    "DPC": "#9abdbb",
    "U03": "#49bde4",
    "S96": "#83cdd2",
    "S95": "#f6f3b2",
    "HS3": "#ff8f4d",
    "E05": "#dca9a1",
    "000": "#6bbdff",
    "Success": "#7effd3"
};

export const errorCodeDescrition = {
    "U91": "Sender bank rejected",
    "U90": "Transaction deemed",
    "U89": "",
    "U86": "",
    "U78": "Beneficiary bank offline",
    "U68": "Credit timeout",
    "U67": "Debit timeout",
    "U66": "Device fingerprint mismatch",
    "U54": "TransactionId or amount in credential block doesn't match ",
    "U31": "Denied by receiver",
    "U30": "Unable to debit",
    "U28": "Unable to connect sender application",
    "U16": "Daily limit exceeded",
    "U09": "Requested timeout for pay",
    "U69": "Collect expired",
    "U03": "Above allowed limit",
    "S96": "",
    "S95": "",
    "HS3": "",
    "E05": "User left transaction incomplete",
    "000": "",
    "Success": "",
};
