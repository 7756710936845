import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import CustomTip from "./CustomTip";



const IbtnWrapper = ({ color,desc }) => {
   
  return (
    <div
      style={{ zIndex: "2" }}
      className="position-absolute bottom-0 end-0 p-2"
    >
      <CustomTip size={10} title={desc} placement="top">
        <i style={{color: color === "text-black" ? "#666362" : "#fafafa"}} class={`fa-solid fa-circle-info fs-4 me-2`}></i>
      </CustomTip>
    </div>
  );
};

export default IbtnWrapper;
