import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FETCH_MERCHANT_PROFILE, GET_ALL_PRIVILAGES, LOG_OUT_API } from "../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../store/Slices/userSlice";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { setAllPrivilagesData } from "../store/Slices/privilagesSlice";
import CheckExportConst from "../Utils/PrivilagesUtilityFun/CheckExportConst";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../Utils/DynamicPortalFunctions";
import useApiServices from "../Utils/useApiService";

/**
 * SideBar component renders SideBar of the application.
 * @description calls the various api's  and renders the sidebar with received data.
 * @returns {ReactNode} A React element that renders SideBar.
 */
export default function SideBar() {
  const [merchantData, setMerchantData] = useState({});
  const [toggle, setToggle] = useState(true);
  const { mid } = useSelector((state) => state.userData);
 
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const { apiCallFnforGet, LogOutAPI } = useApiServices();

  const getProfileDetails = async (mid) => {
    let res = await apiCallFnforGet(FETCH_MERCHANT_PROFILE + mid);
    if (res?.statusCode === 200) {
      dispatch(setProfileData(res?.data));
      setMerchantData(res?.data);
    } else {
      setMerchantData(null);
    }
  };

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        dispatch(setAllPrivilagesData(response?.data));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  const handleLogout = async () => {
   LogOutAPI()
  };

  useEffect(() => {
    getProfileDetails(mid);
  }, []);

  const {
    reportPrivilages,
    ToolsPrivilages,
    PaymentsPrivilages,
    refferelPrivilages,
    SettingPrivilages,
  } = CheckExportConst();

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  const handleHumbergur = () => {
    setToggle(!toggle);
    if (toggle) {
      document.getElementById("main-wrapper").classList.remove("menu-toggle");
      document.getElementById("hamburger").classList.remove("is-active");
    } else {
      document.getElementById("hamburger").classList.add("is-active");
      document.getElementById("main-wrapper").classList.add("menu-toggle");
    }
  };

  return (
    <>
      <div class="nav-header">
        <a   class="brand-logo">
          <img src={logoPath} width="53" height="53px" />
          <h1 width="124px" height="33px" class="brand-title">
            {Name}
          </h1>
        </a>
        <div class="nav-control" onClick={handleHumbergur}>
          <div id="hamburger" class="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
      </div>
      <div class="dlabnav">
        <div class="dlabnav-scroll">
          <ul class="metismenu" id="menu">
            <li class="dropdown header-profile">
              <a
                class="nav-link"
                // href="javascript:void(0);"
                role="button"
                data-bs-toggle="dropdown"
              >
                <img src="/images/profile/pic1.jpg" width="20" alt="" />

                <div class="header-info ms-3 align-items-center">
                  <span class="font-w600 ">
                    Hi, <b>{merchantData?.companyName?.split(" ")[0]}</b>
                  </span>

                  <small class="text-end font-w400">
                    {merchantData?.email?.slice(0, 10)}.....
                  </small>
                </div>
              </a>

              <div class="dropdown-menu dropdown-menu-end">
                <Link to="/Profile" class="dropdown-item ai-icon">
                  <svg
                    id="icon-user1"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-primary"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>

                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>

                  <span class="ms-2">Profile </span>
                </Link>
                <Link
                  to="#"
                  onClick={handleLogout}
                  class="dropdown-item ai-icon"
                >
                  <svg
                    id="icon-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-danger"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>

                  <span class="ms-2">Logout </span>
                </Link>
              </div>
            </li>

            <li>
              <Link to="/Dashboard">
                <i class="fa-solid fa-house"></i>
                <span class="nav-text mt-2">Dashboard</span>
              </Link>
            </li>

            {reportPrivilages ? (
              <li>
                <Link to="/Reports/">
                  <i className="fa-solid fa-chart-bar"></i>
                  <span className="nav-text">Reports</span>
                </Link>
              </li>
            ) : null}

            {PaymentsPrivilages ? (
              <li>
                <Link to="/Payments">
                  <i class="fa-regular fa-credit-card"></i>
                  <span className="nav-text">Payment</span>
                </Link>
              </li>
            ) : null}

            {refferelPrivilages ? (
              <li>
                <Link to={"/Earnings"}>
                  <i class="fa-solid fa-money-bill-1-wave"></i>
                  <span className="nav-text">Earning</span>
                </Link>
              </li>
            ) : null}

            {ToolsPrivilages ? (
              <li>
                <Link to="/Tools/">
                  <i class="fa-solid fa-screwdriver-wrench"></i>
                  <span className="nav-text">Tools</span>
                </Link>
              </li>
            ) : null}

            {SettingPrivilages ? (
              <li>
                <Link to="/Settings/">
                  <i class="fa-solid fa-gear"></i>
                  <span className="nav-text">Settings</span>
                </Link>
              </li>
            ) : null}

            <li>
              <Link class="ai-icon" to="/FAQ's">
                <i class="fa-solid fa-person-circle-question"></i>
                <span class="nav-text">FAQ's</span>{" "}
              </Link>
            </li>

            <li>
              <Link to="/ApiDocs/">
                <i class="fa-solid fa-file-code"></i>
                <span class="nav-text">API's</span>
              </Link>
            </li>
          </ul>

          <div class="copyright">
            <p>
              <strong>Help</strong> Whatsapp :- 6266XXXXXX <br />
            </p>
            <p>
              <strong>{Name}</strong> © 2024 All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
