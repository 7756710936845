import React from "react";
import { useNavigate } from "react-router-dom";

import PayinIcon from "../Assets/images/reportIcons/payin.png";
import PayoutIcon from "../Assets/images/reportIcons/payout.png";
import EKYCIcon from "../Assets/images/reportIcons/e-kyc.png";
import FraudMIcon from "../Assets/images/reportIcons/frm.png";
import OnboardingDoc from "../Assets/images/reportIcons/onb.png";


/**
 * This component renders a Api documentation selection.
 * It displays icons and labels for various API documentation sections.
 * @returns {ReactNode} A React element that renders a selection of api documantation.
 */


const ApiDoc = () => {
  const navigate = useNavigate();

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
            <div class="row text-center text-lg-start">
            <div className={`col-lg-3 col-md-3 col-4 mb-5 `}>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => navigate("/ApiDocs/onboardingDoc")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={OnboardingDoc}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Onboarding
                  </h5>
                </div>
              </div>
              <div className={`col-lg-3 col-md-3 col-4 mb-5`}>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => navigate("/ApiDocs/payinDocs")}
                >
                  <img class="img-fluid img-thumbnail" src={PayinIcon} alt="" />
                  <h5 className="mt-3 text-primary text-md text-sm">Payin</h5>
                </div>
              </div>
              <div className={`col-lg-3 col-md-3 col-4 mb-5 `}>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => navigate("/ApiDocs/payout_docs")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={PayoutIcon}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">payout</h5>
                </div>
              </div>
              <div className={`col-lg-3 col-md-3 col-4 mb-5`}>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => navigate("/ApiDocs/ekycDocs")}
                >
                  <img class="img-fluid img-thumbnail" src={EKYCIcon} alt="" />
                  <h5 className="mt-3 text-primary text-md text-sm">e-kyc</h5>
                </div>
              </div>
              <div className={`col-lg-3 col-md-3 col-4 mb-5 `}>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  onClick={() => navigate("/ApiDocs/frmDocs")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={FraudMIcon}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    fraud management
                  </h5>
                </div>
              </div>
              

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDoc;
