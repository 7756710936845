import React, { useState } from "react";
import CopyButton from "../Common/CopyButton/CopyButton";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  BANK_VERIFICATION,
  COMPANY_AADHAR_OTP_VERIFICATION,
  COMPANY_ADHAAR_VERIFICATION,
  COMPANY_GST_VERIFICATION,
  COMPANY_PAN_VERIFICATION,
  ENCRYPT_CODE,
  PAN_VERIFICATION,
} from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
// import styles from './ekyc.module.css';
import styles from "./ekyc.module.css";




/**
 * This component renders  Api documentation for E-Kyc.
 * It displays Ekyc Api Urls , request parameters, responses, etc.
 * @returns {ReactNode} A React element that renders Ekyc-api documentation.
 */


const Ekyc_docs = () => {
  const { apiCallFnforPostForOpenUrl } = useApiServices();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [aadharResponse, setAadhaarResponse] = useState({});
  const [aadharOtpResponse, setAadhaarOTPResponse] = useState({});
  const [gstResponse, setGSTResponse] = useState({});
  const [bankResponse, setBankesponse] = useState({});
  const [payload, setPayload] = useState({
    panNumber: "",
    apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
    secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
  });

  const [adhaarPayload, setAdhaarPayload] = useState({
    aadhaarNumber: "",
    apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
    secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
  });

  const [adhaarOtpPayload, setAdhaarOtpPayload] = useState({
    refNumber: "",
    otp: "",
    apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
    secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
  });

  const [gstPayload, setGstPayload] = useState({
    gstNumber: "",
    apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
    secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
  });

  const [bankPayload, setBankPayload] = useState({
    accountNumber: "",
    ifsc: "",
    apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
    secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
  });

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handlePANVerification = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(
        COMPANY_PAN_VERIFICATION,
        payload
      );
      console.log("resp::", resp);
      setResponse(resp);
      // setStatus((prev) => ({
      //   ...prev,
      //   code: resp?.statusCode,
      //   value: resp?.status,
      // }));
      // setResponseJson(resp?.data !== "" ? resp?.data : resp?.message);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleClearData = async (key) => {
    if (key === "PAN") {
      setPayload((prev) => ({
        ...prev,
        apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
        secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
        panNumber: "",
      }));
    } else if (key === "AADHAAR") {
      setAdhaarPayload((prev) => ({
        ...prev,
        apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
        secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
        aadhaarNumber: "",
      }));
    } else if (key === "GST") {
      setGstPayload((prev) => ({
        ...prev,
        apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
        secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
        gstNumber: "",
      }));
    } else if (key === "AADHAAROTP") {
      setAdhaarOtpPayload((prev) => ({
        ...prev,
        apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
        secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
        otp: "",
        refNumber: "",
      }));
    }
  };

  const handleClearBankData = () => {
    setBankPayload((prev) => ({
      ...prev,
      apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
      secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
      ifsc: "",
      accountNumber: "",
    }));
  };

  const handleChangeAadhaar = (event) => {
    const { name, value } = event?.target;
    setAdhaarPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleChangeAadhaarOtp = (event) => {
    const { name, value } = event?.target;
    setAdhaarOtpPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleAadhaarVerification = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(
        COMPANY_ADHAAR_VERIFICATION,
        adhaarPayload
      );
      console.log("resp::", resp);
      setAadhaarResponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleAadhaarOTPVerification = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(
        COMPANY_AADHAR_OTP_VERIFICATION,
        adhaarOtpPayload
      );
      console.log("resp::", resp);
      setAadhaarOTPResponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleChangeGST = (event) => {
    const { name, value } = event?.target;
    setGstPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleGstVerification = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(
        COMPANY_GST_VERIFICATION,
        gstPayload
      );
      console.log("resp::", resp);
      setGSTResponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleChangeBank = (event) => {
    const { name, value } = event?.target;
    setBankPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleBankVerification = async () => {
    try {
      setLoading(true);
      const resp = await apiCallFnforPostForOpenUrl(
        BANK_VERIFICATION,
        bankPayload
      );

      setBankesponse(resp);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  return (
    <div className="content-body">
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-xl-8 col-xxl-8" style={{ paddingRight: "1px" }}>
            <div className="card" style={{ borderRadius: "5px" }}>
              <div class="card-body p-3" style={{ overflow: "scroll" }}>
                <h4>e-KYC docs</h4>


                <div>
                    <p className="fs-20 text-black fw-bold m-0">Note : </p>
                    <p className="fs-14 text-black fw-bold m-0">For Uat testing use below apikey and secret key</p>
                    <p className="fs-14 text-black fw-bold m-0">apikey : lincpay_live_testap25IRtaQnWijXFqrSEXZT</p>
                    <p className="fs-14 text-black fw-bold m-0">secrete : secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG</p>
                </div>

                {/* PAN verification */}
                <div className="mt-4">
                  <h4 className="font-weight-bold fw-bold">PAN Verification</h4>
                  <p className="text-black">
                    Provide a smooth onboarding experience to your customers by
                    verifying PANs in real time.
                  </p>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://ekyc.lincpay.in/LincPayKYC/ekyc/validate-pan`}</div>
                            <div>
                              <CopyButton
                                text={`https://ekyc.lincpay.in/LincPayKYC/ekyc/validate-pan`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                          <td>
                            {" "}
                            <div className="text-black">{COMPANY_PAN_VERIFICATION}</div>
                            <div>
                              <CopyButton
                                text={COMPANY_PAN_VERIFICATION}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            panNumber: "ABCDE1234F",
                          apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                          secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          panNumber: "ABCDE1234F",
                          apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                          secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="font-weight-bold fw-bold">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              PAN number
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="panNumber"
                                type="text"
                                value={payload.panNumber}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              apiKey
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="apikey"
                                type="text"
                                value={payload.apikey}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              secrete
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="secrete"
                                type="text"
                                value={payload.secrete}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="font-weight-bold fw-bold">
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>
                          <th style={{ fontWeight: "700" }}>length</th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>PAN Number</td>
                          <td>string</td>
                          <td>10</td>
                          <td>PAN Number</td>
                          <td>XXXPX1234A</td>
                        </tr>
                        <tr>
                          <td>apiKey</td>
                          <td>string</td>
                          <td></td>
                          <td>Public key required to authorize API access</td>
                          <td>key-a89e0a1be44d</td>
                        </tr>
                        <tr>
                          <td>secrete</td>
                          <td>string</td>
                          <td></td>
                          <td>
                            API Secrete key required to authorize API access
                          </td>
                          <td>test-5a8b0b1a</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Verified",
                          status: "Success",
                          statusCode: 200,
                          data: {
                            panNumber: "ABCDE1234F",
                            firstName: "ABC",
                            lastName: "XYZ",
                            name: "ABC XYZ",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        code: 200,
                        timestamp: 1689262297597,
                        transaction_id: "5a8b0b1a-0bc7-4f0f-b3e7-a89e0a1be44d",
                        data: {
                          pan: "AXRPV8945E",
                          first_name: "AMIT",
                          last_name: "JAIN",
                          full_name: "AMIT JAIN",
                          status: "VALID",
                          aadhaar_seeding_status: "Y",
                          category: "Individual",
                          last_updated: "09/10/2021",
                        },
                      },
                      null,
                      2
                    )}
                  </pre>
                </div>

                {/* Aadhaar verification */}
                <div className="mt-4">
                  <h4 className="font-weight-bold fw-bold">
                    AADHAAR Verification
                  </h4>
                  <p className="text-black">
                    Streamline your workflow using Adhaar verification apis.
                  </p>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://ekyc.lincpay.in/LincPayKYC/ekyc/aadhaar/generate-otp`}</div>
                            <div>
                              <CopyButton
                                text={`https://ekyc.lincpay.in/LincPayKYC/ekyc/aadhaar/generate-otp`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                          <td>
                            {" "}
                            <div className="text-black">{COMPANY_ADHAAR_VERIFICATION}</div>
                            <div>
                              <CopyButton
                                text={COMPANY_ADHAAR_VERIFICATION}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            aadhaarNumber: "123456789012",
                            apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                            secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          aadhaarNumber: "123456789012",
                          apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                          secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="font-weight-bold fw-bold">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              AADHAAR number
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="aadhaarNumber"
                                type="text"
                                value={adhaarPayload.aadhaarNumber}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaar}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              apiKey
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="apikey"
                                type="text"
                                value={adhaarPayload.apikey}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaar}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              secrete
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="secrete"
                                type="text"
                                value={adhaarPayload.secrete}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaar}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="font-weight-bold fw-bold">
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>
                          <th style={{ fontWeight: "700" }}>length</th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>AADHAAR Number</td>
                          <td>string</td>
                          <td>12</td>
                          <td>AADHAAR Number</td>
                          <td>537857286039</td>
                        </tr>
                        <tr>
                          <td>apiKey</td>
                          <td>string</td>
                          <td>max(255)</td>
                          <td>Public key required to authorize API access</td>
                          <td>key-a89e0a1be44d</td>
                        </tr>
                        <tr>
                          <td>secrete</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>
                            API Secrete key required to authorize API access
                          </td>
                          <td>test-5a8b0b1a</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Verified",
                          status: "Success",
                          statusCode: 200,
                          data: {
                            panNumber: "ABCDE1234F",
                            firstName: "ABC",
                            lastName: "XYZ",
                            name: "ABC XYZ",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        code: 200,
                        timestamp: 1689262297597,
                        transaction_id: "5a8b0b1a-0bc7-4f0f-b3e7-a89e0a1be44d",
                        data: {
                          pan: "AXRPV8945E",
                          first_name: "AMIT",
                          last_name: "JAIN",
                          full_name: "AMIT JAIN",
                          status: "VALID",
                          aadhaar_seeding_status: "Y",
                          category: "Individual",
                          last_updated: "09/10/2021",
                        },
                      },
                      null,
                      2
                    )}
                  </pre>

                  

                
                </div>

                {/* Aadhaar OTP verification */}
                <div className="mt-4">
                  <h4 className="font-weight-bold fw-bold">
                    AADHAAR OTP Verification
                  </h4>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{COMPANY_AADHAR_OTP_VERIFICATION}</div>
                            <div>
                              <CopyButton
                                text={COMPANY_AADHAR_OTP_VERIFICATION}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://ekyc.lincpay.in/LincPayKYC/ekyc/aadhaar/verify-otp`}</div>
                            <div>
                              <CopyButton
                                text={`https://ekyc.lincpay.in/LincPayKYC/ekyc/aadhaar/verify-otp`}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="text-black mt-4" style={{fontWeight:"bolder"}}>
                    Note: In U8 , there is no facility to send otp on your
                    mobile number <br />
                    Please use following Otp as dummy otp (331456);
                  </p>

                  <div className="mt-4">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div className="text-black pt-2">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            refNumber: "3327315",
                            otp: "331456",
                            apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                            secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          refNumber: "3327315",
                          otp: "331456",
                          apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                          secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black custom-input">
                              refNumber
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="refNumber"
                                type="number"
                                value={adhaarOtpPayload.refNumber}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaarOtp}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              otp
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="otp"
                                type="number"
                                value={adhaarOtpPayload.otp}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaarOtp}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              apiKey
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="apikey"
                                type="text"
                                value={adhaarOtpPayload.apikey}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaarOtp}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              secrete
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="secrete"
                                type="text"
                                value={adhaarOtpPayload.secrete}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeAadhaarOtp}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="font-weight-bold fw-bold">
                    Request paramenter description :
                  </h4>
                  <div class="table-responsive">
                    <table className="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>
                          <th style={{ fontWeight: "700" }}>length</th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>refNumber</td>
                          <td>number</td>
                          <th>{"30(max)"}</th>
                          <td>reference number</td>
                          <td>3331417</td>
                        </tr>
                        <tr>
                          <td>Otp</td>
                          <td>number</td>
                          <td>6</td>
                          <td>One time password</td>
                          <td>331456</td>
                        </tr>
                        <tr>
                          <td>apiKey</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>Public key required to authorize API access</td>
                          <td>key-a89e0a1be44d</td>
                        </tr>
                        <tr>
                          <td>secrete</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>
                            API Secrete key required to authorize API access
                          </td>
                          <td>test-5a8b0b1a</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Verified",
                          status: "Success",
                          statusCode: 200,
                          data: {
                            panNumber: "ABCDE1234F",
                            firstName: "ABC",
                            lastName: "XYZ",
                            name: "ABC XYZ",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        code: 200,
                        timestamp: 1689262297597,
                        transaction_id: "5a8b0b1a-0bc7-4f0f-b3e7-a89e0a1be44d",
                        data: {
                          pan: "AXRPV8945E",
                          first_name: "AMIT",
                          last_name: "JAIN",
                          full_name: "AMIT JAIN",
                          status: "VALID",
                          aadhaar_seeding_status: "Y",
                          category: "Individual",
                          last_updated: "09/10/2021",
                        },
                      },
                      null,
                      2
                    )}
                  </pre>
                </div>

                {/* GST verification */}
                <div className="mt-4">
                  <h4 className="font-weight-bold fw-bold">GST Verification</h4>
                  <p className="text-black">
                    Verify GSTIN in real-time through the GSTN database. Ensure
                    accurate business details <br />
                    associated with the GSTIN making business onboarding a
                    breeze.
                  </p>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://ekyc.lincpay.in/LincPayKYC/ekyc/validate-gstn`}</div>
                            <div>
                              <CopyButton
                                text={`https://ekyc.lincPayKYC/ekyc/validate-gstn`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                          <td>
                            {" "}
                            <div className="text-black">{COMPANY_GST_VERIFICATION}</div>
                            <div>
                              <CopyButton
                                text={COMPANY_GST_VERIFICATION}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            gstNumber: "29ABCDE1234F1Z5",
                            apikey: "key-a89e0a1be44d",
                            secrete: "test-5a8b0b1a",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          gstNumber: "29ABCDE1234F1Z5",
                          apikey: "key-a89e0a1be44d",
                          secrete: "test-5a8b0b1a",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="font-weight-bold fw-bold">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              GST number
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="gstNumber"
                                type="text"
                                value={gstPayload.gstNumber}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeGST}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              apiKey
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="apikey"
                                type="text"
                                value={gstPayload.apikey}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeGST}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              secrete
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="secrete"
                                type="text"
                                value={gstPayload.secrete}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeGST}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="font-weight-bold fw-bold">
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table className="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>
                          <th style={{ fontWeight: "700" }}>length</th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>GST Number</td>
                          <td>string</td>
                          <td>15</td>
                          <td>GST Number</td>
                          <td>23AADCL9984A1ZH</td>
                        </tr>
                        <tr>
                          <td>apiKey</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>Public key required to authorize API access</td>
                          <td>key-a89e0a1be44d</td>
                        </tr>
                        <tr>
                          <td>secrete</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>
                            API Secrete key required to authorize API access
                          </td>
                          <td>test-5a8b0b1a</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Verified",
                          status: "Success",
                          statusCode: 200,
                          data: {
                            gstNumber: "29ABCDE1234F1Z5",
                            city: "Bhopal",
                            name: "ABC XYZ",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        code: 200,
                        timestamp: 1689262297597,
                        transaction_id: "5a8b0b1a-0bc7-4f0f-b3e7-a89e0a1be44d",
                        data: {
                          pan: "AXRPV8945E",
                          first_name: "AMIT",
                          last_name: "JAIN",
                          full_name: "AMIT JAIN",
                          status: "VALID",
                          aadhaar_seeding_status: "Y",
                          category: "Individual",
                          last_updated: "09/10/2021",
                        },
                      },
                      null,
                      2
                    )}
                  </pre>
                </div>

                {/* Bank verification */}
                <div className="mt-4">
                  <h4 className="font-weight-bold fw-bold">
                    Bank Verification
                  </h4>
                  <p className="text-black">
                    Verify Bank account with penny drop and penny less
                    verification APIs. verify bank <br />
                    branch with IFSC details and UPI ID verification.
                  </p>

                  <div class="table-responsive">
                    <table class="table table-bordered table-responsive-sm">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Environment</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Production</td>
                          <td>
                            {" "}
                            <div className="text-black">{`https://ekyc.lincpay.in/LincPayKYC/ekyc/bank/penny-drop`}</div>
                            <div>
                              <CopyButton
                                text={`https://ekyc.lincpay.in/LincPayKYC/ekyc/bank/penny-drop`}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="badge badge-primary">POST</span>{" "}
                          </td>
                          <td class="text-black">Uat / staging</td>
                          <td>
                            {" "}
                            <div className="text-black">{BANK_VERIFICATION}</div>
                            <div>
                              <CopyButton
                                text={BANK_VERIFICATION}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-5">
                  <h4 className={`mb-2 text-black ${styles.font700}`}>
                      For Uat testing , use this dummy payload with same values , given below
                    </h4>
                  </div>

                  <div className="text-black pt-4">
                    <div className="d-flex gap-4">
                      <h4 className="font-weight-bold fw-bold">
                        Request parameters
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            accountNumber: "1234567890",
                            ifsc: "SBIN0123456",
                            apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                            secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          accountNumber: "1234567890",
                          ifsc: "SBIN0123456",
                          apikey: "lincpay_live_testap25IRtaQnWijXFqrSEXZT",
                          secrete: "secret_LincPay_TestQbAsPMjkwaaBAVeeNDsABG",
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>

                  <div className="card-body">
                    <h5 class="font-weight-bold fw-bold">BODY PARAMS:</h5>

                    <div class="basic-list-group">
                      <ul class="list-group">
                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              Account number
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="accountNumber"
                                type="text"
                                value={bankPayload.accountNumber}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeBank}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              ifsc
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="ifsc"
                                type="text"
                                value={bankPayload.ifsc}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeBank}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              apiKey
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="apikey"
                                type="text"
                                value={gstPayload.apikey}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeGST}
                              />
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item p-3">
                          <div class="row d-flex justify-content-between align-items-center">
                            <label class="col-sm-7 col-form-label col-form-label-md text-black">
                              secrete
                            </label>
                            <div class="col-sm-5">
                              <input
                                name="secrete"
                                type="text"
                                value={gstPayload.secrete}
                                className={`form-control form-control-sm text-black ${styles.custominput}`}
                                placeholder=""
                                onChange={handleChangeGST}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h4 class="font-weight-bold fw-bold">
                    Request paramenter description :
                  </h4>

                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm border">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "700" }}>Key</th>
                          <th style={{ fontWeight: "700" }}>
                            Type / Mandatory
                          </th>
                          <th style={{ fontWeight: "700" }}>length</th>

                          <th style={{ fontWeight: "700" }}>Description</th>
                          <th style={{ fontWeight: "700" }}>
                            Possible values/Example
                          </th>
                        </tr>
                      </thead>
                      <tbody className={`${styles.blacktext}`}>
                        <tr>
                          <td>account Number</td>
                          <td>string</td>
                          <td>9-18</td>
                          <td>account Number</td>
                          <td>30XXXXXXX00</td>
                        </tr>

                        <tr>
                          <td>ifsc</td>
                          <td>11</td>
                          <td>string</td>
                          <td>ifsc code of bank account</td>
                          <td>SBINXXXXX31</td>
                        </tr>
                        <tr>
                          <td>apiKey</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>Public key required to authorize API access</td>
                          <td>key-a89e0a1be44d</td>
                        </tr>
                        <tr>
                          <td>secrete</td>
                          <td>string</td>
                          <td>255(max)</td>
                          <td>
                            API Secrete key required to authorize API access
                          </td>
                          <td>test-5a8b0b1a</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 d-flex align-items-center gap-2">
                    <h4 class="font-weight-bold fw-bold">Response:</h4>
                    <CopyButton
                      text={JSON.stringify(
                        {
                          message: "Verified",
                          status: "Success",
                          statusCode: 200,
                          data: {
                            account_exists: true,
                            name_at_bank: "Mr AMIT JAIN",
                            amount_deposited: 1,
                            utr: "319XXXXX0953",
                          },
                          duplicateDate: null,
                          data1: null,
                          totalAmount: null,
                          count: 0,
                          numberOfTransactions: 0,
                        },
                        null,
                        2
                      )}
                    />
                  </div>

                  <pre className="text-black">
                    {JSON.stringify(
                      {
                        message: "Verified",
                        status: "Success",
                        statusCode: 200,
                        data: {
                          account_exists: true,
                          name_at_bank: "Mr AMIT JAIN",
                          amount_deposited: 1,
                          utr: "319XXXXX0953",
                        },
                        duplicateDate: null,
                        data1: null,
                        totalAmount: null,
                        count: 0,
                        numberOfTransactions: 0,
                      },
                      null,
                      2
                    )}
                  </pre>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-xxl-4">
            <div className="card rounded-3">
              <div className="card-body p-3">
                <h4 className="font-weight-bold fw-bold">PAN verification</h4>
                <div style={{ position: "relative" }}>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                    }}
                  >
                    <button
                      onClick={() => handleClearData("PAN")}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handlePANVerification}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Try it"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        panNumber: payload.panNumber,
                        apikey: payload.apikey,
                        secrete: payload.secrete,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-height-300">
                  <div
                    class="d-flex justify-content-between align-item-center pt-2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className="max-width-300px">
                      {response?.status} <span>{response?.statusCode}</span>
                    </p>
                  </div>

                  <div
                    class="card-body pt-2"
                    style={{
                      height: "120px",
                      overflow: "scroll",
                      color: "black",
                    }}
                  >
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(response?.message, null, 2)}
                    </pre>
                  </div>
                  <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(response?.message, null, 2)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body p-2">
                <h4 className="font-weight-bold fw-bold">
                  ADHAAR verification
                </h4>
                <div style={{ position: "relative" }}>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                    }}
                  >
                    <button
                      onClick={() => handleClearData("AADHAAR")}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handleAadhaarVerification}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Try it"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        aadhaarNumber: adhaarPayload.aadhaarNumber,
                        apikey: adhaarPayload.apikey,
                        secrete: adhaarPayload.secrete,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-height-200">
                  <div
                    class="d-flex justify-content-between align-item-center pt-2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className="max-width-300px">
                      {aadharResponse?.status}{" "}
                      <span>{aadharResponse?.statusCode}</span>
                    </p>
                  </div>

                  <div class={`card-body border pt-1 ${styles.card} `}>
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          message: aadharResponse?.message,
                          refNumber: aadharResponse?.data,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>
                  <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(aadharResponse?.data, null, 2)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body p-2">
                <h4 className="font-weight-bold fw-bold">
                  ADHAAR OTP verification
                </h4>
                <div style={{ position: "relative" }}>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                    }}
                  >
                    <button
                      onClick={() => handleClearData("AADHAAROTP")}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handleAadhaarOTPVerification}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Verify Adhar OTP"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        refNumber: adhaarOtpPayload.refNumber,
                        otp: adhaarOtpPayload.otp,
                        apikey: adhaarOtpPayload.apikey,
                        secrete: adhaarOtpPayload.secrete,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-height-300">
                  <div
                    class="d-flex justify-content-between align-item-center pt-2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className="max-width-300px">
                      {aadharOtpResponse?.status}{" "}
                      <span>{aadharOtpResponse?.statusCode}</span>
                    </p>
                  </div>

                  <div
                    class="card-body border pt-1"
                    style={{
                      height: "120px",
                      overflow: "scroll",
                      color: "black",
                    }}
                  >
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          message: aadharOtpResponse?.message,
                          refNumber: aadharOtpResponse?.data,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>
                  <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(aadharOtpResponse?.data, null, 2)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body p-2">
                <h4 className="font-weight-bold fw-bold">GST verification</h4>
                <div style={{ position: "relative" }}>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                    }}
                  >
                    <button
                      onClick={() => handleClearData("GST")}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handleGstVerification}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Try it"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        gstNumber: gstPayload.gstNumber,
                        apikey: adhaarPayload.apikey,
                        secrete: adhaarPayload.secrete,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-height-300">
                  <div
                    class="d-flex justify-content-between align-item-center pt-2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className="max-width-300px">
                      {gstResponse?.status}{" "}
                      <span>{gstResponse?.statusCode}</span>
                    </p>
                  </div>

                  <div class={`card-body border pt-1 ${styles.card}`}>
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(gstResponse?.message, null, 2)}
                    </pre>
                  </div>
                  {/* <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(response?.data, null, 2)}
                    />
                  </div> */}
                </div>
              </div>

              <div className="card-body p-2">
                <h4 className="font-weight-bold fw-bold">Bank verification</h4>
                <div style={{ position: "relative" }}>
                  <div
                    className={`d-flex gap-2 align-items-center ${styles.absolute} `}
                  >
                    <button
                      onClick={handleClearBankData}
                      className={`${styles.custombutton}`}
                    >
                      clear
                    </button>
                    <button
                      onClick={handleBankVerification}
                      className={`${styles.custombutton}`}
                    >
                      {loading ? <MiniLoader /> : "Try it"}
                    </button>
                  </div>

                  <SyntaxHighlighter
                    style={dark}
                    language="javascript"
                    showLineNumbers
                  >
                    {JSON.stringify(
                      {
                        accountNumber: bankPayload.accountNumber,
                        ifsc: bankPayload.ifsc,
                        apikey: bankPayload.apikey,
                        secrete: bankPayload.secrete,
                      },
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
                <div className="m-2 border rounded-2 min-height-300">
                  <div
                    class="d-flex justify-content-between align-item-center pt-2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <p className="text-black fs-8">Response</p>
                    <p className="max-width-300px">
                      {bankResponse?.status}{" "}
                      <span>{bankResponse?.statusCode}</span>
                    </p>
                  </div>

                  <div class={`card-body border pt-1 ${styles.card} `}>
                    <pre class="post-meta d-flex flex-wrap">
                      {JSON.stringify(bankResponse?.message, null, 2)}
                    </pre>
                  </div>
                  {/* <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
                    <CopyButton
                      color={"danger"}
                      text={JSON.stringify(response?.data, null, 2)}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ekyc_docs;
