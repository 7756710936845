import { createSlice } from "@reduxjs/toolkit";

/**
 * Slice for managing Privilages state.
 * Contains initial state and reducers to update the state.
 */
const privilagesSlice = createSlice({
  name: "privilages",
  initialState: {
    privilagesData: [],
    allPrivilagesData: [],
    userType: "",
  },
  reducers: {
    
    /**
     * Update the registered ID.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action containing the payload with the new registered ID.
     */
    setPrivilagesData: (state, action) => {
      state.privilagesData = action.payload;
    },
    setAllPrivilagesData: (state, action) => {
      state.allPrivilagesData = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    removePrivilagesData: (state) => {
      state.privilagesData = [];
    },
  },
});

/**
 * Action creators generated from the slice.
 */
export const {
  setPrivilagesData,
  removePrivilagesData,
  setAllPrivilagesData,
  setUserType,
} = privilagesSlice.actions;

export default privilagesSlice.reducer;
