import React, { useState } from "react";
import VirtualTerminal from "./VirtualTerminal";
import QR from "./QR";
import LinkBasedPayments from "./LinkBasedPayments";
import TapNPay from "./TapNPay";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import swal from "sweetalert";

const Payments = () => {
  const [codeImge, setCodeImage] = useState(null);
  const Type = localStorage.getItem("typeOfServices");
  const paymentType = Type ? JSON.parse(Type) : null;

  const downloadQRCode = () => {
    const link = document.createElement("a");
    link.href = codeImge;
    link.download = "qr_code.png";
    link.click();
  };

  const shareQRCode = async () => {
    if (navigator.share && codeImge) {
      try {
        const response = await fetch(codeImge);
        const blob = await response.blob();
        const file = new File([blob], "qr-code.png", { type: "image/png" });

        await navigator.share({
          files: [file],
          title: "QR Code",
          text: "Here is the QR code you requested.",
        });
      } catch (error) {
        console.error("Sharing failed:", error);
        swal("Error", "Failed to share the QR code", "error");
      }
    } else {
      swal("Error", "Web Share API is not supported in this browser", "error");
    }
  };

  const {
    hasPrivilagesTandP,
    hasPrivilagesLBP,
    hasPrivilagesQR,
    hasPrivilagesVT,
  } = CheckExportConst();

  return (
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          {hasPrivilagesQR ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("QR") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Static QR</h5>
                      <div>
                        <IconWrapper title={"Download QR"}>
                          <i
                            class="fa-solid fa-download"
                            onClick={downloadQRCode}
                          ></i>
                        </IconWrapper>
                        <IconWrapper title={"Share QR"}>
                        <i class="fa-solid fa-share-nodes" onClick={shareQRCode}></i>
                         
                        </IconWrapper>
                      </div>
                    </div>
                    <div class="card-body">
                      <QR setCodeImage={setCodeImage} />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Static QR</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesVT ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("VIRTUAL TERMINAL") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Virtual Terminal</h5>
                    </div>
                    <div class="card-body">
                      <VirtualTerminal />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Virtual Terminal</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesLBP ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("DPC") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Link Based Payments</h5>
                    </div>
                    <div class="card-body">
                      <LinkBasedPayments />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Link Based Payments</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesTandP ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {2 ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Tap And Pay</h5>
                    </div>
                    <div class="card-body">
                      <TapNPay />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Tap And Pay</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Payments;
