import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
 
import {
  updateCompanyRegisteredAddress,
  updateCompanyAddressPincode,
  updateCompanyState,
  updateCompanyCity,
  updateCommunicationAddress,
  updateCommunicationAddressPincode,
  updateCommunicationAddressState,
  updateCommunicationAddressCity,
  updateAuthorizedAddress,
  updateAuthorizedAddressPincode,
  updateAuthorizedAddressState,
  updateAuthorizedAddressCity,
  updateCompanyRegisteredDistrict,
  updateCommunicationAddressDistrict,
  updateAuthorizedAddressDistrict,
} from "../store/Slices/onBoardSlice";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {
  getKeyPressed,
  stopAlphabetInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../Utils/validations";
import {
  FETCH_ALL_PAYMENT_TYPES,
  MERCHANT_ONBOARD_UPDATE,
  SWIPELINC_API,
} from "../Utils/Constants";
 
import useApiServices from "../Utils/useApiService";
const initialState = {
  companyRegisteredAddress: "",
  companyRegisteredPincode: "",
  companyRegisteredState: "",
  companyRegisteredCity: "",
  companyRegisteredDistrict: "",
  isCommunicationAddSameAsRegistered: false,
  principleCommunicationAddress: "",
  principleCommunicationPincode: "",
  principleCommunicationState: "",
  principlCommunicationeCity: "",
  principlCommunicationeDistrict: "",
  isAuthorizedSignatoryAddSameAsComm: false,
  authorizedSignatoryAddress: "",
  authorizedSignatoryPincode: "",
  authorizedSignatoryState: "",
  authorizedSignatoryCity: "",
  authorizedSignatoryDistrict: "",
  typeOfServices: [],
  selectedPaymentTypeIds: [],
  fetchedRegisteredAddPinCodeValues: "",
  fetchedCommunicationAddPinCodeValues: "",
  fetchedAuthorizedAddPinCodeValues: "",
  // typeOfServices: "",
};

/**
 * CompanyAddForm component renders Company Address Form of the onboarding process.
 * @returns {ReactNode} A React element that renders Company Address Form of the onboarding process.
 */
export default function CompanyAddForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pressedKeyName, setPressedKeyName] = useState("");
  const { apiCallFnforGetOpenUrl, apiCallFnforPutForOpenUrl } =
    useApiServices();

  if (!localStorage.getItem("registrationId")) {
    navigate("/MerchantOnBoard");
  }
 

  const {
    otherBusinessCategory,
    businessCategory,
    businessAs,
    panCardNumberVerifyAf,
    panCardNumberAf,
    authorizedPersonNameAf,
    companyName,
    appUrl,
    districtAf,
    stateAf,
    pincodeAf,
    cityAf,
    companyNameAf,
    gstVerified,
    gstNumber,
    businessType,
    companyPanNumberVerified,
    companyPanNumber,
    website,
    firstName,
    lastName,
    aadharNumberAf,
    aadharNumberVerifyAf,
    companyRegisteredAddress,
    companyAddressPincode,
    companyState,
    companyCity,
    companyDistrict,
    communicationAddress,
    communicationAddressPincode,
    communicationAddressState,
    communicationAddressCity,
    communicationAddressDistrict,
    authorizedAddress,
    authorizedAddressPincode,
    authorizedAddressState,
    authorizedAddressCity,
    merchantType,
    authorizedAddressDistrict,
    registrationTypeId,
    businessTypeId,
    incorporationDate,
    panCardHolderDOB
  } = useSelector((state) => state.onBoard);

  useEffect(() => {
    fetchPaymentTypes();
  }, []);

  const getInitialValues = () => {
    const isReturning = localStorage.getItem("isReturning");

    if (isReturning) {
      return {
        ...initialState,
        companyRegisteredAddress: companyRegisteredAddress,
        companyRegisteredPincode: companyAddressPincode,
        companyRegisteredCity: companyCity,
        companyRegisteredDistrict: companyDistrict,
        companyRegisteredState: companyState,
        principleCommunicationAddress: communicationAddress,
        principleCommunicationPincode: communicationAddressPincode,
        principlCommunicationeCity: communicationAddressCity,
        principleCommunicationState: communicationAddressState,
        principlCommunicationeDistrict: communicationAddressDistrict,
        authorizedSignatoryAddress: authorizedAddress,
        authorizedSignatoryPincode: authorizedAddressPincode,
        authorizedSignatoryState: authorizedAddressState,
        authorizedSignatoryCity: authorizedAddressCity,
        authorizedSignatoryDistrict: authorizedAddressDistrict,
      };
    } else {
      return initialState;
    }
  };

  const [formData, setFormData] = useState(getInitialValues);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState();
  const [fetchedRegisteredAddPinCodes, setFetchedRegisteredAddPinCodes] =
    useState([]);
  const [fetchedCommunicationAddPinCodes, setFetchedCommunicationAddPinCodes] =
    useState([]);
  const [fetchedAuthorizedAddPinCodes, setFetchedAuthorizedAddPinCodes] =
    useState([]);

  const [paymentTypes, setPaymentTypes] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const setFetchedPinCodesValue = (value, name) => {
    // setFetchedPinCodes()
    // alert("Fetching Pin Code Values")
    // alert(value + ":" + name);
    if (name === "fetchedRegisteredAddPinCodeValues") {
      let data = fetchedRegisteredAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      // console.log("data", data);
      if (data && data.length > 0) {
        setFormData({
          ...formData,
          companyRegisteredState: data[0]?.stateName,
          companyRegisteredCity: data[0]?.cityName,
          companyRegisteredDistrict: data[0]?.areaName,
          fetchedRegisteredAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          companyRegisteredState: "",
          companyRegisteredCity: "",
          companyRegisteredDistrict: "",
          fetchedRegisteredAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedCommunicationAddPinCodeValues") {
      let data = fetchedCommunicationAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      if (data && data.length > 0) {
        setFormData({
          ...formData,
          principleCommunicationState: data[0]?.stateName,
          principlCommunicationeCity: data[0]?.cityName,
          principlCommunicationeDistrict: data[0]?.areaName,
          fetchedCommunicationAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          principleCommunicationState: "",
          principlCommunicationeCity: "",
          principlCommunicationeDistrict: "",
          fetchedCommunicationAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedAuthorizedAddPinCodeValues") {
      let data = fetchedAuthorizedAddPinCodes.filter(
        (elem) => elem.areaName === value
      );

      if (data && data.length > 0) {
        setFormData({
          ...formData,
          authorizedSignatoryState: data[0]?.stateName,
          authorizedSignatoryCity: data[0]?.cityName,
          authorizedSignatoryDistrict: data[0]?.areaName,
          fetchedAuthorizedAddPinCodeValues: value,
        });
      } else {
        setFormData({
          ...formData,
          authorizedSignatoryState: "",
          authorizedSignatoryCity: "",
          authorizedSignatoryDistrict: "",
          fetchedAuthorizedAddPinCodeValues: "",
        });
      }
    }
  };
  const handelSameCommunicationAddress = () => {
    if (formData.isCommunicationAddSameAsRegistered === false) {
      setFormData({
        ...formData,
        principleCommunicationAddress: formData.companyRegisteredAddress,
        principleCommunicationPincode: formData.companyRegisteredPincode,
        principleCommunicationState: formData.companyRegisteredState,
        principlCommunicationeCity: formData.companyRegisteredCity,
        principlCommunicationeDistrict: formData.companyRegisteredDistrict,
      });
    }

    if (formData.isCommunicationAddSameAsRegistered === true) {
      setFormData({
        ...formData,
        principleCommunicationAddress: "",
        principleCommunicationPincode: "",
        principleCommunicationState: "",
        principlCommunicationeCity: "",
        principlCommunicationeDistrict: "",
      });
    }
  };
  const handelSameAuthorityAddress = () => {
    if (formData.isAuthorizedSignatoryAddSameAsComm === false) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: formData.principleCommunicationAddress,
        authorizedSignatoryPincode: formData.principleCommunicationPincode,
        authorizedSignatoryState: formData.principleCommunicationState,
        authorizedSignatoryCity: formData.principlCommunicationeCity,
        authorizedSignatoryDistrict: formData.principlCommunicationeDistrict,
      });
    }

    if (formData.isAuthorizedSignatoryAddSameAsComm === true) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: "",
        authorizedSignatoryPincode: "",
        authorizedSignatoryState: "",
        authorizedSignatoryCity: "",
        authorizedSignatoryDistrict: "",
      });
    }
  };
  const fetchCityStateByPincode = async (pincode, name) => {
    try {
      
      const response = await apiCallFnforGetOpenUrl(
        `${SWIPELINC_API}MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
      );
      const data = response;
      console.log(response)
     
      if (name === "companyRegisteredPincode") {
        setFetchedRegisteredAddPinCodes(data);
      } else if (name === "principleCommunicationPincode") {
        setFetchedCommunicationAddPinCodes(data);
      } else if (name === "authorizedSignatoryPincode") {
        setFetchedAuthorizedAddPinCodes(data);
      }

      // if (data) {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     companyRegisteredState: data[0].stateName,
      //     companyRegisteredCity: data[0].cityName,
      //     companyRegisteredDistrict: data[0].districtName,
      //   }));
      // } else {
      //   setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     companyRegisteredState: "",
      //     companyRegisteredCity: "",
      //     companyRegisteredDistrict: "",
      //   }));
      // }
    } catch (error) {
      // console.log("Error fetching city and state:", error);
    }
  };
  const fetchPaymentTypes = async () => {
    try {
      const response = await apiCallFnforGetOpenUrl(FETCH_ALL_PAYMENT_TYPES);
      const data = response;

      setPaymentTypes(data);
    } catch (error) {}
  };
  const handleTypeOfServicesChange = (event) => {
    const { value, checked } = event.target;
    let updatedTypeOfServices;

    if (checked) {
      // Add the selected value to the array
      updatedTypeOfServices = [...formData.typeOfServices, value];
    } else {
      // Remove the unselected value from the array
      updatedTypeOfServices = formData.typeOfServices?.filter(
        (service) => service !== value
      );
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      typeOfServices: updatedTypeOfServices,
    }));

    const selectedPaymentTypeIds = paymentTypes
      .filter((paymentType) =>
        updatedTypeOfServices.includes(paymentType.paymentservice)
      )
      .map((selectedPaymentType) => selectedPaymentType.paymentTypeId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedPaymentTypeIds,
    }));
  };
  const handleBackBtn = () => {
    dispatch(updateCompanyRegisteredAddress(formData.companyRegisteredAddress));
    dispatch(updateCompanyAddressPincode(formData.companyRegisteredPincode));
    dispatch(updateCompanyState(formData.companyRegisteredState));
    dispatch(updateCompanyCity(formData.companyRegisteredCity));
    dispatch(
      updateCompanyRegisteredDistrict(formData.companyRegisteredDistrict)
    );
    dispatch(
      updateCommunicationAddress(formData.principleCommunicationAddress)
    );
    dispatch(
      updateCommunicationAddressPincode(formData.principleCommunicationPincode)
    );
    dispatch(
      updateCommunicationAddressState(formData.principleCommunicationState)
    );
    dispatch(
      updateCommunicationAddressCity(formData.principlCommunicationeCity)
    );
    dispatch(
      updateCommunicationAddressDistrict(
        formData.principlCommunicationeDistrict
      )
    );
    dispatch(updateAuthorizedAddress(formData.authorizedSignatoryAddress));
    dispatch(
      updateAuthorizedAddressPincode(formData.authorizedSignatoryPincode)
    );
    dispatch(updateAuthorizedAddressState(formData.authorizedSignatoryState));
    dispatch(updateAuthorizedAddressCity(formData.authorizedSignatoryCity));
    dispatch(
      updateAuthorizedAddressDistrict(formData.authorizedSignatoryDistrict)
    );
    navigate("/AdharForm");
    localStorage.setItem("isReturning", true);
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    if (
      (name === "companyRegisteredAddress" &&
        formData.companyRegisteredAddress.length + pastedText.length >
          addressFieldLength) ||
      (name === "authorizedSignatoryAddress" &&
        formData.authorizedSignatoryAddress.length + pastedText.length >
          addressFieldLength) ||
      (name === "principleCommunicationAddress" &&
        formData.principleCommunicationAddress.length + pastedText.length >
          addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredDistrict" &&
        formData.companyRegisteredDistrict.length + pastedText.length >
          districtLength) ||
      (name === "principlCommunicationeDistrict" &&
        formData.principlCommunicationeDistrict.length + pastedText.length >
          districtLength) ||
      (name === "authorizedSignatoryDistrict" &&
        formData.authorizedSignatoryDistrict.length + pastedText.length >
          districtLength)
    ) {
      swal({
        title: "Alert!",
        text: `Locatity must be lesser than ${districtLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredState" &&
        formData.companyRegisteredState.length + pastedText.length >
          StateLength) ||
      (name === "principleCommunicationState" &&
        formData.principleCommunicationState.length + pastedText.length >
          StateLength) ||
      (name === "authorizedSignatoryState" &&
        formData.authorizedSignatoryState.length + pastedText.length >
          StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredCity" && pastedText.length > cityLength) ||
      (name === "principlCommunicationeCity" &&
        pastedText.length > cityLength) ||
      (name === "authorizedSignatoryCity" && pastedText.length > cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };
  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (
      pressedKeyName === "Backspace" &&
      (name === "companyRegisteredAddress" ||
        name === "companyRegisteredPincode" ||
        name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principleCommunicationAddress" ||
        name === "principleCommunicationPincode" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryAddress" ||
        name === "authorizedSignatoryPincode" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    ) {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    // stop alphabets and special char input in pincode
    if (
      (stopAlphabetInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredPincode" ||
        name === "principleCommunicationPincode" ||
        name === "authorizedSignatoryPincode")
    ) {
      return;
    }

    // stop special char input and number input and allow alphabets INPUT in
    // LOCALITY - STATE - CITY
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    // handel VALIDATION ABOUT LENGTH CONSTRAINTS
    if (
      (formData.companyRegisteredAddress.length >= addressFieldLength &&
        name === "companyRegisteredAddress") ||
      (formData.principleCommunicationAddress.length >= addressFieldLength &&
        name === "principleCommunicationAddress") ||
      (formData.authorizedSignatoryAddress.length >= addressFieldLength &&
        name === "authorizedSignatoryAddress") ||
      (formData.companyRegisteredPincode.length >= pincodeFieldLength &&
        name === "companyRegisteredPincode") ||
      (formData.principleCommunicationPincode.length >= pincodeFieldLength &&
        name === "principleCommunicationPincode") ||
      (formData.authorizedSignatoryPincode.length >= pincodeFieldLength &&
        name === "authorizedSignatoryPincode") ||
      (formData.companyRegisteredDistrict.length >= districtLength &&
        name === "companyRegisteredDistrict") ||
      (formData.principlCommunicationeDistrict.length >= districtLength &&
        name === "principlCommunicationeDistrict") ||
      (formData.authorizedSignatoryDistrict.length >= districtLength &&
        name === "authorizedSignatoryDistrict") ||
      (formData.companyRegisteredState.length >= StateLength &&
        name === "companyRegisteredState") ||
      (formData.principleCommunicationState.length >= StateLength &&
        name === "principleCommunicationState") ||
      (formData.authorizedSignatoryState.length >= StateLength &&
        name === "authorizedSignatoryState") ||
      (formData.companyRegisteredCity.length >= cityLength &&
        name === "companyRegisteredCity") ||
      (formData.principlCommunicationeCity.length >= cityLength &&
        name === "principlCommunicationeCity") ||
      (formData.authorizedSignatoryCity.length >= cityLength &&
        name === "authorizedSignatoryCity")
    ) {
      return;
    }

    if (name === "typeOfServices") {
      // Get the selected payment type ID from the selected option
      const selectedPaymentType = paymentTypes.find(
        (paymentType) => paymentType.paymentservice === fieldValue
      );
      if (selectedPaymentType) {
        setSelectedPaymentTypeId(selectedPaymentType.paymentTypeId);
      }
    }

    if (
      name === "isCommunicationAddSameAsRegistered" ||
      name === "isAuthorizedSignatoryAddSameAsComm" ||
      name === "hasReferralCode"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: fieldValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: fieldValue?.toUpperCase(),
      }));
    }

    if (name === "companyRegisteredPincode") {
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      }
    }

    if (name === "principleCommunicationPincode") {
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      } else {
      }
    }

    if (name === "authorizedSignatoryPincode") {
      // alert("fetch 3 pin")
      if (value.length === 6) {
        fetchCityStateByPincode(value, name);
      } else {
      }
    }
    if (
      name === "fetchedCommunicationAddPinCodeValues" ||
      name === "fetchedRegisteredAddPinCodeValues" ||
      name === "fetchedAuthorizedAddPinCodeValues"
    ) {
      setFetchedPinCodesValue(e.target.value, name);
    }
  };

  const checkLengthValidation = () => {};

  const handelFormSubmit = async (e) => {
    e.preventDefault();
 
    if (formData.companyRegisteredAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Regisered Address Pincode *",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter State*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter City*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationAddress === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Communication Address*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address Pincode*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeDistrict === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication State*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication City*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory Address*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory  PinCode*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Authorized Signatory Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory State*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory City*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode.length > 6) {
      swal({
        title: "Alert!",
        text: "Company Registered Pincode must be lesser than 6 characters",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredAddress.length > 200) {
      swal({
        title: "Alert!",
        text: "Company Registered Address must be lesser than 200 characters",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredCity.length > 50) {
      swal({
        title: "Alert!",
        text: "Company Registered City must be lesser than 50 characters",
        icon: "error",
      });
      return;
    }

    if (formData.typeOfServices.length === 0) {
      swal({
        title: "Alert!",
        text: "Please Choose Services You Want !",
        icon: "error",
      });
      return;
    }

    const payload = {
      aadhaarNumber: aadharNumberAf,
      typesOfMerchant: merchantType,
      aadhaarVerified: aadharNumberVerifyAf,
      authorizedPersonFirstName: firstName,
      authorizedPersonLastName: lastName,
      authorizedPersonName: firstName + " " + lastName,
      authorizedPersonPan: panCardNumberAf,
      authorizedPersonPanVerified: panCardNumberVerifyAf,
      businessAs: businessAs,
      businessTypeId: businessCategory,
      dateOfIncorporation: incorporationDate,
      dateOfBirth: panCardHolderDOB,
      businessTypeName: otherBusinessCategory,
      communicationAddress: formData.principleCommunicationAddress,
      communicationAddressCity: formData.principlCommunicationeCity,
      communicationAddressAreaName: formData.principlCommunicationeDistrict,
      communicationAddressPinCode: formData.principleCommunicationPincode,
      communicationAddressState: formData.principleCommunicationState,
      companyAddress: formData.companyRegisteredAddress,
      companyAddressCity: formData.companyRegisteredCity,
      companyAddressAreaName: formData.companyRegisteredDistrict,
      companyAddressPinCode: formData.companyRegisteredPincode,
      companyAddressState: formData.companyRegisteredState,
      aapUrl: appUrl,
      companyName: companyName,
      companyPanNumber: companyPanNumber,
      companyPanVerified: companyPanNumberVerified,
      companyTypeId: businessType,
      gstNumber: gstNumber,
      gstVerified: gstVerified,
      // paymentTypeId: selectedPaymentTypeIdArray,
      paymentTypeId: formData.selectedPaymentTypeIds,
      newCompanyAddress: companyNameAf,
      referralNo: formData.hasReferralCode ? formData.referralNo : "",
      registeredOfficeAddress: formData.authorizedSignatoryAddress,
      registeredOfficeAddressCity: formData.authorizedSignatoryCity,
      registeredOfficeAddressPinCode: formData.authorizedSignatoryPincode,
      registeredOfficeAddressState: formData.authorizedSignatoryState,
      registeredOfficeAddressAreaName: formData.authorizedSignatoryDistrict,
      registrationId: localStorage.getItem("registrationId"),

      websiteUrl: website,

      registrationTypeId: registrationTypeId,
    };
    try {
      // Make the API request
      setShowLoader(true);
      const response = await apiCallFnforPutForOpenUrl(
        MERCHANT_ONBOARD_UPDATE,
        payload
      );
      setShowLoader(false);
      if (response.statusCode === 200) {
        // Clear form values
        setFormData(initialState);
        localStorage.removeItem("isReturning");
        setIsFormSubmitted(true);
        // setShowPopup(true);
        localStorage.removeItem("AadharForm");
        localStorage.removeItem("MerchantInfoForm");
        localStorage.removeItem("returningUser");
        sessionStorage.removeItem("returningUser");
        localStorage.removeItem("registrationId");
        swal({
          title: "Success!",
          text: "Your Application has been submitted Successfully !",
          icon: "success",
        }).then(function () {
          window.location = "https://swipelinc.com/";
        });
      } else if (response.statusCode === 600) {
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.message,
          icon: "error",
        });
      } else {
        // alert("helo")
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.message,
          icon: "error",
        });
      }
    } catch (error) {
      // console.log("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="container-fluid px-5 py-5">
        {/* <div className="row page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)">Form</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="javascript:void(0)">Wizard</a>
            </li>
          </ol>
        </div> */}

        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
              </div>
              <div className="card-body py-4">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Company Registered Address
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyRegisteredAddress"
                            className="form-control"
                            value={formData.companyRegisteredAddress}
                            onPaste={(e) =>
                              handelPasteEvent(e, "companyRegisteredAddress")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={handleInputChange}
                            required
                          />
                          <div className="d-flex justify-content-end">
                            <span className="text-danger">
                              {`Remaining Characters ${
                                200 - formData.companyRegisteredAddress.length
                              } / 200`}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Pincode
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyRegisteredPincode"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            className="form-control"
                            value={formData.companyRegisteredPincode}
                            onChange={handleInputChange}
                            required
                            pattern="\d{6}"
                          />
                        </div>
                      </div>
                     {fetchedRegisteredAddPinCodes &&
                      fetchedRegisteredAddPinCodes.length > 0 ? (
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Please Select Your Locality
                              <span className="required">*</span>
                            </label>
                            <select
                              // id="businessType"
                              
                              name="fetchedRegisteredAddPinCodeValues"
                              className="form-control"
                              value={formData.fetchedRegisteredAddPinCodeValues}
                              onChange={handleInputChange}
                            >
                              <option value="">
                                Select
                                <span className="star">*</span>
                              </option>
                             {fetchedRegisteredAddPinCodes?.map(
                                (type, index) => (
                                  <option
                                    key={type.areaName}
                                    value={type.areaName}
                                  >
                                    {type.areaName}
                                  </option>
                                )
                              )}  

                            </select>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}  
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Locality
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyRegisteredDistrict"
                            className="form-control"
                            value={formData.companyRegisteredDistrict}
                            onPaste={(e) =>
                              handelPasteEvent(e, "companyRegisteredDistrict")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            State
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyRegisteredState"
                            value={formData.companyRegisteredState}
                            onPaste={(e) =>
                              handelPasteEvent(e, "companyRegisteredState")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={handleInputChange}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            City
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="principlCommunicationeCity"
                            className="form-control"
                            id="companyForm2"
                            value={formData.principlCommunicationeCity}
                            onPaste={(e) =>
                              handelPasteEvent(e, "principlCommunicationeCity")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                        </div>
                      </div>

                      <Col xs={12} lg={12} md={12}>
                        <div className="form-group">
                          <div className="mb-4 d-flex align-items-center gap-2 ">
                            <input
                              type="checkbox"
                              id="registeredAddressToggle"
                              className="form-check-input"
                              c
                              name="isCommunicationAddSameAsRegistered"
                              checked={
                                formData.isCommunicationAddSameAsRegistered
                              }
                              onChange={handleInputChange}
                              onClick={handelSameCommunicationAddress}
                            />
                            <label
                              className="mt-2 fs-4 mb-1 text-dark"
                              htmlFor="registeredAddressToggle"
                            >
                              Same as Company Registered Address
                            </label>
                           
                          </div>
                        </div>
                      </Col>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Company Communication Address
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="principleCommunicationAddress"
                            className="form-control"
                            value={formData.principleCommunicationAddress}
                            onPaste={(e) =>
                              handelPasteEvent(
                                e,
                                "principleCommunicationAddress"
                              )
                            }
                            onKeyDown={(e) => {
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                          <div className="d-flex justify-content-end">
                            <span className="text-danger">
                              {`Remaining Characters ${
                                200 -
                                formData.principleCommunicationAddress.length
                              } / 200`}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Pincode
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            name="principleCommunicationPincode"
                            value={formData.principleCommunicationPincode}
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                            pattern="\d{6}"
                          />
                        </div>
                      </div>
                      {fetchedCommunicationAddPinCodes &&
                      fetchedCommunicationAddPinCodes.length > 0 ? (
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Please Select Your Locality
                              <span className="required">*</span>
                            </label>
                            <select
                              // id="businessType"
                              name="fetchedCommunicationAddPinCodeValues"
                              className="form-control"
                              value={
                                formData.fetchedCommunicationAddPinCodeValues
                              }
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                            >
                              <option value="">
                                Select
                                <span className="star">*</span>
                              </option>
                              {fetchedCommunicationAddPinCodes?.map(
                                (type, index) => (
                                  <option
                                    key={type.areaName}
                                    value={type.areaName}
                                  >
                                    {type.areaName}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Locality
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="principlCommunicationeDistrict"
                            value={formData.principlCommunicationeDistrict}
                            onPaste={(e) =>
                              handelPasteEvent(
                                e,
                                "principlCommunicationeDistrict"
                              )
                            }
                            onKeyDown={(e) => {
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            State
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="principleCommunicationState"
                            value={formData.principleCommunicationState}
                            onPaste={(e) =>
                              handelPasteEvent(e, "principleCommunicationState")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            City
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="principlCommunicationeCity"
                            id="companyForm2"
                            value={formData.principlCommunicationeCity}
                            onPaste={(e) =>
                              handelPasteEvent(e, "principlCommunicationeCity")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isCommunicationAddSameAsRegistered
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                        </div>
                      </div>

                      <Col xs={12} lg={12} md={12}>
                        <div className="form-group">
                          <div className="mb-4 d-flex align-items-center gap-2 ">
                            <input
                              type="checkbox"
                              id="registeredAddressToggle"
                              className="form-check-input "
                              name="isAuthorizedSignatoryAddSameAsComm"
                              checked={
                                formData.isAuthorizedSignatoryAddSameAsComm
                              }
                              onChange={handleInputChange}
                              onClick={handelSameAuthorityAddress}
                            />
                            <label
                              className="mt-2 fs-4 mb-1 text-dark"
                              htmlFor="registeredAddressToggle"
                            >
                              Same as Communication Address
                            </label>
                            {/* <span className="toggle-text">Same as Company Address</span> */}
                          </div>
                        </div>
                      </Col>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Authorized Signatory Address
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="authorizedSignatoryAddress"
                            className="form-control"
                            value={formData.authorizedSignatoryAddress}
                            onPaste={(e) =>
                              handelPasteEvent(e, "authorizedSignatoryAddress")
                            }
                            onKeyDown={(e) => {
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            onChange={
                              formData.isAuthorizedSignatoryAddSameAsComm
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                          <div className="d-flex justify-content-end">
                            <span className="text-danger">
                              {`Remaining Characters ${
                                200 - formData.authorizedSignatoryAddress.length
                              } / 200`}{" "}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Pincode
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                              setPressedKeyName(getKeyPressed(e));
                            }}
                            name="authorizedSignatoryPincode"
                            value={formData.authorizedSignatoryPincode}
                            onChange={
                              formData.isAuthorizedSignatoryAddSameAsComm
                                ? () => {}
                                : handleInputChange
                            }
                            // required={!formData.registeredAddressSame}
                            pattern="\d{6}"
                          />
                        </div>
                      </div>
                      {fetchedAuthorizedAddPinCodes &&
                      fetchedAuthorizedAddPinCodes.length > 0 ? (
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark">
                              Please Select Your Locality
                              <span className="required">*</span>
                            </label>

                            <select
                              // id="businessType"
                              name="fetchedAuthorizedAddPinCodeValues"
                              className="form-control"
                              value={formData.fetchedAuthorizedAddPinCodeValues}
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                            >
                              <option value="">
                                Select
                                <span className="star">*</span>
                              </option>
                              {fetchedAuthorizedAddPinCodes?.map(
                                (type, index) => (
                                  <option
                                    key={type.areaName}
                                    value={type.areaName}
                                  >
                                    {type.areaName}
                                  </option>
                                )
                              )}
                            </select>
                            {formErrors.companyState && (
                              <span className="error-message">
                                {formErrors.companyState}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Locality
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="authorizedSignatoryDistrict"
                            value={formData.authorizedSignatoryDistrict}
                            onPaste={(e) =>
                              handelPasteEvent(e, "authorizedSignatoryDistrict")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isAuthorizedSignatoryAddSameAsComm
                                ? () => {}
                                : handleInputChange
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            State
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="authorizedSignatoryState"
                            className="form-control"
                            value={formData.authorizedSignatoryState}
                            onPaste={(e) =>
                              handelPasteEvent(e, "authorizedSignatoryState")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isAuthorizedSignatoryAddSameAsComm
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            City
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="authorizedSignatoryCity"
                            id="companyForm2"
                            value={formData.authorizedSignatoryCity}
                            onPaste={(e) =>
                              handelPasteEvent(e, "authorizedSignatoryCity")
                            }
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onChange={
                              formData.isAuthorizedSignatoryAddSameAsComm
                                ? () => {}
                                : handleInputChange
                            }
                            required={!formData.registeredAddressSame}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h4 className="card-title">SERVICES OFFERED</h4>
                    </div>

                    <div className="mb-2">
                      <div className="mb-3">
                        <div className="row row-cols-4 row-cols-md-6 g-4 mt-4">
                          {paymentTypes?.map((paymentType) => (
                            <div
                              className="d-flex align-item-center gap-4 my-1"
                              key={paymentType.paymentTypeId}
                            >
                              <input
                                type="checkbox"
                                style={{ border: "1px solid grey" }}
                                name="typeOfServices"
                                className="form-check-input"
                                value={paymentType.paymentservice}
                                checked={formData.typeOfServices.includes(
                                  paymentType.paymentservice
                                )}
                                onChange={handleTypeOfServicesChange}
                              />
                              <label className="mb-1 text-dark">
                                {paymentType.paymentservice}
                              </label>
                            </div>
                          ))}
                        </div>
                        {formErrors.typeOfServices && (
                          <span className="error-message">
                            {formErrors.typeOfServices}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <div className="mb-4 d-flex align-items-center gap-2 ">
                          <input
                            type="checkbox"
                            style={{ border: "1px solid grey" }}
                            s
                            id="referralCodeToggle1"
                            className="form-check-input"
                            name="hasReferralCode"
                            checked={formData.hasReferralCode}
                            onChange={handleInputChange}
                          />
                          <label className="mt-2 fs-4">
                            Do you have a referral code ?
                            <span className="required"> *</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {formData.hasReferralCode && (
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark">
                            Referral Code
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="referralNo"
                            className="form-control"
                            value={formData.referralNo}
                            onChange={handleInputChange}
                            required={formData.hasReferralCode}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Col xs={12} lg={12} md={12}>
                  <div className="d-flex justify-content-end">
                    <div className="d-flex gap-2">
                      <button
                        className="submit_link_info btn btn-primary btn-sm"
                        onClick={handleBackBtn}
                      >
                        Back
                      </button>

                      <button
                        className="submit-button submit_link_info btn btn-primary btn-sm"
                        onClick={handelFormSubmit}
                        id="submitDetailsBtn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
